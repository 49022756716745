import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from "../pages/login/login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  canActivate() {
    const isLoggedIn = !!this.loginService.isAuthenticated()
    if (!isLoggedIn) {
      this.router.navigate(['login']);
    }

    // TODO::Implement user roles permission here
    return isLoggedIn
  }

}
