import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
@Input() title:string;
@Input() id:string;
@ViewChild(TemplateRef,{static:true}) content:TemplateRef<any>

  constructor() { }

  ngOnInit(): void {
}

}
