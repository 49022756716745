import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as moment from "moment";

@Injectable({ providedIn: "root" })
export class ReportsPublicationScheduleService {
    publicationsUrl: string;
    channelUrl: string;

    constructor(private http: HttpClient) {
        this.publicationsUrl = environment.apiUrl + "/publications";
    }

    publisherModelDefaults() {
        return {
            startDate: null,
            endDate: null,
            channels: [],
            userId: null,
            maxDate:null,
            minStartDate: moment(Date.now()).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
            minEndDate: moment(Date.now()).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
        };
    }

    searchChannels(channelName: string, params: PaginationParams) {
        const { page, size } = params
        return this.http.get<VLSResponse>(
            `${this.publicationsUrl}/referenceChannel/search?page=${page}&size=${size}&channelName=${channelName}`
        );
    }

    getSlaveChannels(channelId: string) {
        return this.http.get<VLSResponse>(
            `${this.publicationsUrl}/referenceChannel/${channelId}/slaves`
        );
    }

    publisher(data) {
        return this.http.post<VLSResponse>(
            `${this.publicationsUrl}/publisher`,
            data
        );
    }

    
    defaultPublishingSorting(){
        return [
            {name: 'Service Id', fieldName:'sourceId', isDisplay: true, isAsc: null},
            {name: 'Channel Name', fieldName:'channelNames', isDisplay: true, isAsc: null},
            {name: 'Created Date', fieldName:'createdDate', isDisplay: true, isAsc: false},
          ]
    }
}
