import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ProgramMappingService {
  programMappingUrl: string

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.programMappingUrl = `${apiUrl}/programMapping`
  }

  getProgramMapping(pagination, status = '') {
    return this.http.get<VLSResponse>(`${this.programMappingUrl}?page=${pagination.page}&size=${pagination.size}&status=${status}`)
  }

  getUnmapped(userId) {
    return this.http.put(`${this.programMappingUrl}/unmapped`, {userId})
  }

  createMapping(programMapping) {
    return this.http.post(this.programMappingUrl, programMapping)
  }

  searchProgramMappings(searchData, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.programMappingUrl}/search?page=${page}&size=${size}`
    return this.http.post<ProgramSearch>(searchUrl, searchData)
  }

  requestProviderInfo(programMappingId) {
    return this.http.put(`${this.programMappingUrl}/requestProviderInfo/${programMappingId}`, {})
  }

  submitMapping(programMappings = []) {
    return this.http.put(`${this.programMappingUrl}/mapAndPublish`, programMappings)
  }

  getListOfRolesForCast(){
    return this.http.get(`${this.programMappingUrl}/rolesOfCast`)
  }

  programMappingDefaults() {
    return {
      autoPublished: true,
      connectorId: '',
      episodeNumber: '',
      episodeTitles: [],
      externalRefs: [],
      programId: '',
      published: false,
      releaseYear: '',
      runTime: null,
      seasonNumber: '',
      status: 'unmapped',
      type: 'MOVIE',
      titles: [],
      descriptions: [],
      englishTitle: '',
      englishDescription: '',
      spanishTitle: '',
      spanishDescription: '',
      credits: [],
      ratings: [],
      genres: [],
      providerInfo: [
        {
          system: 'VLS',
          key: 'skipMetadataEnrichment',
          value: false
        }
      ],
      checked: true,
      origAirDate: '',
    }
  }

}
