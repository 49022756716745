import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab/tab.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [TabsComponent, TabComponent],
  imports: [
    CommonModule,NgbNavModule
  ],exports:[TabsComponent, TabComponent]
})
export class TabsModule { }
