import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { EcadService } from './ecad.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { AppConstants } from 'src/app/app.constants';
import * as moment from 'moment';
import * as $ from "jquery";
; @Component({
    selector: 'app-ecad',
    templateUrl: './ecad.component.html',
    styleUrls: ['./ecad.component.scss'],
})
export class EcadComponent implements OnInit {
    searchString: string;
    startDate: Date;
    selectAll: boolean;
    allReferenceChannels: any[];
    monthPeriod: number = 1;
    isLoading: boolean = false;
    readonly CONSTANTS = AppConstants;

    constructor(
        private ecadService: EcadService, private modalService: NgbModal
    ) {

    }

    onSearch() {
        this.ecadService.searchReferenceChannel(this.searchString).subscribe((response) => {
            this.allReferenceChannels = response.response.map(x => {
                return { id: x.id, name: x.channelNames[0].value, selected: false } as EcadChannel;
            });
        });
    }

    onGenerateReport() {
        if (!this.isValid()) {
            return;
        }

        if (this.allReferenceChannels.filter(x => x.selected).length == 0) {
            const popup = this.modalService.open(ConfirmationModalComponent, {
                size: 'lg',
                centered: true,
                backdrop: 'static',
            });
            popup.componentInstance.title = 'Warning'
            popup.componentInstance.message = `Please select at least one channel`;
            popup.componentInstance.isAlert = true;
            return;
        }
        const modal = this.modalService.open(ConfirmationModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
        })
        modal.componentInstance.title = 'Submit request'
        modal.componentInstance.message = `Do you want to generate report for selected channels?`
        modal.componentInstance.isAlert = false
        modal.result.then((result) => {
            if (result) {
                this.isLoading = true;
                this.ecadService.generateReport(this.allReferenceChannels, this.startDate, this.monthPeriod).subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        const s3Url = response.body['url'];
    
                        if (s3Url == null) {
                            this.showAlert(AppConstants.ERROR, `Failed to generate the report.`);
                            return;
                        }                    
                        const fileName = response.headers.get('content-disposition').split(';')[1];
                        let link = document.createElement('a');
                        link.href = s3Url;
                        link.download = fileName;
                        link.click();
                        this.showAlert(AppConstants.SUCCESS, `File ${fileName} is generated successfully.`);
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.showAlert(AppConstants.ERROR, `Failed to generate the report.`);
                        return;
                    }
                });
            }
        });
    }

    showAlert(title: string, message: string) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
          size: "md",
          centered: true,
          backdrop: "static",
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.isAlert = true;
    }

    ngOnInit() {
        this.ecadService.getAllServiceChannel().subscribe((response) => {
            this.allReferenceChannels = response.response.map(x => {
                return { id: x.id, name: x.channelNames[0].value, selected: false } as EcadChannel;
            });

        })
    }

    onSelectAllChange(event) {
        this.allReferenceChannels.forEach(x => x.selected = event.currentTarget.checked);
    }

    isValid() {
        const inValid = []

        var fieldsToValidate = [AppConstants.START_DATE, AppConstants.MONTH_PERIOD]

        fieldsToValidate.forEach((field) => {
            let message = ''
            let fieldToValidate = this[field]
            if (!fieldToValidate) {
                // validate mandatory fields
                message = 'Required field'
            } else if (field === AppConstants.START_DATE || field === AppConstants.MONTH_PERIOD) {
                const startDate = moment(this.startDate, "YYYY-MM").toDate();
                const endDate = moment(this.startDate, "YYYY-MM").add(this.monthPeriod, 'M').toDate();
                // validate startDate and endDate
                if (moment(startDate).isSameOrAfter(endDate)) {
                    message = field === AppConstants.START_DATE ? AppConstants.MESSAGE_INVALID_START_DATE : AppConstants.MESSAGE_INVALID_END_DATE
                }
            }

            if (message !== '') {
                const inputField = document.getElementById(field)
                if (inputField) {
                    if (inputField.nextSibling) {
                        inputField.parentNode.removeChild(inputField.nextSibling)
                    }
                    inValid.push(field)
                    inputField.insertAdjacentHTML(
                        'afterend',
                        '<div class="invalid-feedback">' + message + '</div>'
                    )
                    inputField.classList.add(AppConstants.INVALID_FIELD_INPUT)
                }
            }
        })
        return inValid.length === 0

    }

    onFieldChange(event) {
        const { id, value } = event.target
        if (value) {
            document.getElementById(id).classList.remove('is-invalid')
        }
    }

    chosenYearHandler(year) {
        this.startDate = year;
    }

    chosenMonthHandler(month, dt) {
        this.startDate = month;
        document.getElementById('startDate').classList.remove('is-invalid')
        $('#startDate').val(`${this.getMonthFormat(this.startDate.getMonth())}-${this.startDate.getFullYear()}`);

        dt.close();
    }

    onDatePickerOpen() {
        $(".owl-dt-control-period-button").prop('disabled', true);
    }

    onDatePickerClose() {
        $(".owl-dt-control-period-button").prop('disabled', false);
    }

    onStartDateChange(value) {
        if (!value) {
            this.startDate = null;
        }
    }

    getMonthFormat(month) {
        return `0${month + 1}`.slice(-2);
    }
}