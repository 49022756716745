import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ScheduleEvent} from 'src/app/pages/schedules/schedule';

@Component({
  selector: 'app-schedule-footer',
  templateUrl: './schedule-footer.component.html',
  styleUrls: ['./schedule-footer.component.scss'],
})
export class SchedulesFooterComponent implements OnInit {
  // Accessors
  @Input() isSchedulesLoading = false;
  @Input() selectedScheduleEvents: ScheduleEvent[] = []; // user check on check box to select schedules
  @Input() publishSchedules: Function;
  @Input() saveSchedules: Function;

  @Output() onManageQualifiers: EventEmitter<void> = new EventEmitter();
  @Output() onManageBlackout: EventEmitter<void> = new EventEmitter();
  @Output() onRemoveSelectedEvent: EventEmitter<void> = new EventEmitter();

  // Life cycle methods
  constructor() {
  }

  ngOnInit() {
  }

  onManageQualifiersClicked() {
    this.onManageQualifiers.emit();
  }

  onRemoveSelectedEventsClicked() {
    this.onRemoveSelectedEvent.emit();
  }

  onManageBlackoutClicked() {
    this.onManageBlackout.emit();
  }
}
