import { Component, OnInit } from '@angular/core'
import { VenuesService } from './venues.service'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../users/users.service'
import { AppService } from '../../app.service'
import { ActivatedRoute } from '@angular/router'
import { ConfirmationModalService } from "../../components/confirmation-modal/confirmation-modal.service"
import { AppConstants } from '../../app.constants'

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
})
export class VenuesComponent implements OnInit {
  venue: VenueSoccer
  oldVenue: string
  isSky: boolean
  newVenueId: string
  readonly CONSTANTS = AppConstants

  constructor(
    private venuesService: VenuesService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private confirmModalService: ConfirmationModalService
  ) { }

  ngOnInit() {
    this.isSky = location.href.includes('sky')
    this.venue = this.venuesService.venueDefaults()
    this.venue.imageSizes = this.venuesService.getRatio()
    this.oldVenue = JSON.stringify(this.venue)
  }

  onVenueIdChange(venueId) {
    this.venuesService.getDetailVenue(venueId).subscribe((data) => {
      if (data) {
        this.venue = data.response
        this.venue.externalId = this.getOptaVenueId()
        this.venue.englishName = this.venuesService.getValueByLang(this.venue.names, this.CONSTANTS.EN)
        this.venue.portugueseName = this.venuesService.getValueByLang(this.venue.names, this.CONSTANTS.PT)
        this.venue.spanishName = this.venuesService.getValueByLang(this.venue.names, this.CONSTANTS.ES)
        if (this.isSky) {
          this.venue.otherName = this.venuesService.getOtherName(this.venue.names, [this.CONSTANTS.EN, this.CONSTANTS.PT])
        } else {
          this.venue.otherName = this.venuesService.getOtherName(this.venue.names, [this.CONSTANTS.EN, this.CONSTANTS.ES])
        }
        this.venue.otherLanguage = this.venue.otherName?.lang !== '' ? this.venue.otherName?.lang : null
        this.venue.isEnTitleHide = this.venue.englishName?.value !== '' ? true : false
        this.venue.isPtEsTitleHide = this.venue.spanishName?.value !== '' || this.venue.portugueseName?.value !== '' ? true : false
        this.venue.isOtherTitleHide = this.venue.otherName?.value !== '' ? true : false
        this.venue.newAddress = this.venuesService.getName(this.venue.address, this.isSky)?.value || ''
        this.venue.newCity = this.venuesService.getName(this.venue.cities, this.isSky)?.value || ''
        this.venue.imageSizes = this.venuesService.getRatio()
        this.getImages()
      } else {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
          size: 'sm',
          centered: true,
          backdrop: 'static',
        })
        modalRef.componentInstance.title = 'Warning'
        modalRef.componentInstance.message = 'No data available.'
        modalRef.componentInstance.isAlert = true
      }
    })
  }

  getOptaVenueId() {
    const index = this.venue.externalRefs?.findIndex((item) => item.system === this.CONSTANTS.OPTA && item.refName === this.CONSTANTS.OPTA_VENUEID)
    if (index > -1) {
      return this.venue.externalRefs[index].id
    }
  }

  getImages() {
    let fetchedImages = []
    this.venue.imageSizes = this.venuesService.getRatio()
    this.venue.groupedImages = {}
    this.venue.publishedImages = []
    this.venuesService.getImages(this.venue.id).subscribe(data => {
      fetchedImages = (data?.response || []).filter(image => image.downloadState === 'DOWNLOADED')
      this.venue.groupedImages = this.groupBy(fetchedImages, 'ratio')
      const publishedImages = fetchedImages.filter(image => image.published)
      this.venue.imageSizes.forEach(size => {
        const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
        if (publishRatio) {
           this.venue.publishedImages.push(publishRatio)
        }
      })
      this.oldVenue = JSON.stringify(this.venue)
    })
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  onCreateNewVenue(isCreateNew) {
    if (isCreateNew) {
      this.venue = this.venuesService.venueDefaults()
      this.venue.imageSizes = this.venuesService.getRatio()
      this.oldVenue = JSON.stringify(this.venue)
    }
  }

  onNewVenueCreated(newVenueCreated) {
    this.newVenueId = newVenueCreated
  }
}
