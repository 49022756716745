import { Component, OnInit } from "@angular/core"
import { AppConstants } from "src/app/app.constants"

@Component({
  selector: "app-apg-report",
  templateUrl: "./apg-report.component.html",
  styleUrls: ["./apg-report.component.scss"],
})
export class APGReportComponent implements OnInit {
  searchString: string
  currentTab: string
  readonly CONSTANTS = AppConstants 

  constructor(
  ) { }

  ngOnInit(): void {
    this.currentTab = this.CONSTANTS.GO
    this.searchString = ""
  }
  
  onTabChange(event) {
    this.currentTab = event
    this.searchString = "" 
  }
}