import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs'
import { Md5 } from 'ts-md5/dist/md5'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'

export interface TokenResponse {
  token: string
  isValid: boolean
}

interface LoginResponse {
  user: User
  errorMessage: string
}

@Injectable({ providedIn: 'root' })
export class LoginService {
  userUrl = ''
  validateUrl = ''
  authToken = 'authToken'
  authUserId = 'authUserId'
  authUsername = 'authUsername'
  userRoles = 'userRoles'

  loginStatus = new BehaviorSubject(false)
  currentLoginStatus = this.loginStatus.asObservable()

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    const apiUrl = environment.apiUrl
    this.userUrl = `${apiUrl}/users`
    this.validateUrl = `${apiUrl}/users/validate`
  }

  changeLoginStatus(status: boolean) {
    this.loginStatus.next(status)
  }

  login(username: string, password: string) {
    const user: Login = {
      username: '',
      password: ''
    }
    user.username = username
    user.password = Md5.hashStr(password).toString()
    const mapResponse = (response: LoginResponse) => {
      const { user } = response
      if (user) {
        this.changeLoginStatus(true)
        const userRoles = user.roles as any | Role[]
        localStorage.setItem(this.authUserId, user.id)
        localStorage.setItem(this.authToken, user.accessToken)
        localStorage.setItem(this.authUsername, user.username)
        localStorage.setItem(
          this.userRoles,
          (userRoles || []).map(({ name }) => name).join(', ')
        )
      }
      return response
    }

    return this.http
      .post<LoginResponse>(`${this.userUrl}/login`, user)
      .pipe(map(mapResponse))
  }

  logout() {
    this.doLogOut().subscribe(() => {
      localStorage.clear()
      this.router.navigate(['/login'])
      this.changeLoginStatus(false)
    }, error => {
      // to be removed
      localStorage.clear()
      this.router.navigate(['/login'])
      this.changeLoginStatus(false)
    })
  }

  doLogOut(): Observable<any> {
    const authToken = localStorage.getItem('authToken')
    const logoutUrl = `${environment.apiUrl}/users/logout`
    return this.http.post<any>(logoutUrl, authToken)
  }

  validate(): Observable<TokenResponse> {
    const currentToken = localStorage.getItem(this.authToken)
    return this.http.post<TokenResponse>(this.validateUrl, currentToken)
  }

  getLoggedInUserName() {
    return localStorage.getItem(this.authUsername) || null
  }

  isAuthenticated() {
    return localStorage.getItem(this.authUsername) && localStorage.getItem(this.authToken)
  }
}
