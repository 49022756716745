import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-channel-transmit',
  templateUrl: './channel-transmit.component.html',
  styleUrls: ['./channel-transmit.component.scss'],
})
export class ChannelTransmitComponent implements OnInit {
  serviceChannel: ServiceChannel
  constructor() {}

  ngOnInit(): void {}

  onChannelChange(channel: ServiceChannel) {
    this.serviceChannel = channel
    // retrieve service channel information in BE
  }
}
