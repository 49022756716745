import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { CdkDragStart } from '@angular/cdk/drag-drop'
import { ActivatedRoute } from '@angular/router'
import { PlayersService } from '../players.service'
import { AppConstants } from '../../../app.constants'

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss'],
})
export class PlayerListComponent implements OnInit {
  @Output() playerId: EventEmitter<string> = new EventEmitter()
  @Output() isCreateNew: EventEmitter<any> = new EventEmitter()
  @Input() newPlayerId: string
  showLoader: boolean = false
  searchString: string
  searchChanged: Subject<string> = new Subject<string>()
  getParams: PaginationParams = {
    page: 0,
    size: 20,
  }
  teams: TeamPlayer[]
  selectedTeamId: string
  selectedPlayerId: string
  isSky: boolean
  totalPages: number
  readonly CONSTANTS = AppConstants

  constructor(
    private playersService: PlayersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.teams = []
    this.isSky = location.href.includes('sky')
    this.searchString = ''
    this.getAllTeams()
    this.debouncedSearch()
  }

  ngOnChanges(changes: SimpleChanges) {
    const fields = Object.keys(changes)
     if (fields.includes('newPlayerId') && this.newPlayerId) {
       this.searchString = this.newPlayerId
       this.selectedPlayerId = this.newPlayerId
       this.getParams.page = 0
       this.searchPlayers()
     }
  }

  onScroll(event: any) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= 5
      this.getParams.page++
      if (this.searchString === '') {
        this.getAllTeams()
      } else {
        this.searchPlayers()
      }
    }
  }

  getAllTeams() {
    this.showLoader = true
    this.getParams.page = this.getParams.page == 0 ? 1 : this.getParams.page;
    this.playersService.getAllTeams(this.getParams).subscribe((data : any) =>{
      if (data?.response) {
        if (this.getParams.page <= data?.totalPages ) {
          if (this.getParams.page === 1) {
            this.teams = data.response
          } else {
            const newTeams = data.response
            this.teams.push(...newTeams)
          }
        }
      } else {
        this.getParams.page--
        if (data?.totalPages === 0) {
          this.teams = []
        }
      }
      this.showLoader = false
    })
  }

  searchPlayers() {
    if (this.searchString.length == 14 && this.searchString.startsWith(this.CONSTANTS.PLAYER_ID_PREFIX)) {
      this.showLoader = true
      this.playersService.getPlayerById(this.searchString).subscribe((data : any) =>{
        if (data?.response) {
          this.teams = data.response
        }
        this.showLoader = false
      })
    } else {
      this.showLoader = true
      if (this.getParams.page === 0 || this.getParams.page < this.totalPages) {
        this.playersService.getPlayersByName(this.searchString, this.getParams).subscribe((data : any) =>{
          if (data?.response) {
            this.totalPages = data?.totalPages
            if (this.totalPages === 0) {
              this.teams = []
            } else {
              if (this.getParams.page === 0) {
                this.teams = data.response
              } else {
                const newTeams = data.response
                this.teams.push(...newTeams)
              }
            }
          }
          this.showLoader = false
        })
      } else {
        this.getParams.page--
        this.showLoader = false
      }   
    }
  }

  showTeamPlayers(team: TeamPlayer) {
    this.showLoader = true
    this.selectedTeamId = team.id
    if (team.personDTOs) {
      team.oldPersonDTOs = team.personDTOs
      team.personDTOs = undefined
      this.showLoader = false
    } else {
      if (team.oldPersonDTOs) {
        team.personDTOs = team.oldPersonDTOs
        this.showLoader = false
      } else {
        team.personDTOs = []
        if (team.id) {
          this.playersService
            .getAllTeamPlayers(team.id)
            .subscribe((data: any) => {
              if (data && data.response) {
                team.personDTOs = data.response
                this.showLoader = false
              }
            })
        }
      }
    }
  }

  getTeamName(names: TeamPlayerName[]) {
    if (names?.length > 0) {
      const localLang = this.isSky ? this.CONSTANTS.PT : this.CONSTANTS.ES
      const indexLocal = names.findIndex((item) => item.lang.toLowerCase().includes(localLang) && item.current === true)
      if (indexLocal > -1) {
        return names[indexLocal]?.value
      } else {
        const index = names.findIndex((item) => item.lang.toLowerCase().includes(this.CONSTANTS.EN) && item.current === true)
        if (index > -1) {
          return names[index]?.value
          } else {
          return names[0]?.value
        }
      }
    }
  }

  getPlayerName(names: PlayerName[]) {
    const firstName = this.playersService.getName(names, this.isSky)?.firstName || ''
    const lastName = this.playersService.getName(names, this.isSky)?.lastName || ''
    
    return firstName + " " + lastName
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        if (!!value) {
          this.getParams.page = 0
        }
        if (this.searchString === '') {
          this.getAllTeams()
        } else {
          this.searchPlayers()
        }
      })
  }

  searchKeyup(event: any, container: any) {
    this.searchChanged.next(event.target.value)
    container.scrollTop = 0
  }

  searchChange(event: any, container: any) {
    this.getParams.page = 0
    container.scrollTop = 0
    if (!event) {
      if (this.searchString === '') {
        this.getAllTeams()
      } else {
        this.searchPlayers()
      }
    }
  }

  getStatusLabel(player: Player) {
    if(player.sportsInfo && player.sportsInfo.length > 0){
      this.selectedTeamId = player.sportsInfo[0]?.teamId
    }
    const type = this.getPlayerStatus(player)
    let playerStatus: {
      code: string,
      style: string
    } = {
      code: '',
      style: ''
    }
    if (type?.toLowerCase() === this.CONSTANTS.STATUS[0].toLowerCase()) {
      playerStatus = {
        code: this.CONSTANTS.STATUS[0],
        style: 'success'
      }
    }
    if (type?.toLowerCase() === this.CONSTANTS.STATUS[1].toLowerCase()) {
      playerStatus = {
        code: this.CONSTANTS.STATUS[1],
        style: 'warning'
      }
    } else if (type?.toLowerCase() === this.CONSTANTS.STATUS[2].toLowerCase()) {
      playerStatus = {
        code: this.CONSTANTS.STATUS[2],
        style: 'danger'
      }
    }
    return playerStatus
  }

  getPlayerStatus(player: Player) {
    if (player?.sportsInfo?.length > 0) {
      const index = player?.sportsInfo.findIndex((item) => item.teamId === this.selectedTeamId)
      if (index > -1) {
        return player?.sportsInfo[index].status
      }
    }
  }

  viewPlayer(id: string) {
    this.selectedPlayerId = id
    this.playerId.emit(id)
  }

  createNewPlayer() {
    this.isCreateNew.emit(true)
  }
}
