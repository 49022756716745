import {Component, OnInit} from '@angular/core'
import {TranslateService} from '@ngx-translate/core'
import {LoginService} from "../../pages/login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {PlatformService} from 'src/app/services/platform.service';
import {AppConstants} from 'src/app/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false
  headerTitle: string
  username: string
  currentLanguage: string

  constructor(
    public translate: TranslateService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private platformService: PlatformService
  ) {
    translate.addLangs(['en', 'es'])
    translate.setDefaultLang('en')
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.username = params.username || this.loginService.getLoggedInUserName();
    });
    this.loginService.currentLoginStatus.subscribe(status => {
      this.isLoggedIn = !!this.loginService.isAuthenticated() || status;
    });
  }

  logout() {
    this.loginService.logout()
  }

  showAlert(title, message, isAlert) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = title
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = isAlert
  }

  isAccessDeniedToScreen() {
    const isDTH = this.isDthPlatform()
    if (isDTH === true) {
      this.showAlert(
        AppConstants.WARNING,
        AppConstants.ACCESS_DENIED_MESSAGE,
        true)
    } else {
      this.router.navigateByUrl('/program-mapping')
    }
  }

  isDthPlatform() {
    return this.platformService.verifyPlatform(AppConstants.DTH)
  }

  isSSLA() {
    return location.href.includes('ssla')
  }
}
