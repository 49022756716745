import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../../environments/environment'
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class EcadService {
    channelUrl: string;

    constructor(private http: HttpClient) {
        const apiUrl = environment.apiUrl
        this.channelUrl = `${apiUrl}/channels`
    }

    getAllServiceChannel() {
        return this.http.get<any>(`${this.channelUrl}/get-all`);
    }

    searchReferenceChannel(searchString) {
        return this.http.get<any>(`${this.channelUrl}/search?name=${searchString}`);
    }

    generateReport(referenceChannels, startDate: Date, monthPeriod) {
        const startDateMoment = moment(`${startDate.getMonth() + 1}-1-${startDate.getFullYear()}`, "MM-DD-YYYY");
        const endDateMoment = moment(`${startDate.getMonth() + 1}-1-${startDate.getFullYear()}`, "MM-DD-YYYY").add(monthPeriod, 'M');
        const body = {
            ids: referenceChannels.filter(x => x.selected).map(x => x.id),
            startDate: startDateMoment.utcOffset(0, true).toDate(),
            endDate: endDateMoment.utcOffset(0, true).toDate()
        }
        return this.http.post(`${this.channelUrl}/report`, body, { observe: 'response', responseType: 'json' });
    }
}
