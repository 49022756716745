import {
  Component,
  EventEmitter,
  Input, OnChanges, OnInit,
  Output, SimpleChanges, ViewChild
} from '@angular/core'
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service'
import { AppConstants } from '../../../app.constants'
import { ProgramsService } from '../../programs/programs.service'
import { VenuesService } from '../venues.service'

@Component({
  selector: 'app-venue-form',
  templateUrl: './venue-form.component.html',
  styleUrls: ['./venue-form.component.scss'],
})
export class VenueFormComponent implements OnInit, OnChanges {
  @Output() venueId: EventEmitter<string> = new EventEmitter()
  @Output() newVenueCreated: EventEmitter<string> = new EventEmitter()
  @Input() venue: VenueSoccer
  @Input() oldVenue: string

  @ViewChild('packageSearch', { static: true }) packageSearch: NgbTypeahead
  packageClick$ = new Subject<string>()
  packageFocus$ = new Subject<string>()

  countryCodes: Country[]
  imageCategories: string[]
  image: ProgramImage
  isLoading: boolean
  active: number
  activeCast: number
  navImage: number
  previewUrl: string
  selectedImageCategory = 'Iconic'
  isSky: boolean
  externalRef: externalRefs
  systems: any[]
  refNames : string[]
  readonly CONSTANTS = AppConstants

  constructor(
    private venuesService: VenuesService,
    private modalService: NgbModal,
    private programService: ProgramsService,
    private confirmModalService : ConfirmationModalService
  ) {}

  ngOnInit() {
    this.isSky = location.href.includes('sky')
    this.countryCodes = this.venuesService.getCountries()
    this.imageCategories = this.venuesService.getImageCategories()
    this.image = this.venuesService.imageDefaults() as any
    this.previewUrl = 'assets/images/noImage.jpg'
    this.externalRef = this.programService.externalRefsDefault()
    this.getSystems()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  resetDefaults() {
    /* Reset form validation */
    const invalidInputs = document.querySelectorAll('.is-invalid')
    invalidInputs.forEach((input) => input.classList.remove('is-invalid'))

    /* Reset tab default active */
    this.active = 1
    this.navImage = 1
    this.resetImage()
  }

  countryCodeOnChange() {
    if (this.venue.countryCode != null) {
      this.venue.countryCode = this.venue.countryCode.code
    }
  }

  onFieldChange(event: any, field = this.CONSTANTS.EN, main = false) {
    const { id, value } = event.target
    if (value) {
      document.getElementById(id).classList.remove('is-invalid')
    }
    let lang = this.CONSTANTS.INSENSITIVE_EN
    

    if (main) {
      lang = this.isSky ? this.CONSTANTS.INSENSITIVE_PT : this.CONSTANTS.INSENSITIVE_ES
    }

    if (field !== this.CONSTANTS.EN) {
      const partnerId = lang.concat(field)
      if(document.getElementById(partnerId)) {
        document.getElementById(partnerId).classList.remove('is-invalid')
      }

      if(document.getElementById("otherName")) {
        document.getElementById("otherName").classList.remove('is-invalid')
      }

    }
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.image.imageFile = event.target.files[0]
      document.getElementById('inputLabel').innerText = this.image.imageFile.name
      let fr = new FileReader()
      fr.onload = () => {
        const img = new Image()
        img.onload = () => {
          this.image.height = img.height.toString()
          this.image.width = img.width

          if (this.venue?.imageSizes !== undefined) {
            this.image.ratio = this.venue.imageSizes[this.navImage - 1]
          } else {
            let gcd = this.gcd(this.image.width, this.image.height)
            let left = + this.image.height / gcd;
            this.image.ratio = this.image.width / gcd + ':' + left

            this.venue.images.push(this.image)
            const grouped = this.groupBy(this.venue.images, 'ratio');
            this.venue.imageSizes = Object.keys(grouped)
            this.venue.groupedImages = grouped
          }
        }

        img.src = fr.result.toString() // This is the data URL
        this.image.imageURL = fr.result.toString()
      }
      fr.readAsDataURL(event.target.files[0])
    }
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  gcd(a, b) {
    if (b == 0) return a
    return this.gcd(b, a % b)
  }

  addImage(image: ProgramImage) {
    if (!this.venue.images) {
      this.venue.images = []
    }

    image.published = false
    this.venue.images.push(image)
    if (this.venue.groupedImages[image.ratio]) {
      this.venue.groupedImages[image.ratio].unshift(image)
    } else {
      this.venue.groupedImages = {
        ...this.venue.groupedImages,
        [image.ratio]: [image]
      }
    }
    this.resetImage()
  }

  resetImage() {
    this.image = this.venuesService.imageDefaults() as any
    if (document.getElementById('inputLabel')) {
      document.getElementById('inputLabel').innerText = ''
    }
  }

  setImageCategory(category: string) {
    this.selectedImageCategory = category
    this.image.category = category
  }

  publishedImage(image: ProgramImage) {
    image.published = true

    if (!this.venue.publishedImages) {
      this.venue.publishedImages = [];
    }

    if (!this.venue.images) {
      this.venue.images = [];
    }

    const currentPublishedIndex = this.venue.publishedImages.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    currentPublishedIndex >= 0
      ? (this.venue.publishedImages[currentPublishedIndex] = image)
      : this.venue.publishedImages.push(image)
    this.venue.groupedImages[image.ratio] = (
      this.venue.groupedImages[image.ratio] as any
    ).map((each) => {
      return {
        ...each,
        published: this.isPublishedImage(each,  image)
      }
    })

    if (image.id) {
      const alreadyPub = this.venue.images?.find(({ ratio, id }) => ratio === image.ratio && id !== null)
      if (alreadyPub) {
        // VLS-2236: make sure new image always saved to db
        this.venue.images = this.venue.images?.filter(({ ratio, id }) => ratio !== image.ratio || id === "")
      }
      this.venue.images.push(image)
    }
  }

  isPublishedImage(storedImage, selectedImage) {
    const storedImageName = storedImage.imageFile?.name ? storedImage.imageFile?.name : storedImage.id;
    const selectedImagename = selectedImage.imageFile?.name ? selectedImage.imageFile?.name : selectedImage.id;

    return storedImage.id == selectedImage.id && storedImageName == selectedImagename;
  }

  onImageError(event) {
    event.target.src = this.previewUrl;
  }

  deleteImage(image, index) {
    // remove from image list
    this.venue.groupedImages[image.ratio].splice(index, 1);

    const i = this.venue.images.findIndex(img => {
      return img.imageFile && img.imageFile.name == image.imageFile.name
    })
    // remove from to save images
    this.venue.images.splice(i, 1);

    if (!this.venue.publishedImages) {
      this.venue.publishedImages = [];
    }
    const currentPublishedIndex = this.venue.publishedImages.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    const pubImg = this.venue.publishedImages[currentPublishedIndex]?.imageFile;
    if(pubImg && pubImg.name == image.imageFile.name) {
      //remove from published image
      this.venue.publishedImages.splice(currentPublishedIndex, 1);
    }

  }

  saveVenue() {
    if (JSON.stringify(this.venue) === this.oldVenue) {
      this.showAlert('Warning', `No changes`)
    } else {
      if (this.isValid()) {
        // build venue name
        this.buildVenueName()

        // build external id if available
        if (this.venue.externalId !== undefined && this.venue.externalId !== '') {
          const index = this.venue.externalRefs?.findIndex(
            (item) =>
              item.system === this.CONSTANTS.OPTA &&
              item.refName === this.CONSTANTS.OPTA_VENUEID
          )
          if (index < 0 || index === undefined) {
            this.venue.externalRefs = this.venue.externalRefs || []
            // add new
            this.venue.externalRefs?.push({
              system: this.CONSTANTS.OPTA,
              refName: this.CONSTANTS.OPTA_VENUEID,
              id: this.venue.externalId,
            })
          } else {
            // update new opta venue id
            this.venue.externalRefs[index].id = this.venue.externalId
          }
        } else {
          const index = this.venue.externalRefs?.findIndex(
            (item) =>
              item.system === this.CONSTANTS.OPTA &&
              item.refName === this.CONSTANTS.OPTA_VENUEID
          )
          if (index > -1) {
            this.venue.externalRefs?.splice(index, 1)
          }
        }

        // build venue city if available - use english language as default
        const langCity = this.venuesService.getName(this.venue.cities, this.isSky)?.lang || this.CONSTANTS.EN
        if (this.venue.newCity !== undefined && this.venue.newCity !== '') {
          const index = this.venue.cities?.findIndex(
            (item) => item.lang === langCity
          )
          if (index < 0 || index === undefined) {
            this.venue.cities = this.venue.cities || []
            // add new
            this.venue.cities?.push({
              value: this.venue.newCity,
              lang: langCity,
            })
          } else {
            // update new venue city
            this.venue.cities[index].value = this.venue.newCity
          }
        } else {
          const index = this.venue.cities?.findIndex(
            (item) => item.lang === langCity
          )
          if (index > -1) {
            this.venue.cities?.splice(index, 1)
          }
        }

        // build venue address if available - use english language as default
        const langAddress = this.venuesService.getName(this.venue.address, this.isSky)?.lang || this.CONSTANTS.EN
        if (this.venue.newAddress !== undefined && this.venue.newAddress !== '') {
          const index = this.venue.address?.findIndex(
            (item) => item.lang === langAddress
          )
          if (index < 0 || index === undefined) {
            this.venue.address = this.venue.address || []
            // add new
            this.venue.address?.push({
              value: this.venue.newAddress,
              lang: langAddress,
            })
          } else {
            // update new venue address
            this.venue.address[index].value = this.venue.newAddress
          }
        } else {
          const index = this.venue.address?.findIndex(
            (item) => item.lang === langAddress
          )
          if (index > -1) {
            this.venue.address?.splice(index, 1)
          }
        }

        if (!this.venue.id) {
          this.venue.id = null
        }

        this.venue.longitude = !this.venue.longitude || this.venue.longitude === '' ? null : this.venue.longitude
        this.venue.latitude = !this.venue.latitude || this.venue.latitude === '' ? null : this.venue.latitude
        this.venue.timeZone = !this.venue.timeZone || this.venue.timeZone === '' ? null : this.venue.timeZone
        this.venue.capacity = !this.venue.capacity || this.venue.capacity === '' ? null : this.venue.capacity
        this.venue.countryCode = !this.venue.countryCode || this.venue.countryCode === '' ? null : this.venue.countryCode

        const modal = this.modalService.open(ConfirmationModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
        })
        modal.componentInstance.title = this.CONSTANTS.SUBMIT_CHANGES
        modal.componentInstance.message = this.CONSTANTS.MESSAGE_CONFIRM
        modal.componentInstance.isAlert = false
        modal.result.then((result) => {
          if (result) {
            this.isLoading = true
            this.venuesService.save(this.venue).subscribe(
              (data) => {
                this.showAlert('Success', `Successfully save venue`)
                if (!this.venue.id) {
                  this.newVenueCreated.emit(data?.response.id)
                }
                this.venue.id = data?.response.id
                this.venueId.emit(data?.response.id)
                this.isLoading = false
              },
              (error) => {
                this.showAlert('Error', error.error.errors[0])
              }
            )
          }
        })
      } else {
        // scroll top to see errors
        document.getElementById('venueForm').scrollTo(0, 0);
      }
    }
  }

  buildVenueName() {
    if (
      this.venue.englishName?.value !== undefined &&
      this.venue.englishName?.value !== ''
    ) {
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.EN
      )
      if (index < 0) {
        // add new
        this.venue.names?.push({
          value: this.venue.englishName.value,
          lang: this.CONSTANTS.EN,
        })
      } else {
        // update new venue address
        this.venue.names[index].value = this.venue.englishName.value
      }
    } else {
      // remove name when value is unavailable
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.EN
      )
      if (index > -1) {
        this.venue.names?.splice(index, 1)
      }
    }

    if (
      this.venue.spanishName?.value !== undefined &&
      this.venue.spanishName?.value !== ''
    ) {
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.ES
      )
      if (index < 0) {
        // add new
        this.venue.names?.push({
          value: this.venue.spanishName.value,
          lang: this.CONSTANTS.ES,
        })
      } else {
        // update new venue address
        this.venue.names[index].value = this.venue.spanishName.value
      }
    } else {
      // remove name when value is unavailable
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.ES
      )
      if (index > -1) {
        this.venue.names?.splice(index, 1)
      }
    }

    if (
      this.venue.portugueseName?.value !== undefined &&
      this.venue.portugueseName?.value !== ''
    ) {
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.PT
      )
      if (index < 0) {
        // add new
        this.venue.names?.push({
          value: this.venue.portugueseName.value,
          lang: this.CONSTANTS.PT,
        })
      } else {
        // update new venue address
        this.venue.names[index].value = this.venue.portugueseName.value
      }
    } else {
      // remove name when value is unavailable
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.CONSTANTS.PT
      )
      if (index > -1) {
        this.venue.names?.splice(index, 1)
      }
    }

    if (
      this.venue.otherName?.value !== undefined &&
      this.venue.otherName?.value !== ''
    ) {
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.venue.otherName.lang
      )
      this.venue.names[index].value = this.venue.otherName.value
    } else {
      // remove name when value is unavailable
      const index = this.venue.names?.findIndex(
        (item) => item.lang === this.venue.otherName.lang
      )
      if (index > -1) {
        this.venue.names?.splice(index, 1)
      }
    }
  }

  isValid() {
    const inValid = []
    const fieldsToValidate = []

    if (!this.isSky) {
      if (
        (this.venue.englishName?.value === undefined ||
        this.venue.englishName?.value === '') &&
        (this.venue.spanishName?.value === undefined ||
        this.venue.spanishName?.value === '')
      ) {
        fieldsToValidate.push('englishName')
        fieldsToValidate.push('spanishName')
      }
    } else {
      if (
        (this.venue.englishName?.value === undefined ||
        this.venue.englishName?.value === '') &&
        (this.venue.portugueseName?.value === undefined ||
        this.venue.portugueseName?.value === '')
      ) {
        fieldsToValidate.push('englishName')
        fieldsToValidate.push('portugueseName')
      }
    }

    if (this.venue.otherLanguage && this.venue.otherName?.value === '') {
      fieldsToValidate.push('otherName')
    }

    fieldsToValidate.forEach((field) => {
      let fieldToValidate = this.venue[field]
      if (typeof this.venue[field] === 'object') {
        fieldToValidate = this.venue[field]?.value
      }
      if (fieldToValidate === undefined || fieldToValidate === '') {
        const inputField = document.getElementById(field)
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling)
          }
          inValid.push(field)
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">Required field</div>'
          )
          inputField.classList.add('is-invalid')
        }
      }
    })
    return inValid.length === 0
  }

  showAlert(type: string, message: string) {
    this.isLoading = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  formatHeaderTitle(english, other) {
    const englishName = this.venue[english]
    const otherName = this.venue[other]
    return (
      (englishName && englishName.value) ||
      (otherName && otherName.value)
    )
  }

  showHideInformation(field: string) {
    switch(field) {
      case 'isEnTitleHide':
        this.venue.isEnTitleHide = !this.venue.isEnTitleHide;
        break;
      case 'isPtEsTitleHide':
        this.venue.isPtEsTitleHide = !this.venue.isPtEsTitleHide;
        break;
      case 'isOtherTitleHide':
        this.venue.isOtherTitleHide = !this.venue.isOtherTitleHide;
        break;
    }
  }

  cancel() {
    this.venue = JSON.parse(this.oldVenue)
    this.resetDefaults()
  }

  openModal(modalName, entity = null, field = null, filter = null, id = null) {
    if (field) {  
        this[entity] = Object.assign(
          {},
          this.venue[field].find((data, index) => index === id)
        )
    }

    if (entity === 'externalRef') {
      this.externalRef.index = id
    }

    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  closeModal(){
    this.modalService.dismissAll()
    this.externalRef = this.programService.externalRefsDefault()
  }

  openDeleteModal(field, filter, id) {
    const modal = this.confirmModalService.createModal({
      title: 'Confirm removal',
      message: `Are you sure you want to remove this?`,
      isAlert: false,
      isConfirm: true,
    })
    modal.result.then((res) => {
      if (res) {
        this.venue[field] = this.venue[field].filter(
          (data, index) => index !== id
        )
      }
    })
  }

  onSystemChange(event) {
    this.refNames = this.systems.find((system) => {
      return system.system === event.target.value
    }).refName
    if(this.refNames){
      this.externalRef.refName = this.refNames[0]
    }
  }

  getSystems() {
    this.systems = this.programService.getSystems('venue') as any
  }

  addItem(fieldName, entity = null) {
    if (!this.venue[fieldName]) {
      this.venue[fieldName] = []
    }
    entity?.index != null
    ? (this.venue[fieldName][entity.index] = entity)
    : this.venue[fieldName].unshift(entity)
      
      this.closeModal()
    
  }
}
