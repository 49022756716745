import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class ScheduleReportService {
  scheduleApiUrlV2: string
  qualifiersUrl: string
  reportChannelUrl: string

  constructor(private http: HttpClient) {
    this.scheduleApiUrlV2 = environment.apiUrl + '/v2/schedules';
    this.qualifiersUrl = environment.apiUrl + '/qualifiers';
    this.reportChannelUrl = environment.apiUrl + '/channels/report';
  }

  defaultScheduleReportSorted() {
    return [
      {name: 'Date', fieldName: 'scheduleDate', isDisplay: true, isAsc: false},
      {name: 'ChannelID', fieldName: 'channelId', isDisplay: true, isAsc: null},
      {name: 'Source ChannelID', fieldName: 'sourceId', isDisplay: true, isAsc: null}
    ]
  }

  searchUncompletedChannel(searchData) {
    return this.http.post<VLSResponse>(
      `${this.scheduleApiUrlV2}/report`, (searchData)
    );
  }

  getAvailableFields() {
    return this.http.get<AvailableFieldsResponse>(
      `${this.scheduleApiUrlV2}/report/availableFields`
    );
  }

  getQualifiers() {
    return this.http.get<QualifiersResponse>(this.qualifiersUrl)
  }

  generateReport(scheduleReportDTO : ExportScheduleReportRequestDto) {
    return this.http.post(`${this.scheduleApiUrlV2}/scheduleReport`, scheduleReportDTO, { observe: 'response', responseType: 'json' });
  }

  getReportChannels(search: string): Observable<ChannelsReportResponse[]> {
    return this.http.get<ChannelsReportResponse[]>(`${this.reportChannelUrl}/search?search=${search}`);
  }
}
