import { Component, OnInit } from '@angular/core'
import { CalendarService } from 'src/app/services/calendar.service'

@Component({
  selector: 'app-transmit-history-panel',
  templateUrl: './transmit-history-panel.component.html',
  styleUrls: ['./transmit-history-panel.component.scss'],
})
export class TransmitHistoryPanelComponent implements OnInit {
  startDate: string
  endDate: string
  constructor(private calendarService: CalendarService) {}

  ngOnInit(): void {}

  onCalendarOpen() {
    this.calendarService.calendarOpen()
  }

  searchHistory() {}
}
