import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-schedule-error-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{headerTitle || 'Gap/Overlapped Schedules'}}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="errorMessages.length">
        <div class="row" *ngFor="let message of errorMessages">
          <div class="col">
            <label>&nbsp;{{ message }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="closeModal()">
        Cancel
      </button>
    </div>
  `,
})
export class SchedulesErrorModalComponent {
  @Input() errorMessages
  @Input() headerTitle

  constructor(private activeModal: NgbActiveModal) {
    this.errorMessages = []
  }

  closeModal() {
    this.errorMessages = []
    this.activeModal.close('closeModal')
  }
}
