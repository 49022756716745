import { Component, Directive, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ReportsPublicationScheduleService } from "./reports-publication-schedule.service";
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { NgbModal} from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationModalService } from '../../components/confirmation-modal/confirmation-modal.service'
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from 'moment'
import { AppConstants } from "src/app/app.constants";
import { DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE, formatDateToString } from "src/app/utils/contants";

@Component({
  selector: "app-reports-publication-schedule",
  templateUrl: "./reports-publication-schedule.component.html",
  styleUrls: ["./reports-publication-schedule.component.scss"],
})

export class ReportsPublicationScheduleComponent implements OnInit, OnChanges {

  channels: Channel[];
  channelsWithSlaves: Channel[];
  publisherModel: PublisherModel;

  searchString: string;
  searchChanged: Subject<string> = new Subject<string>()
  showLoader: boolean = false
  sortByPublishingHeader : any[]
  page = 1
  pageSize = 20
  collectionSize = 0
  isPublishing = false

  constructor(
    private reportsPublicationScheduleService: ReportsPublicationScheduleService,
    private modalService: NgbModal,
    private confirmModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    this.searchString = "";
    this.publisherModel =
      this.reportsPublicationScheduleService.publisherModelDefaults();
    this.triggerSearchChannels();
    this.debouncedSearch();
    this.sortByPublishingHeader = this.reportsPublicationScheduleService.defaultPublishingSorting()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  resetDefaults() {
    this.searchString = ''
    this.publisherModel =
    this.reportsPublicationScheduleService.publisherModelDefaults();
    this.triggerSearchChannels()
    this.channels.forEach((ele) => ele.checked = false)
    this.channelsWithSlaves = JSON.parse(JSON.stringify(this.channels))
  }

  triggerSearchChannels() {
    this.showLoader = true;
    this.publisherModel.channels = []
    const params: PaginationParams = {
      page: this.page - 1,
      size: this.pageSize
    }
    const encodeSearchString = this.fixedEncodeURI(this.searchString)
    this.reportsPublicationScheduleService.searchChannels(encodeSearchString, params).subscribe(
      (data: any) => {
        if (data && data.response) {
          this.channels = data.response;
          this.collectionSize = data.totalElements
          this.showLoader = false;
          for (let i = 0; i < this.channels.length; i++) {
            this.channels[i].createdDate = formatDateToString(this.channels[i]?.createdDate, DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE)
            this.channels[i].checked = false
          }
        } else {
          this.channels = [];
        }
        this.channelsWithSlaves = JSON.parse(JSON.stringify(this.channels))
      }
    );
  }

  updateListChannels(channel, index) {
    channel.checked = !channel.checked
    if (channel.checked == true) {
      if (channel.dthInfo?.isSlave == false) {
        this.showLoader = true
        this.reportsPublicationScheduleService.getSlaveChannels(channel._id).subscribe(
          (data: any) => {
            if (data && data.response) {
              channel.slaves = data.response
              this.showLoader = false
              for (let i = 0; i < channel.slaves.length; i++) {
                channel.slaves[i].createdDate = formatDateToString(channel.slaves[i]?.createdDate, DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE)
                channel.slaves[i].checked = channel.checked
                channel.slaves[i].displayAsSlave = true
              }
              this.channelsWithSlaves.splice(index + 1, 0, ...channel.slaves)
            }
          })
      } 
    } else {
      if (channel.dthInfo?.isSlave == false) {
        channel.slaves?.forEach((slave) => slave.checked = false)
        this.channelsWithSlaves.splice(index + 1, channel.slaves?.length)
      }
    }
  }

  publisher() {
    this.publisherModel.userId = localStorage.getItem('authUserId')

    // VLS-4496: only get the checked channel and remove the duplicates
    let channelIds = []
    this.publisherModel.channels = this.channelsWithSlaves.filter((channel) => {
      if (channel.checked == true && !channelIds.includes(channel._id)) {
        channelIds.push(channel._id)
        return channel
      }
    })

    const publisherModel = JSON.parse(JSON.stringify(this.publisherModel))
    publisherModel.startDate = moment(this.publisherModel.startDate).utcOffset(0, true).toISOString()
    publisherModel.endDate = moment(this.publisherModel.endDate).utcOffset(0, true).toISOString()
    
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    modal.componentInstance.title = AppConstants.CONFIRMATION_CHANGES
    modal.componentInstance.message = AppConstants.MESSAGE_CONFIRM
    modal.componentInstance.isAlert = false
    modal.result.then((result) => {
      if (result) {
        this.showLoader = true;
        this.isPublishing = true
        this.reportsPublicationScheduleService
        .publisher(publisherModel)
        .subscribe(
          (data: any) => {
            if (data && data.response === "OK") {
              this.showAlert("Success", `File is processed successfully.`);
              this.resetDefaults()

            } else if (data && data.response){
              this.showAlert("Error", data.response);
            }
            this.showLoader = false;
            this.isPublishing = false
          },
          (error) => {
            this.showLoader = false;
            this.isPublishing = false
            if(error.status === 500){
              this.showAlert("Error", `${error.error.error}`);
            }
            else{
              this.showAlert("Error", `${error.error.errors[0]}`);
            }
          }
        );
      }
    })
  }

  showAlert(type, message) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modalRef.componentInstance.title = type;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isAlert = true;
  }

  searchKeyup(event) {
    this.searchChanged.next(event.target.value)
  }

  searchChange(val) {
    this.sortByPublishingHeader = this.reportsPublicationScheduleService.defaultPublishingSorting()
    this.page = 1
    this.triggerSearchChannels()
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = encodeURIComponent(value).trim()
        this.triggerSearchChannels()
      })
  }

  greaterDateThan(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.publisherModel.startDate >=  this.publisherModel.endDate) {
        return { greaterThan: true };
      }
      return null;
      alert("End date cannot greater than start date!");
    };
  }

  onStartDateChange(): String{
    if (this.publisherModel.startDate !== null){
      this.publisherModel.minEndDate = this.publisherModel.startDate
      return this.publisherModel.maxDate = moment(new Date(this.publisherModel.startDate)).add(13,"days").toISOString()
    } else {
      return this.publisherModel.maxDate = ""
    }
  }

  onEndDateChange(): String{
    const currentDate = moment(Date.now()).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
    const minDate = moment(new Date(this.publisherModel.endDate)).subtract(13,"days")
    if (this.publisherModel.endDate !== null){
      if(currentDate > minDate){
        return this.publisherModel.minStartDate = currentDate.toISOString()
      }else{
        return this.publisherModel.minStartDate = minDate.toISOString()
      }
    } else {
      return this.publisherModel.minStartDate = currentDate.toISOString()
    }
  }

  onChangeSorting(headerName: string,index: number, isAsc: boolean){
    const compare = (v1: string | Date, v2: string | Date) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
    let oldSorting = this.sortByPublishingHeader.findIndex(item => item.isAsc != null)
    if (oldSorting >= 0 && oldSorting != index) {
      this.sortByPublishingHeader[oldSorting].isAsc = null
    }
    this.sortByPublishingHeader[index].isAsc = this.sortByPublishingHeader[index].isAsc != null ? !this.sortByPublishingHeader[index].isAsc : isAsc
    this.channels = this.channels.sort((a,b) => {
      var res
      switch(headerName) {
        case 'createdDate':
          res = compare(new Date(a[headerName]), new Date(b[headerName]))
          break;
        case 'channelNames':
          res = compare((a[headerName][0]?.value).toLowerCase(), (b[headerName][0]?.value).toLowerCase())
          break;
        default:
          res = compare(a[headerName].toLowerCase(), b[headerName].toLowerCase())
      }
      return isAsc === true ? res : -res
    }
    )

    // VLS-4496: Set up the list channels with slaves again after sorting the channels
    let oldChannelsWithSlaves = this.channelsWithSlaves
    this.channelsWithSlaves = []
    this.channels?.forEach((element) => {
      let channel = JSON.parse(JSON.stringify(oldChannelsWithSlaves.find((value) => value._id == element._id)))
      if (element.dthInfo?.isSlave == true) {
        channel.displayAsSlave = false
      }
      this.channelsWithSlaves.push(channel)
      if (element.dthInfo?.isSlave == false && channel.checked == true && channel.slaves?.length > 0) {
        this.channelsWithSlaves.push(...channel.slaves)
      }
    })
  }

  onPageChange(){
    this.sortByPublishingHeader = this.reportsPublicationScheduleService.defaultPublishingSorting()
    this.triggerSearchChannels()
  }

  fixedEncodeURI(str) { 
    return encodeURIComponent(str.trim()).replace(/[!'()*]/g, 
    function(c) { 
      return '%' + c.charCodeAt(0).toString(16); }); 
    }

  getKindOfChannel(channel) {
    if (channel?.dthInfo?.isSlave == true) {
      return "SLAVE"
    } else if (channel?.dthInfo?.isSlave == false) {
      return "MASTER"
    } else {
      return ""
    }
  }

  disabledPublishButton() {
    return !(
      this.publisherModel.startDate != null &&
      this.publisherModel.endDate != null &&
      this.channelsWithSlaves.some((element) => element.checked == true)
    )
  }
}
