import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import { ProgramsService } from '../programs/programs.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  team: any
  teamUrl: string
  venueUrl: string
  readonly CONSTANTS = AppConstants
  
  constructor(
    private http: HttpClient,
    private programService: ProgramsService
  ) {
    const apiUrl = environment.apiUrl
    this.teamUrl = `${apiUrl}/v2/teams`
    this.venueUrl = `${apiUrl}/v2/venues`
    }

  teamDefault() {
    return {
    id: '',
    shortName: "",
    addressZip: "",
    names: [],
    abbreviation: '',
    venue: {},
    founded: '',
    genres: [],
    type: '',
    providerInfo: [],
    externalRefs: [],
    images: [],
    stageIds: [],
    createdDate: '',
    updatedDate: '',
    spanishVenueName: '',
    englishVenueName: '',
    spanishOfficialName: '',
    englishOfficialName: '',
    optaTeamId: '',
    imageSizes: this.programService.getRatio(),
    teamImages: {},
    venueId: ''

    }
  }

  getTeams(params){
    const { page, size } = params
    return this.http.get<VLSResponse>(`${this.teamUrl}/all?page=${page}&size=${size}`)
  }

  addNewTeam(){
    const payload = new FormData
    const addTeamUrl = `${this.teamUrl}/create` 
    return this.http.post<Team>(`${addTeamUrl}`, payload)
  }

  getTeamById(id){
    return this.http.get<Team>(`${this.teamUrl}/${id}`)
  }

  updateTeam(id, data){
    return this.http.put<Team>(`${this.teamUrl}/${id}`, data)
  }

  buildVenueInfo(venue){
    const englishVenueName = venue.names?.find(name =>{
      return name.lang === this.CONSTANTS.EN
    })

    const spanishVenueName = venue.names?.find(name =>{
      return name.lang === this.CONSTANTS.ES
    })
    
    const portugueseVenueName = venue.names?.find(name =>{
      return name.lang === this.CONSTANTS.PT
    })

    venue.portugueseVenueName = portugueseVenueName.value || ''
    venue.englishVenueName = englishVenueName.value || ''
    venue.spanishVenueName = spanishVenueName.value || ''
  }

  buildTeamInfo(team : Team){

    const optaRef = team.externalRefs?.find(ref =>{
      return ref.refName === 'optaTeamId'
    })

    const englishOfficialName = team.names?.find(name =>{
      return name.lang === this.CONSTANTS.EN && name.type === 'officialName'
    })

    const spanishOfficialName = team.names?.find(name =>{
      return name.lang === this.CONSTANTS.ES && name.type === 'officialName'
    })

    const portugueseOfficialName = team.names?.find(name =>{
      return (name.lang === this.CONSTANTS.PT || name.lang === this.CONSTANTS.PT_BR) && name.type === 'officialName'
    })

    team.optaTeamId = optaRef?.id
    team.portugueseOfficialName = portugueseOfficialName?.value || ''
    team.englishOfficialName = englishOfficialName?.value || ''
    team.spanishOfficialName = spanishOfficialName?.value || ''
  }

  save(data) {
    const teamId = data.id
    const payload = new FormData()
    this.team = this.flatten(data)
    for (let key in this.team) {
      if (!key.includes('createdDate') && !key.includes('updatedDate')) {
        payload.append(key, this.team[key])
      }
    }
    return !!teamId
      ? this.http.put<Team>(`${this.teamUrl}/${teamId}`, payload)
      : this.http.post<Team>(`${this.teamUrl}/create`, payload)
  }

  
  flatten(obj) {
    let flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (let key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        let value = currentNode[key]
        if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  searchTeams(params) {
    const { page, size, searchString } = params
    const searchUrl = `${this.teamUrl}?searchString=${searchString}&page=${page}&size=${size}`
    return this.http.get<any>(searchUrl)
  }

  getTeamImages(teamId){
    return this.http.get<Team>(`${this.teamUrl}/${teamId}/images`)
  }

  getVenueById(venueId){
    return this.http.get<Team>(`${this.venueUrl}/search/${venueId}`)
  }

  externalRefsDefaults() {
    return {
      index: null,
      system: '',
      refName: '',
      value: ''
    }
  }

  venueDefault(){
    return{
      names: [],
      spanishVenueName: '',
      englishVenueName: ''
    }
  }
  getAllVenues(searchData, params: PaginationParams){
    const { page, size } = params
    const venueUrl = `${this.venueUrl}/search?page=${page}&size=${size}`
    return this.http.post<any>(`${venueUrl}`, searchData)
  }

  getTeamsByMatchId(matchId : string){
    if(matchId != '' && matchId){
      return this.http.get<VLSResponse>(
        `${this.teamUrl}/matches?matchId=${matchId}`
      )
    }else{
      return this.http.get<VLSResponse>(
        `${this.teamUrl}/matches`
      )
    }
  }
}
