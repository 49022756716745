import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core'
import {ProgramsService} from '../programs.service'
import {Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {CdkDragStart} from '@angular/cdk/drag-drop'
import {ActivatedRoute} from '@angular/router'
import { Schedule } from '../../schedules/schedule'

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss'],
})
export class ProgramListComponent implements OnInit {
  @Output() programId: EventEmitter<string> = new EventEmitter()
  @Output() versionId: EventEmitter<any> = new EventEmitter()
  @Input() channelId: string
  @Input() pageName: string
  @Input() selectedMarket: string
  @Input() dropList: string[]
  @Input() newProgramId: string
  searchString: string
  searchChanged: Subject<string> = new Subject<string>()
  programFilters = [
    {name: 'movies', abbrev: 'MV', value: true},
    {name: 'show', abbrev: 'SH', value: true},
    {name: 'episodes', abbrev: 'EP', value: true},
  ]

  getParams: PaginationParams = {
    page: 1,
    size: 20,
  }
  programs: Program[]
  selectedProgramId: string
  hasEpisodeSearch: boolean
  programTypes: ResourceType[]
  selectedType: ResourceType
  isProgramPage: boolean
  showLoader: boolean

  constructor(
    private programService: ProgramsService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.programTypes = this.programService
      .programTypes()
      .filter((service) => service.code !== 'EP' && service.code !== 'SE')
    this.selectedType = this.programTypes[0]
    this.isProgramPage = this.pageName === 'programs'

    this.route.params.subscribe((params) => {
      if (params.id) {
        this.searchString = params.id
        this.searchPrograms()
      } else {
        this.getPrograms()
      }
    })

    this.debouncedSearch()
  }

  ngOnChanges(changes: SimpleChanges) {
    const fields = Object.keys(changes)
    if (fields.includes('newProgramId') && this.newProgramId) {
      this.searchString = this.newProgramId
      this.selectedProgramId = this.newProgramId
      this.setProgramTypesSelect()
      this.searchPrograms()
    }
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        if (!!value) {
          this.getParams.page = 1
          this.searchPrograms()
        } else {
          this.getPrograms()
        }
      })
  }

  getPrograms() {
    const searchData = {
      searchString: this.searchString || '',
      filters: this.programFilters
        .filter(({value}) => value)
        .map(({abbrev}) => abbrev),
    }
    this.triggerSearchPrograms(searchData)
  }

  getProgramTypeStyle(type) {
    return this.programService.programTypeLabel(type).style
  }

  searchPrograms() {
    this.hasEpisodeSearch = false
    if (this.searchString) {
      const regExp = /\d+/g
      const matches = this.searchString.match(regExp)
      this.hasEpisodeSearch = matches && !!matches.length
      const searchData = {
        searchString: this.searchString,
        filters: this.programFilters
          .filter(({value}) => value)
          .map(({abbrev}) => abbrev),
      }
      this.triggerSearchPrograms(searchData)
    }
  }

  triggerSearchPrograms(searchData, isScroll = false) {
    if (searchData.searchString.length !== 1) {
      this.showLoader = true
      this.programService.searchPrograms(searchData, this.getParams).subscribe(
        (data: any) => {
          this.showLoader = false
          if (data) {
            if (data.response) {
              const programs = data.response
              if (isScroll) {
                this.programs.push(...programs)
              } else {
                this.programs = programs
              }
            } else {
              if (this.getParams.page == 1) {
                this.programs = []
              }
            }
          } else {
            this.programs = []
          }
        },
        (err) => {
          this.showLoader = false
        }
      )
    }
  }

  searchKeyup(event) {
    this.searchChanged.next(event.target.value)
  }

  searchChange(val) {
    this.getParams.page = 1
    if (!val) {
      this.getPrograms()
    }
  }

  viewProgram(id) {
    if (this.pageName === 'programs') {
      this.selectedProgramId = id
      this.programId.emit(id)
      this.setProgramTypesSelect()
    }
  }

  setProgramTypesSelect() {
    this.programTypes = this.programService
      .programTypes()
      .filter((service) => service.code !== 'EP' && service.code !== 'SE')
    if (this.selectedProgramId.substring(0, 2) === 'SH') {
      this.programTypes = this.programService.programTypes()
    }
  }

  addProgram(id) {
    if (this.pageName === 'schedules' || this.pageName === 'program-mapping') {
      this.programId.emit(id)
    }
  }

  onTypeClick(programType) {
    this.selectedType = programType
    this.createNewProgram()
  }

  createNewProgram() {
    this.versionId.emit({
      id: '0',
      provider: 'masterentity',
      programType: this.selectedType,
    })
  }

  onDragStart(event: CdkDragStart<Schedule>, index: number) {
    const programItem = document.getElementById('programItem-' + index)
    if (programItem) {
      programItem.style.border = '1px solid #D3D3D3'
      programItem.style.backgroundColor = 'palegoldenrod'
      programItem.style.borderRadius = '5px'
    }
  }

  onScroll(event) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= this.programs.length
      this.getParams.page++
      const searchData = {
        searchString: this.searchString || '',
        filters: this.programFilters
          .filter(({value}) => value)
          .map(({abbrev}) => abbrev),
      }
      this.triggerSearchPrograms(searchData, true)
    }
  }

  showSeriesEpisode(program) {
    if (program.listEpisode) {
      program.listEpisode = undefined
    } else {
      program.listEpisode = []
      this.showLoader = true
      if (program.programId) {
        this.programService
          .getAllEpisodesOfSeries(program.programId)
          .subscribe((data: any) => {
            if (data && data.response) {
              program.listEpisode = data.response
              program.isGroupedByStage = program.listEpisode.some((item) => item.groupName && item?.sportsInfo?.gameDate)
              program.groupedEpisode = program.isGroupedByStage ? this.groupByStage(program) : this.groupBySeason(program)
              this.showLoader = false
            }
          })
      }
    }
  }

  groupBySeason(program) {
    let rs = new Map()
    for (let i = 0; i < program.listEpisode.length; i++) {
      if (rs.get(program.listEpisode[i].season) == undefined) {
        rs.set(program.listEpisode[i].season, {
          isCollapsed: false,
          results: []
        })
      }

      rs.set(program.listEpisode[i].season, {
        isCollapsed: false,
        results: [
          ...rs.get(program.listEpisode[i].season).results,
          program.listEpisode[i],
        ]
      })
    }
    return rs
  }

  groupByStage(program: any) {
    let rs = new Map()
    for (let i = 0; i < program.listEpisode.length; i++) {
      if (program.listEpisode[i].sportsInfo) {
        if (rs.get(program.listEpisode[i].sportsInfo?.gameDate) == undefined) {
          rs.set(program.listEpisode[i].sportsInfo?.gameDate, {
            isCollapsed: false,
            results: []
          })
        }

        rs.set(program.listEpisode[i].sportsInfo?.gameDate, {
          isCollapsed: false,
          results: [
            ...rs.get(program.listEpisode[i].sportsInfo?.gameDate).results,
            program.listEpisode[i],
          ]
        })
      }
    }
    this.sortGroupName(rs)
    return rs
  }

  sortGroupName(rs: Map<String, any>) {
    for (let [key, value] of rs) {
      value.results.sort((a, b) => a?.groupName?.localeCompare(b?.groupName))
      rs.set(key, value)
    }
    return rs
  }
}
