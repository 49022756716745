// Referenced Timezone ID database: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// For those with timezone ID not found, we will temporarily use the timezone ID which is nearby and shares the same time offset.
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import {Dayjs} from "dayjs";
import { environment } from "src/environments/environment";
import { AppConstants } from "../app.constants";

const OFFSET_OPTIONS = [
  {key: 'GMT (GMT-0)', tooltip: 'GMT', value: 'GMT'},
  {key: 'BRT (GMT-3)', tooltip: 'Brazil/Rio de Janeiro', value: 'Brazil/East'},
  {key: 'AMT (GMT-4)', tooltip: 'Brazil/Amazon', value: 'Brazil/West'},
  {key: 'FNT (GMT-2)', tooltip: 'Brazil/Fernando de Nororonha', value: 'Brazil/DeNoronha'},
  {key: 'ACT (GMT-5)', tooltip: 'Brazil/Acre', value: 'Brazil/Acre'},
  {key: 'ART (GMT-3)', tooltip: 'Argentina', value: 'America/Argentina/Buenos_Aires'},
  {key: 'BOT (GMT-4)', tooltip: 'Bolivia', value: 'Brazil/West'}, // not found in referenced Timezone ID database, temporarily use Brazil/West
  {key: 'CLT (GMT-4)', tooltip: 'Chile', value: 'Chile/Continental'},
  {key: 'EAST (GMT-6)', tooltip: 'Chile/Easter Island', value: 'Chile/EasterIsland'},
  {key: 'COT (GMT-5)', tooltip: 'Colombia', value: 'Brazil/Acre'}, // not found in referenced Timezone ID database, temporarily use Brazil/Acre
  {key: 'ECT (GMT-5)', tooltip: 'Ecuador', value: 'Brazil/Acre'}, // not found in referenced Timezone ID database, temporarily use Brazil/Acre
  {key: 'PYT (GMT-4)', tooltip: 'Paraguay', value: 'Brazil/West'}, // not found in referenced Timezone ID database, temporarily use Brazil/West
  {key: 'UYT (GMT-3)', tooltip: 'Uruguay', value: 'Brazil/East'}, // not found in referenced Timezone ID database, temporarily use Brazil/East
  {key: 'PET (GMT-5)', tooltip: 'Peru', value: 'Brazil/Acre'}, // not found in referenced Timezone ID database, temporarily use Brazil/Acre
  {key: 'SRT (GMT-3)', tooltip: 'Suriname', value: 'Brazil/East'}, // not found in referenced Timezone ID database, temporarily use Brazil/East
  {key: 'VET (GMT-4)', tooltip: 'Venezuela', value: 'Brazil/West'}, // not found in referenced Timezone ID database, temporarily use Brazil/West
  {key: 'FKT (GMT-3)', tooltip: 'Falkland Islands', value: 'Brazil/East'}, // not found in referenced Timezone ID database, temporarily use Brazil/East
  {key: 'GYT (GMT-4)', tooltip: 'Guyana', value: 'America/Guyana'},
  {key: 'GFT (GMT-3)', tooltip: 'French Guiana', value: 'Brazil/East'}, // not found in referenced Timezone ID database, temporarily use Brazil/East
  {key: 'SRT (GMT-2)', tooltip: 'Sandwich Islands', value: 'Brazil/DeNoronha'}, // not found in referenced Timezone ID database, temporarily use Brazil/DeNoronha
]

// Format a day in GMT, base on the format date from api.
const format = (dayjs: Dayjs, pattern?:string): string => {
  return dayjs.tz('GMT').format(pattern??'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

const formatDate = (date: NgbDateStruct): string => {
  const formatMonth = date?.month?.toString().length === 1 ? `0${date?.month}` : date?.month
  const formatDay = date?.day?.toString().length === 1 ? `0${date?.day}` : date?.day
  return date
    ? `${date?.year}-${formatMonth}-${formatDay}`
    : ''
}

const parseDate = (date: string, defaultDate?: NgbDate): NgbDate => {
  const datePart = date?.split('-');
  return datePart ? new NgbDate(+datePart[0], +datePart[1], +datePart[2]) : defaultDate;
}

const formatDateToString = (date: string | Date, formatDate: string, formatString: string): string => {
  return date ? moment(date, formatDate).format(formatString) : '--'
}

const DATEPICKER_FORMAT = 'yyyy-MM-DD HH:mm:ss'
const SCHEDULE_FORMATDATE = 'YYYY-MM-DD'
const SCHEDULE_FORMATTIME = 'HH:mm:ss'
const SCHEDULEID_FORMAT = 'YYYYMMDD'
const MMDDYYYY_FORMATDATE = 'MM/DD/YYYY'

const SCHEDULE_ERROR_HiGHLIGHT = {
  OVERLAPPED: 'overlapped-event',
  GAP: 'gap-event'
}

const getValidQualifiers = () => {
  return environment[AppConstants.VALID_QUALIFIERS] ? environment[AppConstants.VALID_QUALIFIERS] : []
}

const getLangFilters = () => {
  return environment[AppConstants.LANG_FILTERS] ? environment[AppConstants.LANG_FILTERS] : []
}

const sortByOrders = (sortArray, orderList, fieldName) => {
  if (orderList && fieldName) {
    sortArray?.sort((a, b) => {
      let aIndex = orderList.indexOf(a[fieldName]?.toLowerCase());
      let bIndex = orderList.indexOf(b[fieldName]?.toLowerCase());
      if (aIndex == -1) {
          aIndex = 9999;
      }
      if (bIndex == -1) {
          bIndex = 9999;
      }
      return aIndex >= bIndex ? 1 : -1;
    });
  }
}

const isArray = (o) => {
  return Object.prototype.toString.call(o) === '[object Array]'; 
}


export {
  sortByOrders,
  getLangFilters,
  getValidQualifiers,
  OFFSET_OPTIONS,
  format,
  formatDate,
  parseDate,
  formatDateToString,
  isArray,
  SCHEDULE_ERROR_HiGHLIGHT,
  DATEPICKER_FORMAT,
  SCHEDULE_FORMATDATE,
  SCHEDULE_FORMATTIME,
  SCHEDULEID_FORMAT,
  MMDDYYYY_FORMATDATE
}
