import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from "../pages/login/login.service";
import { PlatformService } from './platform.service';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class DTHPermission implements CanActivate {

  constructor(
    private platformService: PlatformService,
    private router: Router
  ) {}

  canActivate() {
    const isDTH = this.platformService.verifyPlatform(AppConstants.DTH)
    if (!isDTH) {
      this.router.navigate(['schedules'])
    }
    return isDTH
  }

}
