import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { NgForm, NgModel, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class ReportsDetailsPublicationsService {

    publicationsUrl: string

    constructor(private http: HttpClient) {
        this.publicationsUrl = environment.apiUrl;
    }

    public getDetailsLSFiles(lsFileID: String): Observable<any> {
        return this.http.get<any>(`${this.publicationsUrl}/lsInfo/search/${lsFileID}`);
    }

}