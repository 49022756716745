import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormControlComponent } from './form-control.component'
import {FormValidationComponent} from "./form-validation/form-validation.component";

@NgModule({
  declarations: [FormControlComponent,FormValidationComponent],
  imports: [CommonModule],
  exports: [FormControlComponent,FormValidationComponent],
})
export class FormControlModule {}
