import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PluginsComponent } from './plugins.component'
import { TranslateModule } from '@ngx-translate/core'
import { PluginListComponent } from './plugin-list/plugin-list.component'
import { PanelModule } from '../../components/panel/panel.module'
import { PluginDetailComponent } from './plugin-detail/plugin-detail.component'
import { PluginVersionComponent } from './plugin-version/plugin-version.component'
import { ListModule } from '../../components/list/list.module'
import { ButtonModule } from 'src/app/components/button/button.module'
import { TagModule } from '../../components/tag/tag.module'
import { FormControlModule } from 'src/app/components/form-control/form-control.module'
import { InputModule } from 'src/app/components/input/input.module'
import { TabsModule } from 'src/app/components/tabs/tabs.module'
import {PluginStoreService} from "./plugin.store";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoadingModule} from "../../components/loading/loading.module";

@NgModule({
  declarations: [
    PluginsComponent,
    PluginListComponent,
    PluginDetailComponent,
    PluginVersionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    PanelModule,
    ListModule,
    ButtonModule,
    TagModule,
    FormControlModule,
    InputModule,
    TabsModule,
    ReactiveFormsModule,LoadingModule
  ],
  exports: [PluginsComponent],
  providers:[PluginStoreService]
})
export class PluginsModule {}
