import { Component, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from './users.service'

interface RoleModal {
  name: string
  description: string
  headerName: string
  selectName: string
  accessPage: any[]
  selectItems: SitePage[] | UserAction[]
}

@Component({
  selector: 'app-users-roles',
  styleUrls: ['./users.component.scss'],
  templateUrl: './users-roles.component.html'
})
export class UsersRolesComponent implements OnInit {
  role: Role
  roles: Role[]
  sitePages: SitePage[]
  userActions: UserAction[]
  showLoader: boolean
  selectedRole: Role
  modal: RoleModal
  constructor(
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.role = {
      id: '',
      name: '',
      label: '',
      description: '',
    }
    this.selectedRole = this.role
    this.getRoles()
    this.getPages()
    this.getUserActions()
  }

  getRoles() {
    this.userService.getUserRoles().subscribe((data: any) => {
      this.roles = data.response
      this.selectedRole = this.roles[0]
    });
  }

  getPages() {
    this.sitePages = this.userService.getPages() as any
  }

  getUserActions() {
    this.userActions = [
      ...this.userService.getUserAction(),
    ]
  }

  addNewRole(roleModal, modal) {
    this.modal = {
      headerName: 'Add new role',
      selectName: 'Page Access',
      selectItems: this.sitePages,
      name: '',
      description: '',
      accessPage: []
    }

    if (modal === 'sitePage') {
      this.modal = {
        headerName: 'Add new site page',
        selectName: 'Actions',
        selectItems: this.userActions,
        name: '',
        description: '',
        accessPage: []
      }
    }
    this.modalService.open(roleModal, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  submit() {
    const data = {
      ...this.modal,
      label: this.modal.description
    }
    this.userService.createUserRoles(data).subscribe((data: any) => {
      this.getRoles();
      this.closeModal();
    });
  }

  onItemClick(role) {
    this.selectedRole = role
  }
}
