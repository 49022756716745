import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {IRepsonseList, IResponseDetail} from "../response.interface";
import {ManufacturerEntity, PluginEntity, PlugInTypeEntity} from "./plugin.interface";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable({providedIn: 'root'})
export class PluginService {
  API_URL = `${environment.apiUrl}/plugins`

  constructor(private httpClient: HttpClient) {

  }

  getList(params: { searchString?: string }) {
    return this.httpClient.get<IRepsonseList<PluginEntity>>(`${this.API_URL}`, {
      params: {
        ...params,
        page: '1', size: "100"
      }
    }).pipe(map(response => response.response || []))
  }

  getOne(plugInId: string, versionId: number) {
    return this.httpClient.get<IResponseDetail<PluginEntity>>(`${this.API_URL}/${plugInId}/versions/${versionId}`).pipe(map(value => value.response));
  }

  getListType() {
    return this.httpClient.get<IResponseDetail<PlugInTypeEntity[]>>(`${environment.apiUrl}/pluginTypes`).pipe(
      map(response => response.response)
    );
  }

  getListManufacturer() {
    return this.httpClient.get<IResponseDetail<ManufacturerEntity[]>>(`${environment.apiUrl}/bbvManufacturerPlatforms`).pipe(map(response => response.response))
  }

  uploadFileToS3(p: { presignedUrl: string; file: File }) {
    const {presignedUrl, file} = p;
    return this.httpClient.put(presignedUrl, file, {
      headers: new HttpHeaders({
        'Content-Type': file.type,
      }), observe: 'response'
    })
  }

  generatePresignedUrl(p: {
    plugInTypeId: string; plugInFileName: string;
    plugInId?: string;
    version?: string; file: File
  }) {
    return this.httpClient.post<{
      response: string
    }>(`${this.API_URL}/generate-presigned-url`, p).pipe(
      switchMap(resp =>
        this.uploadFileToS3({
          presignedUrl: resp.response,
          file: p.file
        }).pipe(map(() => resp.response))))
  }


  createOne(dto: Pick<PluginEntity, "plugInTypeId" | "plugInName" | "description">, tarFile: File) {
    return this.generatePresignedUrl({
      plugInTypeId: dto.plugInTypeId,
      plugInFileName: tarFile.name, file: tarFile
    }).pipe(
      switchMap(presignedUrl => {
        const form = new FormData();
        const blobPayload = {...dto, presignedUrl, createdBy: "admin", targetingUi: "HDUI"}
        const blob = new Blob([JSON.stringify(blobPayload)], {type: 'application/json'})
        form.append('plugIn', blob);
        return this.httpClient.post<IResponseDetail<PluginEntity>>(this.API_URL, form).pipe(map(resp => resp.response))
      })
    )


  }

  updateOne(plugInId: string, versionId: number,
            params: { dto: Pick<PluginEntity, "plugInName" | "plugInTypeId" | "description">, tarFile?: File }
  ) {
    const {dto, tarFile} = params;
    const blobPayload = {...dto, updatedBy: "admin", presignedUrl: null};
    return (tarFile ? this.generatePresignedUrl({
      plugInTypeId: dto.plugInTypeId,
      plugInFileName: tarFile.name, file: tarFile
    }).pipe(map(presignedUrl => {
      blobPayload.presignedUrl = presignedUrl;
      return blobPayload
    })) : of(blobPayload))
      .pipe(
        switchMap(blobPayload => {
          const form = new FormData();
          const blob = new Blob([JSON.stringify(blobPayload)], {type: 'application/json'})
          form.append('plugIn', blob);
          return this.httpClient.put<IResponseDetail<PluginEntity>>
          (`${this.API_URL}/${plugInId}/versions/${versionId}`, form)
            .pipe(map(resp => resp.response))
        })
      )

  }

  getVersions(plugInId: string
  ) {
    return this.httpClient.get<IResponseDetail<PluginEntity[]>>(`${this.API_URL}/${plugInId}/versions`).pipe(map(resp => resp.response))
  }

  deletePlugin(params
                 :
                 {
                   pluginId: string;
                   versionId: number;
                   manufacturerId?: string;
                   modelNumId?: string;
                 }
  ) {
    return this.httpClient.request<IResponseDetail<{
      "manufId": string,
      "modelNum": string,
      "deletedPlugIn": PluginEntity
    }>>('DELETE', `${this.API_URL}/${params.pluginId}/versions/${params.versionId}`, {
      body: {manufId: params.manufacturerId || null, modelNum: params.modelNumId || null},
    }).pipe(map(resp => resp.response));
  }

  publish(params
            :
            {
              plugInId: string;
              versionId: number;
              fastLoad: boolean;
              manufacturerId?: string;
              modelNumId?: string;
            }
  ) {
    return this.httpClient.post<IResponseDetail<{
      "ce": false,
      "fastLoad": false,
      "manufId": string,
      "modelNum": string,
      "publishedPlugIn": PluginEntity
    }>>(`${this.API_URL}/${params.plugInId}/versions/${params.versionId}/handlePlugInStreamcopy`, {
      manufId: params.manufacturerId, modelNum: params.modelNumId,
      ce: false, fastLoad: params.fastLoad
    }).pipe(map(resp => resp.response))
  }
}
