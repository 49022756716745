import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component'

@Injectable({ providedIn: 'root' })
export class AppService {
  isOTT = new BehaviorSubject(true)
  currentPage = this.isOTT.asObservable()

  isAdmin = new BehaviorSubject(false)
  currentUserRole = this.isAdmin.asObservable()

  setOTTPage(isOTT: boolean) {
    // this.isOTT.next(isOTT);
    this.isOTT.next(true)
  }

  setIsAdmin(isAdmin: boolean) {
    this.isAdmin.next(isAdmin)
  }

  promptMarketChange(modalService, market, cb) {
    const modal = modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    modal.componentInstance.title = 'Market Change'
    modal.componentInstance.message = `You are switching now to ${market} page. Would you like to continue?`
    modal.componentInstance.isAlert = false
    modal.result.then((result) => {
      if (result) {
        cb()
      }
    })
  }

  getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  getCurrentLocale() {
    return Intl.DateTimeFormat().resolvedOptions().locale
  }

  isBrazilTimezone() {
    const locale = this.getCurrentLocale()
    const timeZOne = this.getCurrentTimezone()
    return timeZOne === 'America/Sao_Paulo' || locale === 'pt-BR'
  }
}
