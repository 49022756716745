import {Component, OnInit, SimpleChanges} from '@angular/core'
import { ReportsPublicationsService } from './reports-publications.service';
import { NgForm, NgModel, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE, formatDateToString } from 'src/app/utils/contants';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-reports-publications',
  templateUrl: './reports-publications.component.html',
  styleUrls: ['./reports-publications.component.scss'],
})

export class ReportsPublicationsComponent implements OnInit {
  userID: String;
  showLoader: boolean = false
  status: String;
  isDisplayAll: Boolean;
  sortByHistoryHeader: any[]
  searchString: string 
  searchChanged: Subject<string> = new Subject<string>()
  page = 1
  pageSize = 20
  collectionSize = 0

  public reportsPublications: String[];
  public PublisherModel
  public reportsPublicationsModel: ReportsPublicationModel[];
  public reportsPublicationResendModel: ReportsPublicationModel[];
  public showRecordOfUser: ReportsPublicationModel[];

  constructor(private reportsPublicationsService: ReportsPublicationsService, private modalService: NgbModal) {
    this.userID = localStorage.getItem('authUserId');
    this.status = status;
  }

  ngOnInit() {
    this.isDisplayAll = false;
    this.searchString = null
    this.debouncedSearch();
    this.getLSFiles(this.isDisplayAll);
    this.sortByHistoryHeader = this.reportsPublicationsService.defaultHistorySorting()
  }

  public getLSFiles(isDisplayAll: Boolean): void {
    this.sortByHistoryHeader = this.reportsPublicationsService.defaultHistorySorting()
    this.showLoader = true;
    this.isDisplayAll = isDisplayAll;
    const searchData = {
      userId: this.userID,
      status: null,
      isDisplayAll: isDisplayAll,
      channelName: this.searchString
    }
    const params : PaginationParams = {
      size: this.pageSize,
      page: this.page - 1
    }
    this.reportsPublicationsService.getLSFiles(searchData, params).subscribe(
      (response) => {
        this.reportsPublicationsModel = response?.response;
        this.collectionSize  = response?.totalElements;
        this.showLoader = false;
        if (this.reportsPublicationsModel.length === 0) {
          this.showAlert("Warning", `There is no ls file for current user`);
        } else {
          this.formatDate()
        }
      }
    );
  }

  public downloadLSFile(reportsPublication: String): void {
    this.reportsPublicationsService.downloadLSFile(reportsPublication);
  }

  public filterByStatus(status: string) {
    this.sortByHistoryHeader = this.reportsPublicationsService.defaultHistorySorting()
    this.page = 1
    this.showLoader = true;
    this.status = status
    const searchData = {
      userId: this.userID,
      status: status,
      isDisplayAll: this.isDisplayAll,
      channelName: this.searchString
    }
    const params : PaginationParams = {
      size: this.pageSize,
      page: this.page - 1
    }
    this.reportsPublicationsService.getLSFiles(searchData, params).subscribe(
      (data) => {
        this.reportsPublicationsModel = data?.response;
        this.collectionSize = data?.totalElements
        this.showLoader = false;
        if (this.reportsPublicationsModel.length === 0) {
          this.showAlert("Warning", `There is no ls file for current user`);
        } else {
          this.formatDate()
        }
      }
    );
  }

  showAlert(type, message) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  updateListLsInfo(lsInfo, checked: boolean) {
    this.reportsPublicationResendModel = this.reportsPublicationsModel.filter((ele) => ele.id === lsInfo.id);
    this.reportsPublicationResendModel.forEach((lsInfo) => {
      return lsInfo.checked = checked
    })
    this.reportsPublicationResendModel = this.reportsPublicationsModel.filter((lsInfo) => {
      lsInfo.userId =  localStorage.getItem('authUserId')
      return lsInfo.checked === true
    })
  }

  resetDefaults() {
    this.isDisplayAll = false;
    this.getLSFiles(this.isDisplayAll);
    this.reportsPublicationResendModel.forEach((ele) => ele.checked = false)
  }

  resendPublisher() {
    this.showLoader = true;
    this.reportsPublicationsService
      .resendPublisher(this.reportsPublicationResendModel)
      .subscribe(
        (data: any) => {
          if (data && data.response === "OK") {
            this.resetDefaults()
            this.showAlert("Success", `File is processed successfully.`);
          } else if (data && data.response){
            this.showAlert("Error", data.response);
          }
          this.showLoader = false;
        },
        (error) => {
          this.showLoader = false;
          this.showAlert("Error", `${error.error.errors[0]}`);
        }
      );
  }

  disableResendButton() {
    if(this.reportsPublicationResendModel === null || this.reportsPublicationResendModel === undefined) {
      this.reportsPublicationResendModel = []
    }
    return this.reportsPublicationResendModel.length === 0 ;
  } 

  onChangeSorting(headerName: string,index: number, isAsc: boolean){
    const compare = (v1: string | Date, v2: string | Date) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
    let oldSorting = this.sortByHistoryHeader.findIndex(item => item.isAsc != null)
    if (oldSorting >= 0 && oldSorting != index) {
      this.sortByHistoryHeader[oldSorting].isAsc = null
    }
    this.sortByHistoryHeader[index].isAsc = this.sortByHistoryHeader[index].isAsc != null ? !this.sortByHistoryHeader[index].isAsc : isAsc
    this.reportsPublicationsModel = this.reportsPublicationsModel.sort((a,b) => {
      var res
      switch(headerName) {
        case 'startDateDate' || 'endDate' || 'requestedDate':
          res = compare(new Date(a[headerName]), new Date(b[headerName]))
          break;
        default:
          res = compare(a[headerName].toLowerCase(), b[headerName].toLowerCase())
      }
      return isAsc === true ? res : -res
    }
    )
  }

  onSearchChange(val) {
    this.page = 1
    this.getLSFiles(this.isDisplayAll)
    this.reportsPublicationResendModel = []
  }
  
  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        this.getLSFiles(this.isDisplayAll)
      })
  }

  onPageChange(){
    this.getLSFiles(this.isDisplayAll)
    this.reportsPublicationResendModel = []
  }

  onDisplayAllChange(isDisplayAll: Boolean){
    this.page = 1
    this.sortByHistoryHeader = this.reportsPublicationsService.defaultHistorySorting()
    this.getLSFiles(isDisplayAll)
  }
  
  formatDate() {
    for (let i = 0; i < this.reportsPublicationsModel.length; i++) {
      this.reportsPublicationsModel[i].requestedDateString = formatDateToString(this.reportsPublicationsModel[i]?.requestedDate, DATEPICKER_FORMAT, DATEPICKER_FORMAT)
      this.reportsPublicationsModel[i].startDateString = formatDateToString(this.reportsPublicationsModel[i]?.startDate, DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE)
      this.reportsPublicationsModel[i].endDateString = formatDateToString(this.reportsPublicationsModel[i]?.endDate, DATEPICKER_FORMAT, MMDDYYYY_FORMATDATE)
    }
  }
}
