import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {PluginEntity} from "../../services/plugins/plugin.interface";

@Injectable()
export class PluginStoreService {
  selectedPluginId$ = new BehaviorSubject<string>(null);
  selectedPluginVersion$ = new BehaviorSubject<PluginEntity>(null);
  requestCreatePlugin$ = new Subject()
  createdPlugin$ = new Subject<PluginEntity>();
  updatedPlugin$ = new Subject<PluginEntity>();
  deletedPlugin$ = new Subject<PluginEntity>();
  publishPlugin$ = new Subject<PluginEntity>();

  constructor() {
    this.selectedPluginId$.subscribe(() => {
      this.selectedPluginVersion$.next(null);
    })
    this.requestCreatePlugin$.subscribe(()=>{
      this.selectedPluginId$.next(null)
    })
    this.createdPlugin$.subscribe(resp => {
      this.selectedPluginId$.next(resp.id);
      this.selectedPluginVersion$.next(resp);
    })
    this.deletedPlugin$.subscribe((resp) => {
      this.selectedPluginVersion$.next(resp)
    })
    this.updatedPlugin$.subscribe(resp => {
      this.selectedPluginVersion$.next(resp)
    })
  }
}
