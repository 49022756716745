import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import {ListItemComponent, ListItemTitleComponent} from './list-item/list-item.component';



@NgModule({
  declarations: [ListComponent, ListItemComponent,ListItemTitleComponent],
  imports: [
    CommonModule
  ], exports: [ListComponent, ListItemComponent,ListItemTitleComponent]
})
export class ListModule { }
