import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import * as moment from 'moment'
import { DATEPICKER_FORMAT } from 'src/app/utils/contants'

@Injectable({ providedIn: 'root' })
export class ReportsPublicationsService {

    publicationsUrl: string

    constructor(private http: HttpClient) {
        this.publicationsUrl = environment.apiUrl;
    }

    public getLSFiles(searchData,params: PaginationParams): Observable<any> {
        const { page, size } = params
        const searchUrl = `${this.publicationsUrl}/lsInfo/search/?page=${page}&size=${size}`
        return this.http.post<any>(searchUrl, searchData);
    }

    public downloadLSFile(lsFile: String): void {
        window.open(`${this.publicationsUrl}/publications/download?fileName=${lsFile}`, '_blank');
    }

    public getLSFilesByStatus(status: string): Observable<any> {
        return this.http.get<any>(`${this.publicationsUrl}/lsInfo/search/?status=${status}`);
    }

    public resendPublisher(data): Observable<any>  {
      return this.http.post<VLSResponse>(`${this.publicationsUrl}/publications/resendPublisher`, data);
    }

    defaultHistorySorting(){
        return [
            {name: 'Service Id', fieldName:'sourceId', isDisplay: true, isAsc: null},
            {name: 'Channel Name', fieldName:'channelName', isDisplay: true, isAsc: null},
            {name: 'Request Date', fieldName:'requestedDate', isDisplay: true, isAsc: null},
            {name: 'Start Date', fieldName:'startDate', isDisplay: true, isAsc: false},
            {name: 'End Date', fieldName:'endDate', isDisplay: true, isAsc: null},
            {name: 'User Name', fieldName:'username', isDisplay: true, isAsc: null},
            {name: 'Status', fieldName:'status', isDisplay: true, isAsc: null},
            {name: 'Detail', fieldName:'detail', isDisplay: false, isAsc: null},
          ]
    }
}
