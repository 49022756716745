import {Component, OnInit} from '@angular/core'
import {NgbModal,} from '@ng-bootstrap/ng-bootstrap'
import {SchedulesService} from './schedules.service'
import {ConfirmationModalComponent} from 'src/app/components/confirmation-modal/confirmation-modal.component'
import {OFFSET_OPTIONS, parseDate} from 'src/app/utils/contants'
import {ScheduleVersionDetail, ScheduleVersionRequest} from './schedule'
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit {
  // public fields
  request: ScheduleVersionRequest = {
    timezone: environment.timezone ? OFFSET_OPTIONS.find(o => o.value === environment.timezone) : OFFSET_OPTIONS[0],
  };
  selectedScheduleVersions: ScheduleVersionDetail[];
  hidePrograms = true;
  errorMessages: string[] = []
  channelList: {
    id: string
    name: string
  }[] = []
  qualifiers: any[];
  channel: any;

  constructor(
    private scheduleService: SchedulesService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  onChannelChange(channel: SourceChannel) {
    this.channel = channel;
  }

  onProcessingTypeChange(channel: any) {
    if (channel && channel.id === this.request.channel.id) {
      this.request.channel = channel;
    }
  }

  selectDate(selectedDate: string) {
    this.request = {
      ...this.request,
      date: parseDate(selectedDate),
    };
  }

  closeModal() {
    this.errorMessages = []
    this.modalService.dismissAll()
  }

  showAlert(type: string, message: string, size: any = 'sm') {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size,
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  displayEmptyScheduleVersionWarning = (): void => {
    this.showAlert(
      'Warning',
      'No available schedules for this channel with the selected date',
      'lg'
    );
  }

  handleSelectedScheduleVersion($event: ScheduleVersionDetail[]) {
    this.selectedScheduleVersions = $event;
  }

  onScheduleRequestChanged($event: ScheduleVersionRequest) {
    this.request = {...$event};
    this.scheduleService.changeScheduleRequest(this.request);
  }

  onShowOrHideProgramPanel($event: boolean) {
    this.hidePrograms = $event;
  }

  onInvalidProgram($event: string) {
    this.showAlert(
      'Warning',
      `No active version for program with id: ${$event}.`,
      'lg'
    )
  }
}
