/*
  * =============================================================================
  * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
      * RESERVED
  *
  * This software is the confidential and proprietary information of Vrio Corp.
  * ("Proprietary Information"). Any use, reproduction, distribution or
  * disclosure of the software or Proprietary Information, in whole or in part,
  * must comply with the terms of the license agreement, nondisclosure agreement
  * or contract entered into with Vrio Corp. providing access to this software.
  *
  * ============================================================================
  */
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { AppConstants } from '../../app.constants'
import { AssetService } from '../assets-management/assets.service';
import { ProgramsService } from '../programs/programs.service';

@Component({
  selector: 'app-assets-management',
  templateUrl: './assets-management.component.html',
  styleUrls: ['./assets-management.component.scss']
})
export class AssetsManagementComponent implements OnInit {
  asset: Assets
  selectedType: string
  seasonsAndEpisodes: any[]
  assetId: string
  programId: string
  program : Program
  isLoading: boolean
  active: number
  ratings: Rating[]
  mpaaList: Rating[]
  usaParental: Rating[]
  brazilRatings: Rating[]
  ratingsAdvisories: any[]
  genres: Genre[]
  providerGracenote: string
  selectedProvider: string
  selectedVersion: string
  versionData: VersionData
  wideVineInfo: AssetUrlInfo
  playReadyInfo: AssetUrlInfo
  fairPlayInfo: AssetUrlInfo
  readonly CONSTANTS = AppConstants

  constructor(
    private assetService: AssetService,
    private programService : ProgramsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.resetVersionData()
    this.selectedProvider = this.CONSTANTS.MASTERENTITY_PROVIDER
    this.providerGracenote = this.CONSTANTS.GRACENOTE_PROVIDER
    this.getRatings()
    this.getGenres()
    this.wideVineInfo = this.assetService.assetUrlDefault() as any
    this.playReadyInfo = this.assetService.assetUrlDefault() as any
    this.fairPlayInfo = this.assetService.assetUrlDefault() as any
  }

  //get extraInfo from external Refs
  getExternalRefs(externalRefs, key) {
    var extraInfo = {};
    if (externalRefs && externalRefs.length) {
      extraInfo = externalRefs.find(info =>
        info.refName === key
      );
    }

    return extraInfo
  }

  getGenres() {
    this.programService.getAllGenres().subscribe(data => {
      const genreList = []
      data.response.forEach((genre) => {
        const enGenre = (genre.names || []).find(
          (g) => g.language === this.CONSTANTS.EN
        )
        if (enGenre) {
          genreList.push({
            id: genre.id,
            name: enGenre.value,
          })
        }
      })
      this.genres = genreList
    })
  }

  resetVersionData() {
    this.versionData = {
      pageName: 'assets',
      headerTitle: 'Versions',
      subHeader: '',
      metadataVersions: [],
    }
  }

  //set event when select another asset on asset list
  onAssetIdChange(assetId) {
    this.isLoading = true
    this.assetId = assetId;
    this.getAssetSelected(assetId)
  }

  getAssetSelected(assetId) {
    this.assetService.getAssetById(assetId).subscribe((data : any) => {
      this.asset = data.response
      this.programId = this.asset.programId
      this.getProgramVersions(this.programId)
      this.getChannelsString(this.asset.channels)
      this.getUrlType(this.asset)
      this.formatDateTime()    
    })   
  }
  
  getChannelsString(channels: string []){
    this.asset.channelsString = channels
      .map((channel) => `${channel}`)
      .join(', ')
  }

  formatDateTime(){
    this.asset.availabilityStartsAt = moment(this.asset.availabilityStartsAt).utc().format(this.CONSTANTS.DATE_FORMAT)
    this.asset.availabilityEndsAt = moment(this.asset.availabilityEndsAt).utc().format(this.CONSTANTS.DATE_FORMAT)
    this.asset.updatedDate = moment(this.asset.updatedDate).utc().format(this.CONSTANTS.DATE_FORMAT)
    this.asset.createdDate = moment(this.asset.createdDate).utc().format(this.CONSTANTS.DATE_FORMAT)
  }

  getRatings() {
    this.programService.getAllRatings().subscribe((data: any) => {
      this.ratings = data.response
      this.mpaaList = this.programService.filterRatings(this.ratings, this.CONSTANTS.MPAA_RATING)
      this.usaParental = this.programService.filterRatings(this.ratings, this.CONSTANTS.TVPG_RATING)
      this.brazilRatings = this.programService.filterRatings(this.ratings, this.CONSTANTS.DJCTQ_RATING)
    })
  }
  
  getProgramVersions(programId) {
    this.programService.getProgramVersions(programId).subscribe((data) => {
      if (data) {
        const programVersions = data.response.masterEntity
        const sorted = programVersions.sort((a, b) => b.version - a.version)
        this.setMetadataVersions(data.response)
        if (sorted.length) {
          this.selectedVersion = sorted[0].id
          this.getProgramByVersion(sorted[0].id, this.selectedProvider)
        }
      } else {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
          size: 'sm',
          centered: true,
          backdrop: 'static',
        })
        modalRef.componentInstance.title = 'Warning'
        modalRef.componentInstance.message = 'No version available.'
        modalRef.componentInstance.isAlert = true
      }
    })
  }

  getProgramByVersion(versionId, provider = null) {
    this.programService
      .getProgramByVersion(versionId, provider)
      .subscribe((data) => {
        const response = data.response
        if (response) {
          this.program = response
          /* Ratings display */
          const ratingsID = response.ratings?.map(({ id }) => id)
          const selectedRatings = this.ratings.filter(
            (rating) =>
              (ratingsID || []).includes(rating.id) &&
              [this.CONSTANTS.MPAA_RATING, this.CONSTANTS.TVPG_RATING, this.CONSTANTS.DJCTQ_RATING].includes(rating.code)
          )
          const ratingString = selectedRatings
            .map((rating) => `${rating.code}: ${rating.rating}`)
            .join(', ')
          const mpaaRatings = selectedRatings.find(
            ({ code }) => code === this.CONSTANTS.MPAA_RATING
          )
          const tvpgRatings = selectedRatings.find(
            ({ code }) => code === this.CONSTANTS.TVPG_RATING
          )
          const brazilRatings = selectedRatings.find(
            ({ code }) => code === this.CONSTANTS.DJCTQ_RATING
          )

          /* Rating content descriptors of DJCTQ */
          let ratingContentDescriptor = null
          const brazilRating =
            response.ratings && brazilRatings
              ? response.ratings.find(({ id }) => id === brazilRatings.id)
              : null
          if (brazilRating) {
            ratingContentDescriptor = brazilRating.warning
          }

          let titles;
          let eTitle;
          if (response.episodeTitleAvailable) {
            titles = response.parentTitle
            eTitle = response.titles
          } else {
            titles = response.titles
          }

          if (provider === this.CONSTANTS.GRACENOTE_PROVIDER) {
            titles = response.titles
          }

          /* Use to determine short and long title or descrition */
          let titleLength = this.programService.getTitleDescMaxLength(titles, this.CONSTANTS.EN);
          let descLength = this.programService.getTitleDescMaxLength(response.descriptions, this.CONSTANTS.EN);
          let eTitleLength = this.programService.getTitleDescMaxLength(eTitle, this.CONSTANTS.EN);

          /* English titles and descriptions */
          const originalTitle = this.programService.getOriginalTitle(
            titles,
            this.CONSTANTS.EN
          )

          const englishTitle = this.programService.getTitle(
            titles,
            this.CONSTANTS.EN,
            titleLength
          )

          const englishDescription = this.programService.getDesc(
            response.descriptions,
            this.CONSTANTS.EN,
            descLength
          )

          /* Use to determine short and long title or descrition */
          titleLength = this.programService.getTitleDescMaxLength(titles, this.CONSTANTS.ES);
          descLength = this.programService.getTitleDescMaxLength(response.descriptions, this.CONSTANTS.ES);
          eTitleLength = this.programService.getTitleDescMaxLength(eTitle, this.CONSTANTS.ES);

          /* Spanish titles and descriptions */

          const spanishTitle = this.programService.getTitle(
            titles,
            this.CONSTANTS.ES,
            titleLength
          )

          const spanishDescription = this.programService.getDesc(
            response.descriptions,
            this.CONSTANTS.ES,
            descLength
          )

          /** Set Genres */
          let genreIds = response.genres || [];
          if (provider === this.CONSTANTS.GRACENOTE_PROVIDER) {
            genreIds = response.genres.forEach(element => {
              genreIds.push(element?._id);
            });
          }

          /*get genreNames*/
          const genreNames = this.genres.filter((genre) =>
          (response.genres || []).includes(genre.id)
          )

          /*get tmsId*/
          const tmsId = this.getExternalRefs(this.program.externalRefs, 'tmsId')

          this.program = {
            ...response,
            ...this.getCastAndCrewInfo(response.credits),
            originalTitle,
            englishTitle,
            englishDescription,
            descriptions: response.descriptions || [],
            spanishTitle,
            spanishDescription,
            tmsId,
            ratingContentDescriptor,
            ratingString,
            genreIds,
            genres: genreNames.map((g) => g.name),
            mpaaRatings: (mpaaRatings && mpaaRatings.id) || '',
            usaParentalRatings: (tvpgRatings && tvpgRatings.id) || '',
            brazilRatings: (brazilRatings && brazilRatings.id) || '',
            releases: response.releases || [],
            awards: this.parseAwards(),
            keywords: response.keywords || [],
            countriesOfOrigin: (response.countries || []).join(', '),
            productionCompaniesString: this.arrayToString(response.productionCompanies),
            extraInfos: response.extraInfos || [],
            provider: this.selectedProvider,
            parentTitle: response.parentTitle || [],
            vrioId: response.vrioId || null,
          }
        }
        this.getProgramImages()
      })
      this.isLoading = false
  }
  
  setMetadataVersions(metadata) {
    const providers = [this.CONSTANTS.MASTERENTITY_PROVIDER, this.CONSTANTS.GRACENOTE_PROVIDER, this.CONSTANTS.BLIM_PROVIDER]
    this.versionData.contentLock = metadata.contentLock
    providers.forEach((provider) => {
      if (metadata[provider] && metadata[provider].length) {
        this.versionData.metadataVersions.push({
          id: provider.toLowerCase(),
          name: `${this.formatProviderName(provider)} Metadata`,
          versions: metadata[provider],
        })
      }
    })
    this.versionData.metadataVersionIDs = this.versionData.metadataVersions
      .map((m) => m.id)
      .join()
  }

  formatProviderName(provider) {
    return { gracenote: 'Gracenote', masterEntity: 'Master', blim: 'Blim' }[
      provider
    ]
  }

  //parse awards, get person info
  parseAwards() {
    const awards = this.program.awards || []
    return awards.map(award => {
      const person = this.program.credits.find(
        credit => credit.personId === (award.recipient || award.recipientId)
      )
      return {
        ...award,
        recipient: person ? `${person.firstName} ${person.lastName}` : award.recipient
      }
    })
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  //get program images
  getProgramImages() {
    let fetchedImages = []

    if (this.program.provider === this.providerGracenote) {
      const grouped = this.groupBy(this.program.images, 'ratio');
      this.program.imageSizes = Object.keys(grouped)
      this.program.programImages = grouped
      fetchedImages = this.program.images || []
      this.getDefaultImage(fetchedImages)
    } else {
      this.program.images = []
      this.program.imageSizes = this.programService.getRatio()
      this.program.programImages = {}
      this.program.publishedImages = []

      this.programService.getProgramImages(this.program.programId).subscribe(data => {
        fetchedImages = (data.response || []).filter(image => image.downloadState === 'DOWNLOADED')
        this.program.programImages = this.groupBy(fetchedImages, 'ratio')
        const publishedImages = fetchedImages.filter(programImage => programImage.published)
        this.program.imageSizes.forEach(size => {
          const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
          if (publishRatio) {
            this.program.publishedImages.push(publishRatio)
          }
        })
        this.getDefaultImage(fetchedImages)
      })
    }
  }

  //get cast and crew info by credits
  getCastAndCrewInfo(castAndCrew) {
    castAndCrew = castAndCrew?.map((val: CastOrCrew) => {
      if (val.name && val.name.length > 0) {
        let firstName = val.name.firstName ? val.name.firstName : ""
        let lastName = val.name.lastName ? val.name.lastName : ""
        val.castName = firstName + " " + lastName
      } else {
        val.castName = val.firstName + " " + val.lastName
      }
      return val
    })
    return {
      credits: castAndCrew || [],
      groupedCast: this.creditsFilterAndSort(castAndCrew, 'cast', 'ord'),
      groupedCrew: this.creditsFilterAndSort(castAndCrew, 'crew', 'ord'),
      castAndCrewCategories: ['Cast', 'Crew'],
    }
  }

  //default image when no image for program
   getDefaultImage(images) {
    let imageBanner = this.CONSTANTS.IMAGE_PREVIEW_URL
    const defaultImage = this.programService.getDefaultImage(images,
      this.program.programType, this.program.provider)
    if (defaultImage) {
      if (this.program.provider.toLowerCase() === this.providerGracenote)
        defaultImage.baseUrl = this.CONSTANTS.BASE_URL;
      imageBanner = `${defaultImage.baseUrl}${defaultImage.uri}`
    }
    this.program.defaultImage = imageBanner
  }

  //grouped cast and crew
  creditsFilterAndSort(list, filterType, sortField) {
    if (list && list.length > 0) {
      return list.filter(({ type }) => type.toLowerCase() === filterType.toLowerCase()).sort((a, b) => a[sortField] - b[sortField])
    }

    return []
  }

  //convert array to string
  arrayToString(array) {
    let str = ''
    if (array && Array.isArray(array)) {
      str = array.join(', ')
    } else {
      str = array;
    }
    return str
  }

    /**
   * Alternate Episode & Alternate Season Computed
   * @return episodeAltMapping
   */
     get episodeAltMappingComputed(): episodeAltMapping  {
      if (this.program?.episodeAltMappings && this.program.episodeAltMappings.length > 0) {
        return this.program.episodeAltMappings[0]
      }
  
      return {
        channelId: null,
        episodeNumber: null,
        providerId: null,
        region: null,
        regionWide: null,
        seasonNumber: null,
      };
    }

    getUrlType(asset : Assets){
      
      if(asset.urls){
      const wideVine = asset.urls.find(url =>
        url.drms[0]?.drm === this.CONSTANTS.WIDE_VINE
      )

      const fairPlay = asset.urls.find(url =>
        url.drms[0]?.drm === this.CONSTANTS.FAIR_PLAY 
      )

      const playReady = asset.urls.find(url =>
        url.drms[0]?.drm === this.CONSTANTS.PLAY_READY 
      )
      
     this.getUrlInfo(wideVine, this.wideVineInfo)
     this.getUrlInfo(playReady, this.playReadyInfo)
     this.getUrlInfo(fairPlay, this.fairPlayInfo)
    }
  }

  //get Url info from drms and cdns
  getUrlInfo(urlType, info : AssetUrlInfo)
  {
    if(urlType){
      info.drmInfo = urlType.drms[0]
      info.cdnInfo = urlType.cdns[0]
    }
  }
}