import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  @Input() title: string;
  @Input() color: string;

  @HostBinding('style.background') get backgroundColor() {
    return this.color;
  }


  constructor() {
  }

  ngOnInit(): void {
  }

}
