import {Component, HostBinding, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-form-validation',
  template: `    <div class="text-danger">{{ message }}</div>`,
  styles: [
    `
      :host {
        margin-top: 4px;
        display: block;
      }

      div {
        font-size: 12px;
      }
    `
  ]
})
export class FormValidationComponent {
  @Input() message: string;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;

  @HostBinding('style.display') get styleDisplay() {
    return !this.formGroup.get(this.formControlName).valid && this.formGroup.get(this.formControlName).touched ? 'block' : 'none'
  }
}
