import {Component, OnInit} from '@angular/core';
import {ProgramsService} from "../programs/programs.service";
import {ActivatedRoute} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProgramMappingService} from "./program-mapping.service";
import {ConfirmationModalComponent} from "../../components/confirmation-modal/confirmation-modal.component";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Observable, of} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {AppConstants} from '../../app.constants'
import { Schedule } from '../schedules/schedule';

@Component({
  selector: 'app-program-mapping',
  templateUrl: './program-mapping.component.html',
  styleUrls: ['./program-mapping.component.scss']
})
export class ProgramMappingComponent implements OnInit {

  getParams: PaginationParams
  programMappings: ProgramMapping[]
  selectedProgram: ProgramMapping
  compareMapping: ProgramMapping
  program: ProgramMapping
  showLoader: boolean
  programTypes = ['MOVIE', 'EPISODE', 'SERIES']
  selectedProgramType: string
  genres: Genre[]
  ratings: Rating[]
  filteredRatings: Rating[]
  genreIds: string[]
  selectedRole: string
  dropList: string[]
  isSky: boolean
  rolesOfCast: string[]
  programTypeFilters = [
    {name: 'movies', abbrev: 'MV', value: true},
    {name: 'series', abbrev: 'SH', value: true},
    {name: 'episodes', abbrev: 'EP', value: true},
    {name: 'sports', abbrev: 'ES', value: true}
  ]
  mappingStatusFilters = [
    {name: 'mapped', value: false},
    {name: 'unmapped', value: true},
    {name: 'incomplete', value: false},
    {name: 'unmappable', value: false}
  ]
  page = 1
  pageSize = 20
  collectionSize = 0
  searchString: string
  readonly CONSTANTS = AppConstants

  constructor(
    private programService: ProgramsService,
    private programMappingService: ProgramMappingService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.getParams = {
      page: 1,
      size: 20,
    };
    (this.selectedProgram as any) = {
      id: null
    }
    this.selectedRole = 'Actor'
    this.selectedProgramType = 'MOVIE'
    this.dropList = []
    this.programMappings = []
    this.rolesOfCast = []
    this.program = this.programMappingService.programMappingDefaults()
    this.isSky = location.href.includes('sky')
    this.getGenres()
    this.getRatings()
    this.getFormatByCast()
    this.getProgramMappings()
  }

  getFormatByCast() {
    this.programMappingService.getListOfRolesForCast().subscribe((data: any) => {
      this.rolesOfCast = data.map((role) => role.toLowerCase())
    })
  }

  getProgramMappings(){
    this.showLoader = true
    const searchData = {
        searchString: this.searchString || '',
        types: this.programTypeFilters
          .filter(({value}) => value)
          .map(({abbrev}) => abbrev),
          status: this.mappingStatusFilters
          .filter(({value}) => value)
          .map(({name}) => name)
      }
    this.triggerSearchPrograms(searchData)
  }

  triggerSearchPrograms(searchData) {
    this.getParams = {
      page: this.page - 1,
      size: this.pageSize
    }

    if(searchData?.types.length === 0 || searchData?.status.length === 0){
      this.collectionSize = 0
      this.programMappings = []
      this.showLoader = false
    } else {
    this.programMappingService.searchProgramMappings(searchData, this.getParams).subscribe(
      (data: any) => {
        this.showLoader = false
        if (data) {
          this.collectionSize = data.totalElements
          if (data.response.length) {
            this.programMappings = data.response
            this.selectedProgram = this.programMappings[0]
            this.setDropList()
            
          } else {
            if (this.getParams.page == 0) {
              this.programMappings = []
            }
          }
        } else {
          this.programMappings = []
        }
      },
      (err) => {
        this.showLoader = false
      }
  ) 
    }
  }

  setDropList() {
    this.programMappings.forEach((programMapping, index) => {
      if (programMapping.status !== 'incomplete') {
        this.dropList.push(`dropList-${index}`)
      }
    })
  }

  formatProgramType(type) {
    return {
      MOVIE: 'MV',
      EPISODE: 'EP',
      SERIES: 'SH',
      // VLS-2293: add SHOW and SPORT as SH
      SHOW: 'SH',
      SPORT: 'SH'
    }[type]
  }

  getResourceTypeStyle(type) {
    // VLS-2293: add program type SHOW and SPORT
    return {MOVIE: 'warning', SERIES: 'danger', EPISODE: 'secondary', SHOW: 'info', SPORT: 'primary'}[type]
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  openModal(modalName, programMappingId = null) {
    if (programMappingId) {
      this.compareMapping = this.programMappings.find(({id}) => id === programMappingId)
    }
    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  getTitleByLang(program, field, lang) {
    let titleDesc = ''
    if (program[field]) {
      for (let title of program[field]) {
        if (title.lang.includes(lang)) {
          titleDesc = title.value
          break
        }
      }
      // VLS-2476: check if title or episodeTitle is not empty array
      if (titleDesc == '' && program[field].length > 0) {
        titleDesc = program[field][0].value
      }
    }
    return titleDesc
  }

  getTitleDesc(program, field) {
    return this.programService.getFirstTitleDescValue(program, field)
  }

  getTitleDescByLang(program, field, lang) {
    let titleDesc = ''
    if (program[field]) {
      const titleOrDesc = program[field].find(titleDesc => titleDesc?.lang.split('-')[0] === lang)
      titleDesc = titleOrDesc?.value
    }
    return titleDesc
  }

  createMapping() {
    if (this.validated()) {
      const confirm = this.showAlert(
        'Confirm create PPV',
        'Are you sure you want to continue?',
        'lg',
        false)
      confirm.result.then((result) => {
        if (result) {
          this.showLoader = true
          this.buildTitleDesc()
          this.program.genres = this.genreIds
          if (this.program.ratings.length) {
            this.program.ratings = this.program.ratings.map(rating => {
              return {
                id: rating.id,
                rating: rating.name,
                org: rating.org,
                code: rating.code,
              }
            })
          }
          this.programMappingService.createMapping(this.program).subscribe((data: any) => {
            this.showLoader = false
            this.programMappings.push(data.response)
            this.selectedProgram = this.programMappings[0]
            this.showAlert('Success', 'Successfully created PPV program')
            this.closeModal()
          })
        }
      })
    }
  }

  validated() {
    const inValid = []
    let fieldToValidate = ['englishTitle', 'spanishTitle']

    if (this.program.type === 'MOVIE') {
      fieldToValidate.push('releaseYear')
      fieldToValidate = fieldToValidate.filter(field => field !== 'origAirDate')
    } else {
      fieldToValidate.push('origAirDate')
      fieldToValidate = fieldToValidate.filter(field => field !== 'releaseYear')
    }

    fieldToValidate.forEach(field => {
      if (!this.program.id) {
        const inputField = document.getElementById(field) as any
        if (!inputField.value) {
          inValid.push(field)
          this.displayValidationMessage(inputField)
        }
      }
    })
    return inValid.length === 0
  }

  onInputKeyup(event) {
    const {id, value} = event.target
    const inputField = document.getElementById(id)
    if (value) {
      inputField.classList.remove('is-invalid')
    } else {
      this.displayValidationMessage(inputField)
    }
  }

  displayValidationMessage(inputField) {
    if (inputField) {
      if (inputField.nextSibling) {
        inputField.parentNode.removeChild(inputField.nextSibling)
      }
      inputField.insertAdjacentHTML(
        'afterend',
        '<div class="invalid-feedback">Required field</div>'
      )
      inputField.classList.add('is-invalid')
    }
  }

  buildTitleDesc() {
    if (this.program.englishTitle) {
      this.program.titles.push({
        value: this.program.englishTitle,
        length: 120,
        lang: 'en'
      })
    }

    if (this.program.englishDescription) {
      this.program.descriptions.push({
        value: this.program.englishDescription,
        length: 120,
        lang: 'en'
      })
    }

    if (this.program.spanishTitle) {
      this.program.titles.push({
        value: this.program.spanishTitle,
        length: 120,
        lang: this.isSky ? 'pt' : 'es'
      })
    }

    if (this.program.spanishDescription) {
      this.program.descriptions.push({
        value: this.program.spanishDescription,
        length: 120,
        lang: this.isSky ? 'pt' : 'es'
      })
    }
  }

  getGenres() {
    this.programService.getAllGenres().subscribe(data => {
      const genreList = []
      data.response.forEach((genre) => {
        const enGenre = (genre.names || []).find(
          (g) => g.language === 'en'
        )
        if (enGenre) {
          genreList.push({
            id: genre.id,
            name: enGenre.value,
          })
        }
      })
      this.genres = genreList
    })
  }

  getRatings() {
    this.programService.getAllRatings().subscribe((data: any) => {
      this.ratings = data.response
      this.filteredRatings = [
        ...this.programService.filterRatings(this.ratings, 'MPAA'),
        ...this.programService.filterRatings(this.ratings, 'TVPG')
      ]
    })
  }

  genreOnChange() {
    this.genreIds = this.program.genres.map(({id}) => id)
  }

  submitMapping() {
    const selectedMappings = this.programMappings.filter(mapping => mapping.checked)
    if (selectedMappings.length) {
      const confirm = this.showAlert(
        'Confirm Save and Next',
        'Are you sure you want to continue?',
        'lg',
        false)
      confirm.result.then((result) => {
        if (result) {
          this.showLoader = true
          return this.programMappingService.submitMapping(selectedMappings).subscribe(data => {
            this.showLoader = false
            this.showAlert('Success', 'Program successfully mapped')
            this.getProgramMappings()
          })
        }
      })
    } else {
      this.showAlert('Warning', 'Select program to map')
    }
  }

  skipMapping(providerInfo = []) {
    let skip = false;
    if (providerInfo.length) {
      const provider = providerInfo.find(provider => provider.key === 'skipMetadataEnrichment');
      if (provider) {
        skip = provider.value === 'true'
      }
    }
    return skip
  }

  requestProviderInfo(programMappingId) {
    const confirm = this.showAlert(
      'Confirm request provider',
      'Are you sure you want to continue?',
      'lg',
      false)
    confirm.result.then((result) => {
      if (result) {
        this.showLoader = true
        return this.programMappingService.requestProviderInfo(programMappingId).subscribe(data => {
          this.showLoader = false
          this.showAlert('Success', 'Provider successfully requested')
          const selected = this.programMappings.find(({id}) => id === programMappingId)
          selected.status = 'incomplete'
        }, res => {
          this.showLoader = false
          if (res && res.errors) {
            this.showAlert('Error', res.errors[0])
          }
        })
      }
    })
  }

  formatRatings(ratings = []) {
    let formattedRatings = ''
    if (ratings.length) {
      const ratingIds = ratings.map(rating => rating.id)
      // VLS-2221: fix ratings did not display correctly
      const selectedRatings = this.ratings.filter(r => ratingIds.includes(r.id)).map(rating => rating.rating)
      formattedRatings = selectedRatings.join(', ')
    }
    return formattedRatings
  }

  formatGenres(genreIds = []) {
    let formattedGenres = ''
    if (genreIds.length) {
      const selectedGenres = this.genres.filter(genre => genreIds.includes(genre.id)).map(g => g.name)
      formattedGenres = selectedGenres.join(', ')
    }
    return formattedGenres
  }

  checkFirstNameIsExist(credit) {
    return credit.firstName !== null && credit.firstName !== '';
  }

  checkLastNameIsExist(credit) {
    return credit.lastName !== null && credit.lastName !== '';
  }

  formatCreditsByCast(credits) {
    let formattedCredits = ''
    if (credits?.length) {
      const cast = credits
        .filter(cast => cast.role !== null && cast.role !== '' && this.rolesOfCast.includes(cast.role.toLowerCase()))
        .filter(cast => this.checkFirstNameIsExist(cast) || this.checkLastNameIsExist(cast))
        .map((cast) => {
          return this.getFirstNameAndLastName(cast);
        })
      formattedCredits = cast.join(', ')
    }
    return formattedCredits
  }

  formatCreditsByCrew(credits) {
    let formattedCredits = ''
    if (credits?.length) {
      const crew = credits
        .filter(crew => crew.role !== null && crew.role !== '' && !this.rolesOfCast.includes(crew.role.toLowerCase()))
        .filter(crew => this.checkFirstNameIsExist(crew) || this.checkLastNameIsExist(crew))
        .map((crew) => {
          return this.getFirstNameAndLastName(crew);
        })
      formattedCredits = crew.join(', ')
    }
    return formattedCredits
  }

  private getFirstNameAndLastName(credit) {
    if (!this.checkFirstNameIsExist(credit) && this.checkLastNameIsExist(credit)) {
      return `${credit.lastName}`
    } else if (this.checkFirstNameIsExist(credit) && !this.checkLastNameIsExist(credit)) {
      return `${credit.firstName}`
    } else {
      return `${credit.firstName} ${credit.lastName}`
    }
  }

  onProgramIdChange(programId, index = null) {
    this.programService.getProgramVersions(programId).subscribe((data) => {
      const programVersions = data.response.masterEntity
      if (programVersions.length) {
        let versionId = programVersions[0].id
        const published = programVersions.find(program => program.published)
        if (published) {
          versionId = published.id
        }
        this.getProgram(versionId, index)
      } else {
        this.showAlert(
          'Warning',
          'No active version for this program yet.',
          'lg'
        )
      }
    })
  }

  getProgram(versionId, index) {
    this.programService
      .getProgramByVersion(versionId)
      .subscribe((data) => {
        const response = data.response
        if (response) {
          let selected = this.programMappings.find(({id}) => id === this.selectedProgram.id)
          if (index) {
            selected = this.programMappings[index]
          }
          if (selected.status !== 'incomplete') {
            selected.status = 'mapped'
            selected.programId = response.programId
            selected.mappedProgram = response
            selected.mappedProgram.releaseYear = response.releaseYear || response.yearOfRelease || response.origAirDate.split('-')[0]
            selected.mappedProgram.type = response.programType
            selected.mappedProgram.parentTitle = response.parentTitle
            selected.mappedProgram.titles = response.titles
            const tmsId = this.extractRefs(response.externalRefs, 'tmsId')
            const rootId = this.extractRefs(response.externalRefs, 'rootId')
            if (tmsId) {
              selected.externalRefs.push(tmsId)
            }
            if (rootId) {
              selected.externalRefs.push(rootId)
            }
          } else {
            this.showAlert(
              'Warning',
              'Could not map on incomplete program. Please select other program',
              'lg'
            )
          }
        }
      })
  }

  extractRefs(externalRefs, refName) {
    return externalRefs.find(ref => ref.refName === refName)
  }

  onDrop(event: CdkDragDrop<Schedule>, index) {
    this.onProgramIdChange(event.item.data.programId, index)
  }

  searchCredits = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.programService.searchCelebrities(term).pipe(
          catchError(() => {
            return of([])
          })
        )
      )
    )
  }

  formatter(value) {
    return `${value.names[0].firstName} ${value.names[0].lastName || value.names[0].lasName}`
  }

  inputFormatBandListValue(value: any) {
    if (value.names) {
      return `${value.names[0].firstName} ${value.names[0].lastName || value.names[0].lasName}`
    }
  }

  onSearchSelect(event, inputSearch) {
    event.preventDefault();
    const item = event.item
    inputSearch.value = ''
    this.program.credits.push({
      role: this.selectedRole,
      type: this.selectedRole === 'Actor' ? 'cast' : 'crew',
      personId: item.id,
      firstName: item.names[0].firstName,
      lastName: item.names[0].lastName || item.names[0].lasName
    })
  }

  removeCredits(personId) {
    this.program.credits = this.program.credits.filter(credit => credit.personId !== personId)
  }

  showAlert(type, message, size = 'sm', isAlert = true) {
    this.showLoader = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: size,
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = isAlert

    if (!isAlert) {
      return modalRef
    }
  }


  /**
   * Get the reason and detail indicating why Gracenote
   * was not able to map a program
   */
  getReason(providerInfo) {
    // Handle case where no reason is provided from Gracenote
    // Will default to the string being set
    let reason = 'No details provided'
    providerInfo.forEach(pInfo => {
      if (pInfo.key === 'Unmappable.message' && pInfo.value?.reason) {
        reason = pInfo.value?.reason
        if (pInfo.value?.detail) {
          reason += ': ' + pInfo.value?.detail.join(', ')
        }
      }
    })
    return reason;
  }

  getSystemInfo(programMapping: ProgramMapping): string {
    if (!programMapping?.dmsId) {
      return 'PPV';
    }

    return programMapping?.externalRefs?.map(ref => ref.system).find(system => system === 'TBX') ?? 'DMS';
  }

  searchChange(val, isKeyUp) {
    if(!val || isKeyUp){
      this.getProgramMappings()
    }
  }
}
