import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ProgramsService } from '../../pages/programs/programs.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../../pages/users/users.service'
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component"
import { AppConstants } from 'src/app/app.constants'
import { PlatformService } from 'src/app/services/platform.service'

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss'],
})
export class VersionsComponent implements OnInit {
  @Input() versionData: VersionData
  @Output() versionId: EventEmitter<any> = new EventEmitter()
  @Output() type: EventEmitter<ResourceType> = new EventEmitter()
  @Input() selectedVersion: string
  @Input() isLock: boolean
  @Output() versionLock: EventEmitter<boolean> = new EventEmitter()
  @Output() hideVersion: EventEmitter<boolean> = new EventEmitter()
  isSchedulesPage: boolean
  selectedType: ResourceType
  pageName: string
  dthPlatform : boolean
  versionStatusStyle = {
    New: 'info',
    Active: 'danger',
    PUB: 'success',
    SAVED: 'warning',
  }

  constructor(
    private programService: ProgramsService,
    private modalService: NgbModal,
    private userService: UserService,
    private platformService: PlatformService
  ) {}

  ngOnInit() {
    this.isLock = false
    const metadataVersions = this.versionData.metadataVersions || []
    this.pageName = this.versionData.pageName
    this.isSchedulesPage = this.versionData.pageName === 'schedules'

    if (this.isSchedulesPage) {
      this.versionData.metadataVersions = metadataVersions.filter(
        (each) => each.id === 'masterentity'
      )
    }
    this.isDthPlatform()
  }

  lockContent() {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    const action = this.versionData.contentLock ? 'unlock' : 'lock'
    modal.componentInstance.title = 'Confirm'
    modal.componentInstance.message = `Are you sure you want to ${action} this versions?`
    modal.componentInstance.isAlert = false
    modal.result.then(result => {
      if (result) {
        this.versionData.contentLock = !this.versionData.contentLock
        this.versionLock.emit(this.versionData.contentLock)
      }
    })
  }

  setStatusStyles(status) {
    return this.versionStatusStyle[status]
  }

  createNewVersion() {
    if (this.selectedVersion === '0' && this.isSchedulesPage) {
      const modal = this.modalService.open(ConfirmationModalComponent , {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      })
      modal.componentInstance.title = 'Warning'
      modal.componentInstance.message = 'Please save the first created version'
      modal.componentInstance.isAlert = true
    } else {
      this.versionId.emit({ id: '0', provider: 'masterentity' })
    }
    if (!this.isSchedulesPage) {
      this.type.emit(this.selectedType)
    }
  }

  onItemClick(id, provider) {
    this.selectedVersion = id
    this.versionId.emit({ id, provider })
  }

  isDthPlatform(){
    this.dthPlatform = this.platformService.verifyPlatform(AppConstants.DTH)
  }
}
