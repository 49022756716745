import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() title: string
  @Input() message: string
  @Input() isAlert: boolean
  @Input() isConfirm: boolean
  @Input() errorMessages: string[]

  constructor(public activeModal: NgbActiveModal) {}
}
