import { Component } from '@angular/core'
import { LoginService } from "./login.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  username: string;
  password: string;
  showLoader: boolean;
  errorMessage: string;
  submitted = false;
  isValid = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
  ) {}

  isFormValid() {
    this.isValid = true;
    if (!this.username || !this.password) {
      this.errorMessage = 'Username or password is required.';
      this.isValid = false;
    }
    return this.isValid;
  }

  login() {
    if (this.isFormValid()) {
      this.errorMessage = ''
      this.showLoader = true
      this.loginService.login(this.username, this.password).subscribe(
        ({ user, errorMessage }) => {
          this.showLoader = false;
          if (user) {
            this.router.navigate(
              ['/schedules'],
              { queryParams:  { username: user.username } }
            );
          }
          this.errorMessage = errorMessage;
        }, err => {
          this.showLoader = false;
          localStorage.clear();
        });
    }
  }
}
