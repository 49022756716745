import { Component } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'VLS'
  isAuth: boolean

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {
    // this.isAuth = true;
    this.isAuth = false
    // subscribe to route change and set title
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute)
        rt.data.subscribe((data) => {
          this.titleService.setTitle(`${this.title} | ${data.title}`)
          // TODO: check auth here
        })
      })
  }

  getChild(activatedRoute: ActivatedRoute) {
    return activatedRoute.firstChild
      ? this.getChild(activatedRoute.firstChild)
      : activatedRoute
  }
}
