 /*
   * =============================================================================
   * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
 	  * RESERVED
   *
   * This software is the confidential and proprietary information of Vrio Corp.
   * ("Proprietary Information"). Any use, reproduction, distribution or
   * disclosure of the software or Proprietary Information, in whole or in part,
   * must comply with the terms of the license agreement, nondisclosure agreement
   * or contract entered into with Vrio Corp. providing access to this software.
   *
   * ============================================================================
   */
  
 import { Component, OnInit } from '@angular/core'
 import { ChartDataSets, ChartOptions, ChartType } from 'chart.js'
 import { Label } from 'ng2-charts'
 import * as pluginDataLabels from 'chartjs-plugin-datalabels'
 import { AssetService } from '../../assets-management/assets.service'
 import { AppConstants } from '../../../app.constants'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AssetStatsService } from './asset-stats.service'

@Component({
  selector: 'app-asset-stats',
  templateUrl: './asset-stats.component.html',
  styleUrls: ['./asset-stats.component.scss'],
})
export class AssetStatsComponent implements OnInit {
  channels: any[]
  serviceCategories: any[]
  allChannels: any[]
  allServiceCategories: any[]
  types: any[]
  selectedChannelIds: any[]
  selectedCategoryIds: any[]
  selectedType: any[]
  tablePublished: any[]
  tableUnpublished: any[]
  isLoading: boolean
  searchChannel: String
  searchCategory: String
  totalElemChannels: any
  totalElemCategories: any
  enableSelectAllMatchChannel: boolean = false
  enableSelectAllMatchCategory: boolean = false
  searchChannelChanged: Subject<string> = new Subject<string>()
  searchCategoryChanged: Subject<string> = new Subject<string>()
  getParams: any = {
    page: 1,
    size: 10
  }
  getCategoryParams: any = {
    page: 1,
    size: 10
  }
  readonly CONSTANTS = AppConstants

  /* Bar Charts Options */
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontStyle: 'bold',
            fontSize: 13,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero:true,
            fontStyle: 'bold',
            fontSize: 13,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 11,
        },
      },
    },
  }
  barChartLegend = true
  barChartType: ChartType = 'bar'
  barChartPlugins = [
    {
      ...pluginDataLabels,
      beforeInit: (chart) => {
        chart.legend.afterFit = function () {
          this.height += 10
        }
      },
    },
  ]

  /* Total Data - Total Current Assets */
  barChartLabels: Label[] = []
  barChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'All Assets',
      backgroundColor: '#F3A406',
      hoverBackgroundColor: '#F3A406',
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'Yesterday Assets',
      backgroundColor: '#082672',
      hoverBackgroundColor: '#082672',
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'Last Week Assets',
      backgroundColor: '#0B9DE5',
      hoverBackgroundColor: '#0B9DE5',
    },
  ]

  constructor(
    private assetService: AssetService,
    private assetStatsService: AssetStatsService
  ) {}

  ngOnInit(): void {
    this.reset()
    this.getAssetStats()
    this.debouncedSearchChannel()
    this.debouncedSearchCategory()
  }

  reset() {
    this.channels = []
    this.serviceCategories = []
    this.selectedChannelIds = []
    this.selectedCategoryIds = []
    this.searchChannel = ""
    this.searchCategory = ""
    this.types = [
      { name: this.CONSTANTS.ALL, value: true },
      { name: this.CONSTANTS.MOVIE_NAME, value: true },
      { name: this.CONSTANTS.SHOW_NAME, value: true },
      { name: this.CONSTANTS.EPISODE_NAME, value: true },
      { name: this.CONSTANTS.SPORT_SERIES_NAME, value: true },
      { name: this.CONSTANTS.SERIES_NAME, value: true },
    ]
    this.selectedType = this.types.filter((item) => item.value === true && item.name !== this.CONSTANTS.ALL)
    const typeNames = this.selectedType.map((item) => item.name)
    this.barChartLabels = [...typeNames, ...typeNames]

    this.searchChannels(true)
    this.searchCategories(true)
    this.setTablePublishedDefault()
    this.setTableUnPublishedDefault()
  }

  onFilterChange(field: String, index: number) {
    if (field === 'types') {
      // Not apply searching for filtering program types
      this.selectAllNoSearch(field, index)
      this.selectedType = this.types.filter((item) => item.value === true && item.name !== this.CONSTANTS.ALL)
      const typeNames = this.selectedType.map((item) => item.name)
      this.barChartLabels = [...typeNames, ...typeNames]
    } else {
      // Apply searching for filtering channels and service categories
      this.selectAllWithSearch(field, index)
      if (field === 'channels') {
        this.selectedChannelIds = this.allChannels.filter((item) => item.value === true).map((item) => item.id)
        this.selectedChannelIds = this.selectedChannelIds.length > 0 ? this.selectedChannelIds : null
      } else if (field === 'serviceCategories') {
        this.selectedCategoryIds = this.allServiceCategories.filter((item) => item.value === true && item.name !== this.CONSTANTS.ALL).map((item) => item.id)
        this.selectedCategoryIds = this.selectedCategoryIds.length > 0 ? this.selectedCategoryIds : null
      }
    }
    this.getAssetStats()
  }

  selectAllNoSearch(field: any, index: number) {
    // index = 0 -> select/unselect all
    if (index === 0) {
      if (this[field][0].value === true) {
        this[field].forEach((item) => {
          item.value = true
        })
      } else {
        this[field].forEach((item) => {
          item.value = false
        })
      }
    } else {
      // index != 0 -> select/unselect an item
      // if having any unselected items -> untick select all
      // if all items are selected -> tick select all
      if (this[field].find((item) => item.value === false && item.name !== this.CONSTANTS.ALL)) {
        this[field][0].value = false
      } else {
        this[field][0].value = true
      }
    }
  }

  selectAllWithSearch(field: any, index: number) {
    const enableSelectAllMatch = field === "channels" ? "enableSelectAllMatchChannel" : "enableSelectAllMatchCategory"
    const allField = field === "channels" ? "allChannels" : "allServiceCategories"
    // index = 0 -> select/unselect all
    if (index === 0) {
      if (!this[enableSelectAllMatch]) {
        if (this[field][0].value === true) {
          this[field].forEach((item) => {
            item.value = true
          })
          this[allField].forEach((item) => {
            item.value = true
          })
        } else {
          this[field].forEach((item) => {
            item.value = false
          })
          this[allField].forEach((item) => {
            item.value = false
          })
        }
      } else {
        if (this[field][0].value === true) {
          this[field].forEach((item) => {
            item.value = true
            if (item.name !== this.CONSTANTS.ALL) {
              this[allField].find((elem) => elem.id === item.id).value = item.value
            }
          })
        } else {
          this[field].forEach((item) => {
            item.value = false
            if (item.name !== this.CONSTANTS.ALL) {
              this[allField].find((elem) => elem.id === item.id).value = item.value
            }
          })
        }
      }
    } else {
      this[allField].find((item) => item.id === this[field][index].id).value = this[field][index].value
      if (!this[enableSelectAllMatch]) {
        if (this[allField].find((item) => item.value === false)) {
          this[field][0].value = false
        } else {
          this[field][0].value = true
        }
      } else {
        if (this[field].find((item) => item.value === false && item.name !== this.CONSTANTS.ALL)) {
          this[field][0].value = false
        } else {
          this[field][0].value = true
        }
      }
    }
  }

  getAssetStats() {
    this.isLoading = true
    // if no program type selected, reset chart
    // else call API to get data
    if (this.selectedType.length === 0) {
      this.barChartData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.barChartData[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.barChartData[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.isLoading = false
    } else {
      this.assetStatsService.getAssetStats(this.selectedCategoryIds, this.selectedChannelIds).subscribe((data : any) => {
        if (data?.response) {
          // reset published and unpublished table
          this.setTablePublishedDefault()
          this.setTableUnPublishedDefault()

          // if having all asset data, map data into chart
          // else set 0 by default
          if (data?.response['assetAll'] != null) {
            const assetAll = data?.response['assetAll']
            this.selectedType.forEach((item, index) => {
              const publishedItem = assetAll.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === true)
              const unPublishedItem = assetAll.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === false)
              if (publishedItem != null || publishedItem != undefined) {
                this.barChartData[0].data[index] = publishedItem.count
              } else {
                this.barChartData[0].data[index] = 0
              }
  
              if (unPublishedItem != null || unPublishedItem != undefined) {
                this.barChartData[0].data[this.selectedType.length + index] = unPublishedItem.count
              } else {
                this.barChartData[0].data[this.selectedType.length + index] = 0
              }
  
              // calculate data by each program type for published table
              this.tablePublished[index + 1].assetAll += this.barChartData[0].data[index]
              // calculate data by each program type for unpublished table
              this.tableUnpublished[index + 1].assetAll += this.barChartData[0].data[this.selectedType.length + index]
              // calculate total published item
              this.tablePublished[0].assetAll += this.tablePublished[index + 1].assetAll
              // calculate total unpublished item
              this.tableUnpublished[0].assetAll += this.tableUnpublished[index + 1].assetAll
            })
          } else {
            this.barChartData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
  
          // if having yesterday asset data, map data into chart
          // else set 0 by default
          if (data?.response['assetYesterday'] != null) {
            const assetYesterday = data?.response['assetYesterday']
            this.selectedType.forEach((item, index) => {
              const publishedItem = assetYesterday.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === true)
              const unPublishedItem = assetYesterday.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === false)
              if (publishedItem != null || publishedItem != undefined) {
                this.barChartData[1].data[index] = publishedItem.count
              } else {
                this.barChartData[1].data[index] = 0
              }
  
              if (unPublishedItem != null || unPublishedItem != undefined) {
                this.barChartData[1].data[this.selectedType.length + index] = unPublishedItem.count
              } else {
                this.barChartData[1].data[this.selectedType.length + index] = 0
              }
  
              // calculate data by each program type for published table
              this.tablePublished[index + 1].assetYesterday += this.barChartData[1].data[index]
              // calculate data by each program type for unpublished table
              this.tableUnpublished[index + 1].assetYesterday += this.barChartData[1].data[this.selectedType.length + index]
              // calculate total published item
              this.tablePublished[0].assetYesterday += this.tablePublished[index + 1].assetYesterday
              // calculate total unpublished item
              this.tableUnpublished[0].assetYesterday += this.tableUnpublished[index + 1].assetYesterday
            })
          } else {
            this.barChartData[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
  
          // if having last week asset data, map data into chart
          // else set 0 by default
          if (data?.response['assetLastWeek'] != null) {
            const assetLastWeek = data?.response['assetLastWeek']
            this.selectedType.forEach((item, index) => {
              const publishedItem = assetLastWeek.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === true)
              const unPublishedItem = assetLastWeek.find((elem) => elem.programType === item.name.toUpperCase() && elem.published === false)
              if (publishedItem != null || publishedItem != undefined) {
                this.barChartData[2].data[index] = publishedItem.count
              } else {
                this.barChartData[2].data[index] = 0
              }
  
              if (unPublishedItem != null || unPublishedItem != undefined) {
                this.barChartData[2].data[this.selectedType.length + index] = unPublishedItem.count
              } else {
                this.barChartData[2].data[this.selectedType.length + index] = 0
              }
  
              // calculate data by each program type for published table
              this.tablePublished[index + 1].assetLastWeek += this.barChartData[2].data[index]
              // calculate data by each program type for unpublished table
              this.tableUnpublished[index + 1].assetLastWeek += this.barChartData[2].data[this.selectedType.length + index]
              // calculate total published item
              this.tablePublished[0].assetLastWeek += this.tablePublished[index + 1].assetLastWeek
              // calculate total unpublished item
              this.tableUnpublished[0].assetLastWeek += this.tableUnpublished[index + 1].assetLastWeek
            })
          } else {
            this.barChartData[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        }
        this.isLoading = false
      })
    }
  }

  setTablePublishedDefault() {
    this.tablePublished = []
    this.tablePublished.push({
      name: 'TRUE',
      assetAll: 0,
      assetYesterday: 0,
      assetLastWeek: 0
    })
    this.selectedType.forEach((item) => {
      const type = {
        name: item.name,
        assetAll: 0,
        assetYesterday: 0,
        assetLastWeek: 0
      }
      this.tablePublished.push(type)
    })
  }

  setTableUnPublishedDefault() {
    this.tableUnpublished = []
    this.tableUnpublished.push({
      name: 'FALSE',
      assetAll: 0,
      assetYesterday: 0,
      assetLastWeek: 0
    })
    this.selectedType.forEach((item) => {
      const type = {
        name: item.name,
        assetAll: 0,
        assetYesterday: 0,
        assetLastWeek: 0
      }
      this.tableUnpublished.push(type)
    })
  }

  onScroll(filter: String, event: any) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= 5
      if (filter === "channels") {
        this.getParams.page++
      } else {
        this.getCategoryParams.page++
      }
      this.triggerSearch(filter)
    }
  }

  triggerSearch(filter: String) {
    this.isLoading = true
    if (filter === 'channels') {
      this.searchChannels()
    } else {
      this.searchCategories()
    }
  }

  searchKeyup(filter: String, event: any, container: any) {
    if (filter === "channels") {
      this.searchChannelChanged.next(event.target.value)
    } else {
      this.searchCategoryChanged.next(event.target.value)
    }
    container.scrollTop = 0
  }

  searchChange(filter: String, event: any, container: any) {
    if (filter === "channels") {
      this.getParams.page = 1
    } else {
      this.getCategoryParams.page = 1
    }
    container.scrollTop = 0
    if (!event) {
      this.triggerSearch(filter)
    }
  }

  debouncedSearchChannel() {
    this.searchChannelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchChannel = value
        if (!!value) {
          this.getParams.page = 1
        }
        this.triggerSearch("channels")
      })
  }

  debouncedSearchCategory() {
    this.searchCategoryChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchCategory = value
        if (!!value) {
          this.getCategoryParams.page = 1
        }
        this.triggerSearch("serviceCategories")
      })
  }

  searchCategories(isGetAll: Boolean = false) {
    // check if searching category
    // -> select all is select all category matched with the search string
    // -> enableSelectAllMatchCategory = true
    if (this.searchCategory !== "") {
      this.enableSelectAllMatchCategory = true
    } else {
      // -> select all is select all category
      // -> enableSelectAllMatchCategory = true
      this.enableSelectAllMatchCategory = false
    }
    const searchData = {
      searchString: this.searchCategory || ''
    }
    this.assetStatsService.searchCategories(searchData, this.getCategoryParams).subscribe((data : any) =>{
      if (data?.response) {
        this.totalElemCategories = data?.totalElements || 0
        // load category data in the 1st time
        if(isGetAll) {
          this.getAllCategories(this.totalElemCategories)
        }
        if (this.getCategoryParams.page <= data?.totalPages ) {
          if (this.getCategoryParams.page === 1) {
            const oldSelectAll = isGetAll ? true : this.serviceCategories[0]?.value
            this.serviceCategories = data.response.map((item) => {
              return {
                id: item.id,
                name: item.name,
                // if this is the 1st time loading category data, item should be checked by default
                // if not, item should retain its previous state
                value: isGetAll ? true : this.allServiceCategories.find((elem) => elem.id === item.id).value
              }
            })

            // set value for select all when searching
            const haveUnselectedItem = this.allServiceCategories?.some((item) => item.value === false && (item.name.toLowerCase()).includes(this.searchCategory))
            const newSelectAll = !haveUnselectedItem ? true : oldSelectAll
            this.serviceCategories.unshift({
              id: null,
              name: this.CONSTANTS.ALL,
              value: newSelectAll
            })
          } else {
            const newCategories = data.response.map((item) => {
              return {
                id: item.id,
                name: item.name,
                value: this.allServiceCategories.find((elem) => elem.id === item.id).value
              }
            })
            this.serviceCategories.push(...newCategories)
          }
        } else {
          this.getCategoryParams.page--
          if (data?.totalPages === 0) {
            this.serviceCategories = []
          }
        }
      }
      this.isLoading = false
    })
  }

  searchChannels(isGetAll: Boolean = false) {
    // check if searching channel
    // -> select all is select all channel matched with the search string
    // -> enableSelectAllMatchChannel = true
    if (this.searchChannel !== "") {
      this.enableSelectAllMatchChannel = true
    } else {
      // -> select all is select all channel
      // -> enableSelectAllMatchChannel = true
      this.enableSelectAllMatchChannel = false
    }
    const searchData = {
      searchString: this.searchChannel || ''
    }
    this.assetStatsService.searchChannels(searchData, this.getParams).subscribe((data : any) =>{
      if (data?.response) {
        this.totalElemChannels = data?.totalElements || 0
        // load category data in the 1st time
        if(isGetAll) {
          this.getAllChannels(this.totalElemChannels)
        }
        if (this.getParams.page <= data?.totalPages ) {
          if (this.getParams.page === 1) {
            const oldSelectAll = isGetAll ? true : this.channels[0]?.value
            this.channels = data.response.map((item) => {
              return {
                id: item.id,
                name: item.name,
                // if this is the 1st time loading channel data, item should be checked by default
                // if not, item should retain its previous state
                value: isGetAll ? true : this.allChannels.find((elem) => elem.id === item.id).value
              }
            })

            // set value for select all when searching
            const haveUnselectedItem = this.allChannels?.some((item) => item.value === false && (item.name.toLowerCase()).includes(this.searchChannel))
            const newSelectAll = !haveUnselectedItem ? true : oldSelectAll
            this.channels.unshift({
              id: null,
              name: this.CONSTANTS.ALL,
              value: newSelectAll
            })
          } else {
            const newChannels = data.response.map((item) => {
              return {
                id: item.id,
                name: item.name,
                value: this.allChannels.find((elem) => elem.id === item.id).value
              }
            })
            this.channels.push(...newChannels)
          }
        } else {
          this.getParams.page--
          if (data?.totalPages === 0) {
            this.channels = []
          }
        }
      }
      this.isLoading = false
    })
  }

  getAllChannels(totalElements: number) {
    const searchData = {
      searchString: ""
    }
    const params = {
      page: 1,
      size: totalElements
    }
    this.assetStatsService.searchChannels(searchData, params).subscribe((data : any) =>{
      if (data?.response) {
        this.allChannels = data.response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            value: true
          }
        })
        this.selectedChannelIds = this.allChannels.map((item) => item.id)
      }
    })
  }

  getAllCategories(totalElements: number) {
    const searchData = {
      searchString: ""
    }
    const params = {
      page: 1,
      size: totalElements
    }
    this.assetStatsService.searchCategories(searchData, params).subscribe((data : any) =>{
      if (data?.response) {
        this.allServiceCategories = data.response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            value: true
          }
        })
        this.selectedCategoryIds = this.allServiceCategories.map((item) => item.id)
      }
      })
  }
}