import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationModalComponent } from './confirmation-modal.component'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
  constructor(private modalService: NgbModal) {}

  createModal({
    title,
    message = null,
    isAlert = true,
    isConfirm = false,
    errorMessages = null,
  }) {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    modal.componentInstance.title = title
    modal.componentInstance.message = message
    modal.componentInstance.isAlert = isAlert
    modal.componentInstance.isConfirm = isConfirm
    modal.componentInstance.errorMessages = errorMessages

    return modal
  }

  createDefaultErrorMessage() {
    const modal = this.createModal({
      title: 'Error',
      message: 'Something went wrong!',
    })

    return modal
  }
}
