/*
   * =============================================================================
   * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
 	  * RESERVED
   *
   * This software is the confidential and proprietary information of Vrio Corp.
   * ("Proprietary Information"). Any use, reproduction, distribution or
   * disclosure of the software or Proprietary Information, in whole or in part,
   * must comply with the terms of the license agreement, nondisclosure agreement
   * or contract entered into with Vrio Corp. providing access to this software.
   *
   * ============================================================================
   */
  
import { Injectable } from '@angular/core'
import { HttpClient} from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { AppConstants } from '../../../app.constants'

@Injectable({ providedIn: 'root' })
export class AssetStatsService {
  assetUrl: string
  getParams: {
    page: 1
    size: 10
  }
  readonly CONSTANTS = AppConstants

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.assetUrl = `${apiUrl}/assets`
  }

  searchChannels(searchData, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.assetUrl}/search-channels?page=${page}&size=${size}`
    return this.http.post<any>(searchUrl, searchData)
  }

  searchCategories(searchData, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.assetUrl}/search-categories?page=${page}&size=${size}`
    return this.http.post<any>(searchUrl, searchData)
  }

  getAssetStats(selectedCategoryIds, selectedChannelIds) {
    const data = {
      "categories": selectedCategoryIds,
      "channelIds": selectedChannelIds,
    }
    const searchUrl = `${this.assetUrl}/asset-stats`
    return this.http.post<any>(searchUrl, data)
  }
}