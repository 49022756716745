import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { AppConstants } from '../../app.constants'
import { ProgramsService } from '../programs/programs.service'

@Injectable({ providedIn: 'root' })
export class VenuesService {
  venuesUrl: string
  readonly CONSTANTS = AppConstants

  constructor(
    private programService: ProgramsService,
    private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.venuesUrl = `${apiUrl}/v2/venues`
  }

  venueDefaults() {
    return {
      _id: '',
      id: '',
      externalId: '',
      names: [],
      cities: [],
      countryCode: '',
      address: [],
      capacity: '',
      surface: '',
      externalRefs: [],
      externalInfoIds: [],
      providerInfo: [],
      images: [],
      longitude: '',
      latitude: '',
      timeZone: '',
      createdDate: '',
      updatedDate: '',
      englishName: {
        value: '',
        lang: this.CONSTANTS.EN
      },
      spanishName: {
        value: '',
        lang: this.CONSTANTS.ES
      },
      portugueseName: {
        value: '',
        lang: this.CONSTANTS.PT
      },
      otherName: {
        value: '',
        lang: ''
      },
      otherLanguage: undefined,
      isEnTitleHide: true,
      isPtEsTitleHide: true,
      isOtherTitleHide: false,
      publishedImages: [],
      imageSizes: [],
      groupedImages: {},
      newAddress: '',
      newCity: ''
    }
  }

  imageDefaults(ratio = '') {
    return {
      index: null,
      id: '',
      uri: '',
      ratio: ratio,
      width: 0,
      height: 0,
      category: 'Iconic',
      imageFile: null,
      imageURL: '',
      published: false,
    }
  }

  languageList() {
    return [
      { language: this.CONSTANTS.ENGLISH, value: this.CONSTANTS.EN },
      { language: this.CONSTANTS.PORTUGUESE, value: this.CONSTANTS.PT },
      { language: this.CONSTANTS.SPANISH, value: this.CONSTANTS.ES },
    ]
  }

  getCountries() {
    return this.programService.getCountries()
  }

  getImageCategories() {
    return [
      'Iconic',
      'Poster Art',
      'VOD Art',
      'Box Art',
      'Key Art',
      'Banner-L1',
    ]
  }

  getRatio() {
    return [
      '16:9',
      '4:3',
      '3:4',
      '2:3',
      '2:1',
      '1:1',
    ]
  }

  searchVenues(searchData: any, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.venuesUrl}/search?page=${page}&size=${size}`
    return this.http.post<VLSResponse>(searchUrl, searchData)
  }

  save(data: VenueSoccer) {
    const venueId = data.id
    const payload = new FormData()
    const flatData = this.flatten(data)
    for (let key in flatData) {
      if (!key.includes('createdDate') && !key.includes('updatedDate')) {
        payload.append(key, flatData[key])
      }
    }

    return !!venueId
      ? this.http.put<VLSResponse>(this.venuesUrl, payload)
      : this.http.post<VLSResponse>(this.venuesUrl, payload)
  }

  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (let key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        let value = currentNode[key]
        if (newKey.includes('extraInfos') && key === 'value') {
          if (value.length > 1 && value[0] !== undefined) {
            target[newKey] = value
          } else {
            target[newKey] = []
          }
        } else if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  flatten(obj) {
    let flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  getDetailVenue(venueId: string) {
    return this.http.get<VLSResponse>(
      `${this.venuesUrl}/search/${venueId}`
    )
  }

  getValueByLang(fields: VenueName[], lang: string) {
    const index = fields.findIndex((item) => item.lang.toLowerCase().includes(lang))
    if (index > -1) {
      return fields[index]
    } else {
      return {
        lang: '',
        value: ''
      }
    }
  }

  getOtherName(fields: VenueName[], lang: string[]) {
    const index = fields.findIndex((item) => !lang.includes(item.lang.toLowerCase()))
    if (index > -1) {
      return fields[index]
    } else {
      return {
        lang: '',
        value: ''
      }
    }
  }

  getName(names: VenueName[], isSky: boolean) {
    if (names?.length > 0) {
      const localLang = isSky ? this.CONSTANTS.PT : this.CONSTANTS.ES
      const indexLocal = names.findIndex((item) => item.lang.toLowerCase().includes(localLang))
      if (indexLocal > -1) {
        return names[indexLocal]
      } else {
        const index = names.findIndex((item) => item.lang.toLowerCase().includes(this.CONSTANTS.EN))
        if (index > -1) {
          return names[index]
        } else {
          return names[0]
        }
      }
    }
  }

  getImages(venueId: string) {
    return this.http.get<VLSResponse>(`${this.venuesUrl}/images/${venueId}`)
  }
}
