import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {format, formatDate, SCHEDULE_FORMATDATE, SCHEDULEID_FORMAT} from 'src/app/utils/contants'
import {NgbCalendar, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SchedulesService} from "../../../../pages/schedules/schedules.service";
import {
  EditableEventField,
  ProgramDetails,
  ScheduleDetails,
  ScheduleEvent,
  ScheduleVersionDetail,
  ScheduleVersionRequest
} from 'src/app/pages/schedules/schedule';
import {AppConstants} from 'src/app/app.constants'
import {ConfirmationModalComponent} from "../../../confirmation-modal/confirmation-modal.component";
import {ProgramsService} from "../../../../pages/programs/programs.service";
import {PlatformService} from 'src/app/services/platform.service';
import {moveItemInArray} from "@angular/cdk/drag-drop";
import * as dayjs from "dayjs";
import {Dayjs} from "dayjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-schedule-body',
  templateUrl: './schedule-body.component.html',
  styleUrls: ['./schedule-body.component.scss'],
})
export class SchedulesBodyComponent implements OnInit {
  // Accessors
  @Input() request: ScheduleVersionRequest;
  @Input() regions: any[] = [];
  @Input() ratings: any[] = [];
  @Input() qualifiers: any[] = [];
  @Input() genres: any[] = [];
  @Input() selectedScheduleEvents: ScheduleEvent[] = [];
  @Input() onSelectedEventsChanged: Function;

  @Input() set selectedScheduleVersions(userSelection: ScheduleVersionDetail[]) {
    this.selectedScheduleVersionsSubject.next(userSelection);
  }

  @Output() onSchedulesChanged: EventEmitter<ScheduleDetails[]> = new EventEmitter();
  @Output() onInvalidProgramDropped: EventEmitter<string> = new EventEmitter();

  selectedScheduleVersionsSubject: BehaviorSubject<ScheduleVersionDetail[]> = new BehaviorSubject([]);
  schedulesSubject: BehaviorSubject<ScheduleDetails[]> = new BehaviorSubject([]); // will be read from api

  // Life cycle methods
  constructor(
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private programService: ProgramsService,
    private scheduleService: SchedulesService,
    private platformService: PlatformService, 
  ) {
    this.schedulesSubject.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(schedules => this.onSchedulesChanged.emit(schedules));
    this.selectedScheduleVersionsSubject.pipe(distinctUntilChanged())
      .subscribe(versions => this.getSchedulesByVersions(versions));
  }

  ngOnInit() {
  }

  // Handle removing an event.
  removeEvent(schedule: ScheduleDetails, deletedEvent: ScheduleEvent, index: number) {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modal.componentInstance.title = 'Remove Schedule';
    modal.componentInstance.message = 'Are you sure you want to remove this schedule?';
    modal.componentInstance.isAlert = false;
    modal.result.then((result) => {
      const firstSchedule = this.schedulesSubject.getValue()[0];
      if (result) {
        schedule.events = schedule.events.filter(schedule => schedule !== deletedEvent);
        if (index > 0) {
          const previousEvent = schedule.events[index - 1];
          previousEvent.duration = previousEvent.duration + deletedEvent.duration;
        } else if (index === 0 && schedule === this.schedulesSubject.getValue()[1]) {
          // In case of 1st event in 2nd schedule, then, shift the duration to last event of 1st schedule.
          const previousEvent = firstSchedule.events[firstSchedule.events.length - 1];
          previousEvent.duration = previousEvent.duration + deletedEvent.duration;
        }
      }
    });
  }

  // Handle cloning an event.
  cloneEvent(schedule: ScheduleDetails, original: ScheduleEvent, index: number) {
    const copiedEvent = this.copyEvent(original);
    this.addEvent(schedule, copiedEvent, index + 1);
  }

  // Rendering methods
  // Handle dropping either a program or an event into event list on UI.
  onDrop(schedule: ScheduleDetails, $event, scheduleIndex: number) {
    if ($event.previousContainer.id === 'programList') {
      this.onDropProgram(schedule, $event, scheduleIndex);
    } else {
      this.onDropEvent($event, scheduleIndex);
    }
  }

  // Handle checking on a single event.
  checkEvent = (checked: boolean, event: ScheduleEvent) => {
    let scheduleEvents = [];
    if (checked && !this.selectedScheduleEvents.includes(event)) {
      scheduleEvents = this.selectedScheduleEvents;
      scheduleEvents.push(event);
    } else if (!checked) {
      scheduleEvents = this.selectedScheduleEvents.filter(e => event !== e);
    }
    this.onSelectedEventsChanged(scheduleEvents);
  }

  // Handle action in case user press 'Enter' button after edit an event.
  correctInvalidTiming(currentSchedule: ScheduleDetails, currentEvent: ScheduleEvent, field: EditableEventField): void {
    // Navigate to next input on UI.
    this.navigateToNextEvent(currentSchedule, currentEvent, field);

    // Perform correct the data automatically on UI.
    // Determine the next event that might be correct. This could be either the previous or next event.
    const cascading = this.getCascadingEvent(currentSchedule, currentEvent, field);
    const cascadingSchedule = cascading[0];
    const cascadingEvent = cascading[1];
    if (!currentEvent.shouldDisplay(this.request.timezone, this.request.date)) {
      return; // Never apply correctness on any hiding event
    }

    this.correctCurrentEvent(currentEvent, field);

    this.reAssignEvent(currentSchedule, currentEvent);

    if (!cascadingEvent || !cascadingEvent.shouldDisplay(this.request.timezone, this.request.date)) {
      return; // Never apply correctness on any hiding event
    }

    this.correctCascadingEvent(currentEvent, cascadingEvent, cascadingSchedule, field);
  }

  // Update schedule time of an event.
  private navigateToNextEvent(currentSchedule: ScheduleDetails, event: ScheduleEvent, $event: EditableEventField) {
    const next = this.getCascadingEvent(currentSchedule, event, EditableEventField.DURATION); // always move focus to next event
    const nextSchedule = next[0];
    const nextEvent = next[1];
    if (!nextEvent) {
      return;
    }
    const nextId = `${$event}-${nextEvent.displayId(nextSchedule.events.indexOf(nextEvent))}`;
    const nextInput = document.getElementById(nextId) as HTMLInputElement;
    if (nextInput) {
      // Automatically focus to next input on UI
      nextInput.focus();
    } else {
      // Otherwise, focus to current input on UI
      const currentId = `${$event}-${event.displayId(currentSchedule.events.indexOf(event))}`;
      const currentInput = document.getElementById(currentId) as HTMLInputElement;
      currentInput.focus();
    }
  }

  // Re-assign the event to the correct schedule.
  private reAssignEvent(currentSchedule: ScheduleDetails, event: ScheduleEvent) {
    const nextScheduleDate = dayjs.tz(formatDate(this.request.date), SCHEDULE_FORMATDATE, 'GMT').add(1, 'day');
    if (this.schedulesSubject.getValue().indexOf(currentSchedule) === 0 && !event.utcStartDate().isBefore(nextScheduleDate)) {
      // Move event forward to 2nd schedule, if start date is the same or after 2ns schedule start date
      this.schedulesSubject.getValue()[1].events.unshift(this.schedulesSubject.getValue()[0].events.pop());
    } else if (this.schedulesSubject.getValue().indexOf(currentSchedule) === 1 && event.utcStartDate().isBefore(nextScheduleDate)) {
      // Move event backward to 1st schedule, if start date is before 2nd schedule start date
      this.schedulesSubject.getValue()[0].events.push(this.schedulesSubject.getValue()[1].events.shift());
    }
  }

  // Only correct if cascading event is invalid.
  private correctCascadingEvent(currentEvent: ScheduleEvent, cascadingEvent: ScheduleEvent,
                                cascadingSchedule: ScheduleDetails, field?: EditableEventField) {
    if (!field || EditableEventField.DURATION === field) {
      this.correctNextEvent(currentEvent, cascadingEvent, cascadingSchedule, field);
    } else {
      this.correctPreviousEvent(currentEvent, cascadingEvent);
    }
  }

  // Only correct if cascading event is invalid.
  private correctPreviousEvent(currentEvent: ScheduleEvent, previousEvent: ScheduleEvent) {
    previousEvent.endDate = currentEvent.startDate;
    if (!previousEvent.utcStartDate().isAfter(currentEvent.utcStartDate())) {
      previousEvent.correctDuration(currentEvent.utcStartDate().diff(previousEvent.utcStartDate(), 'seconds'));
      previousEvent.correctEndDate();
    }
  }

  // Only correct if cascading event is invalid.
  private correctNextEvent(currentEvent: ScheduleEvent, nextEvent: ScheduleEvent,
                           cascadingSchedule: ScheduleDetails, field?: EditableEventField) {
    if (currentEvent.isGap(nextEvent) || currentEvent.isOverlapped(nextEvent)) {
      const deltaInSecond = currentEvent.isGap(nextEvent)
        ? nextEvent.utcStartDate().diff(currentEvent.utcEndDate(), 'seconds')
        : currentEvent.utcEndDate().diff(nextEvent.utcStartDate(), 'seconds');
      const duration = currentEvent.isGap(nextEvent)
        ? nextEvent.duration + deltaInSecond
        // in case of overlapped, can only correct when duration is greater than delta
        : nextEvent.duration > deltaInSecond ? nextEvent.duration - deltaInSecond : nextEvent.duration;
      nextEvent.correctStartDate(currentEvent.endDate);
      nextEvent.correctDuration(duration);
      // Recursively correct the cascading event
      this.correctInvalidTiming(cascadingSchedule, nextEvent, field);
    }
  }

  // Perform correct the event that is displayed on UI.
  private correctCurrentEvent(event: ScheduleEvent, field: EditableEventField): void {
    if (!event.isGap() && !event.isOverlapped()) {
      return;
    }
    if (!field || EditableEventField.DURATION === field) {
      event.correctEndDate();
    } else if (!event.utcStartDate().isAfter(event.utcEndDate())) {
      event.correctDuration(event.utcEndDate().diff(event.utcStartDate(), 'seconds'));
    }
  }

  // Create a new event, then copy the information from a given event except date time info.
  private copyEvent(original: ScheduleEvent): ScheduleEvent {
    const copiedEvent = Object.assign(new ScheduleEvent(), original);
    copiedEvent.id = undefined;
    copiedEvent.startDate = original.endDate;
    copiedEvent.endDate = original.endDate;
    copiedEvent.duration = 0;
    return copiedEvent;
  }

  // Add an event into a specific position in a given schedule.
  private addEvent(schedule: ScheduleDetails, event: ScheduleEvent, index: number) {
    if (!schedule.events) {
      schedule.events = [];
    }
    schedule.events.splice(index, 0, event);
  }

  // Get details of schedules from the api based on the given schedule versions.
  private getSchedulesByVersions(versions: ScheduleVersionDetail[]): void {
    const versionIds = versions?.filter(v => v.id > 0 + '').map(v => v.id);
    const stringDate = formatDate(this.request?.date ?? this.calendar.getToday());
    const date = dayjs.tz(stringDate, SCHEDULE_FORMATDATE, this.request?.timezone?.value ?? 'GMT');
    const startDate = date.tz('GMT');
    const endDate = date.add(1, "day").subtract(1, 'minute').tz('GMT');
    const emptySchedules = versions?.length === 2
      ? [this.createEmptySchedule(startDate), this.createEmptySchedule(endDate)]
      : [this.createEmptySchedule(startDate)];
    if (!versionIds || versionIds.length === 0) {
      this.schedulesSubject.next(emptySchedules);
      return;
    }
    this.scheduleService.getSchedulesByVersions(versionIds)
      .subscribe((data) => {
          if (data && data.length > 0) {
            let schedules = data.map(schedule => {
              let s = new ScheduleDetails();
              schedule = Object.assign(s, schedule);
              schedule.events = schedule.events
                ? schedule.events.map(event => {
                  let e = Object.assign(new ScheduleEvent(), event);
                  e.programDetails = Object.assign(new ProgramDetails(), event.programDetails);
                  return e;
                })
                : [];
              return s;
            });
            const result = [...schedules];
            if (!schedules.map(item => item.scheduleId.split('-')[1]).includes(startDate.format(SCHEDULEID_FORMAT))) {
              // Add 1st schedule if api do not return
              result.unshift(this.createEmptySchedule(startDate));
            }
            if (!schedules.map(item => item.scheduleId.split('-')[1]).includes(endDate.format(SCHEDULEID_FORMAT))) {
              // Add 2nd schedule if api do not return
              result.push(this.createEmptySchedule(endDate));
            }
            this.schedulesSubject.next(result);
          } else {
            this.schedulesSubject.next(emptySchedules);
          }
        }, () => {
          this.schedulesSubject.next(emptySchedules);
        }
      );
  }

  // Update events by the given events, from a given index.
  private updateEvent(source: ScheduleEvent[] = [], target: ScheduleEvent[] = [], startIndex: number) {
    // Apply all changes from a given source events to a target events, but till keep the original date time information.
    source.forEach((e, i) => {
      const startDate = target[i + startIndex].startDate;
      const endDate = target[i + startIndex].endDate;
      const duration = target[i + startIndex].duration;
      target[i + startIndex] = Object.assign(new ScheduleEvent(), e);
      target[i + startIndex].programDetails = Object.assign(new ProgramDetails(), e.programDetails);
      target[i + startIndex].startDate = startDate;
      target[i + startIndex].endDate = endDate;
      target[i + startIndex].duration = duration;
      target[i + startIndex].modified = true;
    });
  }

  // Handle dropping an event in event list on UI.
  private onDropEvent($event, scheduleIndex: number) {
    // Original state
    let previousIndex = 0;
    let currentIndex = 0;
    // Determine user action regard to drop context
    let isDropInSameSchedule = $event.previousContainer.id === $event.container.id;
    let isDropInFirstSchedule = scheduleIndex === 0;
    let displayedEvents: ScheduleEvent[] = [];
    this.schedulesSubject.getValue()?.forEach(s => {
      displayedEvents = displayedEvents.concat(s.displayedEvent(this.request.timezone, this.request.date));
    });
    let eventsInFirstSchedule = this.schedulesSubject.getValue()[0]?.displayedEvent(this.request.timezone, this.request.date).length;
    if (isDropInSameSchedule && isDropInFirstSchedule) {
      // Drop event inside 1st schedule
      previousIndex = $event.previousIndex;
      currentIndex = $event.currentIndex;
    } else if (isDropInSameSchedule && !isDropInFirstSchedule) {
      // Drop event inside 2nd schedule
      previousIndex = $event.previousIndex + eventsInFirstSchedule;
      currentIndex = $event.currentIndex + eventsInFirstSchedule;
    } else if (!isDropInSameSchedule && !isDropInFirstSchedule) {
      // Drop event from 1st to 2nd schedule
      previousIndex = $event.previousIndex;
      currentIndex = eventsInFirstSchedule + $event.currentIndex - 1;
    } else if (!isDropInSameSchedule && isDropInFirstSchedule) {
      // Drop event from 2nd to 1st schedule
      previousIndex = eventsInFirstSchedule + $event.previousIndex;
      currentIndex = $event.currentIndex;
    }
    const startIndex = previousIndex <= currentIndex ? previousIndex : currentIndex;
    const endIndex = previousIndex > currentIndex ? previousIndex + 1 : currentIndex + 1;
    // Arrange items accordingly to user's action
    moveItemInArray(displayedEvents, previousIndex, currentIndex);
    // Apply changes to data behind to render back to UI
    // Changes in 1st schedule
    const limit = endIndex < eventsInFirstSchedule ? endIndex : eventsInFirstSchedule;
    this.updateEvent(
      displayedEvents.slice(startIndex, limit),
      this.schedulesSubject.getValue()[0]?.events,
      startIndex + this.schedulesSubject.getValue()[0].events.length - eventsInFirstSchedule
    );
    // Changes in 2nd schedule
    const starting = startIndex >= eventsInFirstSchedule ? startIndex : eventsInFirstSchedule;
    this.updateEvent(
      displayedEvents.slice(starting, endIndex),
      this.schedulesSubject.getValue()[1]?.events,
      starting - eventsInFirstSchedule
    );
  }

  // Handle dropping a program in event list on UI.
  private onDropProgram(schedule: ScheduleDetails, $event, scheduleIndex: number) {
    // // Original state
    const nextChannelDate = dayjs
      .tz(formatDate(this.request.date), SCHEDULE_FORMATDATE, 'GMT')
      .add(24, 'hour');
    let eventsInFirstSchedule = 0;
    this.schedulesSubject.getValue()?.forEach(s => s.events?.forEach(e => {
      if (e.shouldDisplay(this.request.timezone, this.request.date) && e.utcStartDate().isBefore(nextChannelDate)) {
        eventsInFirstSchedule++;
      }
    }));
    let addingIndex = 0;
    let previousEvent;
    if (schedule.events && schedule.events.length > 0) {
      addingIndex = scheduleIndex === 0
        ? $event.currentIndex + this.schedulesSubject.getValue()[0]?.events?.length - eventsInFirstSchedule
        : $event.currentIndex;
    }
    if (addingIndex > 0) {
      previousEvent = schedule.events[addingIndex - 1];
    }
    const addingEvent = this.createEmptyScheduleEvent();
    addingEvent.duration = 0;
    let startDateProgram = null;
    if (previousEvent) {
      addingEvent.startDate = previousEvent.endDate;
      addingEvent.duration = 3600;
      addingEvent.modified = true;
      addingEvent.endDate = format(addingEvent.utcStartDate().add(1, 'hour'));
      startDateProgram = addingEvent.utcStartDate();
    } else {
      const startDate = scheduleIndex === 0
        ? dayjs.tz(formatDate(this.request.date), SCHEDULE_FORMATDATE, this.request.timezone.value)
        : nextChannelDate;
      addingEvent.startDate = format(startDate);
      addingEvent.endDate = format(startDate.add(1, 'hour'));
      addingEvent.duration = 3600;
      addingEvent.modified = true;
      startDateProgram = startDate;
    }
    this.addEvent(schedule, addingEvent, addingIndex);

    // load program
    this.programService.getProgramVersions($event.item.data.programId).subscribe((data) => {
      const programVersions = data.response.masterEntity
      if (programVersions.length) {
        const published = programVersions.find(program => program.published);
        this.programService.getProgramByVersion(published ? published.id : programVersions[0].id)
          .subscribe(data => {
            if (data.response) {
              addingEvent.programDetails = Object.assign(new ProgramDetails(), data.response);
              addingEvent.programId = data.response.programId;
              addingEvent.ratings = data.response.ratings;
              // Update the duration when has program runtime
              const isDTH = this.platformService.verifyPlatform(AppConstants.DTH);
              let runtTime = +data.response.runTime;
              if (runtTime && isDTH) {
                runtTime = Math.round(runtTime / 60)*60;
                addingEvent.endDate = format(startDateProgram.add(runtTime, 'second'));
                addingEvent.duration = runtTime;
                addingEvent.modified = true;
              }

              const programItem = document.getElementById('programItem-' + $event.previousIndex);
              programItem.style.backgroundColor = '#FFFFFF';
            }
          });
      } else {
        this.onInvalidProgramDropped.emit($event.item.data.programId);
      }
    });
  }

  // Get cascading schedule and event for further processing.
  private getCascadingEvent(currentSchedule: ScheduleDetails,
                            event: ScheduleEvent, field: EditableEventField): [ScheduleDetails, ScheduleEvent] {
    let cascadingSchedule = currentSchedule;
    const currentIndex = currentSchedule.events.indexOf(event);
    let cascadingEventIndex;
    if (!field || EditableEventField.DURATION === field) {
      cascadingEventIndex = currentIndex + 1;
      // If we are at the last event of first schedule, then keep correct 1st event of the second schedule!
      if (this.schedulesSubject.getValue()?.indexOf(currentSchedule) === 0 && currentIndex === currentSchedule.events.length - 1) {
        cascadingSchedule = this.schedulesSubject.getValue()[1];
        cascadingEventIndex = 0;
      }
      return [cascadingSchedule, cascadingSchedule?.events[cascadingEventIndex]];
    } else {
      cascadingEventIndex = currentIndex - 1;
      // If we are at the 1st event of second schedule, then keep correct last event of the first schedule!
      if (this.schedulesSubject.getValue()?.indexOf(currentSchedule) === 1 && currentIndex === 0) {
        cascadingSchedule = this.schedulesSubject.getValue()[0];
        cascadingEventIndex = this.schedulesSubject.getValue()[0].events.length - 1;
      }
      return [cascadingSchedule, cascadingSchedule?.events[cascadingEventIndex]];
    }
  }

  private createEmptySchedule(scheduleDate: Dayjs): ScheduleDetails {
    const schedule = new ScheduleDetails();
    schedule.events = [];
    schedule.channelId = this.request?.channel?.id;
    schedule.completed = true;
    schedule.contentLock = false;
    schedule.scheduleDate = format(scheduleDate, 'YYYY-MM-DD');
    schedule.scheduleId = `${this.request?.channel?.id}-${format(scheduleDate, 'YYYYMMDD')}`;
    schedule.externalRefs = [];
    schedule.status = 'NEW';
    schedule.version = 1;
    schedule.processingType = 'AUTO';
    schedule.provider = 'VLS';
    return schedule;
  }

  private createEmptyScheduleEvent(): ScheduleEvent {
    const event = new ScheduleEvent();
    event.blackout = false;
    event.blackoutRegions = [];
    return event;
  }
}
