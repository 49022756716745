import {Component, OnInit} from '@angular/core';
import {PluginStoreService} from "../plugin.store";
import {PluginService} from "../../../services/plugins/plugin.service";
import {
  ManufacturerEntity,
  PluginEntity,
  PluginStatusEnum,
  PlugInTypeEntity
} from "../../../services/plugins/plugin.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {finalize} from "rxjs/operators";
import {ConfirmationModalService} from "../../../components/confirmation-modal/confirmation-modal.service";

@Component({
  selector: 'app-plugin-detail',
  templateUrl: './plugin-detail.component.html',
  styleUrls: ['./plugin-detail.component.scss']
})
export class PluginDetailComponent implements OnInit {
  formGroup = new FormGroup({
    id: new FormControl(),
    plugInName: new FormControl(null, [Validators.required]),
    createdBy: new FormControl(),
    createdDate: new FormControl(),
    updatedBy: new FormControl(),
    updatedDate: new FormControl(),
    status: new FormControl(),
    plugInTypeId: new FormControl(null, [Validators.required]),
    uri: new FormControl(), targetingUi: new FormControl(),
    plugInTarFile: new FormControl(null,[Validators.required]), description: new FormControl(),
    plugInVersion: new FormControl(),
    apgSendTime: new FormControl(),
  })
  publishFormGroup = new FormGroup({
    manufacturerId: new FormControl(),
    fastLoad: new FormControl(false)
  })
  pluginTypes: PlugInTypeEntity[] = [];
  manufactures: ManufacturerEntity[] = []
  isLoading = false;

  get detailTitle() {
    const value = this.formGroup.getRawValue() as PluginEntity;
    if (value.plugInName && value.id) {
      return `${value.plugInName}: ${value.id}`
    }
    return 'New plugin'
  }

  get disabledSave() {
    const value = this.formGroup.getRawValue() as PluginEntity;
    return (value.id !== null && value.status !== PluginStatusEnum.Act && value.status !== PluginStatusEnum.Publish) || this.isLoading === true;
  }

  get disabledDelete() {
    return !(this.formGroup.get('id')?.value !== null && this.formGroup.get('status')?.value !== PluginStatusEnum.UnAct &&
      this.publishFormGroup.get('fastLoad')?.value !== null)  || this.isLoading === true;
  }

  get disabledPublish() {
    const formGroupValue = this.formGroup.getRawValue()
    const publishFormGroup = this.publishFormGroup.getRawValue();
    return !(formGroupValue.id !== null  && publishFormGroup.fastLoad !== null)  || this.isLoading === true;
  }

  get disableFastLoad(){
    return this.disabledPublish || this.formGroup.get('status')?.value === PluginStatusEnum.UnAct;
  }


  get pluginTarFile() {
    const pluginTarFile = this.formGroup.get('plugInTarFile')?.value
    return typeof pluginTarFile === 'string' ? pluginTarFile : pluginTarFile instanceof File ? pluginTarFile.name : '';
  }


  constructor(private storeService: PluginStoreService,
              private pluginService: PluginService,
              private confirmationModal: ConfirmationModalService
  ) {
  }

  // loadData(pluginId: string, plugInVersion: number) {
  //   this.pluginService.getOne(pluginId, plugInVersion).subscribe(value => {
  //     const {} = value;
  //     this.formGroup.patchValue(value)
  //     this.formGroup.get('plugInType').patchValue(value.plugInType)
  //   })
  // }

  ngOnInit(): void {
    this.storeService.selectedPluginVersion$.subscribe(value => {
      if (value) {
        const {plugInType, ...dto} = value;
        dto.plugInTypeId = plugInType.id;
        this.formGroup.patchValue(dto)
      } else {
        this.formGroup.reset()
      }
    })
    this.pluginService.getListType().subscribe(value => this.pluginTypes = value);
    this.storeService.requestCreatePlugin$.subscribe(() => {
      this.formGroup.reset()
    })
    this.pluginService.getListManufacturer().subscribe(value => this.manufactures = value)
  }

  save() {
    const {plugInTarFile, id, plugInVersion, ...dto} = this.formGroup.getRawValue() as PluginEntity;
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid) {
      if (!id) {
        this.isLoading = true;
        if ((plugInTarFile as any) instanceof File) {
          this.pluginService.createOne(dto, plugInTarFile as unknown as File)
            .pipe(finalize(() => this.isLoading = false),
            )
            .subscribe(resp => {
              this.formGroup.reset();
              this.storeService.createdPlugin$.next(resp)
            })
        }
      } else {

        this.isLoading = true;
        this.pluginService.updateOne(id, plugInVersion, {
          dto,
          tarFile: typeof plugInTarFile === 'string' ? undefined : plugInTarFile as unknown as File
        })
          .pipe(finalize(() => this.isLoading = false))
          .subscribe(resp => {
            this.storeService.updatedPlugin$.next(resp);
            // this.loadData(id, resp.plugInVersion)
          })
      }
    }
  }

  deletePlugin() {
    const value = this.formGroup.getRawValue() as PluginEntity;
    if (value?.id) {
      const {manufacturerId} = this.publishFormGroup.getRawValue();
      const manufacturer = manufacturerId ? this.manufactures.find(f => f.id === manufacturerId) : null;
      this.isLoading = true;
      this.pluginService.deletePlugin({
        pluginId: value.id,
        versionId: value.plugInVersion,
        manufacturerId: manufacturer?.manufacturerId || null,
        modelNumId: manufacturer?.modelNumber || null
      }).pipe(finalize(() => this.isLoading = false))
        .subscribe((resp) => {
          this.storeService.deletedPlugin$.next(resp.deletedPlugIn)
        })
    }
  }

  publish() {
    const value = this.formGroup.getRawValue() as PluginEntity;
    if (value?.id) {
      const {manufacturerId, fastLoad} = this.publishFormGroup.getRawValue();
      const manufacturer = manufacturerId ? this.manufactures.find(f => f.id === manufacturerId) : null;
      this.isLoading = true;
      this.pluginService.publish({
        plugInId: value.id,
        versionId: value.plugInVersion,
        manufacturerId: manufacturer?.manufacturerId || null,
        modelNumId: manufacturer?.modelNumber || null,
        fastLoad
      }).pipe(finalize(() => this.isLoading = false))
        .subscribe((resp) => {
          this.storeService.publishPlugin$.next()
        })
    }
  }


}
