import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
@Input() title:string;
@Input() description:string;
@Input() selected=false;
  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector:"app-list-item-title",
  template:`    <strong class="text-truncate title">
    {{title}}
  </strong>`,
  styles:[
    `.title{
      font-size: 12px;
    }`
  ]
})
export class ListItemTitleComponent {
  @Input() title: string;
}
