import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service'
import { ChannelManagementService } from '../channel-management.service'
import * as moment from 'moment'
import { AppConstants } from 'src/app/app.constants'
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime'
import { NATIVE_FORMATS_CHANNEL } from 'src/app/services/calendar.service'
import { PlatformService } from 'src/app/services/platform.service'
import { OFFSET_OPTIONS } from 'src/app/utils/contants'

@Component({
  selector: 'app-channel-modal',
  templateUrl: './channel-modal.component.html',
  styleUrls: ['./channel-modal.component.scss'],
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: NATIVE_FORMATS_CHANNEL},
  ],
})
export class ChannelModalComponent implements OnInit, OnChanges {
  @Input() channel: SourceChannel
  @Input() isCreate: boolean
  @Input() regions: Region[]
  @Input() categories: ChannelCategory[]
  previewUrl: string
  platformTypes = ['OTT', 'DTH']
  resolutionTypes = ['SD', 'HD', '4K']
  channelTypes = ['LINEAR', 'VOD', 'HYBRID']
  processingTypes = ['AUTO', 'NON-AUTO', 'BUBBLE']
  showLoader
  masterSources = []
  offsetOptions = OFFSET_OPTIONS
  readonly CONSTANTS = AppConstants

  constructor(
    private activeModal: NgbActiveModal,
    private confirmationModal: ConfirmationModalService,
    private serviceChannel: ChannelManagementService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private platformService: PlatformService
  ) { 
    this.dateTimeAdapter.setLocale('en-IN');
  }

  ngOnInit(): void {
    this.previewUrl =
      'https://aptsoundtesting.co.uk/wp-content/uploads/2018/07/default_no_image-430x250.jpg'
    this.showLoader = false
    if (this.regions === undefined) {
      this.serviceChannel.getRegions().subscribe(({ response }) => {
        this.regions = response
      })
    }

    this.serviceChannel.getAllMasterChannel().subscribe((response) => {
      this.masterSources = response.response.map(x => (
        {
          id: x.id,
          name: x.sourceId + ' - ' + x.channelNames[0]?.value,
          sourceId: x.sourceId,
          timeZone: x.timeZone
        })
      )
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  closeModal() {
    this.activeModal.dismiss()
  }

  submit() {
    // confirm submission to BE service
    this.generateChannelData();
    if (this.isValid()) {
      this.confirmationModal
        .createModal({
          title: 'Save/Update Service channel',
          message: 'Are you sure you want to save this service channel?',
          isConfirm: true,
          isAlert: false,
        })
        .result.then((result) => {
          // Submit -> Yes
          if (this.channel.dayParts && (this.channel.dayParts[0].startDate === undefined
            || this.channel.dayParts[0].endDate === undefined)) {
            this.channel.dayParts = [];
          }
          if (result) {
            this.showLoader = true

            // delete time zone if channel is slave
            if (this.channel?.channelTarget?.toLowerCase() == AppConstants.DTH && this.channel?.dthInfo?.masterSource) {
              delete this.channel.timeZone
            }

            this.serviceChannel
              .upsertServiceChannel(this.channel, this.isCreate)
              .subscribe(
                (res) => {
                  this.showLoader = false
                  // successfully updated the channel information
                  if (!!res.response) {
                    this.confirmationModal
                      .createModal({
                        title: 'Success',
                        message: 'Updated successfully',
                      })
                      .result.then(() => {
                        // returns the update channel information
                        this.channel.masterSource = res.response.masterSource;
                        this.activeModal.close(this.channel)
                      })
                  }
                  // catching known errors
                  if (!!res.errors) {
                    this.confirmationModal.createModal({
                      title: 'Error',
                      message: result.errors[0],
                    })
                  }
                },
                ({ error }) => {
                  this.showLoader = false
                  // system errors
                  if (!!error.errors) {
                    this.confirmationModal.createModal({
                      title: 'Error',
                      message: error.errors[0],
                    })
                  } else {
                    this.confirmationModal.createDefaultErrorMessage()
                  }
                }
              )
          }
        })
    }
  }

  onFieldChange(event, field = null) {
    if (field === "licenseStartDate" || field === "licenseEndDate" ) {
      if (event) {
        document.getElementById(field).classList.remove('is-invalid')
        if (this.isValidDates()) {
          document.getElementById('licenseStartDate').classList.remove('is-invalid');
          document.getElementById('licenseEndDate').classList.remove('is-invalid');
        }
      }
    }
    else if (field == "channelTarget") {
     if (this.isCreate) {
       this.channel.dthInfo = this.channel.dthInfo ?? this.serviceChannel.dthInfoDefault()
       if (this.channel[field].toLowerCase() == this.CONSTANTS.DTH) {
         
         this.channel.dthInfo.channelResolution = this.resolutionTypes[0]
       }
     }
   }
    else if (field == "masterSources") {
      if (event ) {
        document.getElementById("licenseStartDate").classList.remove('is-invalid')
        document.getElementById("licenseEndDate").classList.remove('is-invalid')
        this.channel.licenseStartDate = null;
        this.channel.licenseEndDate = null;
        this.channel.timeZone = event?.timeZone
      }
    }
    else {
      const { id, value } = event.target
      if (value) {
        document.getElementById(id).classList.remove('is-invalid')
      }
    }
    return false;
  }

  isValidDates() {
    const startDate = moment(this.channel.licenseStartDate);
    const endDate = moment(this.channel.licenseEndDate);

    if (startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate)) {
      const inputFieldStartDate = document.getElementById('licenseStartDate');
      const inputFieldEndDate = document.getElementById('licenseEndDate');
      if (inputFieldStartDate) {
        inputFieldStartDate.classList.remove('is-invalid');
      }
      if (inputFieldEndDate) {
        inputFieldEndDate.classList.remove('is-invalid');
      }
      return true;
    }
    return false;
  }

  generateChannelData() {

    // Titles
    this.channel.channelNames = [];
    this.addTitlesDescription('channelNames', this.channel.name, 'full');
    this.addTitlesDescription('channelNames', this.channel.callSign, 'short');

    // Descriptions
    this.channel.descriptions = [];
    this.addTitlesDescription('descriptions', this.channel.fullDescription, 'full');

    //Regions
    this.channel.regions = [];
    const addedRegion = this.channel.region.split(',');
    (addedRegion || []).forEach(reg => {
      if (reg) {
        this.channel.regions.push({ regionId: null, regionName: reg});
      }
    });

    // Categories
    this.channel.categories = [];
    const addedCategories = this.channel.category.split(',');
    (addedCategories || []).forEach(cat => {
      if (cat) {
        this.channel.categories.push({ id: null, name: cat });
      }
    });

    // Mappings
    if (this.isCreate == false && !this.isDthPlatform()) {
      this.channel.dayParts = this.channel.dayParts
    } else if (!this.channel?.masterChannelName?.name) {
      const mapping = {
        startDate: moment(this.channel.licenseStartDate).utcOffset(0, true).format(),
        endDate: moment(this.channel.licenseEndDate).utcOffset(0, true).format(),
      }
      this.channel.dayParts = [];
      this.channel.dayParts.push({
        ...mapping
      })
    }
    if (this.channel.dthInfo?.isSlave == true) {
      this.channel.dayParts = this.channel.oldDayPart
    }

    // DTH Info
    if (this.channel?.channelTarget?.toLowerCase() == this.CONSTANTS.OTT) {
      this.channel.dthInfo = null
    } else {
      if (this.channel?.masterChannelName?.id) {
        this.channel.dthInfo.masterSource = this.channel.masterChannelName.id
      } else {
        this.channel.dthInfo.masterSource = null
      }
    }
  }

  addTitlesDescription(entity: string, name: string, types: string) {
    if (name !== '') {
      this.channel[entity].push(
        { value: name, type: types, language: this.channel.language || '' }
      )
    }
  }

  isValid() {
    const inValid = [];
    const platform = this.channel.channelTarget;
    const basedFields = ['language', 'channelTarget', 'channelType', 'sourceId'];
    let fieldsToValidate = [...basedFields, 'licenseStartDate', 'licenseEndDate'];
    const dthInfo = ['viewerNumber'];

    if (platform === 'DTH') {
      fieldsToValidate.push(...dthInfo);
      if (this.channel?.masterChannelName?.name) {
        fieldsToValidate = basedFields;
      }
    }

    if (!this.channel.name && !this.channel.callSign) {
      fieldsToValidate.push('name');
    }

    fieldsToValidate.forEach((field) => {
      let fieldToValidate = this.channel[field];
      if (dthInfo.includes(field)) {
        fieldToValidate = this.channel?.dthInfo[field];
      } else if (typeof this.channel[field] === 'object' && this.channel[field]?.getDate) {
        fieldToValidate = this.channel[field];
      } else if (typeof this.channel[field] === 'object' && !this.channel[field]?.getDate) {
        fieldToValidate = this.channel[field]?.value;
      }
      if (!fieldToValidate) {
        const inputField = document.getElementById(field);
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling);
          }
          inValid.push(field);
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">Required field</div>'
          );
          inputField.classList.add('is-invalid');
        }
      }
    });

    const startDate = moment(this.channel.licenseStartDate);
    const endDate = moment(this.channel.licenseEndDate);

    if (startDate.isValid() && endDate.isValid() && startDate.isSameOrAfter(endDate)) {
      inValid.push('licenseStartDate', 'licenseEndDate');
      const inputFieldStartDate = document.getElementById('licenseStartDate');
      const inputFieldEndDate = document.getElementById('licenseEndDate');

      if (inputFieldStartDate) {
        if (inputFieldStartDate.nextSibling) {
          inputFieldStartDate.parentNode.removeChild(inputFieldStartDate.nextSibling);
        }
        inputFieldStartDate.insertAdjacentHTML(
          'afterend',
          '<div class="invalid-feedback">The start date must be before the end date</div>'
        );
        inputFieldStartDate.classList.add('is-invalid');
      }

      if (inputFieldEndDate) {
        if (inputFieldEndDate.nextSibling) {
          inputFieldEndDate.parentNode.removeChild(inputFieldEndDate.nextSibling);
        }
        inputFieldEndDate.insertAdjacentHTML(
          'afterend',
          '<div class="invalid-feedback">The end date must be after the start date</div>'
        );
        inputFieldEndDate.classList.add('is-invalid');
      }
    }

    return inValid.length === 0;
  }

  onSwitch(isPublished) {
    if(isPublished){
      this.channel.published = !this.channel.published
    } else {
      this.channel.dthInfo.isEngineering = !this.channel.dthInfo?.isEngineering
    }
  }

  isDthPlatform() {
    return this.platformService.verifyPlatform(AppConstants.DTH)
  }
}


