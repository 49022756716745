import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Output() onSearch = new EventEmitter<string>();
  searchText: string;
 @Input() placeholder: string = 'Enter search text';

  constructor() {
  }

  ngOnInit(): void {
  }

}
