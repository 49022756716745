import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import {ProgramsService} from "../../../../../pages/programs/programs.service";
import {EditableEventField, Offset, ScheduleDetails, ScheduleEvent} from "../../../../../pages/schedules/schedule";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

@Component({
  selector: 'app-schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.scss'],
})
export class ScheduleEventComponent implements OnInit {
  // Accessors
  // SHOULD NEVER check input value, to ensure the data MUST be passed to this component!
  @Input() event: ScheduleEvent;
  @Input() nextEvent: ScheduleEvent;
  @Input() schedule: ScheduleDetails;

  @Input() index = 0;
  @Input() scheduleIndex = 0;

  @Input() qualifiers: any[];
  @Input() regions: any[];
  @Input() ratings: any[];
  @Input() genres: any[];

  @Input() selectedTimeZone: Offset;
  @Input() selectedDate: NgbDateStruct;
  @Input() isFirstSchedule = true;
  @Input() onEventChecked: Function;

  @Output() onEventDeleted: EventEmitter<ScheduleEvent> = new EventEmitter();
  @Output() onEventCloned: EventEmitter<ScheduleEvent> = new EventEmitter();
  @Output() onCorrectInvalidTiming: EventEmitter<EditableEventField> = new EventEmitter();

  // public fields
  isCollapsed = true;
  hourUpdate = new Subject<KeyboardEvent>();
  minuteUpdate = new Subject<KeyboardEvent>();
  durationUpdate = new Subject<KeyboardEvent>();

  constructor(
    private programService: ProgramsService,
  ) {
    // Debounce update hour of the event.
    this.hourUpdate.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(value => this.updateHour(value));
    // Debounce update minute of the event.
    this.minuteUpdate.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(value => this.updateMin(value));
    // Debounce update duration of the event.
    this.durationUpdate.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(value => this.updateDuration(value));
  }

  ngOnInit() {
  }

  // Handle deleting when user clicks on delete icon.
  onDeletingEvent() {
    this.onEventDeleted.emit(this.event);
  }

  // Handle cloning when user clicks on clone icon.
  onCloningSchedule() {
    this.onEventCloned.emit(this.event);
  }

  // Get title of the program to display on UI.
  getTitleDescValue(program, field, truncate = true) {
    return this.programService.getFirstTitleDescValue(program, field, truncate);
  }

  // Get type of the program to display on UI.
  getProgramTypeStyle(type) {
    return this.programService.programTypeLabel(type).style;
  }

  // Style the program type to display on UI.
  programTypeBadge(type) {
    return this.programService.programTypeLabel(type).code;
  }

  // Handle image error on UI.
  onImageError(event) {
    event.target.src = 'assets/images/noImage.jpg';
  }

  // Get the url for program image.
  getProgramImage() {
    let imageBanner = 'assets/images/noImage.jpg'
    const defaultImage = this.programService?.getDefaultImage(
      this.event?.programDetails?.programImages,
      this.event?.programDetails?.programType
    )
    if (defaultImage) {
      imageBanner = `${this.schedule.imageBaseUrl}${defaultImage.uri}`
    }
    return imageBanner;
  }

  getGenresNames(event) {
    if (event.programDetails?.genres) {
      return this.genres.filter((i) => event.programDetails.genres.includes(i.id)).map(({name}) => name).join(', ');
    }
    return "N/A";
  }

  getParentTitle(event) {
    if (event.programDetails?.parentTitles) {
      return this.getTitleDescValue(event.programDetails, 'parentTitles');
    } else if (event.programDetails?.parentTitle) {
      return this.getTitleDescValue(event.programDetails, 'parentTitle');
    }
    return 'N/A';
  }

  // Style class for the event.
  eventClass() {
    let eventClass;
    if (this.event.blackout) {
      eventClass = 'blackout';
    } else if (this.event.validationStatus(this.nextEvent)) {
      eventClass = this.event.validationStatus(this.nextEvent);
    } else if (!this.event.id) {
      eventClass = 'added-' + this.scheduleIndex;
    } else if (this.event.modified) {
      eventClass = 'updated-' + this.scheduleIndex;
    }

    return 'schedule-item-' + this.scheduleIndex + ' ' + eventClass;
  }

  updateHour($event) {
    this.event.setHour($event.target.value, this.selectedTimeZone.value);
    this.correctTiming($event, EditableEventField.HOUR);
  }

  private updateMin($event) {
    this.event.setMinute($event.target.value, this.selectedTimeZone.value);
    this.correctTiming($event, EditableEventField.MIN);
  }

  private updateDuration($event) {
    this.event.correctDurationInMinute($event.target.value);
    this.correctTiming($event, EditableEventField.DURATION);
  }

  // Handle updating time when user input on UI.
  private correctTiming($event, field: EditableEventField) {
    if ($event.key === 'Enter') {
      this.onCorrectInvalidTiming.emit(field);
    }
  }
}