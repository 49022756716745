import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { formatDate } from "@angular/common";
import { DATEPICKER_FORMAT } from "src/app/utils/contants";
import * as moment from "moment";

@Injectable({ providedIn: "root" })
export class APGReportService {
    apgUrl: string

    constructor(private http: HttpClient) {
        const apiUrl = environment.apiUrl
        this.apgUrl = `${apiUrl}/apg-reporting`
    }

    getGoFiles(params: PaginationParams, body) {
      const page = params.page + 1
      const size = params.size
      const getUrl = `${this.apgUrl}/search?page=${page}&size=${size}`
      return this.http.post<any[]>(getUrl, body)
    }

    resend(body) {
      const url = `${this.apgUrl}/resend`
      return this.http.post<VLSResponse>(url, body)
    }

    download(body) {
      const url = `${this.apgUrl}/download`
      return this.http.post(url, body, { observe: 'response', responseType: 'arraybuffer' })
    }

    formatDate(listInfos: APGReport[]) {
      listInfos?.forEach((goInfo) => {
        goInfo.createdDateString = goInfo.createdDate
          ? moment(goInfo.createdDate, DATEPICKER_FORMAT).format(
              DATEPICKER_FORMAT
            )
          : '--'
        goInfo.sentDateString = goInfo.sentDate
          ? moment(goInfo.sentDate, DATEPICKER_FORMAT).format(DATEPICKER_FORMAT)
          : '--'
      })
      return listInfos
    }
}