/*
 * =============================================================================
 * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
   * RESERVED
 *
 * This software is the confidential and proprietary information of Vrio Corp.
 * ("Proprietary Information"). Any use, reproduction, distribution or
 * disclosure of the software or Proprietary Information, in whole or in part,
 * must comply with the terms of the license agreement, nondisclosure agreement
 * or contract entered into with Vrio Corp. providing access to this software.
 *
 * ============================================================================
 */

import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { AssetService } from '../assets-management/assets.service';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-asset-reports',
  templateUrl: './asset-reports.component.html',
  styleUrls: ['./asset-reports.component.scss']
})
export class AssetReportsComponent implements OnInit {
  assetType: string
  assetList: any
  params: PaginationParams
  isLoading: boolean
  reportType: string
  allAssetsTypes: {}[]
  readonly CONSTANTS = AppConstants
  constructor(
    private assetService: AssetService
  ) { }

  ngOnInit(): void {
    this.params = {
      page: 1,
      size: 1
    }
    this.isLoading = true
    this.allAssetsTypes = [
      {
        id: this.CONSTANTS.ALL_ASSETS_ID,
        label: this.CONSTANTS.ALL_ASSETS_LABEL
      },
      {
        id: this.CONSTANTS.DUPLICATE_ASSETS_ID,
        label: this.CONSTANTS.DUPLICATE_ASSETS_LABEL
      },
      {
        id: this.CONSTANTS.ASSETS_WO_GENRES_ID,
        label: this.CONSTANTS.ASSETS_WO_GENRES_LABEL
      },
      {
        id: this.CONSTANTS.ASSETS_WO_RELEASE_YEAR_ID,
        label: this.CONSTANTS.ASSETS_WO_RELEASE_YEAR_LABEL
      },
      {
        id: this.CONSTANTS.ASSETS_WO_URLS_ID,
        label: this.CONSTANTS.ASSETS_WO_URLS_LABEL
      },
      {
        id: this.CONSTANTS.ASSETS_WO_RATINGS_ID,
        label: this.CONSTANTS.ASSETS_WO_RATINGS_LABEL
      }
      ,
      {
        id: this.CONSTANTS.ASSETS_WO_EPISODES_ID,
        label: this.CONSTANTS.ASSETS_WO_EPISODES_LABEL
      },
      {
        id: this.CONSTANTS.ASSETS_WO_SHOWS_ID,
        label: this.CONSTANTS.ASSETS_WO_SHOWS_LABEL
      }
    ]
  }

  //get data by tab when tab change
  onTabChange($event: NgbNavChangeEvent) {
    this.assetType = $event.nextId
    this.reportType = $event.nextId
    const filterType = {
      reportType: this.reportType,
      isListAll: true
    }
    this.assetService.getAllAsset(filterType, this.params).subscribe((data: any) => {
      this.assetList = data.response
      this.assetService.formatStatus(this.assetList)
    })
  }

  exportToExcel() {

    //define column and data
    const col = [
      {
        title: this.CONSTANTS.TYPE_COLUMN,
        value: (item) => item.program?.programType
      },
      {
        title: this.CONSTANTS.NAME_COLUMN,
        value: (item) => item.asset?.title
      },
      {
        title: this.CONSTANTS.SHOW_NAME,
        value: (item) => item.program?.seriesTitle
      },
      {
        title: this.CONSTANTS.SEASON_NUMBER_COLUMN,
        value: (item) => item.asset?.displaySeasonNumber
      },
      {
        title: this.CONSTANTS.EPISODE_NUMBER_COLUMN,
        value: (item) => item.asset?.displayEpisodeNumber
      },
      {
        title: this.CONSTANTS.GENRE_COLUMN,
        value: (item) => item.program?.genres
      },
      {
        title: this.CONSTANTS.PROVIDER_COLUMN,
        value: (item) => item.asset?.provider
      },
      {
        title: this.CONSTANTS.CATEGORY_COLUMN,
        value: (item) => item.asset?.categories
      },
      {
        title: this.CONSTANTS.CHANNEL_COLUMN,
        value: (item) => item.asset?.channel
      },
      {
        title: this.CONSTANTS.PROGRAM_MAPPER_ID_COLUMN,
        value: (item) => item.asset?.programMapperId
      },
      {
        title: this.CONSTANTS.VRIO_ID_COLUMN,
        value: (item) => item.asset?.vrioId
      },
      {
        title: this.CONSTANTS.PROGRAM_ID_COLUMN,
        value: (item) => item.asset?.programId
      },
      {
        title: this.CONSTANTS.DMS_ID_COLUMN,
        value: (item) => item.asset?.dmsId
      },
      {
        title: this.CONSTANTS.TMS_ID_COLUMN,
        value: (item) => item.program?.tmsId
      },
      {
        title: this.CONSTANTS.SERIES_TMS_ID_COLUMN,
        value: (item) => item.program?.seriesTmsId
      },
      {
        title: this.CONSTANTS.ASSET_ID_COLUMN,
        value: (item) => item.asset?.assetId
      },
      {
        title: this.CONSTANTS.REGION_COLUMN,
        value: (item) => item.asset?.regions
      },
      {
        title: this.CONSTANTS.PUB_STATUS_COLUMN,
        value: (item) => item.asset?.pubStatus
      },
      {
        title: this.CONSTANTS.CREATED_DATE_COLUMN,
        value: (item) => item.asset?.createdDate
      },
      {
        title: this.CONSTANTS.LICENSE_START_COLUMN,
        value: (item) => item.asset?.availabilityStartsAt
      },
      {
        title: this.CONSTANTS.LICENSE_END_COLUMN,
        value: (item) => item.asset?.availabilityEndsAt
      }
    ]

    //format xlsx file name
    const currentDate = new Date().toJSON().slice(0, 10)
    const fileName = `${this.reportType}-${currentDate}`
    const exports: any = this.assetList?.map((item) => {
      let obj = {}
      col?.forEach((i) => {
        obj[i.title] = i.value(item)

      })
      return obj
    })

    // Import to excel
    if (exports) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exports)
      this.autoFitColumns(exports, ws)
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, fileName)
      XLSX.writeFile(wb, `${fileName}.xlsx`)
    }
  }

  //set column max width
  autoFitColumns(json: any[], worksheet: XLSX.WorkSheet) {
    const objectMaxLength: number[] = [];
    json.map(jsonData => {
      Object.entries(jsonData)
        .map(([, v], idx) => {
          const columnValue = v as string
          objectMaxLength[idx] = objectMaxLength[idx] >= columnValue?.length ? objectMaxLength[idx] : columnValue?.length
        })
    })

    const wsCols = objectMaxLength.map((w: number) => ({ width: w }))
    worksheet["!cols"] = wsCols;
  }
}