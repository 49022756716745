import {Component, OnInit} from '@angular/core';
import {PluginService} from "../../../services/plugins/plugin.service";
import {PluginEntity} from "../../../services/plugins/plugin.interface";
import {PluginStoreService} from "../plugin.store";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-plugin-list',
  templateUrl: './plugin-list.component.html',
  styleUrls: ['./plugin-list.component.scss']
})
export class PluginListComponent implements OnInit {
  items: Array<PluginEntity> = []
  isLoading = false;

  constructor(private pluginService: PluginService,
              private store: PluginStoreService) {
  }

  ngOnInit(): void {
    this.fetchData();
    this.store.createdPlugin$.subscribe(() => {
      this.fetchData()
    })
  }

  fetchData(searchText: string = '') {
    this.isLoading = true;
    this.pluginService.getList({searchString: searchText})
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => this.items = res.response)
  }

  onClick(plugin: PluginEntity) {
    this.store.selectedPluginId$.next(plugin.id);
  }

  search(searchText: string) {
    this.fetchData(searchText)
  }

  createPlugin() {
    this.store.requestCreatePlugin$.next()
  }

  isSelected(plugInId: string) {
    return this.store.selectedPluginId$.value === plugInId
  }

}
