import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
@Input() activeId:string;
@Output() activeIdChange = new EventEmitter<string>()
@ContentChildren(TabComponent) tabs!:QueryList<TabComponent>
active = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
