import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders, HttpErrorResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LoginService } from '../pages/login/login.service'
import {tap} from "rxjs/operators";
import {ConfirmationModalService} from "../components/confirmation-modal/confirmation-modal.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private loginService: LoginService,private confirmationModal:ConfirmationModalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.loginService.isAuthenticated() && req.url.indexOf('basicauth') === -1) {
      var newHeaders = req.headers.append('x-auth-token', localStorage.getItem('authToken'));
      var authReq = req.clone({ headers: newHeaders });
      return next.handle(authReq).pipe( tap({
        error: (error: HttpErrorResponse) => {
          this.confirmationModal.createModal({
            title: 'Error',
            message: error.error.errors[0],
          })
        }
      }))
    } else {
      return next.handle(req).pipe( tap({
        error: (error: HttpErrorResponse) => {
          this.confirmationModal.createModal({
            title: 'Error',
            message: error.error.errors[0],
          })
        }
      }))
    }
  }
}
