import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, Subject} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {ScheduleDetails, ScheduleVersion, ScheduleVersionRequest} from "./schedule";
import {formatDate} from "../../utils/contants";

@Injectable({providedIn: 'root'})
export class SchedulesService {
  tokenExpirationStatus = new BehaviorSubject(false)
  methodCallSource = new Subject<any>()
  schedulesUrlV1: string
  schedulesUrlV2: string
  qualifiersUrl: string
  scheduleVersionUrlV1: string
  scheduleVersionUrlV2: string
  currentTokenExpirationStatus = this.tokenExpirationStatus.asObservable()
  changeScheduleRequestCall = this.methodCallSource.asObservable()

  constructor(private http: HttpClient) {
    this.schedulesUrlV2 = environment.apiUrl + '/v2/schedules'
    this.schedulesUrlV1 = environment.apiUrl + '/schedules'
    this.qualifiersUrl = environment.apiUrl + '/qualifiers'
    this.scheduleVersionUrlV1 = this.schedulesUrlV1 + '/version'
    this.scheduleVersionUrlV2 = this.schedulesUrlV2 + '/version'
  }

  changeTokenExpirationStatus(status: boolean) {
    this.tokenExpirationStatus.next(status)
  }

  save(data) {
    const submitUrl = data.published ? `${this.scheduleVersionUrlV2}/saveAndPublish` : this.scheduleVersionUrlV2
    return this.http.post<any>(submitUrl, data)
  }

  getVersionsByChannelIdAndDate(request: ScheduleVersionRequest): Observable<ScheduleVersion[]> {
    const channel = request.channel?.id;
    const date = formatDate(request.date);
    const timezone = request.timezone?.value?.split(',')[0];
    return this.http.get<ScheduleVersion[]>(`${this.scheduleVersionUrlV2}?channelId=${channel}&date=${date}&timeZone=${timezone}`);
  }

  getSchedulesByVersions(versionIds: string[]): Observable<ScheduleDetails[]> {
    return this.http.get<ScheduleDetails[]>(`${this.scheduleVersionUrlV2}/${versionIds}`);
  }

  saveAndPublish(data: ScheduleDetails[], published: boolean): any {
    const url = published
      ? `${this.scheduleVersionUrlV2}/saveAndPublish`
      : this.scheduleVersionUrlV2;
    return this.http.post<ScheduleDetails[]>(url, data);
  }

  publish(versionId: string) {
    return this.http.put<any>(`${this.scheduleVersionUrlV2}/${versionId}/publish`, {})
  }

  contentLock(scheduleId: string) {
    return this.http.put<any>(`${this.scheduleVersionUrlV1}/lock/${scheduleId}`, {})
  }

  getQualifiers() {
    return this.http.get<any>(this.qualifiersUrl)
  }

  changeScheduleRequest(request: any) {
    this.methodCallSource.next(request);
  }
}
