import { Component, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Md5 } from 'ts-md5/dist/md5'
import { UserService } from './users.service'
import { ConfirmationModalService } from '../../components/confirmation-modal/confirmation-modal.service'
import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  active: number
  user: User
  users: User[]
  hasUser = false
  isEdit = false
  showLoader = false
  action = 'Add'
  responseMessage: string
  searchUser
  page = 1
  pageSize = 10
  collectionSize = 0
  userRoles: Role[] = []
  getParams: PaginationParams
  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private confirmModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.getParams = {
      page: 1,
      size: 200,
    }

    this.active = 1
    this.getRoles()
    this.getUsers()
  }

  getRoles() {
    this.userService.getUserRoles().subscribe((data: any) => {
      this.userRoles = data.response
    })
  }

  getUsers() {
    this.user = this.userService.userDefaultValues()
    this.userService.getUsers(this.getParams).subscribe((data: any) => {
      this.users = data.response
      this.collectionSize  = this.users.length;
      this.hasUser = !!Object.keys(data).length
    })
  }

  formatRoles(roles) {
    return (roles || [])
      .map((id) => {
        const role = this.userRoles.find((role) => role.id === id)
        return role && role.name
      })
      .join(', ')
  }

  setRoles(roleId) {
    return (this.user.roles || []).includes(roleId)
  }

  openModal(userModal, userId = null) {
    this.action = 'Add'
    this.isEdit = false
    const userDefaults = this.userService.userDefaultValues()
    delete userDefaults.id
    this.user = userDefaults

    if (userId) {
      this.isEdit = true
      this.action = 'Edit'
      this.user = this.users.find(({ id }) => id === userId)
      this.user.roles = [];
      this.userRoles.forEach(userRole => {
        if (this.user.roleIds.indexOf(userRole.id) >= 0) {
          this.user.roles.push(userRole);
        }
      })
    }

    this.modalService.open(userModal, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  submit() {
    this.showLoader = true
    // this.setUserRoles()
    let oUser = this.user

    if (this.user.newPassword && this.user.newPassword !== "") {
      oUser = {
        ...oUser,
        password: this.hashString(this.user.newPassword),
        confirmPassword: this.hashString(this.user.confirmPassword),
      }
    } else {
      oUser = {
        ...oUser,
        password: this.hashString(this.user.password),
        confirmPassword: this.hashString(this.user.password),
      }
    }
    const role = [];
    (oUser.roles || []).forEach(element => {
      role.push(element.id)
    });

    oUser = {
      ...oUser,
      roleIds: role
    }

    if(this.isValid(oUser)) {
      this.userService.submitUser(oUser).subscribe((response) => {
        if (response.errorMessage) {
          this.showLoader = false
          this.responseMessage = response.errorMessage
        } else {
          this.getUsers()
          this.closeModal()
        }
      },(error) => {
        this.confirmModalService.createModal({
          title: 'Error',
          message: error.error.errors[0],
        })
      }
      )
    }
  }

  isValid(oUser) {
    let fieldsToValidate = ['firstName', 'lastName', 'username', 'email']
    let passMatch = true;

    if(oUser.password !== oUser.confirmPassword ) {
      passMatch = false;
    }

    if(!this.isEdit) {
      fieldsToValidate.push('password', 'confirmPassword');
    }

    const inValid = []
    fieldsToValidate.forEach((field) => {
      let invalidMessage = '<div class="invalid-feedback">Required field</div>';
      let fieldToValidate = oUser[field]

      if (fieldToValidate === "") {
        const inputField = document.getElementById(field)
        this.insertError(inputField, inValid, invalidMessage, field);
      } else {
        if(!passMatch && field === "confirmPassword") {
          invalidMessage = '<div class="invalid-feedback">Password not match</div>';
          const inputField = document.getElementById(field)
          this.insertError(inputField, inValid, invalidMessage, field);
        }
      }
    })
    return inValid.length === 0
  }

  insertError(inputField: any, inValid, message, field) {
    if (inputField) {
      if (inputField.nextSibling) {
        inputField.parentNode.removeChild(inputField.nextSibling)
      }
      inValid.push(field)
      inputField.insertAdjacentHTML(
        'afterend',
        message
      )
      inputField.classList.add('is-invalid')
    }
  }

  manageUserStatus(user) {
    this.showLoader = true

    user.confirmPassword = user.password;
    this.userService.submitUser(user).subscribe((response) => {
      this.showLoader = false
    })
  }

  hashString(str) {
    return !str ? '' : Md5.hashStr(str).toString()
  }

  closeModal() {
    this.getUsers() // temp fixes for resetting edited users
    this.user = this.userService.userDefaultValues()
    this.modalService.dismissAll()
    this.responseMessage = ''
    this.showLoader = false
  }

  onSearchFilter() {
    if (!this.searchUser) {
      this.getUsers()
    } else {
      this.userService
        .searchByUsernameAndEmail(this.searchUser)
        .subscribe((data : any) => {
          this.users = data.response
          this.hasUser = !!Object.keys(data).length
        })
    }
  }

  searchChange(val) {
    if (!val) {
      this.getUsers()
    }
  }

  openDeleteModal(user: User) {
    const modal = this.confirmModalService.createModal({
      title: 'Remove User',
      message: `Are you sure you want to remove '${user.username}'?`,
      isAlert: false,
      isConfirm: true,
    })

    modal.result.then((res) => {
      if (res) {
        this.userService.removeUser(user.id).subscribe(({ message }) => {
          this.confirmModalService.createModal({
            title: 'Information',
            message: "User Deleted"
          })
          this.getUsers()
        })
      }
    })
  }
}
