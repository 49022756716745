import {Component, Input, OnInit} from '@angular/core'
import {ScheduleEvent} from "../../pages/schedules/schedule";

@Component({
  selector: 'app-blackout-modal',
  templateUrl: './blackout-modal.component.html',
  styleUrls: ['./blackout-modal.component.scss'],
})
export class BlackoutModalComponent implements OnInit {
  @Input() regions: any[];
  @Input() onCloseModal: Function;
  @Input() onApplyingBlackout: Function;

  @Input() set events(events: ScheduleEvent[]) {
    this.mobileBlackout = events?.every(s => s.labels && s.labels.length > 0);
    this.inUsedRegions = [];
    events?.forEach(e => e.blackoutRegions?.forEach(r => {
      if (!this.inUsedRegions.includes(r)) {
        (this.inUsedRegions.push(r));
      }
    }));
  }

  mobileBlackout = false;
  inUsedRegions: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  displayRegion(regionId: string): string {
    return this.regions.find(r => r.regionId === regionId)?.regionName;
  }

  onAddingRegion(region: any) {
    this.inUsedRegions.push(region.regionId);
  }

  // Remove a region on selection.
  onRemovingRegion(region: string) {
    this.inUsedRegions = this.inUsedRegions.filter(q => q !== region);
  }

  onCheckMobileBlackout($event) {
    this.mobileBlackout = $event.target.checked;
  }

  availableRegions() {
    return this.regions?.filter(r => !this.inUsedRegions.includes(r.regionId));
  }
}
