import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Md5 } from 'ts-md5'
import { environment } from '../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class UserService {
  userUrl: string
  searchUrl: string
  updatePasswordUrl: string
  manageUserStatusUrl: string
  rolesUrl: string

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    const usersApiUrl = `${apiUrl}/users`
    this.userUrl = usersApiUrl
    this.searchUrl = `${usersApiUrl}/search`
    this.updatePasswordUrl = `${usersApiUrl}/password`
    this.manageUserStatusUrl = `${usersApiUrl}/status`
    this.rolesUrl = `${apiUrl}/roles`
  }

  getUsers(params: PaginationParams) {
    const {page, size} = params;
    return this.http.get<VLSResponse>(`${this.userUrl}?searchString&page=${page}&size=${size}`)
  }

  getUserRoles() {
    return this.http.get<VLSResponse>(`${this.rolesUrl}`)
  }

  createUserRoles(role: any) {
    return this.http.post<VLSResponse>(`${this.rolesUrl}/`, role)
  }

  userDefaultValues() {
    return {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      admin: '',
      accessToken: '',
      logonUserId: 0,
      roleIds: [],
      roles: []
    }
  }

  submitUser(user) {
    return !!user.id
      ? this.http.put<any>(`${this.userUrl}/${user.id}`, user)
      : this.http.post<any>(`${this.userUrl}/`, user)
  }

  removeUser(id) {
    return this.http.delete<any>(`${this.userUrl}/${id}`)
  }

  searchByUsernameAndEmail(searchUser: string) {
    return this.http.get<User[]>(`${this.searchUrl}?searchString=${searchUser}`)
  }

  updatePassword(user: User) {
    const postBody = {
      id: localStorage.getItem('authUserId'),
      logonUserId: parseInt(localStorage.getItem('authUserId'), 10),
      password: Md5.hashStr(user.password).toString(),
      newPassword: Md5.hashStr(user.newPassword).toString(),
      confirmPassword: Md5.hashStr(user.confirmPassword).toString(),
    }
    return this.http.post<any>(this.updatePasswordUrl, postBody)
  }

  getCurrentUserRoles() {
    const roles = localStorage.getItem('userRoles')
    return (roles && roles.split(', ')) || []
  }

  isAdmin() {
    return this.getCurrentUserRoles().includes('admin')
  }

  isOTTUser() {
    return this.getCurrentUserRoles().includes('ott')
  }

  isOTTBrUser() {
    return this.getCurrentUserRoles().includes('ottBR')
  }

  isProductDataUser() {
    return this.getCurrentUserRoles().includes('productData')
  }

  isProductDataBrUser() {
    return this.getCurrentUserRoles().includes('productDataBR')
  }

  isVODUser() {
    return this.getCurrentUserRoles().includes('vod')
  }

  isVODBrUser() {
    return this.getCurrentUserRoles().includes('vodBR')
  }

  isBrazilUser() {
    return (
      this.isOTTBrUser() || this.isProductDataBrUser() || this.isVODBrUser()
    )
  }

  hasLatamBrOTT() {
    const roles = this.getCurrentUserRoles()
    return roles.includes('ott') && roles.includes('ottBR')
  }

  hasLatamBrPD() {
    const roles = this.getCurrentUserRoles()
    return roles.includes('productData') && roles.includes('productDataBR')
  }

  hasLatamBrVOD() {
    const roles = this.getCurrentUserRoles()
    return roles.includes('vod') && roles.includes('vodBR')
  }

  hasAccessBoth() {
    return (
      this.isAdmin() ||
      this.isReadOnlyUser() ||
      this.hasLatamBrOTT() ||
      this.hasLatamBrPD() ||
      this.hasLatamBrVOD()
    )
  }

  isReadOnlyUser() {
    const roles = this.getCurrentUserRoles()
    return roles.length === 1 && roles.includes('readonly')
  }

  getUsersMock() {
    return [
      {
        id: '5e91617629a867875824dd13',
        username: 'xrexonx',
        firstname: 'Rexon',
        lastname: 'De los Reyes',
        email: 'rd098y@att.com',
        password: '8f8c238b67ff9512601fff194896e8e7',
        admin: true,
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f06bbbaf43fdc67ec3c8227',
        username: 'lr674n',
        firstname: 'Luis',
        lastname: 'Reyes',
        email: 'lr674n@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06bd19f43fdc67ec3c822b',
        username: 'pe512j',
        firstname: 'Paola',
        lastname: 'Estrada',
        email: 'pe512j@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: false,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06bdfff43fdc67ec3c822d',
        username: 'mrodriguez',
        firstname: 'Maria',
        lastname: 'Rodriguez',
        email: 'maria.rodriguez@globant.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06be6bf43fdc67ec3c822f',
        username: 'b.betancur',
        firstname: 'Elena',
        lastname: 'Betancur Gallón',
        email: 'b.betancur@globant.com',
        password: 'bdae55c60893a5b1d5be641f735a915a',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06bed9f43fdc67ec3c8231',
        username: 'am4784',
        firstname: 'Anil',
        lastname: 'Malladi',
        email: 'am4784@att.com',
        password: '459d2376439bb8731a7f03f240c7221a',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06bf43f43fdc67ec3c8235',
        username: 'kg019w',
        firstname: 'Katy',
        lastname: 'Rivera',
        email: 'kg019w@att.com',
        password: 'aeba979743b925de53701ca6491ede1b',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06bfe8f43fdc67ec3c8237',
        username: 'mb735s',
        firstname: 'Michele',
        lastname: 'Blankl',
        email: 'mb735s@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c0eaf43fdc67ec3c823b',
        username: 'ma2549',
        firstname: 'Mireya',
        lastname: 'Fulford',
        email: 'ma2549@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c18bf43fdc67ec3c823f',
        username: 'jb202r',
        firstname: 'Eduardo',
        lastname: 'Bustos',
        email: 'jb202r@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c1d4f43fdc67ec3c8241',
        username: 'im685f',
        firstname: 'Isaac',
        lastname: 'Munoz',
        email: 'im685f@att.com',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c150f43fdc67ec3c823d',
        username: 'kpachon',
        firstname: 'Karen Lizeth',
        lastname: 'Pachon Pedroza',
        email: 'kpachon@directvla.com.co',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c237f43fdc67ec3c8243',
        username: 'frodri11',
        firstname: 'HECTOR FERNANDO',
        lastname: 'RODRIGUEZ VACA',
        email: 'frodri11@directvla.com.co',
        password: '47830e80289bfd9fcc3ba3232f8e9642',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f06c273f43fdc67ec3c8245',
        username: 'rllanogo',
        firstname: 'RICARDO',
        lastname: 'LLANO GONZALEZ',
        email: 'rllanogo@directvla.com.co',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c2cbf43fdc67ec3c8249',
        username: 'angela.corredor',
        firstname: 'Angela',
        lastname: 'Corredor',
        email: 'angela.corredor@globant.com',
        password: '7fdc527240f5b2b915db2514e7d33dcf',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c311f43fdc67ec3c824b',
        username: 'jchalla',
        firstname: 'Jesai',
        lastname: 'Challa',
        email: 'jc279e@att.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: [
          '5eb8e0d401dd58446d5735fc',
          '5eb8e0f001dd58446d5735fd',
          '5eb8e11401dd58446d5735ff',
        ],
      },
      {
        id: '5f06c3a1f43fdc67ec3c824d',
        username: 'anne.gabutan',
        firstname: 'Anne',
        lastname: 'Gabutan',
        email: 'anne.gabutan@fsoft.com.vn',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f06c422f43fdc67ec3c824f',
        username: 'donna.yamada',
        firstname: 'Donna Joy',
        lastname: 'Yamada',
        email: 'donna.yamada@fsoft.com.vn',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: [
          '5eb8e0d401dd58446d5735fc',
          '5eb8e0f001dd58446d5735fd',
          '5f07f5775a42e3f358fff096',
        ],
      },
      {
        id: '5f06c463f43fdc67ec3c8251',
        username: 'vtejano',
        firstname: 'Val Roger',
        lastname: 'Tejano',
        email: 'val.tejano@fsoft.com.vn',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: false,
        roles: ['5eb8e0d401dd58446d5735fc', '5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb168db1bc05b6ac84c4d',
        username: 'gcarnevale',
        firstname: 'Gerardo',
        lastname: 'Carnevale',
        email: 'gcarnevale@directvla.com.ar',
        password: '0b7b245b735136dc78a6da3984bf411f',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb224db1bc05b6ac84c4f',
        username: 'mgomez',
        firstname: 'Martín ',
        lastname: 'Gómez',
        email: 'mgomez@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb281db1bc05b6ac84c51',
        username: 'fmaciel',
        firstname: 'Facundo',
        lastname: 'Maciel',
        email: 'Fmaciel@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb37edb1bc05b6ac84c53',
        username: 'truival',
        firstname: 'Tomás',
        lastname: 'Ruival',
        email: 'truival@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb3ccdb1bc05b6ac84c55',
        username: 'jpaz1',
        firstname: 'Joaquín',
        lastname: 'Paz',
        email: 'jpaz1@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb40cdb1bc05b6ac84c57',
        username: 'avazque1',
        firstname: 'Adrián',
        lastname: 'Vazquez',
        email: 'avazque1@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb437db1bc05b6ac84c59',
        username: 'mkestler',
        firstname: 'Maia',
        lastname: 'Kestler',
        email: 'mkestler@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb46bdb1bc05b6ac84c5b',
        username: 'jromagno',
        firstname: 'Juan',
        lastname: 'Romagnoli\t',
        email: 'jromagno@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb4a9db1bc05b6ac84c5d',
        username: 'melgorri',
        firstname: 'Mariela',
        lastname: ' Elgorriaga',
        email: 'melgorri@directvla.com.ar',
        password: 'c7de989759023bcfb0dcc677bed67520',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb4d2db1bc05b6ac84c5f',
        username: 'nruiz',
        firstname: 'Nancy',
        lastname: 'Ruiz',
        email: 'nruiz@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb534db1bc05b6ac84c61',
        username: 'disierra',
        firstname: 'Diego',
        lastname: 'Sierra',
        email: 'disierra@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb570db1bc05b6ac84c63',
        username: 'gnicola',
        firstname: 'Guillermo',
        lastname: 'Denicola',
        email: 'gnicola@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb5a6db1bc05b6ac84c65',
        username: 'sshekerm',
        firstname: 'Serafin',
        lastname: 'Shekerdemian',
        email: 'sshekerm@directvla.com.ar',
        password: '3a78cf12e2165254cd89bbe8e44884e9',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb615db1bc05b6ac84c67',
        username: 'jarmas',
        firstname: 'Jorge',
        lastname: 'Armas',
        email: 'jarmas@directvla.com.ar',
        password: 'cf7e59b9b33fde79c56db2e05eb0a04c',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb646db1bc05b6ac84c69',
        username: 'crojasbr',
        firstname: 'César',
        lastname: 'Rojas',
        email: 'crojasbr@directvla.com.ar',
        password: '2e30d8697b242734c049b85ab048b924',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f0eb68fdb1bc05b6ac84c6b',
        username: 'cmarziol',
        firstname: 'Hernán',
        lastname: 'Marzioli',
        email: 'cmarziol@directvla.com.ar',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f101f6edb1bc07ce9a7ceef',
        username: 'sr7115',
        firstname: 'sambamdam',
        lastname: 'Ramanathan',
        email: 'sr7115@att.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '5f1f1a66db1bc0344c751fed',
        username: 'macunagr',
        firstname: 'Mauricao',
        lastname: 'Acuña',
        email: 'macunagr@directvla.com.co',
        password: '9523722ae953d446c1cae840062484ef',
        active: true,
        roles: ['5eb8e11401dd58446d5735ff', '5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f1f1b3cdb1bc0344c752a01',
        username: 'aquicen3',
        firstname: 'Andres ',
        lastname: 'Quiceno',
        email: 'aquicen3@directvla.com.co',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: [
          '5eb8e0d401dd58446d5735fc',
          '5eb8e11401dd58446d5735ff',
          '5f8a4f87db1bc040780e07b3',
        ],
      },
      {
        id: '5f1f1bf2db1bc0344c7532a2',
        username: 'cbohorq3',
        firstname: 'Felipe ',
        lastname: 'Bohorquez',
        email: 'cbohorq3@directvla.com.co',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: [
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
          '5f8a4f87db1bc040780e07b3',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '5f1f1c1cdb1bc0344c753427',
        username: 'nguerre3',
        firstname: 'Néstor ',
        lastname: 'Guerrero',
        email: 'nguerre3@directvla.com.co',
        password: 'ade3a32d35d10d2e4f9f8262976f812c',
        active: true,
        roles: [
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
          '5f8a4f87db1bc040780e07b3',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '5f1f1c51db1bc0344c75368c',
        username: 'fvergar2',
        firstname: 'Fabio ',
        lastname: 'Vergara',
        email: 'fvergar2@directvla.com.co',
        password: '0132a1cc2c67cacc8af6ab41399c0e52',
        active: true,
        roles: [
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
          '5f8a4f87db1bc040780e07b3',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '5f2d9c4cdb1bc00fd876e599',
        username: 'jnunezco',
        firstname: 'Jorge',
        lastname: 'Nunez CORRADA',
        email: 'jnunezco@directv.cl',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f2d9c8cdb1bc00fd876e59b',
        username: 'ngonza18',
        firstname: 'Nicolas',
        lastname: 'Gonzalez Navarrete',
        email: 'ngonza18@directv.cl',
        password: '3c862a57b10748699b2c84958a1147c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '5f52a7cbdb1bc01738c84ae6',
        username: 'ppietro',
        firstname: 'Patricia',
        lastname: 'Pietropaolo',
        email: 'ppietro@directvla.com.ar',
        password: '4e9878fff0d6d016316b5db1c2e11c2b',
        active: true,
        roles: [
          '5eb8e0f001dd58446d5735fd',
          '5f8a4fe9db1bc040780e280d',
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
        ],
      },
      {
        id: '5f624deddb1bc024fecd47bc',
        username: 'shernandez',
        firstname: 'Sebastián',
        lastname: 'Hernández',
        email: 'sherna10@directvla.com.co',
        password: '47830e80289bfd9fcc3ba3232f8e9642',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f624e40db1bc024fecd47be',
        username: 'lkopyto',
        firstname: 'Lucía',
        lastname: 'Kopyto',
        email: 'lucia.kopyto@gmail.com',
        password: '47830e80289bfd9fcc3ba3232f8e9642',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f624e81db1bc024fecd47c0',
        username: 'mtorre',
        firstname: 'Manuel',
        lastname: 'De la Torre',
        email: 'mdlt.dg@gmail.com',
        password: '47830e80289bfd9fcc3ba3232f8e9642',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f624ec3db1bc024fecd47c2',
        username: 'npano',
        firstname: 'Natalia',
        lastname: 'Pano',
        email: 'ncpano@gmail.com',
        password: '47830e80289bfd9fcc3ba3232f8e9642',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a5e2fdb1bc040781090e7',
        username: 'mdiaz',
        firstname: 'Moises',
        lastname: 'Diaz',
        email: 'moises.dr@globant.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a5ea5db1bc0407810a21c',
        username: 'jmanjarres',
        firstname: 'Jose',
        lastname: 'Manjarres',
        email: 'jmanjarr@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a5f2bdb1bc0407810b51d',
        username: 'jojeda',
        firstname: 'Johan',
        lastname: 'Ojeda',
        email: 'jojedaca@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a5f8edb1bc0407810c337',
        username: 'iibanez',
        firstname: 'Ivan',
        lastname: 'Ibañez',
        email: 'iibanezd@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a5fdddb1bc0407810cde4',
        username: 'csuarez',
        firstname: 'Carlos',
        lastname: 'Suarez',
        email: 'csuarezv@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6086db1bc0407810e466',
        username: 'sbarragan',
        firstname: 'Santiago',
        lastname: 'Barragan',
        email: 'sbarraga@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6196db1bc040781107b4',
        username: 'jhidalgo',
        firstname: 'Jhoan',
        lastname: 'Hidalgo',
        email: 'jhoan.hidalgo@globant.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a61dddb1bc040781110b5',
        username: 'jcomba',
        firstname: 'Jeisson',
        lastname: 'Comba',
        email: 'jcombaor@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6220db1bc040781118d4',
        username: 'fuseda',
        firstname: 'Felipe',
        lastname: 'Useda',
        email: 'fusedaga@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6250db1bc04078111ee7',
        username: 'dpineda',
        firstname: 'Diego',
        lastname: 'Pineda',
        email: 'dpinedat@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6297db1bc04078112767',
        username: 'jangel',
        firstname: 'Jessica',
        lastname: 'Angel',
        email: 'jangelgo@directvla.com.co',
        password: '4f3c9ce039215ed0613b4e78ec24f44d',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6344db1bc04078113b6f',
        username: 'esuarez',
        firstname: 'Edwin',
        lastname: 'Suarez',
        email: 'edwsua@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a63a7db1bc040781146d3',
        username: 'hburr',
        firstname: 'Heath',
        lastname: 'Burr',
        email: 'hb782y@att.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a63e0db1bc04078114d1b',
        username: 'lpardo',
        firstname: 'Lina',
        lastname: 'Pardo',
        email: 'lpardoro@directvla.com.co',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a6445db1bc040781158ac',
        username: 'lszydlo',
        firstname: 'Lisa',
        lastname: 'SZYDLO-ROQUE',
        email: 'ls424n@att.com',
        password: '3738f00da0ed0a83ecad232566d4f94f',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5f8a4f87db1bc040780e07b3'],
      },
      {
        id: '5f8a672fdb1bc0407811ac2f',
        username: 'erodriguez',
        firstname: 'Elaine',
        lastname: 'Rodriguez',
        email: 'elaine.rodrigues@sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a67c1db1bc0407811bc9d',
        username: 'ldziedicz',
        firstname: 'Lucas',
        lastname: 'Dziedics',
        email: 'lucas.dziedicz@sky.com.br',
        password: '781ceec3db51cb72de6b79f6dc533f25',
        active: true,
        roles: [
          '5eb8e0d401dd58446d5735fc',
          '5f8a4fe9db1bc040780e280d',
          '5f8a5010db1bc040780e339e',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '5f8a6800db1bc0407811c38f',
        username: 'csilva',
        firstname: 'Claudio',
        lastname: 'Silva',
        email: 'claudio.silva@sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5eb8e0d401dd58446d5735fc', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6849db1bc0407811cb64',
        username: 'bcorreia',
        firstname: 'Barbara',
        lastname: 'Fabiola Correia',
        email: 'barbara.correia@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6909db1bc0407811e08c',
        username: 'gvilela',
        firstname: 'Gabriel',
        lastname: 'Fonseca Vilela',
        email: 'gabriel.vilela@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a69b1db1bc0407811f284',
        username: 'rrodrigues',
        firstname: 'Rodrigo',
        lastname: 'Alves Rodrigues',
        email: 'rodrigo.alvesrodrigues@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a69fbdb1bc0407811fa59',
        username: 'rmachado',
        firstname: 'Rodrigo',
        lastname: 'Ribeiro Machado',
        email: 'rodrigo.machado@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6a80db1bc04078120854',
        username: 'koliveira',
        firstname: 'Kauane Cristiny',
        lastname: 'Silva Oliveira',
        email: 'kauane.silvaoliveira@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6adadb1bc04078121195',
        username: 'jsantos',
        firstname: 'Jose',
        lastname: 'Vinicius Galdino dos Santos',
        email: 'jose.galdino@sky.com.br',
        password: '0dc9f1716d3b81f54c0adfc1350caf6c',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6b2adb1bc040781219c1',
        username: 'crodrigues',
        firstname: 'Carolina',
        lastname: 'Sanches Rodrigues',
        email: 'Carolina.Rodrigues@sky.com.br',
        password: '5125b90e48566e32af3b23a564cb22e2',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6b5fdb1bc04078121f93',
        username: 'moliveira',
        firstname: 'Mario',
        lastname: 'Sergio Candido Oliveira',
        email: 'Mario.Oliveira@sky.com.br',
        password: 'faf0d48f61dc82f79c97e63011d2a1cf',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6ba0db1bc0407812261e',
        username: 'cyamakawa',
        firstname: 'Chadia',
        lastname: 'Ali Yamakawa',
        email: 'Chadia.Yamakawa@sky.com.br',
        password: '03eaebf5dd8fe64ff682860730a0f504',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6be8db1bc04078122d4c',
        username: 'vdeoliveira',
        firstname: 'Vitor',
        lastname: 'Hugo Ferrari Galvão de Oliveira',
        email: 'Vitor.deoliveira@sky.com.br',
        password: 'c87e5d9ed20fdececff458f4386b096c',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6c20db1bc040781232be',
        username: 'rroccia',
        firstname: 'Ricardo',
        lastname: 'Melo Roccia',
        email: 'Ricardo.Roccia@sky.com.br',
        password: 'a40dd326c43204bd05d34b8a7c596a01',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6c55db1bc040781237e0',
        username: 'cmendes',
        firstname: 'CARLA',
        lastname: 'CAROLINA ADOLFI MENDES',
        email: 'Carla.Mendes@sky.com.br',
        password: 'f8a6f2eaefca43dc0782eb70bab2d5da',
        active: true,
        roles: [
          '5f8a4fe9db1bc040780e280d',
          '5f8a5010db1bc040780e339e',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '5f8a6c80db1bc04078123bdd',
        username: 'blodi',
        firstname: 'BRUNO',
        lastname: 'LUIZ LODI',
        email: 'Bruno.Lodi@sky.com.br',
        password: 'b3858ce1ec9592cafbdae0d2aec5272c',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6cb4db1bc04078124122',
        username: 'vvasconcelos',
        firstname: 'VANESSA',
        lastname: 'ALVES DE VASCONCELLOS',
        email: 'Vanessa.Vasconcelos@sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6ce2db1bc0407812460f',
        username: 'bviana',
        firstname: 'BEATRIZ',
        lastname: 'CORDEIRO VIANA',
        email: 'beatriz.viana@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f8a6d0fdb1bc04078124aa2',
        username: 'mhabib',
        firstname: 'Miguel',
        lastname: 'Habib',
        email: 'miguel.habib@terceiro-sky.com.br',
        password: '42916412b70d766ad77dfdc39fa5216a',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '5f93329ddb1bc01832ab9590',
        username: 'mtrepich',
        firstname: 'Martin',
        lastname: 'Trepicho',
        email: 'mtrepich@directv.cl',
        password: '53c6ac97d15daa6506216dcd899ae41f',
        active: true,
        roles: ['5eb8e11401dd58446d5735ff', '5f8a5010db1bc040780e339e'],
      },
      {
        id: '602560aedb1bc0493ddbf8c5',
        username: 'dmedina',
        firstname: 'David',
        lastname: 'Medina',
        email: 'david.medina@globant.com',
        password: 'bf4c356d79ed40752fdbdbe6b730fb81',
        active: true,
        roles: ['5eb8e11401dd58446d5735ff', '5f8a5010db1bc040780e339e'],
      },
      {
        id: '603ee9b8db1bc039a7cf6b3c',
        username: 'Regina',
        firstname: 'Regina',
        lastname: 'da Silva',
        email: 'Regina.Silva@sky.com.br',
        password: 'fcea920f7412b5da7be0cf42b8c93759',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '6047839ddb1bc039a7e8bcc2',
        username: 'nrosa',
        firstname: 'Natasha',
        lastname: 'Rosa',
        email: 'Natasha.Rosa@sky.com.br',
        password: 'e0eaad768d7a70628ceece3c324b83c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '604a777ddb1bc039a7f0da47',
        username: 'abonzon',
        firstname: 'Amparo',
        lastname: 'Bonzón',
        email: 'abonzon@ezena.com',
        password: '639080060e116b0137b9383568a1fa3b',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '604a77bbdb1bc039a7f0da6b',
        username: 'abustos',
        firstname: 'Alejandra',
        lastname: ' Bustos',
        email: 'abustos@ezena.com',
        password: '639080060e116b0137b9383568a1fa3b',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '604a77f2db1bc039a7f0da8b',
        username: 'jlepez',
        firstname: 'Julia',
        lastname: ' Lepez',
        email: 'jlepez@ezena.com',
        password: '639080060e116b0137b9383568a1fa3b',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd', '5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '606b5612db1bc05a7c1d7aab',
        username: 'msantos',
        firstname: 'Marcelo',
        lastname: 'Santos',
        email: 'Marcelo.Aparecido@sky.com.br',
        password: 'f41dfcdece7b03309eeffe395a167f40',
        active: true,
        roles: [
          '5f8a4fe9db1bc040780e280d',
          '5f8a5010db1bc040780e339e',
          '5f07f5775a42e3f358fff096',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '60759fbbdb1bc026b46c2b16',
        username: 'apossato',
        firstname: 'Adriano',
        lastname: 'Possato',
        email: 'Adriano.Possato@sky.com.br',
        password: 'e0eaad768d7a70628ceece3c324b83c8',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '60957518db1bc01fe18f2126',
        username: 'gucesar',
        firstname: 'Gustavo',
        lastname: 'Cesar',
        email: 'gustavo.ribeiro@terceiro-sky.com.br',
        password: '52f43fdb84b989384404e48f3bc9ed77',
        active: true,
        roles: ['5f8a4fe9db1bc040780e280d'],
      },
      {
        id: '60a2a9bbdb1bc01fe1c15886',
        username: 'jbcoperacao',
        firstname: 'Operação',
        lastname: 'JBC',
        email: 'opjbc@sky.com.br',
        password: 'ad91998d7bfecf0cb21625b6af606a13',
        active: true,
        roles: ['5f07f5775a42e3f358fff096'],
      },
      {
        id: '60a56035db1bc01fe1cf7a2b',
        username: 'jromero2',
        firstname: 'Joaquin',
        lastname: 'Lozano',
        email: 'jromero2@directvla.com.ar',
        password: 'c9c98253730574c60df612c6d7f241e4',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '60a58d0adb1bc01fe1cffa65',
        username: 'jf5281',
        firstname: 'JAIME',
        lastname: 'FIALLOS,',
        email: 'jf5281@att.com',
        password: 'bb98bbbf03cd1861b542c54ed3357c21',
        active: true,
        roles: ['5eb8e0f001dd58446d5735fd'],
      },
      {
        id: '60ae6110db1bc01fe1f4e37f',
        username: 'gsilva',
        firstname: 'Gilsilene',
        lastname: 'Silva',
        email: 'gilsilene.silva@sky.com.br',
        password: '7e03d43842bb32c3474dce045373de61',
        active: true,
        roles: [
          '5f8a4fe9db1bc040780e280d',
          '5f8a5010db1bc040780e339e',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '60b8e85fdb1bc0536e31feff',
        username: 'jospina',
        firstname: 'Jose',
        lastname: 'Ospina',
        email: 'joseerney.ospina@globant.com',
        password: 'ff35cdecdbe59a72924fc13fff499a2d',
        active: true,
        roles: [
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
          '5f8a4f87db1bc040780e07b3',
          '5f8a5041db1bc040780e3faa',
        ],
      },
      {
        id: '60c29406db1bc0536e60dabc',
        username: 'dpolanco',
        firstname: 'David',
        lastname: 'Polanco',
        email: 'jpolanc2@directvla.com.co',
        password: '953295ae41248787f22e7b34b8a2b3c7',
        active: true,
        roles: [
          '5eb8e0f001dd58446d5735fd',
          '5f8a4fe9db1bc040780e280d',
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
        ],
      },
      {
        id: '6112fc59db1bc02fc2b93e9e',
        username: 'jolaya',
        firstname: 'Johann',
        lastname: 'Olaya',
        email: 'johann.dejesus@globant.com',
        password: 'ec149bf3782ec8df198e48017147cbd3',
        active: true,
        roles: [
          '5eb8e0f001dd58446d5735fd',
          '5f8a4fe9db1bc040780e280d',
          '5eb8e11401dd58446d5735ff',
          '5f8a5010db1bc040780e339e',
        ],
      },
    ]
  }

  getRolesMock() {
    return [
      {
        id: "5eb8e0d401dd58446d5735fc",
        name: 'admin',
        label: 'Administrator',
        accessPages: [],
      },
      {
        id: '5eb8e0f001dd58446d5735fd',
        name: 'ott',
        label: 'Super User',
        accessPages: [],
      },
      {
        id: '5f07f5775a42e3f358fff096',
        name: 'guest',
        label: 'Guest',
        accessPages: [],
      },
      {
        id: '5eb8e11401dd58446d5735ff',
        name: 'catalog',
        label: 'Catalog',
        accessPages: [],
      },
      {
        id: '5f8a4f87db1bc040780e07b3',
        name: 'ottSchedule',
        label: 'OTT Schedule',
        accessPages: [],
      },
      {
        id: '5f8a4fe9db1bc040780e280d',
        name: 'ottChannelMapping',
        label: 'OTT Channel Mapping',
        accessPages: [],
      },
      {
        id: '5f8a5010db1bc040780e339e',
        name: 'dthSchedule',
        label: 'DTH Schedule',
        accessPages: [],
      },
      {
        id: '5f8a5041db1bc040780e3faa',
        name: 'dthChannelMapping',
        label: 'DTH Channel Mapping',
        accessPages: [],
      },
    ]
  }

  getPages() {
    return [
      {
        id: '5eb8e0d401dd58446d5735fc',
        name: 'Schedules',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
      {
        id: '5eb8e0f001dd58446d5735fd',
        name: 'Programs',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
      {
        id: '5f07f5775a42e3f358fff096',
        name: 'Program Mapping',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
      {
        id: '5f07f5775a42e3f358fff096',
        name: 'Channel Mapping',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
      {
        id: '5eb8e11401dd58446d5735ff',
        name: 'Reports',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
      {
        id: '5f8a4f87db1bc040780e07b3',
        name: 'User Management',
        actions: ['Create', 'Read', 'Update', 'Delete'],
      },
    ]
  }

  getUserAction() {
    return [
      { id: "5eb8e0d401dd58446d5735fc", name: 'Create' },
      { id: '5eb8e0f001dd58446d5735fd', name: 'Read' },
      { id: '5f07f5775a42e3f358fff096', name: 'Update' },
      { id: '5f07f5775a42e3f358fff096', name: 'Delete' },
      { id: '5eb8e11401dd58446d5735ff', name: 'Publish' },
      { id: '5f8a4f87db1bc040780e07b3', name: 'Copy' },
    ]
  }
}
