import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {debounceTime, tap} from "rxjs/operators";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'], providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    }
  ]
})
export class DatePickerComponent implements OnInit,ControlValueAccessor {
  onChange: (value: string) =>void;
  onTouch :()=>void;
  @Input() disabled: boolean;
  formControl = new FormControl('')
  constructor() { }
  writeValue(value: Date | string): void {
    let dateString = '';
    if(typeof value === 'string' ){
      dateString = value;
      if(value.includes('Z')){
        const date = new Date(value);
        dateString = new DatePipe('en').transform(date,'yyyy-MM-dd');
      }
    }else {
      const date = value as Date;
      dateString = new DatePipe('en').transform(date, 'yyyy-MM-dd');
    }
    this.formControl.setValue(dateString, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        tap(value => this.onChange(value)),
      )
      .subscribe();
  }

}
