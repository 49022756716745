import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service';
import { ProgramsService } from '../../programs/programs.service';
import { TeamService } from '../team.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit {
  @Output() isCreate: EventEmitter<boolean> = new EventEmitter()
  @Output() teamId: EventEmitter<string> = new EventEmitter()
  @Input() newTeamId: string
  
  isSky: boolean
  selectedTeamId: string
  teams: Team[]
  team: Team
  image: ProgramImage
  newTeam: Team
  showLoader: boolean
  searchString: any
  previewUrl : string
  active: number
  searchChanged: Subject<string> = new Subject<string>()
  getParams = {
    page: 1,
    size: 20,
    searchString: ''
  }
  constructor(
    private modalService: NgbModal,
    private teamService: TeamService,
  ) { }

  ngOnInit(): void {
    // this.getTeams()
    this.isSky = location.href.includes('sky')
    this.team = this.teamService.teamDefault() as any
    this.getTeams()
    this.active = 1
    this.debouncedSearch()
  }

  ngOnChanges(changes: SimpleChanges) {
    const fields = Object.keys(changes)
    if (fields.includes('newTeamId')) {
      this.searchString = this.newTeamId
      this.selectedTeamId = this.newTeamId
      this.searchTeams()
    }
  }
  
  closeModal() {
    this.modalService.dismissAll()
  }

  searchKeyup(event) {
    this.searchChanged.next(event.target.value)
  }

  searchChange(val) {
    this.getParams.page = 1
    if (!val) {
      this.getTeams()
    }
  }

  getTeams() {
    const searchString = this.searchString || ''
    this.triggerSearchTeams(searchString)
  }

  searchTeams() {
    if (this.searchString) {
      this.triggerSearchTeams(this.searchString)
    }
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        if (!!value) {
          this.getParams.page = 1
          this.searchTeams()
        } else {
          this.getTeams()
        }
      })
  }

  triggerSearchTeams(searchString, isScroll = false) {
      this.showLoader = true
      this.getParams.searchString = searchString
      this.teamService.searchTeams(this.getParams).subscribe(
        (data: any) => {
          this.showLoader = false
          if (data) {
            if (data.response) {
              const teams = data.response
              teams.forEach((team) => {
                this.teamService.buildTeamInfo(team)
              })
              if (isScroll) {
                this.teams.push(...teams)
              } else {
                this.teams = teams
              }
            } else {
              if (this.getParams.page == 1) {
                this.teams = []
              }
            }
          } else {
            this.teams = []
          }
        }
      )
    }

  onScroll(event) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= this.teams.length
      this.getParams.page++
      const searchString = this.searchString || ''
      this.triggerSearchTeams(searchString, true)
    }
  }

  genreOnChange() {
    this.team.genres = this.team.genres.map((genre: any) => genre.id)
  }

  viewTeamInfo(teamId) {
    this.selectedTeamId = teamId
    this.teamId.emit(teamId)
  }
  
  createTeam(){
    this.isCreate.emit(true)
  }
}
