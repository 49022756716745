import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ScheduleEvent} from "../../pages/schedules/schedule";

@Component({
  selector: 'app-qualifiers-modal',
  templateUrl: './qualifiers-modal.component.html',
  styleUrls: ['./qualifiers-modal.component.scss'],
})
export class QualifiersModalComponent implements OnInit {
  @Input() onCloseModal: Function;
  @Input() onApplyChanges: Function;

  @Input() set qualifiers(qualifiers: any[]) {
    this.allQualifiers = qualifiers;
  };

  @Input() set selectedScheduleEvents(selectedScheduleEvents: ScheduleEvent[]) {
    selectedScheduleEvents?.forEach(event => {
      if (event.qualifiers && event.qualifiers.length > 0) {
        event.qualifiers.forEach(qualifier => {
          const name = qualifier.split('epgQualifier')[1];
          if (qualifier && !this.inUsedQualifiers.find(q => q === name)) {
            this.inUsedQualifiers.push(name);
          }
        });
      }
    });
  };

  allQualifiers: any[] = [];
  inUsedQualifiers: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  // Filter available qualifiers to display on UI.
  availableQualifiers() {
    return this.allQualifiers?.filter(q => !this.inUsedQualifiers.includes(q.code));
  }

  // Add a qualifier.
  onAddingQualifier(qualifier: any) {
    this.inUsedQualifiers.push(qualifier.code);
  }

  // Remove a qualifier on selection.
  onRemovingQualifier(qualifier: any) {
    this.inUsedQualifiers = this.inUsedQualifiers.filter(q => q !== qualifier);
  }

}
