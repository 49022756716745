import { Injectable } from '@angular/core'
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class CalendarService {
  // force calendar to set date on date selection
  public calendarOpen() {
    // attach event to date click selection
    const dates = document.querySelectorAll('.owl-dt-calendar-cell')
    dates.forEach((element) => {
      if (!element.classList.contains('owl-dt-calendar-cell-disabled')) {
        element.addEventListener('click', () => {
          const querySelectors =
            'owl-date-time-container .owl-dt-container-buttons button:last-child'
          ;(document.querySelector(querySelectors) as HTMLElement).click()
        })
      }
    })

    // attach event to month switcher
    const monthSliderBtn = document.querySelectorAll(
      '.owl-dt-calendar-control > button'
    )
    monthSliderBtn.forEach((element) => {
      element.addEventListener('click', this.calendarOpen)
    })
  }

  public formatToISODateTime(date) {
    if (
      date === 'undefined' ||
      date === undefined ||
      date === null ||
      date === ''
    ) {
      return undefined
    }

    return moment.isMoment(date)
      ? date.set({ s: 0 }).format('YYYY-MM-DDTHH:mm:ss')
      : date
  }

  public formatToISODate(date) {
    if (
      date === 'undefined' ||
      date === undefined ||
      date === null ||
      date === ''
    ) {
      return undefined
    }

    return moment.isMoment(date) ? date.format('YYYY-MM-DD') : date
  }

  public validateDate(startDate, endDate) {
    if (startDate instanceof Object && endDate instanceof Object) {
      return startDate.isBefore(endDate)
    }

    return true
  }
}

export const NATIVE_FORMATS_CHANNEL = {
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
};

export const NATIVE_FORMATS_BULK_COPY = {
  datePickerInput: {year: 'numeric', month: 'short', day: 'numeric'},
};