export interface PluginEntity {
  id: string;
  plugInName: string;
  plugInType: PlugInTypeEntity;
  plugInTarFile: string;
  plugInVersion: number;
  httpRefId: string;
  targetingUi: string;
  createdDate: string;
  createdBy: string;
  updatedDate?: any;
  updatedBy: string;
  apgSendTime: string;
  status: number;
  uri?: any;
  plugInTypeId: string;
  description: string;
}

export interface PlugInTypeEntity {
  createdDate: string;
  updatedDate?: any;
  id: string;
  plugInName: string;
  plugInDescription: string;
  httpRefId: string;
  indexType: number;
  plugInNetwork: number;
  conditionalFeatureTestId: number;
  createdBy: string;
  updatedBy?: any;

}

export interface ManufacturerEntity {
  "id": "string",
  "platform": "string",
  "manufacturerName": "string",
  "manufacturerId": "string",
  "modelNumber": "string"
}

export enum PluginStatusEnum {
  Act = 1,
  Publish = 3,
  UnAct
}
