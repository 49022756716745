import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportsDetailsPublicationsService} from './reports-publications-details.service';
import {ActivatedRoute} from '@angular/router';
import {ReportsPublicationsService} from '../reports-publications.service';

@Component({
  selector: 'app-reports-publications-details',
  templateUrl: './reports-publications-details.component.html',
  styleUrls: ['./reports-publications-details.component.scss']
})
export class ReportsPublicationsDetailsComponent implements OnInit {

  @Output() downloadLSFileEvent = new EventEmitter<string>();
  @Input() fileName: string = '';

  public reportsDetailsPublicationsModel: ReportsPublicationModel[];

  constructor(
    private reportsDetailsPublicationsService: ReportsDetailsPublicationsService,
    private route: ActivatedRoute,
    private reportsPublicationsService: ReportsPublicationsService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getLDetailSFiles(params.id);
    });
  }

  public getLDetailSFiles(publicationId: string): void {
    this.reportsDetailsPublicationsService.getDetailsLSFiles(publicationId).subscribe(
      (data: any) => {
        if (data.response?.lsBatches?.length > 0) {
          const lsInfo = data.response;
          this.reportsDetailsPublicationsModel = data.response?.lsBatches.map((pathInfo) => {
            return {
              channelName: lsInfo.channelName,
              sourceId: lsInfo.sourceId,
              status: lsInfo.status,
              system: lsInfo.system,
              username: lsInfo.username,
              serviceName: lsInfo.serviceName,
              fileName: pathInfo.fileName,
              priority: pathInfo.priority,
            }
          })
        } else {
          this.reportsDetailsPublicationsModel = [data.response];
        }
      }
    );
  }

  public downloadLSFile(fileName: string): void {
    this.reportsPublicationsService.downloadLSFile(fileName);
  }

  public splitLsFileName(fileName: string): String {
    const fileNameArray = fileName.split('/');
    return fileNameArray[fileNameArray.length - 1]
  }
}
