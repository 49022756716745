import { Component, OnInit, ViewChild } from '@angular/core'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../users/users.service'
import { AppService } from '../../app.service'
import { ActivatedRoute } from '@angular/router'
import { ConfirmationModalService } from '../../components/confirmation-modal/confirmation-modal.service'
import { AppConstants } from '../../app.constants'
import { PlayersService } from './players.service'
import { ProgramsService } from '../programs/programs.service'
import * as moment from 'moment'
import { PlayerListComponent } from './player-list/player-list.component'

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss'],
})
export class PlayersComponent implements OnInit {
  @ViewChild(PlayerListComponent) playerListComponent: PlayerListComponent
  player: Player
  oldPlayer: string
  isSky: boolean
  newPlayerId: string
  readonly CONSTANTS = AppConstants

  constructor(
    private programsService: ProgramsService,
    private playersService: PlayersService,
    private modalService: NgbModal,
    private userService: UserService,
    private appService: AppService,
    private route: ActivatedRoute,
    private confirmModalService: ConfirmationModalService
  ) {}

  ngOnInit() {
    this.isSky = location.href.includes('sky')
    this.player = this.playersService.playerDefaults()
    this.player.imageSizes = this.programsService.getRatio()
    this.oldPlayer = JSON.stringify(this.player)
  }

  onPlayerIdChange(playerId) {
    this.playersService.getPlayerById(playerId).subscribe((data) => {
      if (data) {
        this.player = data.response[0].personDTOs[0]
        this.player.externalId = this.getOptaId()
        if (this.player.dob) {
          const birthday = new Date(this.player.dob.toString())
          this.player.birthday = moment(birthday).format(this.CONSTANTS.DATE_FORMAT)
        } else {
          this.player.birthday = ''
        }
        
        if( this.player.sportsInfo && !this.player.sportsInfo[0].soccer){
          this.player.sportsInfo[0].soccer = this.playersService.sportInfoDefaults().soccer
        }

        this.player.englishFirstName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.EN
        )?.firstName
        this.player.englishLastName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.EN
        )?.lastName
        this.player.englishFullName =
          this.player.englishFirstName + ' ' + this.player.englishLastName

        this.player.spanishFirstName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.ES
        )?.firstName
        this.player.spanishLastName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.ES
        )?.lastName
        this.player.spanishFullName =
          this.player.spanishFirstName + ' ' + this.player.spanishLastName

        this.player.portugueseFirstName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.PT
        )?.firstName
        this.player.portugueseLastName = this.playersService.getValueByLang(
          this.player.names,
          this.CONSTANTS.PT
        )?.lastName
        this.player.portugueseFullName =
          this.player.portugueseFirstName + ' ' + this.player.portugueseLastName

        if (this.isSky) {
          const otherName = this.playersService.getOtherName(
            this.player.names,
            [this.CONSTANTS.EN, this.CONSTANTS.PT]
          )
          this.player.otherFirstName = otherName?.firstName
          this.player.otherLastName = otherName?.lastName
          this.player.otherLanguage =
            otherName?.language !== '' ? otherName?.language : null
        } else {
          const otherName = this.playersService.getOtherName(
            this.player.names,
            [this.CONSTANTS.EN, this.CONSTANTS.ES]
          )
          this.player.otherFirstName = otherName?.firstName
          this.player.otherLastName = otherName?.lastName
          this.player.otherLanguage =
            otherName?.language !== '' ? otherName?.language : null
        }
        this.player.otherFullName =
          this.player.otherFirstName + ' ' + this.player.otherLastName

        this.player.isEnTitleHide =
          this.player.englishFullName.replace(' ', '') !== '' ? true : false
        this.player.isPtEsTitleHide =
          this.player.spanishFullName.replace(' ', '') !== '' ||
          this.player.portugueseFullName.replace(' ', '') !== ''
            ? true
            : false
        this.player.isOtherTitleHide =
          this.player.otherFullName.replace(' ', '') !== '' ? true : false

        this.getImages()
      } else {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
          size: 'sm',
          centered: true,
          backdrop: 'static',
        })
        modalRef.componentInstance.title = 'Warning'
        modalRef.componentInstance.message = 'No data available.'
        modalRef.componentInstance.isAlert = true
      }
    })
  }

  getOptaId() {
    const index = this.player.externalRefs?.findIndex(
      (item) =>
        item.system === this.CONSTANTS.OPTA &&
        item.refName === this.CONSTANTS.OPTA_PLAYERID
    )
    if (index > -1) {
      return this.player.externalRefs[index].id
    }
  }

  getImages() {
    let fetchedImages = []
    this.player.imageSizes = this.programsService.getRatio()
    this.player.groupedImages = {}
    this.player.publishedImages = []
    this.playersService.getImages(this.player.id).subscribe((data) => {
      fetchedImages = (data?.response || []).filter(
        (image) => image.downloadState === 'DOWNLOADED'
      )
      this.player.groupedImages = this.groupBy(fetchedImages, 'ratio')
      const publishedImages = fetchedImages.filter((image) => image.published)
      this.player.imageSizes.forEach((size) => {
        const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
        if (publishRatio) {
          this.player.publishedImages.push(publishRatio)
        }
      })
      this.oldPlayer = JSON.stringify(this.player)
    })
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  onCreateNewPlayer(isCreateNew) {
    if (isCreateNew) {
      this.player = this.playersService.playerDefaults()
      this.player.imageSizes = this.programsService.getRatio()
      this.oldPlayer = JSON.stringify(this.player)
    }
  }

  onNewPlayerCreated(newPlayerCreated) {
    this.newPlayerId = newPlayerCreated
    this.playerListComponent.searchPlayers()
  }
}
