/*
  * =============================================================================
  * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
      * RESERVED
  *
  * This software is the confidential and proprietary information of Vrio Corp.
  * ("Proprietary Information"). Any use, reproduction, distribution or
  * disclosure of the software or Proprietary Information, in whole or in part,
  * must comply with the terms of the license agreement, nondisclosure agreement
  * or contract entered into with Vrio Corp. providing access to this software.
  *
  * ============================================================================
  */
import {
    Component,
    Input,
    OnInit,
    ViewChild
  } from '@angular/core'
  import { AssetService } from '../assets.service'
  import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
  import { Subject} from 'rxjs'
  
  import { AppConstants } from '../../../app.constants'
  import { ProgramsService } from '../../programs/programs.service'
  import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
  
  @Component({
    selector: 'app-assets-form',
    templateUrl: './assets-form.component.html',
    styleUrls: ['./assets-form.component.scss']
  })
  export class AssetsFormComponent implements OnInit{
    @Input() asset: Assets
    @Input() seasonsAndEpisodes: any[]
    @Input() programId : string
    @Input() program : Program
    @Input() isLoading: boolean
    @Input() wideVineInfo: any
    @Input() playReadyInfo: any
    @Input() fairPlayInfo: any
  
    @ViewChild('packageSearch', { static: true }) packageSearch: NgbTypeahead
    packageClick$ = new Subject<string>()
    packageFocus$ = new Subject<string>()
  
    active: number
    active2:number
    previewUrl : string
    readonly CONSTANTS = AppConstants
  
    constructor(
      private assetService: AssetService,
      private programService: ProgramsService
    ) { }
  
    ngOnInit() {
      this.isLoading = false
      this.active = 1
      this.asset = this.assetService.assetDefault() as any;
      this.program = this.programService.programDefaults() as any;
      this.previewUrl = this.CONSTANTS.IMAGE_PREVIEW_URL
    }
  
    //get asset type to display color
    getAssetTypeStyle(published) {
      return this.assetService.assetTypeLabel(published).style
    }
  
    //get asset status
    getAssetStatus(published){
      if(published === true)
      {
        return this.CONSTANTS.PUBLISHED
      } else if(published === false)
      {
        return this.CONSTANTS.UNPUBLISHED
      }
      return this.CONSTANTS.NOT_AVAILABLE
    }
  
      /**
     * Alternate Episode & Alternate Season Computed
     * @return episodeAltMapping
     */
       get episodeAltMappingComputed(): episodeAltMapping  {
        if (this.program?.episodeAltMappings && this.program.episodeAltMappings.length > 0) {
          return this.program.episodeAltMappings[0]
        }
    
        return {
          channelId: null,
          episodeNumber: null,
          providerId: null,
          region: null,
          regionWide: null,
          seasonNumber: null,
        };
      }
  
      //get image error
      onImageError(event) {
        event.target.src = this.previewUrl;
      }
  }