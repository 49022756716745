import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core'
import {VenuesService} from '../venues.service'
import {Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {CdkDragStart} from '@angular/cdk/drag-drop'
import {ActivatedRoute} from '@angular/router'
import {AppConstants} from '../../../app.constants'
import { Schedule } from '../../schedules/schedule'

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss'],
})
export class VenueListComponent implements OnInit {
  @Output() venueId: EventEmitter<string> = new EventEmitter()
  @Output() isCreateNew: EventEmitter<any> = new EventEmitter()
  @Input() newVenueId: string
  searchString: string
  searchChanged: Subject<string> = new Subject<string>()
  getParams: PaginationParams = {
    page: 0,
    size: 15,
  }
  venues: VenueSoccer[]
  countries: Country[]
  selectedVenueId: string
  showLoader: boolean = false
  isSky: boolean
  readonly CONSTANTS = AppConstants

  constructor(
    private venuesService: VenuesService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.venues = []
    this.isSky = location.href.includes('sky')
    this.searchString = ''
    this.countries = this.venuesService.getCountries()
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.searchString = params.id
      }
      this.searchVenues()
    })

    this.debouncedSearch()
  }

  ngOnChanges(changes: SimpleChanges) {
    const fields = Object.keys(changes)
    if (fields.includes('newVenueId') && this.newVenueId) {
      this.searchString = this.newVenueId
      this.selectedVenueId = this.newVenueId
      this.getParams.page = 0
      this.searchVenues()
    }
  }

  searchVenues() {
    const searchData = {
      searchString: this.searchString || ''
    }
    this.showLoader = true
    this.venuesService.searchVenues(searchData, this.getParams).subscribe((data: any) => {
      if (data?.response) {
        if (this.getParams.page < data?.totalPages) {
          if (this.getParams.page === 0) {
            this.venues = data.response
          } else {
            const newVenues = data.response
            this.venues.push(...newVenues)
          }
        } else {
          this.getParams.page--
          if (data?.totalPages === 0) {
            this.venues = []
          }
        }
      }
      this.showLoader = false
    })
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        if (!!value) {
          this.getParams.page = 0
        }
        this.searchVenues()
      })
  }

  searchKeyup(event: any, container: any) {
    this.searchChanged.next(event.target.value)
    container.scrollTop = 0
  }

  searchChange(event: any, container: any) {
    this.getParams.page = 0
    container.scrollTop = 0
    if (!event) {
      this.searchVenues()
    }
  }

  onScroll(event: any) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= 5
      this.getParams.page++
      this.searchVenues()
    }
  }

  getName(names: VenueName[]) {
    return this.venuesService.getName(names, this.isSky)?.value
  }

  getCountry(code: string) {
    if (!!code) {
      const index = this.countries.findIndex((item) => item.code === code)
      if (index > -1) {
        return this.countries[index].name
      } else {
        return code
      }
    }
  }

  onDragStart(event: CdkDragStart<Schedule>, index: number) {
    const venueItem = document.getElementById('venueItem-' + index)
    if (venueItem) {
      venueItem.style.border = '1px solid #D3D3D3'
      venueItem.style.backgroundColor = 'palegoldenrod'
      venueItem.style.borderRadius = '5px'
    }
  }

  viewVenue(id: string) {
    this.selectedVenueId = id
    this.venueId.emit(id)
  }

  createNewVenue() {
    this.isCreateNew.emit(true)
  }
}
