import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { AppConstants } from '../../app.constants'

@Injectable({ providedIn: 'root' })
export class PlayersService {
  teamsUrl: string
  playersUrl: string
  readonly CONSTANTS = AppConstants

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.teamsUrl = `${apiUrl}/v2/teams`
    this.playersUrl = `${apiUrl}/persons`
  }

  playerDefaults() {
    return {
      _id: '',
      id: '',
      vrioId: '',
      externalId: '',
      names: [],
      dob: null,
      gender: this.CONSTANTS.GENDERS[0],
      type: this.CONSTANTS.TYPES[0],
      height: 0,
      weight: 0,
      sportsInfo: [],
      externalRefs: [],
      providerInfo: [],
      images: [],
      longitude: '',
      latitude: '',
      timeZone: '',
      createdDate: '',
      updatedDate: '',
      englishFullName: '',
      englishFirstName: '',
      englishLastName: '',
      spanishFullName: '',
      spanishFirstName: '',
      spanishLastName: '',
      portugueseFullName: '',
      portugueseFirstName: '',
      portugueseLastName: '',
      otherFullName: '',
      otherFirstName: '',
      otherLastName: '',
      isEnTitleHide: true,
      isPtEsTitleHide: true,
      isOtherTitleHide: false,
      birthday: '',
      otherLanguage: undefined,
      publishedImages: [],
      imageSizes: [],
      groupedImages: {},
    }
  }

  sportInfoDefaults() {
    return {
      index: null,
      teamId: '',
      sportType: '',
      playerType: '',
      status: '',
      soccer: {
        foot: '',
        position: '',
      },
    }
  }

  getAllTeams(params: PaginationParams) {
    const { page, size } = params
    return this.http.get<VLSResponse>(
      `${this.teamsUrl}?page=${page}&size=${size}`
    )
  }

  getAllTeamPlayers(teamId: string) {
    return this.http.get<VLSResponse>(
      `${this.playersUrl}/search?teamId=${teamId}`
    )
  }

  getPlayerById(playerId: string) {
    return this.http.get<VLSResponse>(`${this.playersUrl}/${playerId}`)
  }

  getPlayersByName(name: string, params: PaginationParams) {
    const { page, size } = params
    return this.http.get<VLSResponse>(
      `${this.playersUrl}/search?name=${name}&page=${page}&size=${size}`
    )
  }

  getName(names: PlayerName[], isSky: boolean) {
    if (names?.length > 0) {
      const localLang = isSky ? this.CONSTANTS.PT : this.CONSTANTS.ES
      const indexLocal = names.findIndex((item) =>
        item.language?.toLowerCase().includes(localLang)
      )
      if (indexLocal > -1) {
        return names[indexLocal]
      } else {
        const index = names.findIndex((item) =>
          item.language?.toLowerCase().includes(this.CONSTANTS.EN)
        )
        if (index > -1) {
          return names[index]
        } else {
          return names[0]
        }
      }
    }
  }

  getImages(playerId: string) {
    return this.http.get<VLSResponse>(`${this.playersUrl}/images/${playerId}`)
  }

  save(data: Player) {
    const playerId = data.id
    const payload = new FormData()
    const flatData = this.flatten(data)
    for (let key in flatData) {
      if (!key.includes('createdDate') && !key.includes('updatedDate')) {
        payload.append(key, flatData[key])
      }
    }

    return !!playerId
      ? this.http.put<VLSResponse>(`${this.playersUrl}/${playerId}`, payload)
      : this.http.post<VLSResponse>(`${this.playersUrl}/`, payload)
  }

  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        var newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        var value = currentNode[key]
        if (newKey.includes('extraInfos') && key === 'value') {
          if (value.length > 1 && value[0] !== undefined) {
            target[newKey] = value
          } else {
            target[newKey] = []
          }
        } else if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  getValueByLang(fields: PlayerName[], lang: string) {
    const index = fields.findIndex((item) =>
      item.language?.toLowerCase().includes(lang)
    )
    if (index > -1) {
      return fields[index]
    } else {
      return {
        firstName: '',
        lastName: '',
        language: lang
      }
    }
  }

  getOtherName(fields: PlayerName[], lang: string[]) {
    const index = fields.findIndex((item) => !lang.includes(item.language?.toLowerCase()))
    if (index > -1) {
      return fields[index]
    } else {
      return {
        firstName: '',
        lastName: '',
        language: ''
      }
    }
  }
}
