import {Component, OnInit} from '@angular/core'
import {PluginStoreService} from "../plugin.store";
import {PluginService} from "../../../services/plugins/plugin.service";
import {PluginEntity, PluginStatusEnum} from "../../../services/plugins/plugin.interface";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-plugin-version',
  templateUrl: './plugin-version.component.html',
  styleUrls: ['./plugin-version.component.scss'],
})
export class PluginVersionComponent implements OnInit {
  items: Array<PluginEntity> = [];
  PluginStatus = PluginStatusEnum;
  selectedPluginVersionId: number;
  isLoading = false;

  constructor(private storeService: PluginStoreService, private pluginService: PluginService) {
  }

  loadData(plugInId?: string) {
    this.items = []
    if (plugInId) {

      this.isLoading = true
      this.pluginService.getVersions(plugInId)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(values => {
          this.items = values;
        })
    }
  }

  ngOnInit(): void {
    this.storeService.selectedPluginId$.subscribe(plugInId => {
      this.loadData(plugInId)
    })
    this.storeService.updatedPlugin$.subscribe(resp => {
      this.loadData(this.storeService.selectedPluginId$.value)
    })
    this.storeService.deletedPlugin$.subscribe(resp => {
      this.loadData(this.storeService.selectedPluginId$.value)
    })
    this.storeService.publishPlugin$.subscribe(resp => {
      this.loadData(this.storeService.selectedPluginId$.value)
    })
    this.storeService.selectedPluginVersion$.subscribe(resp => {
      this.selectedPluginVersionId = resp?.plugInVersion;
    })
  }

  clickItem(item: PluginEntity) {
    this.storeService.selectedPluginVersion$.next(item)
  }

  protected readonly PluginStatusEnum = PluginStatusEnum;
}
