import {Component, ContentChildren, ElementRef, Input, OnInit, QueryList} from '@angular/core';
import {ListItemComponent} from "./list-item/list-item.component";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ContentChildren(ListItemComponent, {read: ElementRef}) items: QueryList<ListItemComponent>;
	@Input() isLoading:boolean;
  get isHasChild() {
    return this.items && this.items?.length > 0;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
