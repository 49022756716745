/*
 * =============================================================================
 * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
   * RESERVED
 *
 * This software is the confidential and proprietary information of Vrio Corp.
 * ("Proprietary Information"). Any use, reproduction, distribution or
 * disclosure of the software or Proprietary Information, in whole or in part,
 * must comply with the terms of the license agreement, nondisclosure agreement
 * or contract entered into with Vrio Corp. providing access to this software.
 *
 * ============================================================================
 */

import { Component, Input, OnInit } from '@angular/core';
import { AssetService } from '../../assets-management/assets.service';
import * as moment from 'moment';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-all-assets',
  templateUrl: './all-assets.component.html',
  styleUrls: ['../asset-reports.component.scss']
})
export class AllAssetsComponent implements OnInit {
  @Input() assetType = '';
  assets: Assets[]
  params: PaginationParams
  headerFields: object[]
  filters: Record<string, string> = {}
  assetList: any
  isLoading: boolean
  readonly CONSTANTS = AppConstants
  constructor(
    private assetService: AssetService,
  ) { }

  ngOnInit(): void {
    this.params = {
      page: 1,
      size: 20
    }
    this.getAllAssets();
    this.assetList = this.assets
    this.headerFields = [
      { name: 'program.programType', label: this.CONSTANTS.TYPE_COLUMN },
      { name: 'asset.title', label: this.CONSTANTS.NAME_COLUMN },
      { name: 'program.seriesTitle', label: this.CONSTANTS.SHOW_NAME_COLUMN },
      { name: 'asset.displaySeasonNumber', label: this.CONSTANTS.SEASON_NUMBER_COLUMN },
      { name: 'asset.displayEpisodeNumber', label: this.CONSTANTS.EPISODE_NUMBER_COLUMN },
      { name: 'program.genres', label: this.CONSTANTS.GENRE_COLUMN },
      { name: 'asset.provider', label: this.CONSTANTS.PROVIDER_COLUMN },
      { name: 'asset.category', label: this.CONSTANTS.CATEGORY_COLUMN },
      { name: 'asset.channel', label: this.CONSTANTS.CHANNEL_COLUMN },
      { name: 'asset.programMapperId', label: this.CONSTANTS.PROGRAM_MAPPER_ID_COLUMN },
      { name: 'asset.vrioId', label: this.CONSTANTS.VRIO_ID_COLUMN },
      { name: 'asset.programId', label: this.CONSTANTS.PROGRAM_ID_COLUMN },
      { name: 'asset.dmsId', label: this.CONSTANTS.DMS_ID_COLUMN },
      { name: 'asset.tmsId', label: this.CONSTANTS.TMS_ID_COLUMN },
      { name: 'program.seriesTmsId', label: this.CONSTANTS.SERIES_TMS_ID_COLUMN },
      { name: 'asset.assetId', label: this.CONSTANTS.ASSET_ID_COLUMN },
      { name: 'asset.regions', label: this.CONSTANTS.REGION_COLUMN },
      { name: 'asset.pubStatus', label: this.CONSTANTS.PUB_STATUS_COLUMN },
      { name: 'asset.createdDate', label: this.CONSTANTS.CREATED_DATE_COLUMN },
      { name: 'asset.availabilityStartsAt', label: this.CONSTANTS.LICENSE_START_COLUMN },
      { name: 'asset.availabilityEndsAt', label: this.CONSTANTS.LICENSE_END_COLUMN }
    ]
  }

  formatDateTime(date: Date) {
    return moment(date).utc().format(this.CONSTANTS.DATETIME_FORMAT)
  }
  //filter on header of all assets table
  filterChange(event) {
    const { id, value } = event.target
    if (!value) {
      this.assets = this.assetList
    }
    this.filters[id] = value
    this.assets = this.assets.filter(item => {
      if (id.includes('.')) {
        const idSplit = id.split('.')
        const searchVal = item[idSplit[0]][idSplit[1]]
        if (searchVal != null) {
          return searchVal.toString().toLowerCase().includes(value.toString().toLowerCase())
        }
      } else {
        return item[id].toString().toLowerCase().includes(value.toString().toLowerCase())
      }
    })
  }

  //get all asset from DB
  getAllAssets() {
    const payload = {
      reportType: this.assetType,
      isListAll: false
    }
    this.assetService.getAllAsset(payload, this.params).subscribe((data: any) => {
      this.assets = data.response
      this.assetList = this.assets
      this.assetService.formatStatus(this.assets)
      this.isLoading = false
    })
  }

  //set event when scroll
  onScroll(event) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= this.assets.length
      this.params.page++
      this.triggerSearchPrograms(true)
    }
  }

  //action when scroll
  triggerSearchPrograms(isScroll = false) {
    this.isLoading = true
    const payload = {
      reportType: this.assetType,
      isListAll: false
    }
    this.assetService.getAllAsset(payload, this.params).subscribe(
      (data: any) => {
        if (data) {
          if (data.response) {
            const assets = data.response
            this.isLoading = false
            if (isScroll) {
              this.assets.push(...assets)
            } else {
              this.assets = assets
            }
          } else {
            if (this.params.page == 1) {
              this.assets = []
            }
          }
        } else {
          this.assets = []
        }
      }
    )
  }
}