import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../confirmation-modal/confirmation-modal.component';
import {AppConstants} from "../../../app.constants";
import {SchedulesService} from "../../../pages/schedules/schedules.service";
import {formatDate, SCHEDULE_FORMATDATE, SCHEDULEID_FORMAT} from "../../../utils/contants";
import {ScheduleVersion, ScheduleVersionDetail, ScheduleVersionRequest} from "../../../pages/schedules/schedule";
import * as dayjs from "dayjs";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

// Constants
const DEFAULT_FIRST_SCHEDULE_VERSION: ScheduleVersionDetail = {
  id: '-1',
  version: 1,
  provider: 'VLS',
  status: 'NEW'
};
const DEFAULT_SECOND_SCHEDULE_VERSION: ScheduleVersionDetail = {
  id: '-2',
  version: 1,
  provider: 'VLS',
  status: 'NEW'
};
const DEFAULT_FIRST_SCHEDULE: ScheduleVersion = {
  scheduleId: '-1',
  versions: [DEFAULT_FIRST_SCHEDULE_VERSION],
  contentLock: false
};
const DEFAULT_SECOND_SCHEDULE: ScheduleVersion = {
  scheduleId: '-2',
  versions: [DEFAULT_SECOND_SCHEDULE_VERSION],
  contentLock: false
};

@Component({
  selector: 'app-schedule-versions',
  templateUrl: './schedule-versions.component.html',
  styleUrls: ['./schedule-versions.component.scss']
})
export class ScheduleVersionsComponent implements OnInit, OnDestroy {
  // Accessors
  @Input() displayEmptyScheduleVersionWarning: Function;

  @Output() onSelectedVersionChanged: EventEmitter<ScheduleVersionDetail[]> = new EventEmitter();

  // public fields
  versionsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  requestSubject: BehaviorSubject<ScheduleVersionRequest> = new BehaviorSubject({});
  schedulesSubject: Subject<ScheduleVersion[]> = new Subject();
  selectedVersionsSubject: BehaviorSubject<ScheduleVersionDetail[]> = new BehaviorSubject([]);
  subscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private scheduleService: SchedulesService,
  ) {
    this.subscription = this.scheduleService.changeScheduleRequestCall.subscribe((request) => {
      this.versionsLoadingSubject.next(true)
      this.requestSubject.next(request);
    });
    this.selectedVersionsSubject.pipe(distinctUntilChanged())
      .subscribe(versions => this.onSelectedVersionChanged.emit(versions));
    this.schedulesSubject.pipe(distinctUntilChanged())
      .subscribe(schedules => {
        let selected = [];
        schedules.forEach(schedule => {
          const publishedVersion = schedule.versions.find(v => v.version === schedule.scheduledPublishedVersion);
          selected.push(publishedVersion ? publishedVersion : schedule.versions[0]);
        });
        this.selectedVersionsSubject.next(selected);
        this.versionsLoadingSubject.next(false);
      });
    this.requestSubject.pipe(distinctUntilChanged())
      .subscribe(request => {
        const timezone = this.requestSubject?.getValue()?.timezone?.value;
        const emptySchedules = timezone === 'GMT'
          ? [DEFAULT_FIRST_SCHEDULE]
          : [DEFAULT_FIRST_SCHEDULE, DEFAULT_SECOND_SCHEDULE];
        if (
          !request.timezone?.value
          || !request.date
          || !request.channel?.id
        ) {
          this.schedulesSubject.next(emptySchedules);
          return; // Don't call to api if there is any missing param
        }
        if (request.channel?.dthInfo?.isSlave === true) {
          const modal = this.modalService.open(ConfirmationModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
          });
          modal.componentInstance.title = AppConstants.WARNING;
          modal.componentInstance.message = AppConstants.MESSAGE_SLAVE_CHANNEL_MODAL
            .replace(new RegExp('sourceId', 'g'), request.channel?.dthInfo?.masterSource);
          modal.componentInstance.isAlert = true;
          modal.result.then((result) => {
            if (result) {
              this.closeModal()
            }
          });
          return; // Don't call api in case of slave schedule???
        }
        this.scheduleService.getVersionsByChannelIdAndDate(request)
          .subscribe(
            schedules => {
              const result = [...schedules];
              const stringDate = formatDate(this.requestSubject?.getValue()?.date);
              const date = dayjs.tz(stringDate, SCHEDULE_FORMATDATE, timezone);
              const endDate = date.add(1, "day").subtract(1, 'minute').tz('GMT');
              const startDate = date.tz('GMT');

              if (!schedules.map(item => item.scheduleId.split('-')[1]).includes(startDate.format(SCHEDULEID_FORMAT))) {
                // Add 1st schedule if api do not return
                result.unshift(DEFAULT_FIRST_SCHEDULE);
              }

              if (!schedules.map(item => item.scheduleId.split('-')[1]).includes(endDate.format(SCHEDULEID_FORMAT))) {
                // Add 2nd schedule if api do not return
                result.push(DEFAULT_SECOND_SCHEDULE);
              }
              this.schedulesSubject.next(result);
              
              // VLS-4321: No available schedules for this channel with the selected date warning
              // Only check first schedule to empty event or not
              if (result[0].scheduleId == "-1") {
                this.displayEmptyScheduleVersionWarning();
                return;
              }
            },
            () => {
              this.displayEmptyScheduleVersionWarning();
              this.schedulesSubject.next(emptySchedules);
            });
      });
  }

  // Life cycle methods
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Rendering methods
  closeModal() {
    this.modalService.dismissAll();
  }

  showAlert(type: string, message: string, size: any = 'sm') {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size,
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  isSelectedVersion(id: string) {
    return this.selectedVersionsSubject?.getValue()?.map(i => i.id).includes(id);
  }

  onSelectVersion(schedule: ScheduleVersion, selected: ScheduleVersionDetail) {
    // Remove previous selection of the schedule
    const selectedVersions = this.selectedVersionsSubject.getValue()?.filter(version => !schedule.versions.includes(version));
    // Add new selection to selected versions
    selectedVersions.push(selected);
    // emit an event to indicate that the selection has been changed
    this.selectedVersionsSubject.next(selectedVersions);
  }

  lockContent(schedule: ScheduleVersion) {
    // VLS-2185: Fix schedule content lock is not working properly
    if (this.requestSubject?.getValue().channel?.processingType === 'BUBBLE') {
      // display a warning message when lock bubble channel
      this.showAlert(
        'Warning',
        `Could not lock/unlock schedule version if channel is bubble`,
        'lg'
      )
    } else {
      // call toggle lock channel schedule api
      const scheduleDate = dayjs.tz(formatDate(this.requestSubject?.getValue()?.date), SCHEDULE_FORMATDATE, 'GMT').format('YYYYMMDD');
      this.scheduleService.contentLock(`${this.requestSubject?.getValue()?.channel?.id}-${scheduleDate}`
      ).subscribe(() => {
        schedule.contentLock = !schedule.contentLock;
        // display a success message and update isLock
        this.showAlert(
          'Success',
          `Successfully ${schedule.contentLock ? 'locked' : 'unlocked'} schedules`,
          'lg'
        )
      }, error => {
        // display an error message and revert lock state
        this.showAlert('Error message', error.error.errors, 'lg');
      })
    }
  }
}
