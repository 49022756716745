import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {debounceTime, tap} from "rxjs/operators";

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileComponent)
    }
  ]
})
export class FileComponent implements OnInit {

  get filePath() {
    return this.formControl.value?.name || '';
  }

  onChange: (value: File) => void;
  onTouch: () => void;
  @Input() disabled: boolean;
  formControl = new FormControl('')

  constructor() {
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        tap(value => {
          this.onChange(value)
        }),
      )
      .subscribe();
  }

  changeFile(e: Event) {
    const target = e.target as HTMLInputElement
    if (target.files[0]) {
      const file = target.files[0];
      this.formControl.setValue(file)
    }
  }

  writeValue(value: File): void {
    this.formControl.setValue(value, {emitEvent: false});
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }


}
