import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import {ChannelManagementService} from '../channel-management.service'
import {ConfirmationModalService} from 'src/app/components/confirmation-modal/confirmation-modal.service'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {Subject} from 'rxjs'
import {CdkDragDrop} from '@angular/cdk/drag-drop'
import {ChannelMasterSlaveModalComponent} from '../channel-master-slave-model/channel-master-slave-model.component'
import {AppConstants} from 'src/app/app.constants'
import {PlatformService} from 'src/app/services/platform.service'

@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss'],
})
export class ChannelsListComponent implements OnInit, OnChanges {
  @Input() targetComponent: string
  @Input() componentName: string
  @Output() autoPublish: EventEmitter<string> = new EventEmitter()
  @Output() channel: EventEmitter<SourceChannel> = new EventEmitter()
  @Input() isChannel;
  @Input() refreshSourceId: string
  @Input() refresh: boolean
  channelType: string
  disableButton: boolean
  channels
  selectedChannel
  searchString = ''
  searchChanged: Subject<string> = new Subject<string>()
  getParams: PaginationParams = {
    page: 1,
    size: 500,
  }
  showLoader: string
  channelFilters = [
    { name: 'OTT', abbrev: 'ott', value: false },
    { name: 'DTH', abbrev: 'dth', value: false },
    { name: 'ALL', abbrev: 'all', value: true },
  ]
  additionalFilters
  optionalFilters
  filterList = this.getFilterList()
  providerSource = ['Gracenote', 'BLIM']
  selectedMasterSourceId: string
  masterSourceChannels
  backupChannels
  isInit: boolean
  readonly CONSTANTS = AppConstants

  constructor(
    private channelService: ChannelManagementService,
    private modalService: NgbModal,
    private confirmationModal: ConfirmationModalService,
    private platformService: PlatformService
  ) { }

  ngOnInit() {
    this.isInit = true
    this.additionalFilters = this.filterList.find(
      (x) => x.component === this.targetComponent
    ).filters
    if (this.targetComponent === 'provider') {
    this.optionalFilters = this.additionalFilters.filter(item => item.name !== 'Both');
    }
    else {
      this.optionalFilters = this.additionalFilters;
    }
    this.debouncedSearch()
    this.getChannels()
  }

  ngOnChanges(changes: SimpleChanges) {
    const fields = Object.keys(changes)
    if ((fields.includes('refreshSourceId') && this.refreshSourceId) ||
      (fields.includes('refresh') && this.refresh != undefined && this.refresh != null)) {
      this.searchString = this.refreshSourceId
      this.refresh = !this.refresh
      this.getChannels()
    }
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (!!value) {
          this.searchString = value
          this.getChannels()
        } else {
          this.getChannels()
        }
      })
  }

  // Enhance search service channel
  selectFilter(selectedIndex: number) {
    if (this.targetComponent !== 'provider') {
      this.channelFilters.forEach((filter, index) => {
        filter.value = index === selectedIndex;
        // clean all filter
        this.additionalFilters.forEach((item) => {
          item.value = false
        })
        if (filter?.value && filter?.name === "OTT") {
          this.optionalFilters = this.additionalFilters.filter(item => item.name !== 'HD' && item.name !== 'SD' && item.name !== '4K');
        } else if (filter?.value && filter?.name === "DTH") {
          this.optionalFilters = this.additionalFilters.filter(item => item.name !== 'LINEAR' && item.name !== 'VOD' && item.name !== 'HYBRID');
        } else if (filter?.value && filter?.name === "ALL") {
          this.optionalFilters = this.additionalFilters;
        }
      });
    }
    else { 
      this.additionalFilters.forEach((filter, index) => {
        filter.value = index === selectedIndex;})
    }
    this.getChannels();
    }

  // retrieves channels
  getChannels() {
    // filter only checked platforms
    const fnFilter = (lst) => [
      ...lst.filter((x) => !!x.value).map(({name}) => name),
    ]

    const isScheduleComponent = this.targetComponent === 'schedules';

    // service channel list
    if (this.targetComponent !== 'provider') {
        const chanType : string[] = []
        const processingType = isScheduleComponent ? ''
          : this.additionalFilters[3].value ? 'BUBBLE' : ''

        let channelResolution: string[] = []
        if (this.isChannel && this.additionalFilters[4]?.value) {
          channelResolution.push("HD")
        }
        if (this.isChannel && this.additionalFilters[5]?.value) {
          channelResolution.push("SD")
        }
        if (this.isChannel && this.additionalFilters[6]?.value) {
          channelResolution.push("4K")
        }

        if (this.isChannel && this.additionalFilters[0]?.value) {
          chanType.push("LINEAR")
        }
        if (this.isChannel && this.additionalFilters[1]?.value) {
          chanType.push("VOD")
        }
        if (this.isChannel && this.additionalFilters[2]?.value) {
          chanType.push("HYBRID")
        }
  
        const excludeSlaveChannel = isScheduleComponent || !this.additionalFilters[7].value ? "true" : "false";

        const data: any = {
          chanType,
          processingType,
          channelResolution,
          searchString: this.searchString,
          excludeSlaveChannel
        }

        let platforms = [];
        if (this.channelFilters[0]?.value) {
          platforms = ['OTT'];
        }
        if (this.channelFilters[1]?.value) {
          platforms = ['DTH'];
        }
        if (this.channelFilters[2]?.value) {
            data.platform = ['OTT', 'DTH','Both']; 
        }
        if (platforms.length > 0 && platforms.length < 3) {
          data.platform = platforms;
        }

        if (isScheduleComponent) {
          data.published = "true"
          data.platform = [ 'OTT', 'DTH', 'Both' ]
        }

        this.getServiceChannels(data)
    } else {
      // provider channel list
      if(this.additionalFilters[0].value || this.additionalFilters[1].value) {
        this.additionalFilters[2].value =true;
      }
      let source = fnFilter(this.additionalFilters)
      if(source.length > 0 && source[0] === 'ALL' || source[0] === 'Both') {
        source = this.additionalFilters.filter(item => item.name !== 'ALL').map(elem => elem.name);
      }
      const providerSearch: SearchProvider = {
        searchString: this.searchString,
        source: source,
      }

      this.getProviders(providerSearch)
    }
  }

  getChannelNames() {
    this.channels.forEach(channel => {
      // set default dthInfo
      if (!channel.dthInfo) {
        channel.dthInfo = this.channelService.dthInfoDefault() as DTHInfo
      }

      (channel.channelNames || []).forEach(element => {
        if (element?.type.toLowerCase() === "short") {
          channel.channelNameFull = element?.value;
          channel.channelName = (element?.value || '').substring(0, 25);
        }
      });
      channel.channelNameFull = channel.channelNameFull !== ''
        ? channel.channelNameFull
        : channel?.channelNames[0].value;
      channel.channelName = channel.channelName !== ''
        ? channel.channelName
        : (channel?.channelNames[0].value || '').substring(0, 25);

      if (channel.dayParts?.length > 0 && channel.dayParts[0].mappings) {
        channel.gracenoteId = channel.dayParts[0]?.mappings[0]?.prgSvcId
      }
    });
    this.channels.sort(function (a, b) {
      if (a.channelName < b.channelName) {
        return -1;
      }
      if (a.channelName > b.channelName) {
        return 1;
      }
      return 0;
    })
  }

  getServiceChannels(body) {
    this.channelService.getServiceChannels(this.getParams, body).subscribe(
      (data: ResponseDTO) => {
        this.channels = data.response
        if (this.targetComponent === 'schedules') {
          this.channels = this.channels.filter(channel => ['LINEAR', 'HYBRID'].includes(channel.channelType))
        }

        this.getChannelNames();

        if (this.channels.length > 0) {
          this.setChannel(this.channels[0])
        }
        if (this.isInit && body.searchString === '') {
          this.masterSourceChannels = [...this.channels]
          this.backupChannels = [...this.channels]
        }
      },
      (error) => {
        const errorMessages = error.error.errors // error messages service response
        if (errorMessages === undefined) {
          // in case cannot connect or callable backend, display default error message
          this.confirmationModal.createDefaultErrorMessage()
        } else {
          this.confirmationModal.createModal({
            title: 'Search service channel error',
            message: errorMessages,
          })
        }
      }
    )
  }

  getProviders(body) {
    this.channelService.getProviderChannelList(this.getParams, body).subscribe(
      (data: any) => {
        this.channels = data.response.map(channel => {
          return {
            ...channel,
            name: channel.names.find(c => c.key === 'shortName')?.value
          }
        }).sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      },
      (error) => {
        const errorMessages = error.error.errors // error messages service response
        if (errorMessages === undefined) {
          // in case cannot connect or callable backend, display default error message
          this.confirmationModal.createDefaultErrorMessage()
        } else {
          this.confirmationModal.createModal({
            title: 'Search provider error',
            message: errorMessages,
          })
        }
      }
    )
  }

  openModal(modal) {
    this.clearFilter() // init service channel list for dropdown
    this.modalService.open(modal, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      keyboard: false,
    })
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  searchKeyUp(event) {
    this.searchChanged.next(event.target.value)
  }

  searchChange(val: string) {
    if (!val) {
      this.getChannels()
    }
  }

  channelClick(event, channel: SourceChannel) {
    if (this.targetComponent !== 'transmit') {
      event.preventDefault()
      this.setChannel(channel);
    } else {
      if (event.target.id === 'channelList') {
        channel.checked = !channel.checked
      }
    }
  }

  // sets the selected channel and emit the value
  setChannel(channel: SourceChannel) {
    if (
      this.targetComponent === 'service' ||
      this.targetComponent === 'schedules'
    ) {
      this.selectedChannel = Object.assign({}, channel)

      this.channel.emit({
        ...channel,
        name: channel.channelNames[0].value,
        mappings: channel.mappings === undefined ? [] : channel.mappings,
        category:
          !channel.categories ? '' : channel.categories.map(({name}) => name).join(','),
      })
    }
  }

  // the start of draggable action
  onDragStart(event: CdkDragDrop<ProviderChannel>, index: string) {
    const channelItem = document.getElementById('providerItem-' + index)
    if (channelItem) {
      channelItem.style.border = '1px solid #D3D3D3'
      channelItem.style.borderRadius = '5px'
    }
  }

  getFilterList() {
    return [
      {
        component: 'service',
        filters: [
          {name: 'LINEAR', abbrev: 'lin', value: false},
          {name: 'VOD', abbrev: 'vod', value: false},
          {name: 'HYBRID', abbrev: 'hyb', value: false},
          {name: 'Bubble', abbrev: 'bubble', value: false},
          {name: 'HD', abbrev: 'hd', value: false},
          {name: 'SD', abbrev: 'sd', value: false},
          {name: '4K', abbrev: '4k', value: false},
          {name: 'Slave', abbrev: 'slave', value: false},
        ],
      },
      {
        component: 'provider',
        filters: [
          {name: 'OTT', abbrev: 'ott', value: false},
          {name: 'DTH', abbrev: 'dth', value: false},
          {name: 'Both', abbrev: 'both', value: false},
          {name: 'ALL', abbrev: 'all', value: true},
        ],
      },
      {
        component: 'schedules',
        filters: [
          {name: 'GRACENOTE', abbrev: 'gn', value: true},
          {name: 'BLIM', abbrev: 'bl', value: true},
        ],
      },
      {
        component: 'transmit',
        filters: [
          {name: 'OTT', abbrev: 'ott', value: true},
          {name: 'DTH', abbrev: 'dth', value: true},
        ],
      },
    ]
  }

  /**
   * Filter channel list base on selected platform
   *
   * @param value @link channelFilters
   */
  onMasterMappingFilterChange(platform) {
    // checks for undefined param
    if (platform !== undefined) {
      // load channel filtered by platform
      this.masterSourceChannels = [
        ...this.backupChannels.filter(
          (channel) =>
            channel.platform.toLowerCase() === platform.abbrev.toLowerCase()
        ),
      ]
    }
  }

  clearFilter() {
    // initialize to original state when clearing platform filter for master source mapping
    this.masterSourceChannels = [...this.backupChannels]
  }

  /**
   * Handle Mapping master source action.
   * User select master service channel and click submit button
   */
  mappingMasterSource() {
    const title = 'Mapping Master Source'
    this.channelService
      .mappingMasterSource(this.selectedChannel.id, this.selectedMasterSourceId)
      .subscribe(
        (result) => {
          // update selected channel
          this.selectedChannel.masterSource = result.response.masterSource
          // display message
          const message = 'Successfully mapped Master source channel'
          const errorMessages =
            result.errors !== undefined && result.errors.length > 0
              ? result.errors
              : []
          this.confirmationModal.createModal({title, message, errorMessages})
        },
        (error) => {
          const errorMessages = error.error.errors // error messages service response
          if (errorMessages === undefined) {
            // in case cannot connect or callable backend, display default error message
            this.confirmationModal.createDefaultErrorMessage()
          } else {
            this.confirmationModal.createModal({
              title,
              message: errorMessages,
            })
          }
        }
      )

    this.closeModal()
    this.selectedMasterSourceId = null // reset previous selected value
  }

  autoPublishOnChange(value, channel) {
    const title = 'Channel processing';
    const processingType = value ? 'AUTO' : 'BUBBLE';

    this.channelService.triggerAuto(channel.id, value)
      .subscribe((result) => {
        const errorMessages =
          result.errors !== undefined && result.errors.length > 0
            ? result.errors
            : []
        let message;
        if (result.errors.length === 0) {
          channel.processingType = processingType;

          this.channels.forEach(element => {
            if (element.id == channel.id) {
              element.processingType = processingType
            }
          });

          this.autoPublish.emit(channel);
          message = 'Sucessfully updated the publishing status.'
        }
        this.confirmationModal.createModal({title, message, errorMessages})
      }, (error) => {
        const errorMessages = error.error.errors // error messages service response
        if (errorMessages === undefined) {
          // in case cannot connect or callable backend, display default error message
          this.confirmationModal.createDefaultErrorMessage()
        } else {
          this.confirmationModal.createModal({
            title,
            message: errorMessages,
          })
        }
      });
  }

  openMasterSlaveModal(channel) {
    const modal = this.modalService.open(ChannelMasterSlaveModalComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    })

    modal.componentInstance.channel = channel;
    modal.result.then(() => {
      this.getChannels()
    })
  }

  isDthPlatform() {
    return this.platformService.verifyPlatform(AppConstants.DTH)
  }
}
