import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputComponent } from './input.component'
import { TextAreaComponent } from './text-area/text-area.component'
import { DatePickerComponent } from './date-picker/date-picker.component'
import { ToggleComponent } from './toggle/toggle.component'
import { FileComponent } from './file/file.component'
import { ButtonComponent } from '../button/button.component'
import { ButtonModule } from '../button/button.module'
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
  declarations: [
    InputComponent,
    TextAreaComponent,
    DatePickerComponent,
    ToggleComponent,
    FileComponent,
    SearchBarComponent,
  ],
  imports: [CommonModule, ButtonModule],
  exports: [
    InputComponent,
    TextAreaComponent,
    DatePickerComponent,
    ToggleComponent,FileComponent,FormsModule,ReactiveFormsModule,SearchBarComponent
  ],
})
export class InputModule {}
