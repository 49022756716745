import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { APGReportService } from '../apg-report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-apg-epo-report',
  templateUrl: './apg-epo-report.component.html',
  styleUrls: ['../apg-report.component.scss']
})
export class ApgEpoReportComponent implements OnInit, OnChanges {
  @Input() searchString: string
  
  listEPOInfos: APGReport[]
  searchChanged: Subject<string> = new Subject<string>()
  showLoader: boolean = false
  loaderDownload: boolean = false
  loaderResend: boolean = false
  selectedEPOInfo: APGReport
  selectedIndex: number
  getParams: PaginationParams = {
    page: 0,
    size: 20,
  }
  sortByHeader: any[]
  readonly CONSTANTS = AppConstants

  constructor(
    private apgReportService: APGReportService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.listEPOInfos = []
    this.sortByHeader = this.defaultSorting()
    this.reset()
    this.debouncedSearch()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fields = Object.keys(changes)
    if (fields.includes('searchString')) {
      this.searchChange(this.searchString)
    }
  }

  searchChange(value: string) {
    this.searchChanged.next(value)
  }

  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        this.sortByHeader = this.defaultSorting()
        this.reset()
      })
  }

  defaultSorting() {
    return [
      {name: 'EPO Header', fieldName:'goHeader', isDisplay: true, isAsc: true},
      {name: 'Program ID', fieldName:'programId', isDisplay: true, isAsc: null},
      {name: 'Created Date', fieldName:'createdDate', isDisplay: true, isAsc: null},
      {name: 'Sent Date', fieldName:'sentDate', isDisplay: true, isAsc: null},
      {name: 'File Path', fieldName:'filePath',isDisplay: false},
      {name: 'Status', fieldName:'status', isDisplay: true, isAsc: null},
      {name: 'Reason', fieldName:'reason', isDisplay: false},
    ]
  }

  onChangeSorting(index: number, isAsc: boolean) {
    let oldSorting = this.sortByHeader.findIndex(item => item.isAsc != null)
    if (oldSorting >= 0 && oldSorting != index) {
      this.sortByHeader[oldSorting].isAsc = null
    }
    this.sortByHeader[index].isAsc = this.sortByHeader[index].isAsc != null ? !this.sortByHeader[index].isAsc : isAsc
    this.reset()
  }
  
  reset() {
    this.selectedIndex = null
    this.selectedEPOInfo = null
    let container = document.getElementById("tableEPOFiles")
    if (container) {
      container.scrollTop = 0
    }
    this.getParams.page = 0
    this.getEPOFiles()
  }

  getEPOFiles() {
    const searchData = {
      goType: this.CONSTANTS.EPO,
      programId: this.searchString || null,
      sort: this.sortByHeader.filter(item => item.isAsc != null)
    }

    this.showLoader = true
    this.apgReportService.getGoFiles(this.getParams, searchData).subscribe((data : any) =>{
      if (data?.response) {
        if (this.getParams.page < data?.totalPages ) {
          if (this.getParams.page === 0) {
            this.listEPOInfos = data.response
          } else {
            const newData = data.response
            this.listEPOInfos.push(...newData)
          }
        } else {
          this.getParams.page--
          if (data?.totalPages === 0) {
            this.listEPOInfos = []
          }
        }

        // format date
        this.listEPOInfos = this.apgReportService.formatDate(this.listEPOInfos)
      }
      this.showLoader = false
    })
  }

  onScroll(event: any) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit && this.showLoader == false) {
      elem.scrollTop -= this.listEPOInfos.length
      this.getParams.page++
      this.getEPOFiles()
    }
  }

  checked(index: number) {
    if (this.selectedIndex != null) {
      this.selectedIndex = null
      this.selectedEPOInfo = null
    } else {
      this.selectedIndex = index
      this.selectedEPOInfo = this.listEPOInfos[index]
    }
    
  }

  resend() {
    const body = {
      goType: this.CONSTANTS.EPO,
      header: this.selectedEPOInfo.goHeader || null
    }
    this.loaderResend = true

    this.apgReportService.resend(body).subscribe((response) => {
      if(response){
        this.showAlert(AppConstants.SUCCESS, AppConstants.MESSAGE_PROCESS_FILE_SUCCESS)
        this.loaderResend = false
      }
    },
    (error) => {
      this.loaderResend = false
      this.showAlert(AppConstants.ERROR, AppConstants.MESSAGE_PROCESS_FILE_FAIL)
    })
  }

  download() {
    const body = {
      goType: this.CONSTANTS.EPO,
      header: this.selectedEPOInfo.goHeader || null
    }
    this.loaderDownload = true

    this.apgReportService.download(body).subscribe((response) => {
      if(response){
        const blob = new Blob([response.body], { type: response.headers.get('content-type') })
        const fileName = response.headers.get('content-disposition').split(';')[1]
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
        this.loaderDownload = false
      }
    },
    (error) => {
      this.loaderDownload = false
      this.showAlert(AppConstants.ERROR, `${error?.error?.errors[0]}`)
    })
  }

  showAlert(type, message) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: "sm",
      centered: true,
      backdrop: "static",
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
    modalRef.result.then((res) => {
      if (res) {
        this.searchString = ''
        this.sortByHeader = this.defaultSorting()
        this.reset()
      }
    })
  }
}
