import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import categories from './categories.json'
import countries from './countries.json'
import { AppConstants } from 'src/app/app.constants'
import { isArray } from 'src/app/utils/contants'
import { PlatformService } from 'src/app/services/platform.service'

@Injectable({ providedIn: 'root' })
export class ProgramsService {
  programsUrl: string
  programsUrlV2: string
  genreUrl: string
  ratingsUrl: string
  teamsUrl: string
  venuesUrl: string
  advisoryUrl: string
  celebrityUrl: string
  usaParentalRatings: string[]
  booleanField: string[]
  mpaaRatings: string[]
  brRatings: string[]
  availableTitleLenEn: number[]
  availableTitleLenEs: number[]
  availableDescLenEn: number[]
  availableDescLenEs: number[]
  getParams: {
    page: 0
    size: 300
  }
  programTest: any
  providerGracenote: string
  systemUrl : string
  stageUrl: string
  readonly CONSTANTS = AppConstants
  
  constructor(
    private http: HttpClient,
    private platformService: PlatformService
    ) {
    const apiUrl = environment.apiUrl
    this.programsUrl = `${apiUrl}/programs`
    this.programsUrlV2 = `${apiUrl}/v2/programs`
    this.genreUrl = `${apiUrl}/genres`
    this.ratingsUrl = `${apiUrl}/ratings`
    this.teamsUrl = `${apiUrl}/teams`
    this.venuesUrl = `${apiUrl}/venues`
    this.advisoryUrl = `${apiUrl}/advisories`
    this.celebrityUrl = `${apiUrl}/celebrities`
    this.systemUrl = `${apiUrl}/programs/systemAndRefName`
    this.stageUrl = `${apiUrl}/stages`

    this.usaParentalRatings = ['G', 'TVG', 'TVPG', 'TVMA']
    this.mpaaRatings = ['PG-13', 'TV14', 'R', 'N-17']
    this.brRatings = ['L', '10', '12', '14', '16', '18']
    this.programTest = {}
    this.providerGracenote = 'gracenote'
    this.booleanField = ['isSports']
  }

  searchPrograms(searchData, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.programsUrlV2}/search?page=${page}&size=${size}`
    return this.http.post<ProgramSearch>(searchUrl, searchData)
  }

  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        var newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        var value = currentNode[key]
        if (newKey.includes('extraInfos') && key === 'value') {
          // Fix be removed extraInfos.value after updating program
          if (isArray(value)) {
            if ( value.length > 0 && value[0] !== undefined) {
              target[newKey] = value
            } else {
              target[newKey] = []
            }
          } else  {
            target[newKey] = value
          }
        } else if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  save(data) {
    // remove targetPlatform for SKY UI
    if (!this.isSSLA()) {
      delete data.targetPlatform
    }

    for (const key in data) {
      if (data[key] == null) {
        delete data[key];
      }
      // just convert string to boolean, ignore null, undefined
      if (this.booleanField.includes(key)) {
        if (data[key] === 'false') {
          data[key] = false
        }
        if (data[key] === 'true') {
          data[key] = true
        }
      }
    }
    const programId = data.programId

    if (!!programId && data.images?.length > 0) {
      let imagesData = { images: data.images }
      let flatImagesData = this.flatten(imagesData)
      var uploadPayload = new FormData();
      for (let key in flatImagesData) {
        uploadPayload.append(key, flatImagesData[key])
      }
      
      var responseUpload =  this.http.post(`${this.programsUrl}/uploadImage/${programId}`, uploadPayload).toPromise()
      data.images = []
    }

    const payload = new FormData()
    this.programTest = this.flatten(data)
    for (let key in this.programTest) {
      if (!key.includes('createdDate') && !key.includes('updatedDate')) {
        payload.append(key, this.programTest[key])
      }
    }

    return !!programId
      ? this.http.put<Program>(`${this.programsUrl}/${programId}`, payload)
      : this.http.post<Program>(this.programsUrl, payload)
  }

  getMarket() {
    return this.http.get<VLSResponse>(`${this.programsUrl}/market`)
  }

  getProgramVersions(programId: string) {
    return this.http.get<VLSResponse>(
      `${this.programsUrl}/version/${programId}`
    )
  }

  getSeasonsAndEpisodes(programId: string, season: number = 1) {
    return this.http.get<VLSResponse>(
      `${this.programsUrl}/episodes/${programId}?season=${season}`
    )
  }

  getAllEpisodesOfSeries(seriesId: string) {
    return this.http.get<VLSResponse>(
      `${this.programsUrl}/${seriesId}/episodes`
    )
  }

  getProgramByVersion(versionId: string, provider: string = 'masterentity') {
    this.setLengthValues()
    return this.http.get<VLSResponse>(
      `${this.programsUrl}/${versionId}?provider=${provider.toLowerCase()}`
    )
  }

  lockProgramContent(contentLock: boolean, versionId: string) {
    return this.http.put<programVersion>(
      `${this.programsUrl}/${versionId}/contentlock`,
      { contentLock }
    )
  }

  getAllGenres() {
    const options = { params: { page: '0', size: '300' } }
    return this.http.get<VLSResponse>(this.genreUrl, options)
  }

  getAllRatings() {
    const options = { params: { page: '0', size: '300' } }
    return this.http.get<VLSResponse>(this.ratingsUrl, options)
  }

  filterRatings(selectedRatings = [], code) {
    return selectedRatings
      .filter((rating) => rating.code === code)
      .map((r) => {
        return {
          id: r.id,
          name: r.rating,
          org: r.organization
        }
      })
  }

  getTeams() {
    const options = { params: { page: '0', size: '300' } }
    return this.http.get<VLSResponse>(this.teamsUrl, options)
  }

  getVenues() {
    const options = { params: { page: '0', size: '300' } }
    return this.http.get<VLSResponse>(this.venuesUrl, options)
  }

  getAdvisories() {
    const options = { params: { page: '0', size: '300' } }
    return this.http.get<VLSResponse>(this.advisoryUrl, options)
  }

  getCelebrities() {
    return this.http.get<VLSResponse>(this.celebrityUrl)
  }

  getSystems(component){
    if(component === 'programs'){
      return [
        {
          system: 'gracenote',
          refName: ['tmsId', 'rootId', 'seriesId', 'connectorId']
        },
        {
          system: 'opta',
          refName: ['optaMatchId', 'competitionId', 'tournamentCalendarId']
        },
        {
          system: 'fifa',
          refName: ['fifaMatchId']
        }
      ]
    }
    if(component === 'team'){
      return [
        {
          system: 'gracenote',
          refName: ['gnTeamId', 'gnTeamBrandId']
        },
        {
          system: 'opta',
          refName: ['optaTeamId']
        }
      ]
    }
    if(component === 'venue'){
      return [
        {
          system: 'gracenote',
          refName: ['gnVenueId', 'gnVenueBrandId']
        },
        {
          system: 'opta',
          refName: ['optaVenueId', 'optaCountryId']
        }
      ]
    }
  }

  getDuplicateProgram(data){
    const checkDuplicateProgramUrl = `${this.programsUrl}/dateAndTitles`
    return this.http.post<any>(checkDuplicateProgramUrl, data)
  }

  sendImage(formData: FormData, market: string = ''): Observable<string> {
    const marketParams = market === 'BRA' ? `?market=BRA` : ''
    return this.http.post<string>(
      `${this.programsUrl}/uploadImage${marketParams}`,
      formData,
      { responseType: 'text' as 'json' }
    )
  }

  programTypeLabel(type) {
    if(type){
      type = type.trim()
    }
    let programType: {
      code: string,
      style: string
    } = {
      code: 'MV',
      style: 'warning'
    }
    if (type === 'EPISODE') {
      programType = {
        code: 'EP',
        style: 'secondary'
      }
    } else if (type === 'SERIES') {
      programType = {
        code: 'SH',
        style: 'danger'
      }
    } else if (type === 'SHOW') {
      programType = {
        code: 'SH',
        style: 'info'
      }
    } else if (type === 'SPORT') {
      programType = {
        code: 'SH',
        style: 'primary'
      }
    }
    return programType
  }

  programDefaults() {
    return {
      _id: '',
      id: '',
      contentLock: false,
      createdDate: '',
      name: '',
      description: '',
      shortDescription: '',
      episodeTitle: '',
      episodeNumber: '',
      seasonNumber: '',
      type: '',
      programId: '',
      ratingString: '',
      ratings: [],
      ratingSummary: [],
      pictures: {},
      pictureID: '',
      genres: [],
      genreIds: [],
      credits: [],
      groupedCastAndCrew: {},
      groupedCrew: [],
      groupedCast: [],
      castAndCrewCategories: [],
      images: [],
      source: '',
      yearOfRelease: '',
      seasonYear: '',
      originalAudiolang: '',
      originalTitle: {
        value: '',
        length: 0,
        type: 'original',
      },
      englishTitle: {
        value: '',
        length: null,
      },
      englishShortTitle: {
        value: '',
        length: null,
      },
      englishEpisodeTitle: {
        value: '',
        length: null,
      },
      englishDescription: {
        value: '',
        length: null,
      },
      englishShortDescription: {
        value: '',
        length: null,
      },
      spanishTitle: {
        value: '',
        length: null,
      },
      spanishShortTitle: {
        value: '',
        length: null,
      },
      spanishEpisodeTitle: {
        value: '',
        length: null,
      },
      spanishDescription: {
        value: '',
        length: null,
      },
      spanishShortDescription: {
        value: '',
        length: null,
      },
      portugueseTitle: {
        value: '',
        length: null,
      },
      portugueseShortTitle: {
        value: '',
        length: null,
      },
      portugueseEpisodeTitle: {
        value: '',
        length: null,
      },
      portugueseDescription: {
        value: '',
        length: null,
      },
      portugueseShortDescription: {
        value: '',
        length: null,
      },
      language: 'es',
      programType: '',
      subType: '',
      mpaaRatings: '',
      usaParentalRatings: '',
      brazilRatings: '',
      imageCategories: [],
      extraInfo: {},
      extraInfos: [],
      releaseYear: '',
      venueIds: [],
      teamIds: [],
      titles: [],
      parentTitle: [],
      descriptions: [],
      keywords: [],
      externalLinks: '',
      movieVersionLabel: '',
      productionCompanies: [],
      productionCompaniesString: '',
      provider: 'masterentity',
      awards: [],
      releases: [],
      runTime: '',
      countriesOfOrigin: '',
      advisories: [],
      advisoryNames: [],
      providerInfo: [],
      originalSoundMixes: '',
      originalPictureformat: '',
      url: '',
      tmsId: {
        id: '',
        refName: '',
        system: '',
        lang: '',
      },
      rootId: {
        id: '',
        refName: '',
        system: '',
        lang: '',
      },
      connectorId: {
        id: '',
        refName: '',
        system: '',
        lang: '',
      },
      listEpisode: [],
      vrioId: '',
      sportsInfo: {
        sportSeason: {},
        playoffRoundId: '',
        playoffRound: '',
      },
      imgIdsRemove: [],
      dthInfo: {
        useEpisodeTitle: false
      },
      targetPlatform: this.isSSLA() ? AppConstants.OTT.toUpperCase() : null,
      concatSeriesTitle: null
    }
  }

  releaseDefaults() {
    return {
      index: null,
      country: '',
      date: '',
      type: '',
      medium: '',
    }
  }

  externalRefsDefault() : externalRefs {
    return {
      index: null,
      lang: '',
      refName: '',
      system: '',
      id: ''
    }
  }
  awardsDefaults() {
    return {
      index: null,
      award: '',
      awardId: '',
      awardRootId: '',
      won: 'true',
      category: '',
      categoryId: '',
      recipient: '',
      recipientId: '',
      name: '',
      year: '',
    }
  }

  imageDefaults(ratio = '') {
    return {
      index: null,
      id: '',
      uri: '',
      ratio: ratio,
      width: 0,
      height: 0,
      category: 'Iconic',
      imageFile: null,
      imageURL: '',
      published: false,
    }
  }

  keywordsDefaults() {
    return {
      key: '',
      src: '',
      value: '',
    }
  }

  programTypes() {
    return [
      { code: 'MV', name: 'Movie', subType: '' },
      { code: 'SV', name: 'Show', subType: '' },
      { code: 'EP', name: 'Episode', subType: 'Series Episode' },
      { code: 'SP', name: 'Sport', subType: 'Sport Series' },
      { code: 'SE', name: 'Episode', subType: 'Sport Episode' },
    ]
  }

  languageList() {
    return [
      { language: 'English', value: 'en' },
      { language: 'Portuguese', value: 'pt' },
      { language: 'Spanish', value: 'es' },
    ]
  }

  transientFields() {
    return [
      'name',
      'shortDescription',
      'ratingString',
      'ratingIds',
      'ratingSummary',
      'pictures',
      'genreIds',
      'transmit',
      'isEpisode',
      'imageUrl',
      'episodeTitle',
      'castAndCrewCategories',
      'groupedCastAndCrew',
      'originalTitle',
      'englishEpisodeTitle',
      'englishTitle',
      'englishShortTitle',
      'englishDescription',
      'englishShortDescription',
      'spanishEpisodeTitle',
      'spanishTitle',
      'spanishShortTitle',
      'spanishShortDescription',
      'mpaaRatings',
      'usaParentalRatings',
      'brazilRatings',
      'advisoryNames',
      'imageCategories',
      'imageSizes',
      'productionCompaniesString',
      'originalSoundMixes',
      'originalPictureformat',
      'url',
    ]
  }

  setLengthValues() {
    const availableTitleLen = [150, 120, 100, 70, 40, 10]
    const availableDescLen = [500, 250, 150, 120, 100, 70, 60, 40, 10]
    this.availableTitleLenEn = availableTitleLen
    this.availableDescLenEn = availableDescLen
    this.availableTitleLenEs = availableTitleLen
    this.availableDescLenEs = availableDescLen
  }

  getOriginalTitle(titles, originalAudiolang) {
    if (!titles || titles.length === 0) {
      return {
        value: '',
        lang: originalAudiolang,
        type: 'original',
        length: 120,
      }
    }

    const normalizedLang = originalAudiolang
      ? originalAudiolang.split('-')[0]
      : null
    const title = titles.find(({ type, lang }) => {
      const normalizedTitleLang = lang?.split('-')[0]
      return (
        type === 'original' &&
        (normalizedTitleLang === normalizedLang || normalizedTitleLang === 'en')
      )
    })

    return (
      title || {
        value: '',
        lang: originalAudiolang,
        type: 'original',
        length: 120,
      }
    )
  }

  // getTitle(titlesOrDesc, language, fullLength) {
  //   const titleOrDesc = (titlesOrDesc || []).find(({ type, length, lang }) => {
  //     lang = lang?.split('-')[0]
  //     if (
  //       lang === language &&
  //       (type === 'full' || type === 'original') && fullLength == length
  //     ) {
  //       return true
  //     }
  //   })
  //   return (
  //     titleOrDesc || { value: '', lang: language, type: 'full', length: 120 }
  //   )
  // }

  getTitle(titlesOrDesc, language, fullLength) {
    // VLS-5503: Apply priority for tiles
    // In order to display or update the titles,
    // it should follow this rule: full > red > original
    const priorityMap = { full: 3, red: 2, original: 1 }

    let bestMatch = null
    let bestPriority = -1;

    (titlesOrDesc || []).forEach(({ value, type, length, lang, subType }) => {
      const normalizedLang = lang?.split('-')[0]
      if (normalizedLang === language && fullLength == length) {
        const currentPriority = priorityMap[type] || 0
        if (currentPriority > bestPriority) {
          bestPriority = currentPriority
          bestMatch = { value, lang, type, subType, length }
        }
      }
    })

    return bestMatch || { value: '', lang: language, type: 'full', length: 120 }
  }


  getVenueName(venues, language){
    let venue
    if(venues){
       venue = (venues || []).find(({lang}) => {
        lang = lang?.split('-')[0]
        if (
          lang === language.split('-')[0]
        ) {
          return true
        }
      })
    }
    return (
      venue || { value: '', lang: language}
    )
  }

  getDesc(titlesOrDesc, language, fullLength) {
    const titleOrDesc = (titlesOrDesc || []).find(({ type, length, lang }) => {
      lang = lang?.split('-')[0]
      if (lang === language && fullLength == length) {
        return true
      }
    })
    return titleOrDesc || { value: '', lang: language, type: 'plot', length: 500 }
  }

  getShortTitle(titlesOrDesc, language, fullLength) {
    return (
      (titlesOrDesc || []).find(({ type, length, lang }) => {
        lang = lang?.split('-')[0]
        if (
          lang === language &&
          (type === 'red' && (length <= fullLength && type != 'original'))
        ) {
          return true
        }
      }) || { value: '', lang: language, type: 'red', length: 70 }
    )
  }

  getShortDesc(titlesOrDesc, language, fullLength) {
    return (
      (titlesOrDesc || []).find(({ type, length, lang }) => {
        lang = lang?.split('-')[0]
        if (
          lang === language &&
          (length < fullLength && type != 'original')
        ) {
          return true
        }
      }) || { value: '', lang: language, type: 'plot', length: 250 }
    )
  }

  getTitleDescMaxLength(titlesOrDesc, language) {
    var lengthList = []
      ; (titlesOrDesc || []).forEach(({ length, lang }) => {
        lang = lang?.split('-')[0]
        if (lang === language) {
          lengthList.push(length)
        }
      })
    return Math.max(Math.max(...lengthList))
  }

  getLanguage(providerInfo) {
    const info = (providerInfo || []).find(({ lang }) => lang !== '')

    return info?.lang ? info.lang.split('-')[0] : 'es'
  }

  searchCelebrities(searchData) {
    const page = 0
    const size = 8
    if (searchData === '') {
      return of([])
    }

    const searchUrl = `${this.celebrityUrl}/search?name=${searchData}&page=${page}&size=${size}`
    return this.http.get<VLSResponse>(searchUrl).pipe(
      map((data) => {
        return data.response
      })
    )
  }

  castOrCrewDefaults() {
    return {
      index: null,
      type: 'cast',
      ord: '',
      role: 'Actor',
      characterName: '',
      castName: '',
      firstName: '',
      lastName: '',
      name: null
    }
  }

  getCategories() {
    return categories
  }

  getCountries() {
    return countries
  }

  getReleaseType() {
    return ['Limited', 'Original', 'Premiere', 'Wide', 'Subsequent', 'Others']
  }

  getReleaseMedium() {
    return ['Cinema', 'DVD', 'Screening', 'Theatre', 'TV', 'VHS', 'Others']
  }

  getRoleCast() {
    return [
      'Others',
      'Actor',
      'Actress',
    ]
  }

  getRoleCrew() {
    return [
      'Others',
      'Director',
      'Producer',
      'Executive Producer',
      'Casting Director',
      'Production Designer',
      'Art Director',
      'Set Dresser',
      'Director of Photography',
      'Camera Operator',
    ]
  }

  getImageCategories() {
    return [
      'Iconic',
      'Poster Art',
      'VOD Art',
      'Box Art',
      'Key Art',
      'Banner-L1',
    ]
  }

  getProgramImages(programId: string) {
    return this.http.get<VLSResponse>(`${this.programsUrl}/images/${programId}`)
  }

  getDefaultImage(images, programType: string, provider: string = 'vls') {
    let defaultImage = null
    let selectedImages = null;
    if (provider === 'gracenote') {
      selectedImages = images?.filter(
        image => image.ratio === '2:3')

      if (selectedImages?.length) {
        const getImageByCategory = category => selectedImages.find(image => image.category === category);
        const vodArt = getImageByCategory('VOD Art');
        const posterArt = getImageByCategory('Poster Art');
        const keyArt = getImageByCategory('Key Art');
        const bannerL1 = getImageByCategory('Banner-L1');
        const bannerL2 = getImageByCategory('Banner-L2');
        const iconic = getImageByCategory('Iconic');

        // movie = VOD Art, Poster Art, Key Art, Banner-L1
        if (programType === 'MOVIE') {
          if (vodArt) {
            defaultImage = vodArt
          } else if (posterArt) {
            defaultImage = posterArt
          } else if (keyArt) {
            defaultImage = keyArt
          } else if (bannerL1) {
            defaultImage = bannerL1
          }
        } else if (programType === 'EPISODE') {
          if (iconic) {
            defaultImage = iconic
          } else if (bannerL2) {
            defaultImage = bannerL2
          }
        }
        else {
          // show = Banner-L1, Banner-L2, Iconic
          if (bannerL1) {
            defaultImage = bannerL1
          } else if (bannerL2) {
            defaultImage = bannerL2
          } else if (iconic) {
            defaultImage = iconic
          }
        }
      }
    }
    else {
      selectedImages = images?.filter(
        image => image.downloadState === 'DOWNLOADED' && image.published && image.ratio === '2:3')

      if (selectedImages?.length) {
        defaultImage = selectedImages[0]
      }
    }

    return defaultImage
  }

  getFirstTitleDescValue(program, field, truncate = true) {
    let value = `No ${field}`
    if (program && program[field]) {
      // VLS-2064: Should display local language title when available
      const isDTH = this.platformService.verifyPlatform(AppConstants.DTH)
      const lang = isDTH ? this.CONSTANTS.PT_BR : (location.href.includes('sky') ? 'pt' : 'es')

      value = this.getTitleByLanguage(program, field, lang, isDTH)

      if (field === 'titles' && program.parentTitles?.length) {
        const parentTitle = this.getFirstTitleDescValue(program, 'parentTitles')
        if (parentTitle) {
          value = `${value} | ${parentTitle}`
        }
      }
      if (value && value.length >= 50 && truncate) {
        value = `${value.substring(0, 50)}...`
      }
    }
    return value
  }

  getTitleByLanguage(program, field, language, isDTH) {
    const titles = program[field].filter(title => title.type !== 'original')
    const langTitle = isDTH ? titles.find(({ lang }) => lang === this.CONSTANTS.PT_BR) 
                            : titles.find(({ lang }) => lang?.split('-')[0] === language)

    return langTitle ? langTitle.value : program[field] && program[field][0]?.value
  }

  getRatio() {
    return [
      '16:9',
      '4:3',
      '3:4',
      '2:3',
      '2:1',
      '1:1',
    ]
  }

  getStagesByMatchId(matchId){
    return this.http.get<VLSResponse>(
      `${this.stageUrl}/matches?matchId=${matchId}`
    )
  }

  tcsConfigurations(){
    return  [
      {
        action: AppConstants.USE_EPISODE_TITLE,
        field: "useEpisodeTitle",
        useFor: "SE"
      },
      {
        action: AppConstants.USE_SERIES_INFO,
        field: "publishParent",
        useFor: "EP"
      }
    ]
  }

  isSSLA() {
    return location.href.includes('ssla')
  }
}
