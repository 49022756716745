import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class ChannelManagementService {
  channelUrl: string
  providerUrl: string
  serviceChannelUrl: string
  regionUrl: string
  labelUrl: string
  scheduleUrl: string
  channelImages: any

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.regionUrl = `${apiUrl}/regions`
    this.channelUrl = `${apiUrl}/channels`
    this.providerUrl = `${apiUrl}/providers/platform`
    this.labelUrl = `${apiUrl}/labels`
    this.serviceChannelUrl = `${apiUrl}/channels/servicechannel`
    this.scheduleUrl = `${apiUrl}/schedules`
    this.channelImages = {}
  }

  getServiceChannels(params: PaginationParams, body): Observable<any> {
    const { page, size } = params
    const getUrl = `${this.serviceChannelUrl}/search?page=${page}&size=${size}`
    return this.http.post<any[]>(getUrl, body)
  }

  upsertServiceChannel(channel: SourceChannel, isCreate: boolean) {
    return isCreate
      ? this.http.post<any>(this.serviceChannelUrl, channel)
      : this.http.put<any>(`${this.serviceChannelUrl}?userId=`, channel)
  }

  /**
   * Function to call backend API base on trigger type(field)
   *
   * @param field Trigger type: status/adult/auto/bubble
   * @param value true/false
   * @param channelId current ChannelId
   * @returns ResponseDTO
   */
  trigger(field: string, value: boolean, channelId: string) {
    const userId = 'user1' //TODO update real value.
    if (field === 'status') {
      return this.triggerStatus(channelId, value, userId)
    } else if (field === 'adult') {
      return this.triggerAdult(channelId, value, userId)
    } else if (field === 'auto') {
      return this.triggerAuto(channelId, value, userId)
    } else if (field === 'bubble') {
      return this.triggerBubble(channelId, value, userId)
    } else if (field === 'engineering') {
      return this.triggerEngineering(channelId, value, userId)
    }
    else {
      return null
    }
  }

  /**
   * Trigger update Auto Channel.
   *
   * @param id current ChannelId
   * @param auto update value "true is equivalent to AUTO processing Type else BUBBLe"
   * @param userId current logined userId
   * @returns ResponseDTO
   */
  triggerAuto(id: string, auto: boolean, userId: string = 'admin') {
    return this.http.put<any>(
      `${this.serviceChannelUrl}/${id}/auto-transmit?userId=${userId}&value=${auto}`,
      null
    )
  }

  /**
   * Trigger update Bubble Channel.
   *
   * @param id current ChannelId
   * @param bubble update value
   * @param userId current logined userId
   * @returns ResponseDTO
   */
  triggerBubble(id: string, bubble: boolean, userId: string) {
    return this.http.put<any>(
      `${this.serviceChannelUrl}/${id}/bubble?userId=${userId}&value=${bubble}`,
      null
    )
  }

  /**
   * Trigger update Adult Channel.
   *
   * @param id current ChannelId
   * @param adult update value
   * @param userId current logined userId
   * @returns ResponseDTO
   */
  triggerAdult(id: string, adult: boolean, userId: string) {
    return this.http.put<any>(
      `${this.serviceChannelUrl}/${id}/adult?userId=${userId}&value=${adult}`,
      null
    )
  }

  /**
   * Trigger update Status Channel.
   *
   * @param id current ChannelId
   * @param status update value
   * @param userId current logined userId
   * @returns ResponseDTO
   */
  triggerStatus(id: string, status: boolean, userId: string) {
    return this.http.put<any>(
      `${this.serviceChannelUrl}/${id}/status?userId=${userId}&value=${status}`,
      null
    )
  }

    /**
   * Trigger update Engineering of dthInfo
   *
   * @param id current ChannelId
   * @param isEngineering update value
   * @param userId current logged userId
   * @returns ResponseDTO
   */
    triggerEngineering(id: string, isEngineering: boolean, userId: string) {
      return this.http.put<any>(
        `${this.serviceChannelUrl}/${id}/engineering?userId=${userId}&value=${isEngineering}`,
        null
      )
    }

  getRegions() {
    return this.http.get<any>(this.regionUrl)
  }

  getCategories() {
    return this.http.get<any>(this.labelUrl)
  }

  /**
   * Call mapping master-slave channel API
   *
   * @param channelId selected channel (slave)
   * @param masterSourceId mapping channel (master)
   * @returns ResponseDTO
   */
  mappingMasterSource(channelId: string, masterSourceId: string) {
    const userId = 'user1'
    const url = `${this.channelUrl}/mapping/masterSource/${channelId}?userId=${userId}&masterSourceId=${masterSourceId}`
    return this.http.post<any>(url, null)
  }

  /**
   * Get day(s) guide channel's schedules
   * Default: 14-days
   *
   * @param channelId current channelId
   * @returns ResponseDTO
   */
  requestChannelGuide(channelId: string, startDate, endDate) {
    return this.http.get<any>(
      `${this.channelUrl}/${channelId}/guide?startDate=${startDate}&endDate=${endDate}`
    )
  }

  /**
   * Save provider mapping
   *
   * @param channel Request Channel DTO
   * @returns ResponseDTO
   */
  providerMapping(channel: any) {
    return this.http.post<any>(`${this.channelUrl}/mappingChannel`, channel)
  }

  getProviderChannelList(
    paginationParams: PaginationParams,
    data: SearchProvider
  ) {
    const { page, size } = paginationParams
    const providerUrl = `${this.providerUrl}?page=${page}&size=${size}`
    return this.http.post(providerUrl, data)
  }

  /**
   * find name by type
   * @param channelNames
   * @param nameType ("short or full")
   * @returns
   */
  findNameDescription(channelNames: any[], nameType: string) {
    const name = (channelNames || []).find(channel =>
      nameType === channel?.type.toLowerCase());

    return name?.value || '';
  }

  getChannelDetails(channelId: string) {
    return this.http.get<any>(`${this.channelUrl}/search/${channelId}`)
  }

  getChannelImage(channelId: string, prgSrvcId: string) {
    prgSrvcId = prgSrvcId ? prgSrvcId : "0";
    return this.http.get<any>(`${this.channelUrl}/images/search/${channelId}/${prgSrvcId}`)
  }

  requestGuide(prgSvcId: string, startDate: string, endDate: string, sourceId: string) {
    return this.http.post<any>(`${this.scheduleUrl}/mi/${prgSvcId}/${startDate}/${endDate}/${sourceId}`, {})
  }

  saveChannelImages(data) {
    const payload = new FormData()
    this.channelImages = this.flatten(data)
    for (let key in this.channelImages) {
      payload.append(key, this.channelImages[key])
    }
    return this.http.post<any>(`${this.channelUrl}/images`, payload)
  }

  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        var newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        var value = currentNode[key]
        if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  channelImageDeffault() {
    return {
      index: null,
      id: '',
      uri: '',
      ratio: '4:3',
      width: 0,
      height: 0,
      category: 'Logo',
      imageFile: null,
      imageURL: '',
      published: false,
    }
  }

  dthInfoDefault() {
    return {
      viewerNumber: null,
      masterSource: null,
      channelResolution: null,
      isSlave: null,
      isEngineering: false
    }
  }

  getAllChannelImages(prgSrvcId: string, channelId: string) {
    // Set prgSrvcId to "0" if it is undefined
    prgSrvcId = prgSrvcId ? prgSrvcId : "0";
    return this.http.get<any>(`${this.channelUrl}/images/search/${channelId}?prgSrvcId=${prgSrvcId}`)
  }

  getAllMasterChannel() {
    return this.http.get<any>(`${this.serviceChannelUrl}/masterChannel`)
  }

  getAllRegularChannel(currentChannelId: string) {
    return this.http.get<any>(`${this.serviceChannelUrl}/${currentChannelId}/regularChannel`)
  }

  updateSlaveChannels(masterChannelId: string, unLinkedSlaveIds: string[], newLinkedSlaveIds: string[]) {
    const body = {
      unLinkedSlaveIds: unLinkedSlaveIds,
      newLinkedSlaveIds: newLinkedSlaveIds
    }
    return this.http.put<any>(`${this.serviceChannelUrl}/${masterChannelId}/masterSlavesChannel`, body);
  }

  unLinkMaster(slaveId: string, masterId: string) {
    const body = {
      masterId: masterId
    }
    return this.http.put<any>(`${this.serviceChannelUrl}/${slaveId}/blankChannel`, body);
  }

  getMasterChannelOfSlave(masterSource: String) {
    return this.http.get<any>(`${this.channelUrl}/master/${masterSource}`)
  }

  getSlaveChannelsByMasterSource(sourceId: string) {
    return this.http.get<any>(`${this.serviceChannelUrl}/${sourceId}/slaves`)
  }
}
