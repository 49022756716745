import { Component, OnInit } from '@angular/core'
import { ProgramsService } from './programs.service'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../users/users.service'
import { AppService } from '../../app.service'
import { ActivatedRoute } from '@angular/router'
import { ConfirmationModalService } from "../../components/confirmation-modal/confirmation-modal.service"
import { AppConstants } from '../../app.constants'
import { getValidQualifiers } from '../../utils/contants'
import { TeamService } from '../world-cup-team/team.service'
import { isEmpty } from 'lodash'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  versionData: VersionData
  selectedVersion: string
  program: Program
  selectedType: string
  selectedProvider: string
  genres: Genre[]
  ratings: Rating[]
  mpaaList: Rating[]
  usaParental: Rating[]
  brazilRatings: Rating[]
  ratingsAdvisories: any[]
  seasonsAndEpisodes: any[]
  masterProgramId: string
  latestVersion: number
  market: string
  oldProgram: string
  providerGracenote: string
  newProgramId: string
  publishVersion: number
  homeTeam : any
  awayTeam: any
  seasonTeams: any []
  isSky : boolean
  stages = []

  readonly CONSTANTS = AppConstants

  constructor(
    private programService: ProgramsService,
    private modalService: NgbModal,
    private userService: UserService,
    private appService: AppService,
    private route: ActivatedRoute,
    private confirmModalService: ConfirmationModalService,
    private teamService: TeamService
  ) { }

  ngOnInit() {
    this.oldProgram = ''
    this.selectedProvider = 'masterentity'
    this.providerGracenote = 'gracenote'
    this.isSky = location.href.includes('sky')
    this.resetVersionData()
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.onProgramIdChange(params.id)
      }
    })
    this.getMarket();
    this.getGenres()
    this.getRatings()
    this.getRatingsAdvisories()
  }

  resetVersionData() {
    this.versionData = {
      pageName: 'programs',
      headerTitle: 'Versions',
      subHeader: '',
      metadataVersions: [],
    }
  }

  onProgramIdChange(programId) {
    this.masterProgramId = programId;
    this.resetVersionData()
    this.selectedProvider = 'masterentity'
    this.getProgramVersions(programId);
  }

  onNewProgramCreated(newProgramCreated) {
    this.newProgramId = newProgramCreated
  }

  onCopyToMasterChange(value) {
    if (value) {
      const masterEntity = this.versionData.metadataVersions.find(
        ({ id }) => id === 'masterentity'
      )
      if (masterEntity) {
        const latestVersion = masterEntity.versions[0]
        if (latestVersion && this.selectedVersion !== '0') {
          this.selectedVersion = 'newVersion'
          masterEntity.versions.unshift({
            id: this.selectedVersion,
            version: parseInt(latestVersion.version) + 1,
            provider: 'VLS',
            status: 'New',
          })
        }
      }
    }
  }

  getSeasonsAndEpisodes(programId, season: number = 1) {
    if (programId) {
      this.programService.getSeasonsAndEpisodes(programId, season).subscribe((data: any) => {
        this.seasonsAndEpisodes = data.response;
      });
    }

  }

  getProgramVersions(programId) {
    this.programService.getProgramVersions(programId).subscribe((data) => {
      if (data.response) {
        if(data.response.masterEntity)
        {
          const programVersions = data.response.masterEntity
          const sorted = programVersions.sort((a, b) => b.version - a.version)
          if (sorted.length) {
            this.selectedVersion = sorted[0].id
            this.getProgramByVersion(sorted[0].id, this.selectedProvider)
          }
        }
        this.setMetadataVersions(data.response)
      } else {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
          size: 'sm',
          centered: true,
          backdrop: 'static',
        })
        modalRef.componentInstance.title = 'Warning'
        modalRef.componentInstance.message = 'No version available.'
        modalRef.componentInstance.isAlert = true
      }
    })
  }

  setMetadataVersions(metadata) {
    const providers = ['masterEntity', 'gracenote', 'blim']
    if(metadata.contentLock){
      this.versionData.contentLock = metadata.contentLock
    }
    providers.forEach((provider) => {
      if (metadata[provider] && metadata[provider].length) {
        this.versionData.metadataVersions.push({
          id: provider.toLowerCase(),
          name: `${this.formatProviderName(provider)} Metadata`,
          versions: metadata[provider],
        })
      }
    })
    this.versionData.metadataVersionIDs = this.versionData.metadataVersions
      .map((m) => m.id)
      .join()

    // VLS-2197: Publish version program if version number greater than current version number
    // Add publishVersion for check when update program
    this.publishVersion = this.versionData.metadataVersions[0].versions.find(version => version.published).version
  }

  formatProviderName(provider) {
    return { gracenote: 'Gracenote', masterEntity: 'Master', blim: 'Blim' }[
      provider
    ]
  }

  createNewVersion(programType) {
    const englishTitle = this.program?.englishTitle
    const englishShortTitle = this.program?.englishShortTitle
    const spanishTitle = this.program?.spanishTitle
    const spanishShortTitle = this.program?.spanishShortTitle
    const portugueseTitle = this.program?.portugueseTitle
    const portugueseShortTitle = this.program?.portugueseShortTitle
    const englishDescription = this.program?.englishDescription
    const englishShortDescription = this.program?.englishShortDescription
    const spanishDescription = this.program?.spanishDescription
    const panishShortDescription = this.program?.spanishShortDescription
    const portugueseDescription = this.program?.portugueseDescription
    const portugueseShortDescription = this.program?.portugueseShortDescription
    const originalTitle = this.program?.originalTitle
    const originalAudiolang = this.program?.originalAudiolang
    const origAirDate = this.program?.origAirDate
    const parentTitle = this.program?.titles

    this.program = this.programService.programDefaults() as any
    this.program.source = 'VLS'
    this.program.programType = programType.name.toUpperCase()
    this.program.type = programType.code
    this.program.market = this.market
    this.program.imageSizes = this.programService.getRatio()
    this.program.programImages = {}

    if (this.program.type === 'SE' || this.program.type === 'EP') {
      this.program.isSports = this.program.type === 'SE'

      this.program.englishTitle = englishTitle
      this.program.englishShortTitle = englishShortTitle
      this.program.spanishTitle = spanishTitle
      this.program.spanishShortTitle = spanishShortTitle
      this.program.portugueseTitle = portugueseTitle
      this.program.portugueseShortTitle = portugueseShortTitle
      this.program.englishDescription = englishDescription
      this.program.englishShortDescription = englishShortDescription
      this.program.spanishDescription = spanishDescription
      this.program.spanishShortDescription = panishShortDescription
      this.program.portugueseDescription = portugueseDescription
      this.program.portugueseShortDescription = portugueseShortDescription
      this.program.originalTitle = originalTitle
      this.program.originalAudiolang = originalAudiolang
      this.program.origAirDate = origAirDate
      this.program.episodeTitleAvailable = true
      this.program.parentTitle = parentTitle
      //VLS-2907: fixing unable to create new program
      this.program.sportsInfo = this.getSportsInfo().sportsInfo as SportsInfo
      this.program.homeTeam = {
        isHome: true,
        team: '',
        value: ''
      },
      this.program.awayTeam = {
        isHome: false,
        team: '',
        value: ''
      }
    }

    /* open fields by default when creating new program */
    this.program.isEnTitleHide = true
    this.program.isPtEsTitleHide = true
    this.program.isEnDescHide = true
    this.program.isPtEsDescHide = true

    /*Set 2nd language */
    this.program.language = this.program.market === 'sky' ? 'pt' : 'es';

    // assign seriesId if creating new episode
    if (this.program.type === 'EP' || this.program.type === 'SE') {
      this.program.seriesId = this.masterProgramId;
    }

    this.program.castAndCrewCategories = ['Cast', 'Crew']
    this.versionData.contentLock = false
    this.versionData.metadataVersions = []
    this.versionData.metadataVersions.push({
      id: 'masterentity',
      name: `Master Metadata`,
      versions: [
        {
          id: null,
          version: 1,
          provider: 'VLS',
          status: 'New',
        },
      ],
    })

    this.versionData.metadataVersionIDs = this.versionData.metadataVersions
      .map((m) => m.id)
      .join()
  }

  onVersionIdChange({ id, provider, programType }) {
    const proceedVersionChanged = () => {
      this.selectedVersion = id
      this.selectedProvider = provider
      if (id !== '0') {
        this.getProgramByVersion(id, provider)
      } else {
        this.createNewVersion(programType)
      }
    }
    const masterEntityVersions = this.getMasterEntityVersions()
    if (masterEntityVersions[0] && masterEntityVersions[0].id === 'newVersion') {
      const modal = this.confirmModalService.createModal({
        title: 'Confirm',
        message: `Changing version will discard unsaved version created. Do you wish to continue?`,
        isAlert: false,
        isConfirm: true
      })
      modal.result.then((res) => {
        if (res) {
          const newMasterEntityVersions = masterEntityVersions
            .filter(version => version.id !== 'newVersion')
          this.versionData.metadataVersions = this.versionData.metadataVersions.map(metadataVersion => {
            return {
              ...metadataVersion,
              versions: metadataVersion.id === 'masterentity'
                ? newMasterEntityVersions
                : metadataVersion.versions
            }
          })
          proceedVersionChanged()
        }
      })
    } else {
      proceedVersionChanged()
    }
  }

  getMasterEntityVersions() {
    const masterEntity = this.versionData.metadataVersions.find(
      ({ id }) => id === 'masterentity'
    )
    return masterEntity && masterEntity.versions || []
  }

  getMarket() {
    this.programService.getMarket().subscribe(data => {
      this.market = data.response;
    });
  }

  getGenres() {
    this.programService.getAllGenres().subscribe(data => {
      const genreList = []
      data.response.forEach((genre) => {
        const enGenre = (genre.names || []).find(
          (g) => g.language === 'en'
        )
        if (enGenre) {
          genreList.push({
            id: genre.id,
            name: enGenre.value,
          })
        }
      })
      this.genres = genreList
    })
  }

  getRatings() {
    this.programService.getAllRatings().subscribe((data: any) => {
      this.ratings = data.response
      this.mpaaList = this.programService.filterRatings(this.ratings, 'MPAA')
      this.usaParental = this.programService.filterRatings(this.ratings, 'TVPG')
      this.brazilRatings = this.programService.filterRatings(this.ratings, 'DJCTQ')
    })
  }

  getRatingsAdvisories() {
    this.ratingsAdvisories = []

    this.programService.getAdvisories().subscribe((data: any) => {
      data.response.forEach((ratingAdvisory) => {
        const advisoryName = ratingAdvisory.name && ratingAdvisory.name[0] && ratingAdvisory.name[0].value
        this.ratingsAdvisories.push({ id: ratingAdvisory.id, name: advisoryName })
      })
    })
  }

  buildSeasonTeams(teams){
    const teamList = []
    teams.forEach((team) => {
      if(!this.isSky){
        const esTeam = (team.names || []).find(
          (g) => g.lang === this.CONSTANTS.ES || g.lang === this.CONSTANTS.EN
        )
        if (esTeam && esTeam.value != '') {
          teamList.push({
            id: team.id,
            team: esTeam.value,
            isHome: true
          })
        }
      }
      if(this.isSky){
        const ptTeam = (team.names || []).find(
          (g) => g.lang === this.CONSTANTS.PT || g.lang === this.CONSTANTS.PT_BR || g.lang === this.CONSTANTS.EN
        )
        if (ptTeam && ptTeam.value != '') {
          teamList.push({
            id: team.id,
            team: ptTeam.value,
            isHome: true
          })
        }
      }
    })
    this.seasonTeams = teamList
  }

  buildStages(stages){
    this.stages = []
    stages.forEach(obj =>{
      if(obj.hasOwnProperty('stageName')){
        this.stages.push({
          playoffRoundId: obj.id,
          playoffRound : obj.stageName,
          groupName: obj.groupName
        })
      }
    })
  }

  getProgramByVersion(versionId, provider = null) {
    this.programService
      .getProgramByVersion(versionId, provider)
      .subscribe(async (data) => {
        const response = data.response
        if (response) {
          if (response.isHighLight) {
            response.highLightExternalRefs = Object.assign([], response?.externalRefs)
            if (response?.parentProgramId) {
              const dataVersions = await this.programService.getProgramVersions(response?.parentProgramId).toPromise()
              const parentPublishedVersion = dataVersions.response?.masterEntity?.filter((version) => version?.published === true)[0]
              if (parentPublishedVersion?.id) {
                const dataVersion = await this.programService.getProgramByVersion(parentPublishedVersion?.id, provider).toPromise()
                response.sportsInfo = dataVersion?.response?.sportsInfo
                dataVersion?.response?.externalRefs?.forEach((item) => {
                  if(!response.externalRefs?.find(other => item.system == other.system && item.refName == other.refName && item.id == other.id)) {
                    response.externalRefs.push(item)
                  }
                })
                response.isWorldCup = dataVersion?.response?.isWorldCup
              }
            }
          }
          this.program = response
          this.program.parentProgramId = response?.parentProgramId || ''
          const ratingFilter = getValidQualifiers();
          /* Ratings display */
          const ratingsID = response.ratings?.map(({ id }) => id)
          let selectedRatings = this.ratings?.filter(
            (rating) =>
              (ratingsID || []).includes(rating.id) &&
              ratingFilter.includes(rating.code)
          )

          if (isEmpty(selectedRatings)) {
            selectedRatings = this.ratings?.filter(
              (rating) =>
                (ratingsID || []).includes(rating.id) &&
            ['MPAA'].includes(rating.code)
            )
          }

          const ratingString = selectedRatings
            .map((rating) => `${rating.code}: ${rating.rating}`)
            .join(', ')
          const mpaaRatings = selectedRatings.find(
            ({ code }) => code === 'MPAA'
          )
          const tvpgRatings = selectedRatings.find(
            ({ code }) => code === 'TVPG'
          )
          const brazilRatings = selectedRatings.find(
            ({ code }) => code === 'DJCTQ'
          )

          /* Rating content descriptors of DJCTQ */
          let ratingContentDescriptor = null
          const brazilRating =
            response.ratings && brazilRatings
              ? response.ratings.find(({ id }) => id === brazilRatings.id)
              : null
          if (brazilRating) {
            ratingContentDescriptor = brazilRating.warning
          }

          /* Ratings Advisories */
          const advisoryNames = this.ratingsAdvisories.filter((genre) =>
            (response.advisories || []).includes(genre.id)
          )

          let titles;
          let eTitle;
          if (response.episodeTitleAvailable) {
            titles = response.parentTitle
            eTitle = response.titles
          } else {
            titles = response.titles
          }

          if (provider === "gracenote") {
            titles = response.titles
          }

          /* Use to determine short and long title or descrition */
          let titleLength = this.programService.getTitleDescMaxLength(titles, 'en');
          let descLength = this.programService.getTitleDescMaxLength(response.descriptions, 'en');
          let eTitleLength = this.programService.getTitleDescMaxLength(eTitle, 'en');

          /* English titles and descriptions */
          const originalTitle = this.programService.getOriginalTitle(
            titles,
            response.originalAudiolang
          )

          const englishEpisodeTitle = this.programService.getTitle(
            eTitle,
            'en',
            eTitleLength
          )

          const englishTitle = this.programService.getTitle(
            titles,
            'en',
            titleLength
          )
          const englishShortTitle = this.programService.getShortTitle(
            titles,
            'en',
            titleLength
          )
          const englishDescription = this.programService.getDesc(
            response.descriptions,
            'en',
            descLength
          )
          const englishShortDescription = this.programService.getShortDesc(
            response.descriptions,
            'en',
            descLength
          )

          /* Use to determine short and long title or descrition */
          titleLength = this.programService.getTitleDescMaxLength(titles, 'es');
          descLength = this.programService.getTitleDescMaxLength(response.descriptions, 'es');
          eTitleLength = this.programService.getTitleDescMaxLength(eTitle, 'es');

          /* Spanish titles and descriptions */
          const spanishEpisodeTitle = this.programService.getTitle(
            eTitle,
            'es',
            eTitleLength
          )

          const spanishTitle = this.programService.getTitle(
            titles,
            'es',
            titleLength
          )
          const spanishShortTitle = this.programService.getShortTitle(
            titles,
            'es',
            titleLength
          )
          const spanishDescription = this.programService.getDesc(
            response.descriptions,
            'es',
            descLength
          )
          const spanishShortDescription = this.programService.getShortDesc(
            response.descriptions,
            'es',
            descLength
          )

          /* Use to determine short and long title or descrition */
          titleLength = this.programService.getTitleDescMaxLength(titles, 'pt');
          descLength = this.programService.getTitleDescMaxLength(response.descriptions, 'pt');
          eTitleLength = this.programService.getTitleDescMaxLength(eTitle, 'pt');

          /* Portuguese titles and descriptions */
          const portugueseEpisodeTitle = this.programService.getTitle(
            eTitle,
            'pt',
            eTitleLength
          )

          const portugueseTitle = this.programService.getTitle(
            titles,
            'pt',
            titleLength
          )
          const portugueseShortTitle = this.programService.getShortTitle(
            titles,
            'pt',
            titleLength
          )
          const portugueseDescription = this.programService.getDesc(
            response.descriptions,
            'pt',
            descLength
          )
          const portugueseShortDescription = this.programService.getShortDesc(
            response.descriptions,
            'pt',
            descLength
          )

          /** Set Genres */
          let genreIds = response.genres || [];
          if (provider === "gracenote" && response.genres) {
              genreIds = response.genres.forEach(element => {
                genreIds.push(element?._id);
              });
          }

          let programType = this.program.programId.substring(0, 2);

          // VLS-2275: if program is sport episode then type = SE else type = EP
          if(this.program?.isSports?.toString() === 'true') {
            programType = this.CONSTANTS.SPORT_EPISODE
          }

          /** Set sportsInfo */
          const sportsInfo = this.getSportsInfo();

          const genreNames = this.genres.filter((genre) =>
            (response.genres || []).includes(genre.id)
          )

          const extraInfo: any = this.formatExtraInfo(response.extraInfos)

          /* Get Gracenote external Refs */
          const tmsId = this.getExternalRefs(this.program.externalRefs, 'tmsId')
          const rootId = this.getExternalRefs(this.program.externalRefs, 'rootId')
          const seriesId = this.getExternalRefs(this.program.externalRefs, 'seriesId')
          const connectorId = this.getExternalRefs(this.program.externalRefs, 'connectorId')

          const graceNoteExRef = {
            "graceNoteExRef": {
              tmsId,
              rootId,
              seriesId,
              connectorId
            }
          }

          let originalAudiolang = response.originalAudiolang || 'en';
          originalAudiolang = originalAudiolang.split("-")[0];

          /*Set 2nd language */
          const language = this.program.market === 'sky' ? 'pt' : 'es';

          /*VLS-2215 check If other language is available */
          let otherLanguage = this.getOtherLanguage(response.titles, language);
          otherLanguage = otherLanguage? otherLanguage.lang.split("-")[0] : undefined;

          /* VLS-2518 bugfix - Program management UI not display other title*/
          /* Use to determine short and long for other title or other description */
          titleLength = this.programService.getTitleDescMaxLength(titles, otherLanguage);
          descLength = this.programService.getTitleDescMaxLength(response.descriptions, otherLanguage);

          /* Get title and short title for other language */
          const otherTitle = this.programService.getTitle(
            titles,
            otherLanguage,
            titleLength
          )
          const otherShortTitle = this.programService.getShortTitle(
            titles,
            otherLanguage,
            titleLength
          )

          /* Get description and short description for other language*/
          const otherDescription = this.programService.getDesc(
            response.descriptions,
            otherLanguage,
            descLength
          )
          const otherShortDescription = this.programService.getShortDesc(
            response.descriptions,
            otherLanguage,
            descLength
          )

          /*VLS-2215 check title or desc per language if to display*/
          let otherEpisodeTitle: any;
          let isPtEsTitleHide= false;
          let isPtEsDescHide= false;
          const isEnTitleHide = this.checkTitleField(englishTitle, englishShortTitle, englishEpisodeTitle);
          const isEnDescHide= this.checkDescriptionField(englishDescription, englishShortDescription);

          if(language == "pt") {
            isPtEsTitleHide = this.checkTitleField(portugueseTitle, portugueseShortTitle, portugueseEpisodeTitle);
            isPtEsDescHide= this.checkDescriptionField(portugueseDescription, portugueseShortDescription);
            otherEpisodeTitle = this.getOtherEpisodeTitle(otherLanguage, portugueseEpisodeTitle, eTitle);
          } else {
            isPtEsTitleHide = this.checkTitleField(spanishTitle, spanishShortTitle, spanishEpisodeTitle);
            isPtEsDescHide= this.checkDescriptionField(spanishDescription, spanishShortDescription);
            otherEpisodeTitle = this.getOtherEpisodeTitle(otherLanguage, spanishEpisodeTitle, eTitle);
          }

          const isOtherTitleHide= this.checkTitleField(otherTitle, otherShortTitle, otherEpisodeTitle);
          const isOtherDescHide= this.checkDescriptionField(otherDescription, otherShortDescription);

          let venueNames;
          if(response.sportsInfo?.venue){
            venueNames = response.sportsInfo?.venue.names || []
          }

          this.homeTeam = {
            id: '',
            team: '',
            isHome : true
          }

          this.awayTeam = {
            id: '',
            team: '',
            isHome: false
          }

          if(response.isWorldCup && response.sportsInfo?.match?.teams){
            this.getTeamInfo(response.sportsInfo?.match.teams)
          }
          if(response.sportsInfo?.teams && !response.isWorldCup){
            this.getTeamInfo(response.sportsInfo?.teams)
          }

          //VLS-2759 set default for toggle
          const isEnVenueHide = true
          const isEsVenueHide = true
          const isPtVenueHide = true

          let matchId = ''

          if(this.program.sportsInfo)
          {
            matchId = this.program.sportsInfo?.matchId
          }

          this.teamService.getTeamsByMatchId(matchId).subscribe((data) =>{
            if(data.response){
              this.buildSeasonTeams(data.response)
            }else{
              this.seasonTeams = []
            }
          })

          if(matchId != '' && matchId){
            this.programService.getStagesByMatchId(matchId).subscribe((data) => {
              if (data.response && data.response.length > 0) {
                this.buildStages(data.response)
              } else {
                this.stages = []
              }
            })
          }

          let venueValue
          if(!sportsInfo.sportsInfo?.venue?.value){
            if(!this.isSky && sportsInfo.sportsInfo?.venue?.names){
              venueValue = sportsInfo.sportsInfo.venue.names.find(name =>
                name.lang === this.CONSTANTS.ES || name.lang === this.CONSTANTS.EN
              )
            }

            if(this.isSky && sportsInfo.sportsInfo?.venue?.names){
              venueValue = sportsInfo.sportsInfo.venue.names.find(name =>
                name.lang === this.CONSTANTS.PT_BR || name.lang === this.CONSTANTS.PT || name.lang === this.CONSTANTS.EN
              )
            }
          }

          if(venueValue){
            sportsInfo.sportsInfo.venue.value = venueValue.value
          }

          console.log("The response: ", response)
          this.program = {
            ...response,
            ...this.getCastAndCrewInfo(response.credits),
            language,
            originalTitle,
            englishTitle,
            englishShortTitle,
            englishEpisodeTitle,
            englishDescription,
            englishShortDescription,
            descriptions: response.descriptions || [],
            spanishTitle,
            spanishShortTitle,
            spanishEpisodeTitle,
            spanishDescription,
            spanishShortDescription,
            portugueseTitle,
            portugueseShortTitle,
            portugueseEpisodeTitle,
            portugueseDescription,
            portugueseShortDescription,
            otherEpisodeTitle,
            otherTitle,
            otherShortTitle,
            otherDescription,
            otherShortDescription,
            ratingContentDescriptor,
            ratingString,
            type: programType,
            genres: genreNames.map((g) => g.name),
            genreIds,
            originalAudiolang,
            mpaaRatings: (mpaaRatings && mpaaRatings.id) || '',
            usaParentalRatings: (tvpgRatings && tvpgRatings.id) || '',
            brazilRatings: (brazilRatings && brazilRatings.id) || '',
            advisoryNames: advisoryNames.map((advisory) => advisory.name),
            releases: response.releases || [],
            awards: this.parseAwards(),
            keywords: response.keywords || [],
            countriesOfOrigin: (response.countries || []).join(', '),
            productionCompaniesString: this.arrayToString(response.productionCompanies),
            soundMixes: this.arrayToString(extraInfo && extraInfo.soundMixes),
            pictureFormats: this.arrayToString(extraInfo && extraInfo.pictureFormats),
            externalLinks: this.arrayToString(extraInfo && extraInfo.externalLinks),
            url: this.arrayToString(extraInfo && extraInfo.url),
            extraInfos: response.extraInfos || [],
            provider: this.selectedProvider,
            parentTitle: response.parentTitle || [],
            vrioId: response.vrioId || null,
            gracenoteProviderInfo: this.getProviderInfo(),
            isEnTitleHide,
            isPtEsTitleHide,
            isOtherTitleHide,
            isEnDescHide,
            isPtEsDescHide,
            isOtherDescHide,
            otherLanguage,
            ...graceNoteExRef,
            ...sportsInfo,
            isEnVenueHide,
            isEsVenueHide,
            isPtVenueHide,
            homeTeam : this.homeTeam,
            awayTeam: this.awayTeam,
            dthInfo: response.dthInfo || {
              useEpisodeTitle: false
            },
            concatSeriesTitle: response.dthInfo?.concatSeriesTitle ? response.dthInfo?.concatSeriesTitle : null
          }

        }

        this.getSeasonsAndEpisodes(this.program?.programId)
        this.getProgramImages()
      })
  }

  getTeamInfo(teams = []){
    this.homeTeam = teams?.find((team) => {
      return team.isHome === true
    })

    this.awayTeam = teams?.find((team) => {
      return team.isHome === false
    })

    if(teams.length >=1 && !this.homeTeam && teams[0]?.isHome === null){
      this.homeTeam = teams[0]
    }

    if(teams.length >= 1 && teams[1]?.isHome === null && !this.awayTeam){
      this.awayTeam = teams[1]
    }

    if(!this.homeTeam){
      this.homeTeam = {team: '', isHome: true, id: ''}
    }

    if(!this.awayTeam){
      this.awayTeam = {team: '', isHome: false, id: ''}
    }
  }

  getOtherEpisodeTitle(newLang: any, episodeTitle: Title, eTitle: any){
    let otherEpisodeTitle = { value: '', lang: '', type: 'full', length: 120 };
    if(this.program.programType === this.CONSTANTS.FULL_EPISODE && newLang) {
      if(!episodeTitle?.value && this.program.episodeTitleAvailable) {
        const eTitleLength = this.programService.getTitleDescMaxLength(eTitle, newLang)
        otherEpisodeTitle = this.programService.getTitle(eTitle, newLang, eTitleLength)
      }
    }
    return otherEpisodeTitle;
  }

  getOtherLanguage(titlesOrDesc: any, defaultLang: string) {
    const envDefaultLang = environment[AppConstants.DEFAULT_LANG]?.split('-')[0]
    return (
      (titlesOrDesc || []).find(({ lang }) => {
        lang = lang?.split('-')[0]
        if (lang != defaultLang && lang != this.CONSTANTS.EN && lang != envDefaultLang) {
          return true
        }
      })
    )
  }

  /**
   * VLS-2215 check all title field if empty
   * @param lang
   * @returns boolean:isEmpty if fields is empty
   */
   checkTitleField(title: any, shortTitle: any, episodeTitle: any) {
    let isEmpty = true;
    if(title.value == "" &&
      shortTitle.value == "" &&
      episodeTitle.value == "") {
        isEmpty = false;
    }
      return isEmpty;
  }

   /**
   * VLS-2215 check all description field if empty
   * @param lang
   * @returns boolean:isEmpty if fields is empty
   */
    checkDescriptionField(description: any, shortDescription: any) {
      let isEmpty = true;
      if(description.value == "" &&
        shortDescription.value == "") {
          isEmpty = false;
      }
        return isEmpty;
    }

  parseAwards() {
    const awards = this.program.awards || []
    return awards.map(award => {
      const person = this.program.credits.find(
        credit => credit.personId === (award.recipient || award.recipientId)
      )
      return {
        ...award,
        recipient: person ? `${person.firstName} ${person.lastName}` : award.recipient
      }
    })
  }

  onLockVersionChange(contentLock) {
    this.programService.lockProgramContent(contentLock,
      this.masterProgramId).subscribe((data: any) => { });


  }

  arrayToString(array) {
    let str = ''
    if (array && Array.isArray(array)) {
      str = array.join(', ')
    } else {
      str = array;
    }
    return str
  }

  getSportsInfo() {
    return {
      sportsInfo: {
        gameDate: this.program.sportsInfo?.gameDate || null,
        gameTime: this.program.sportsInfo?.gameTime || null,
        gameTimezone: this.program.sportsInfo?.gameTimezone || null,
        playoffRound: this.program.sportsInfo?.playoffRound || null,
        playoffRoundId: this.program.sportsInfo?.playoffRoundId || null,
        gameNumber: this.program.sportsInfo?.gameNumber || null,
        sportSeason: {
          type: this.program.sportsInfo?.sportSeason?.type || null,
          value: this.program.sportsInfo?.sportSeason?.value || null
        },
        venue : this.program.sportsInfo?.venue || {id: '', names: [], value: ''},
        matchId : this.program.sportsInfo?.matchId || null,
        teams: this.program.sportsInfo?.teams || null,
        groupName: this.program.sportsInfo?.groupName || null
      }
    }
  }

  getProviderInfo() {
    const providerIds: any = {}
    const providerInfo = this.program.providerInfo as any
    (providerInfo || []).forEach((each) => {
      (providerIds[each.key] = each.value)
    })
    return {
      providerInfo: {
        ...providerIds,
        networkName:
          providerIds.networks &&
          providerIds.networks[0] &&
          providerIds.networks[0].networkName,
      },
    }
  }

  formatExtraInfo(extraInfos) {
    const extraInfo = {}
    if (extraInfos && extraInfos.length) {
      extraInfos.forEach(info => {
        extraInfo[info.key] = info.value
      })
    }
    return extraInfo
  }

  getExternalRefs(externalRefs, key) {
    var extraInfo = {};
    if (externalRefs && externalRefs.length) {
      extraInfo = externalRefs.find((info) =>
        info.refName === key
      );
    }

    if(!extraInfo){
      return {}
    }

    return extraInfo
  }

  getProgramImages() {
    let fetchedImages = []

    if (this.program.provider === this.providerGracenote) {
      const grouped = this.groupBy(this.program.images, 'ratio');
      this.program.imageSizes = Object.keys(grouped)
      this.program.programImages = grouped
      fetchedImages = this.program.images || []
      this.getDefaultImage(fetchedImages)
    } else {
      this.program.images = []
      this.program.imageSizes = this.programService.getRatio()
      this.program.programImages = {}
      this.program.publishedImages = []

      this.programService.getProgramImages(this.program.programId).subscribe(data => {
        fetchedImages = (data.response || []).filter(image => image.downloadState === 'DOWNLOADED')
        this.program.programImages = this.groupBy(fetchedImages, 'ratio')
        const publishedImages = fetchedImages.filter(programImage => programImage.published)
        this.program.imageSizes.forEach(size => {
          const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
          if (publishRatio) {
            this.program.publishedImages.push(publishRatio)
          }
        })
        this.getDefaultImage(fetchedImages)
        this.oldProgram = JSON.stringify(this.program)
      })
    }
  }

  getDefaultImage(images) {
    let imageBanner = 'assets/images/noImage.jpg'
    const defaultImage = this.programService.getDefaultImage(images,
      this.program.programType, this.program.provider)
    if (defaultImage) {
      if (this.program.provider.toLowerCase() === this.providerGracenote)
        defaultImage.baseUrl = 'http://tmsimg.com/';
      imageBanner = `${defaultImage.baseUrl}${defaultImage.uri}`
    }
    this.program.defaultImage = imageBanner
  }

  getCastAndCrewInfo(castAndCrew) {
    castAndCrew = castAndCrew?.map((val: CastOrCrew) => {
      if (val.name && val.name.length > 0) {
        let firstName = val.name.firstName ? val.name.firstName : ""
        let lastName = val.name.lastName ? val.name.lastName : ""
        val.castName = firstName + " " + lastName
      } else {
        val.castName = val.firstName + " " + val.lastName
      }
      return val
    })
    return {
      credits: castAndCrew || [],
      groupedCast: this.creditsFilterAndSort(castAndCrew, 'cast', 'ord'),
      groupedCrew: this.creditsFilterAndSort(castAndCrew, 'crew', 'ord'),
      castAndCrewCategories: ['Cast', 'Crew'],
    }
  }

  creditsFilterAndSort(list, filterType, sortField) {
    if (list && list.length > 0) {
      return list.filter(({ type }) => type.toLowerCase() === filterType.toLowerCase()).sort((a, b) => a[sortField] - b[sortField])
    }

    return []
  }

  groupBy(xs, key) {
    return (xs || [])
      .sort((a, b) => b?.resized - a?.resized)
      .reverse()
      .reduce((rv, x) => {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
  }
}
