import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { ProgramsService } from '../../programs/programs.service';
import { TeamService } from '../team.service';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss']
})
export class TeamManagementComponent implements OnInit {

  newTeamId: string
  newTeamCreated: string
  isCreate: boolean
  // genres: Genre[]
  venues: any[]
  venue: any
  teamId: string
  selectedTeam: Team
  isLoading: boolean
  oldTeam: string
  readonly CONSTANTS = AppConstants

  constructor(
    private teamService: TeamService,
    private programService: ProgramsService
  ) { }

  ngOnInit() {
    // this.getGenres()
    this.getVenues()
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  onCreateTeam() {
    this.venue = this.teamService.venueDefault()
    this.selectedTeam = this.teamService.teamDefault() as any
    this.selectedTeam.teamImages = {}
    this.selectedTeam.imageSizes = this.programService.getRatio()
    this.oldTeam = JSON.stringify(this.selectedTeam)
  }

  onNewTeamCreated(newTeamCreated) {
    this.newTeamId = newTeamCreated
  }

  onTeamIdChange(teamId) {
    this.venue = this.teamService.venueDefault()
    this.isLoading = true
    this.teamService.getTeamById(teamId).subscribe((data: any) => {
      if (data.response) {
        this.selectedTeam = data.response
        // const genreNames = this.genres.filter((genre) =>
        // (this.selectedTeam.genres || []).includes(genre.id)
        // )
        const venueName = this.venues?.filter((venue) =>
        (this.selectedTeam.venueId || []).includes(venue.id)
        )
        this.selectedTeam.venue = venueName?.map((v) => v.name)
        // this.selectedTeam.genres = genreNames.map((g) => g.name)
        this.teamService.buildTeamInfo(this.selectedTeam as any)
        this.teamService.getTeamImages(teamId).subscribe((data: any) => {
          if (data) {
            let fetchedImages = []
            this.selectedTeam.images = []
            this.selectedTeam.imageSizes = this.programService.getRatio()
            this.selectedTeam.teamImages = {}
            this.selectedTeam.publishedImages = []

            fetchedImages = (data.response || []).filter(image => image.downloadState === 'DOWNLOADED')
            this.selectedTeam.teamImages = this.groupBy(fetchedImages, 'ratio')
            const publishedImages = fetchedImages.filter(teamImage => teamImage.published)
            this.selectedTeam.imageSizes.forEach(size => {
              const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
              if (publishRatio) {
                this.selectedTeam.publishedImages.push(publishRatio)
              }
            })
            this.getDefaultImage(fetchedImages)
            this.selectedTeam.images = data.response
            this.oldTeam = JSON.stringify(this.selectedTeam)
            this.isLoading = false
          }
        })
        if(this.selectedTeam.venueId)
        {
          this.teamService.getVenueById(this.selectedTeam.venueId).subscribe((data: any) => {
            if (data.response) {
              this.venue = data.response
              this.teamService.buildVenueInfo(this.venue)
            }
          })
        }
      }
    })
  }

  getDefaultImage(images) {
    let imageBanner = this.CONSTANTS.IMAGE_BANNER
    const defaultImage = this.programService.getDefaultImage(images, null, null)
    if (defaultImage) {
      defaultImage.baseUrl = this.CONSTANTS.BASE_URL;
      imageBanner = `${defaultImage.baseUrl}${defaultImage.uri}`
    }
    this.selectedTeam.defaultImage = imageBanner
  }

  getVenues() {
    const params = {
      page: 0,
      size: 500
    }
    const searchData = {
      searchString: ''
    }
    this.teamService.getAllVenues(searchData, params).subscribe(data => {
      const venueList = []
      data.response.forEach((venue) => {
        const enVenue = (venue.names || []).find(
          (g) => g.lang === this.CONSTANTS.EN
        )
        if (enVenue) {
          venueList.push({
            id: venue.id,
            name: enVenue.value,
          })
        }
      })
      this.venues = venueList
    })
  }

  // getGenres() {
  //   this.programService.getAllGenres().subscribe(data => {
  //     const genreList = []
  //     data.response.forEach((genre) => {
  //       const enGenre = (genre.names || []).find(
  //         (g) => g.language === this.CONSTANTS.EN
  //       )
  //       if (enGenre) {
  //         genreList.push({
  //           id: genre.id,
  //           name: enGenre.value,
  //         })
  //       }
  //     })
  //     this.genres = genreList
  //   })
  // }
}
