import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  appConfigUrl: string

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.appConfigUrl = `${apiUrl}/app-configs`
  }

  verifyPlatform(platform : String){
    if(environment.hasOwnProperty(AppConstants.PLATFORM) && environment[AppConstants.PLATFORM].toLowerCase() ===  platform.toLowerCase()){
      return true 
    }
    else{
      return false
    }
  }

  getDefaultConfigs() {
    return this.http.get<any>(`${this.appConfigUrl}/default-configs?reload=true`)
  }
}
