import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {PanelComponent, PanelHeaderComponent} from "./panel.component";
import { PanelFooterComponent } from './panel-footer/panel-footer.component';

@NgModule({
    imports:[CommonModule],
    declarations:[PanelComponent, PanelFooterComponent,PanelHeaderComponent],
    exports:[PanelComponent,PanelFooterComponent,PanelHeaderComponent]
})
export class PanelModule{

}
