import {Component, ContentChild, Input} from '@angular/core';
@Component({
  selector:'app-panel-header',
  template:'<ng-content></ng-content>',
})
export class PanelHeaderComponent{

}

@Component({
  selector: 'app-panel',
  templateUrl:'./panel.component.html',
  styleUrls: [ './panel.component.scss' ]
})
export class PanelComponent {
  @Input() title:string;
  @ContentChild(PanelHeaderComponent) panelHeader?:PanelHeaderComponent;
}

