import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constants';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service';
import { ProgramsService } from '../../programs/programs.service';
import { TeamService } from '../team.service';

@Component({
  selector: 'app-team-form',
  templateUrl: './team-form.component.html',
  styleUrls: ['./team-form.component.scss']
})
export class TeamFormComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean
  @Input() team: Team
  @Input() oldTeam: string
  // @Input() genres: Genre[]
  @Input() venues: any[]
  @Input() venue: any
  @Output() newTeamCreated: EventEmitter<string> = new EventEmitter()
  @Output() newTeamId: EventEmitter<any> = new EventEmitter

  isSky: boolean
  newTeam: Team
  showLoader: boolean
  previewUrl: string
  active: number
  disabled: boolean
  image: ProgramImage
  navImage: number
  imageCategories: string[]
  selectedImageCategory = 'Iconic'
  systems: any[]
  externalRef: externalRefs
  refNames: any
  readonly CONSTANTS = AppConstants

  constructor(
    private teamService: TeamService,
    private confirmModalService: ConfirmationModalService,
    private modalService: NgbModal,
    private programService: ProgramsService
  ) { }

  ngOnInit() {
    this.isSky = location.href.includes('sky')
    this.previewUrl = this.CONSTANTS.IMAGE_BANNER
    this.active = 1
    this.team = this.teamService.teamDefault() as any
    this.venue = 
    this.image = this.programService.imageDefaults() as any
    this.navImage = 1
    this.imageCategories = this.programService.getImageCategories()
    this.externalRef = this.programService.externalRefsDefault()
    this.getSystems()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  getSystems() {
    this.systems = this.programService.getSystems('team') as any
  }
  
  onSystemChange(event) {
    this.refNames = this.systems.find((system) => {
      return system.system === event.target.value
    }).refName
    if(this.refNames){
      this.externalRef.refName = this.refNames[0]
    }
  }

  openDeleteModal(field, filter, id) {
    const modal = this.confirmModalService.createModal({
      title: 'Confirm removal',
      message: `Are you sure you want to remove this?`,
      isAlert: false,
      isConfirm: true,
    })
    modal.result.then((res) => {
      if (res) {
        this.team[field] = this.team[field].filter(
          (data, index) => index !== id
        )
      }
    })
  }

  formatHeaderTitle(english, other) {
    const englishName = this.team[english]
    const otherName = this.team[other]
    return (
      englishName|| otherName
    )
  }
  
  resetDefaults() {
    /* Reset form validation */
    const invalidInputs = document.querySelectorAll('.is-invalid')
    invalidInputs.forEach((input) => input.classList.remove('is-invalid'))

        /* Reset tab default active */
        this.active = 1
        this.navImage = 1
        this.resetImage()
  }

  setImageCategory(category: string) {
    this.selectedImageCategory = category
    this.image.category = category
  }

  addItem(fieldName, entity = null) {
    if (!this.team[fieldName]) {
      this.team[fieldName] = []
    }

    if (fieldName === 'images') {
      entity.published = false
      this.team[fieldName].push(entity)
      if (this.team.teamImages[entity.ratio]) {
        this.team.teamImages[entity.ratio].unshift(entity)
      } else {
        this.team.teamImages = {
          ...this.team.teamImages,
          [entity.ratio]: [entity]
        }
      }
      this.resetImage()
    }else{
      entity?.index != null
      ? (this.team[fieldName][entity.index] = entity)
      : this.team[fieldName].unshift(entity)
      
      this.closeModal()
    }
  }

  closeModal(){
    this.modalService.dismissAll()
    this.externalRef = this.programService.externalRefsDefault()
  }

  resetImage() {
    this.image = this.programService.imageDefaults() as any
    if (document.getElementById('inputLabel')) {
      document.getElementById('inputLabel').innerText = ''
    }
  }

  save() {
    if(this.isValid()){
      if (JSON.stringify(this.team) != this.oldTeam) {
        if (this.team.id) {
          this.updateTeam()
          this.isLoading = false
        } else {
          this.createTeam()
          this.isLoading = false
        }
      } else {
        this.showAlert('Warning', `No changes`)
      }
    }  
    else{
      document.getElementById('teamForm').scrollTo(0, 0);
    }
  }

  onFieldChange(event: any, field: string = this.CONSTANTS.EN, main: boolean = false) {
    const { id, value } = event.target
    if (value) {
      document.getElementById(id).classList.remove('is-invalid')
    }
    let lang = this.CONSTANTS.ENGLISH.toLowerCase()

    if (main) {
      lang = this.isSky ? this.CONSTANTS.PORTUGUESE.toLowerCase() : this.CONSTANTS.SPANISH.toLowerCase()
    }

    if (field !== this.CONSTANTS.EN) {
      const partnerId = lang.concat(field)
      if(document.getElementById(partnerId)) {
        document.getElementById(partnerId).classList.remove('is-invalid')
      }

      if(document.getElementById("abbreviation")){
        document.getElementById("abbreviation").classList.remove('is-invalid')
      }

      if(document.getElementById("otherName")) {
        document.getElementById("otherName").classList.remove('is-invalid')
      }

    }
    }

  isValid() {
    const inValid = []
    const fieldsToValidate = ['abbreviation']

    if (!this.isSky) {
      if (
        (this.team.englishOfficialName === undefined ||
        this.team.englishOfficialName === '') &&
        (this.team.spanishOfficialName === undefined ||
        this.team.spanishOfficialName === '')
      ) {
        fieldsToValidate.push('englishOfficialName')
        fieldsToValidate.push('spanishOfficialName')
      }
    } else {
      if (
        (this.team.englishOfficialName === undefined ||
        this.team.englishOfficialName=== '') &&
        (this.team.portugueseOfficialName=== undefined ||
        this.team.portugueseOfficialName === '')
      ) {
        fieldsToValidate.push('englishOfficialName')
        fieldsToValidate.push('portugueseOfficialName')
      }
    }
    
    fieldsToValidate.forEach((field) => {
      let fieldToValidate = this.team[field]
      if (typeof this.team[field] === 'object') {
        fieldToValidate = this.team[field]?.value
      }
      if (fieldToValidate === undefined || fieldToValidate === '') {
        const inputField = document.getElementById(field)
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling)
          }
          inValid.push(field)
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">Required field</div>'
          )
          inputField.classList.add('is-invalid')
        }
      }
    })
    return inValid.length === 0
    }

  showAlert(type, message) {
    this.isLoading = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  openModal(modalName, entity = null, field = null, filter = null, id = null) {
    if (field) {  
        this[entity] = Object.assign(
          {},
          this.team[field].find((data, index) => index === id)
        )
    }

    if (entity === 'externalRef') {
      this.externalRef.index = id
    }

    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  onImageError(event) {
    event.target.src = this.previewUrl;
  }

  gcd(a, b) {
    if (b == 0) return a
    return this.gcd(b, a % b)
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.image.imageFile = event.target.files[0]
      document.getElementById('inputLabel').innerText =
        this.image.imageFile.name
      let fr = new FileReader()
      fr.onload = () => {
        const img = new Image()
        img.onload = () => {
          this.image.height = img.height.toString()
          this.image.width = img.width

          if (this.team?.imageSizes !== undefined) {
            this.image.ratio = this.team.imageSizes[this.navImage - 1]
          } else {
            let gcd = this.gcd(this.image.width, this.image.height)
            let left = + this.image.height / gcd;
            this.image.ratio = this.image.width / gcd + ':' + left;

            this.team.images.push(this.image);
            const grouped = this.groupBy(this.team.images, 'ratio');
            this.team.imageSizes = Object.keys(grouped)
            this.team.teamImages = grouped
          }
        }

        img.src = fr.result.toString() // This is the data URL
        this.image.imageURL = fr.result.toString()
      }
      fr.readAsDataURL(event.target.files[0])
    }
  }


  deleteImage(image, index) {
    // remove from image list
    this.team.teamImages[image.ratio].splice(index, 1);

    const i = this.team.images.findIndex(img => {
      return img.imageFile && img.imageFile.name == image.imageFile.name
    })
    // remove from to save images
    this.team.images.splice(i, 1);

    if (!this.team.publishedImages) {
      this.team.publishedImages = [];
    }
    const currentPublishedIndex = this.team.publishedImages?.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    const pubImg = this.team.publishedImages[currentPublishedIndex]?.imageFile;
    if (pubImg && pubImg.name == image.imageFile.name) {
      //remove from published image
      this.team.publishedImages.splice(currentPublishedIndex, 1);
    }

  }

  isPublishedImage(storedImage, selectedImage) {
    const storedImageName = storedImage.imageFile?.name ? storedImage.imageFile?.name : storedImage.id;
    const selectedImageName = selectedImage.imageFile?.name ? selectedImage.imageFile?.name : selectedImage.id;

    return storedImage.id == selectedImage.id && storedImageName == selectedImageName;
  }

  publishedImage(image) {
    image.published = true

    if (!this.team.publishedImages) {
      this.team.publishedImages = [];
    }

    const currentPublishedIndex = this.team.publishedImages.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    currentPublishedIndex >= 0
      ? (this.team.publishedImages[currentPublishedIndex] = image)
      : this.team.publishedImages.push(image)
    this.team.teamImages[image.ratio] = (
      this.team.teamImages[image.ratio] as any
    ).map((each) => {

      return {
        ...each,
        published: this.isPublishedImage(each, image)
      }
    })

    if (image.id) {
      const alreadyPub = this.team.images.find(({ ratio, id }) => ratio === image.ratio && id !== null)
      if (alreadyPub) {
        this.team.images = this.team.images.filter(({ ratio, id }) => ratio !== image.ratio || id === "")
      }
      this.team.images.push(image)
    }
  }

  resetData() {
    this.team = this.teamService.teamDefault() as any
    this.oldTeam = JSON.stringify(this.teamService.teamDefault() as any)
  }

  // genreOnChange() {
  //   this.team.genreIds = this.team.genres.map(({id}) => id)
  // }

  venueOnChange(event) {
    this.team.venueId = event.id
  }

  updateTeam() {
    this.newTeam = Object.assign({}, this.team)
    // this.newTeam.genres = this.newTeam.genreIds
    this.newTeam.names = [{
      value: this.team.englishOfficialName,
      lang: this.CONSTANTS.EN,
      type: "officialName",
      isCurrent: true
    },
      {
        value: this.team.spanishOfficialName,
        lang: this.CONSTANTS.ES,
        type: "officialName",
        isCurrent: true
      },
      {
        value: this.team.portugueseOfficialName,
        lang: this.CONSTANTS.PT_BR,
        type: "officialName",
        isCurrent: true
      }
    ]
    const modal = this.confirmModalService.createModal({
      title: 'Confirm Change',
      message: `Are you sure you want to change this?`,
      isAlert: false,
      isConfirm: true,
    })
    modal.result.then((result) => {
      if (result) {
        this.isLoading = true
        this.teamService.save(this.newTeam).subscribe(data => {
          if (data) {
            this.showAlert('Success', `Successfully update team`)
          }
        })
      }
    })
  }

  getDefaultImage(images) {
    let imageBanner = this.CONSTANTS.IMAGE_BANNER
    const defaultImage = this.programService.getDefaultImage(images, null, null)
    if (defaultImage) {
      defaultImage.baseUrl = this.CONSTANTS.BASE_URL;
      imageBanner = `${defaultImage.baseUrl}${defaultImage.uri}`
    }
    this.team.defaultImage = imageBanner
  }


  createTeam() {
    this.newTeam = Object.assign({}, this.team)
    // this.newTeam.genres = this.newTeam.genreIds
    this.newTeam.names = [{
      value: this.team.englishOfficialName,
      lang: this.CONSTANTS.EN,
      type: "officialName",
      isCurrent: true
    },
      {
        value: this.team.spanishOfficialName,
        lang: this.CONSTANTS.ES,
        type: "officialName",
        isCurrent: true
      },
      {
        value: this.team.portugueseOfficialName,
        lang: this.CONSTANTS.PT_BR,
        type: "officialName",
        isCurrent: true
      }
    ]
      const modal = this.confirmModalService.createModal({
        title: 'Confirm Create',
        message: `Are you sure you want to create this team?`,
        isAlert: false,
        isConfirm: true,
      })
      modal.result.then(result => {
        if (result) {
          this.isLoading = true
          this.modalService.dismissAll()
          this.teamService.save(this.newTeam).subscribe((data: any) => {
            this.newTeam = data.response
            if (this.newTeam) {
              this.showAlert('Success', `Successfully create team`)
              this.team = data.response
              this.teamService.buildTeamInfo(this.team)
              this.oldTeam = JSON.stringify(this.team)
              this.newTeamCreated.emit(this.team.id)             
              this.teamService.getTeamImages(this.team.id).subscribe((data: any) => {
                if (data) {
                  let fetchedImages = []
                  this.team.images = []
                  this.team.imageSizes = this.programService.getRatio()
                  this.team.teamImages = {}
                  this.team.publishedImages = []
      
                  fetchedImages = (data.response || []).filter(image => image.downloadState === 'DOWNLOADED')
                  this.team.teamImages = this.groupBy(fetchedImages, 'ratio')
                  const publishedImages = fetchedImages.filter(teamImage => teamImage.published)
                  this.team.imageSizes.forEach(size => {
                    const publishRatio = publishedImages.find(({ ratio }) => ratio === size)
                    if (publishRatio) {
                      this.team.publishedImages.push(publishRatio)
                    }
                  })
                  this.getDefaultImage(fetchedImages)
                  this.team.images = data.response
                  this.oldTeam = JSON.stringify(this.team)
                  this.isLoading = false
                }
              })
            }
            else {
              this.showAlert('Error', `Failed to create team`)
            }
          }
          )
        }
      })
  }
}
