import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ScheduleReportService} from '../schedules-report.service';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-schedules-report',
  templateUrl: './schedules-report.component.html',
  styleUrls: ['./schedules-report.component.scss']
})
export class SchedulesReportComponent implements OnInit {
  currentDate = moment(Date.now()).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
  startDate: string = this.currentDate.toISOString()
  endDate: string = this.currentDate.add(13, "days").toISOString()
  searchString: string = ""
  showLoader: boolean = false
  activeStep: number = 1;
  source: any[] = [];
  target: any[] = [];
  qualifiers: Qualifier[] = []
  selectedQualifiers: Qualifier[] = []
  selectedChannelValues: string[] = [];
  reportChannels: ChannelName[] = [];
  selectAll = false;
  selectedChannels: ChannelName[] = [];

  constructor(
    private scheduleReportService: ScheduleReportService,
    private modalService: NgbModal
  ) {

  }


  ngOnInit(): void {
    this.initializeAvailableFields();
    this.initializeQualifiers();
    this.showLoader = false
    this.getReportChannels(this.searchString)
  }

  checkRequireFields() {
    return (this.startDate == null || this.endDate == null || this.selectedChannelValues?.length < 1);
  }

  getReportChannels(search: string) {
    this.showLoader = true;
    this.selectAll = false;
    this.reportChannels = [];
    this.updateSelectedChannels();
    this.scheduleReportService.getReportChannels(search).subscribe(res => {
      this.showLoader = false;
      res.forEach(item => {
        if (item.channelNames.length > 0) {
          const tmpChannel: ChannelName = item.channelNames[0];
          tmpChannel.id = item.id;
          this.reportChannels.push(tmpChannel);
        }
      });
    });
  }

  onChannelChecked(channel: any) {
    if (channel.isChecked) {
      this.selectedChannels.push(channel);
    } else {
      const index = this.selectedChannels.indexOf(channel);
      if (index > -1) {
        this.selectedChannels.splice(index, 1);
      }
    }
    this.updateSelectedChannels();
  }

  updateSelectedChannels() {
    this.selectedChannels = this.reportChannels.filter(channel => channel.isChecked);
    this.selectedChannelValues = this.selectedChannels?.map((channel) => channel.id);
  }

  toggleSelectAll() {
    this.reportChannels.forEach(channel => channel.isChecked = this.selectAll);
    if (this.selectAll == true) {
      this.selectedChannels = this.reportChannels;
    } else {
      this.selectedChannels = [];
    }
    this.updateSelectedChannels();
  }

  initializeAvailableFields() {
    this.scheduleReportService.getAvailableFields().subscribe(res => {
      this.source = res.availableFields;
      this.target = res.defaultFields;
      this.reorderLists();
    })
  }

  initializeQualifiers() {
    this.scheduleReportService.getQualifiers().subscribe(res => {
      this.qualifiers = res.response.map(item => {
        const newName = this.splitAndCapitalizeName(item.name.slice(12)); // Remove "epgQualifier" prefix
        return {...item, showName: newName};
      });
      const noneValue: Qualifier = {
        id: "none",
        name: "None",
        description: "none",
        showName: "None",
      };
      this.selectedQualifiers = this.qualifiers;
      this.selectedQualifiers.splice(0, 0, noneValue);
    });
  }

  onListChange() {
    this.reorderLists();
  }

  reorderLists() {
    this.source.sort((a, b) => a.order - b.order);
    this.target.sort((a, b) => a.order - b.order);
  }

  splitAndCapitalizeName(name: string): string {
    let processedName = name[0];
    const regex = /([A-Z])(?=[^A-Z])/g;
    processedName += name.slice(1).replace(regex, ' $1');
    return processedName;
  }

  onClickNext() {
    this.activeStep = 2;
  }

  onClickBack() {
    this.activeStep = 1;
  }

  onDateChange(isStartDate: boolean) {
    if (isStartDate) {
      this.startDate = moment(this.startDate).utcOffset(0, true).toISOString();
    } else {
      this.endDate = moment(this.endDate).utcOffset(0, true).toISOString();
    }
  }

  onClickExport() {
    this.showLoader = true;
    const qualifiers: string[] = this.selectedQualifiers.map((item) => item.name);
    const exportFields: string[] = this.target.map((item) => item.displayName);

    var request: ExportScheduleReportRequestDto = {
      startDate: this.startDate.split("T")[0],
      endDate: this.endDate.split("T")[0],
      qualifiers: qualifiers,
      channelIds: this.selectedChannelValues,
      exportFields: exportFields,
    }

    this.scheduleReportService.generateReport(request).subscribe({
      next: (response) => {
        this.showLoader = false
        const jsonString = JSON.stringify(response?.body)
        const blob = new Blob([jsonString], { type: 'application/json' })
        const fileName = response?.headers?.get('content-disposition')?.split(';')[1]
        this.downloadFile(blob, fileName)
        this.showAlert(AppConstants.SUCCESS, `File ${fileName} is generated successfully.`);
      },
      error: (error) => {
        this.showLoader = false;
        this.showAlert(AppConstants.ERROR, `Failed to generate the report.`);
        return
      },
    })
  }

  private downloadFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    // Clean up the temporary URL (optional, but recommended to avoid memory leaks)
    window.URL.revokeObjectURL(url);
  }

  showAlert(title: string, message: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isAlert = true;
    modalRef.result.then((res) => {
      if (res) {
        this.reset()
      }
    })
  }

  reset() {
    this.activeStep = 1
    this.searchString = ""
    this.showLoader = false
    this.currentDate = moment(Date.now()).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
    this.startDate = this.currentDate.toISOString()
    this.endDate = this.currentDate.add(13, "days").toISOString()
    this.initializeAvailableFields()
    this.initializeQualifiers()
    this.getReportChannels(this.searchString)
  }
}
