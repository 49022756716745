import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { UserService } from '../../users/users.service'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { Subject, Observable, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators'
import { ConfirmationModalService } from '../../../components/confirmation-modal/confirmation-modal.service'
import { AppConstants } from '../../../app.constants'
import { PlayersService } from '../players.service'
import { ProgramsService } from '../../programs/programs.service'

@Component({
  selector: 'app-player-form',
  templateUrl: './player-form.component.html',
  styleUrls: ['./player-form.component.scss'],
})
export class PlayerFormComponent implements OnInit, OnChanges {
  @Output() playerId: EventEmitter<string> = new EventEmitter()
  @Output() newPlayerCreated: EventEmitter<string> = new EventEmitter()
  @Input() player: Player
  @Input() oldPlayer: string

  imageCategories: string[]
  image: ProgramImage
  isLoading: boolean
  active: number
  activeCast: number
  navImage: number
  previewUrl: string
  selectedImageCategory = 'Iconic'
  isSky: boolean
  sportInfo: PlayerSportsInfo
  readonly CONSTANTS = AppConstants

  constructor(
    private programsService: ProgramsService,
    private playerService: PlayersService,
    private userService: UserService,
    private modalService: NgbModal,
    private confirmModalService: ConfirmationModalService
  ) { }

  ngOnInit() {
    this.isSky = location.href.includes('sky')
    this.imageCategories = this.programsService.getImageCategories()
    this.image = this.programsService.imageDefaults() as any
    this.previewUrl = this.CONSTANTS.IMAGE_BANNER
    this.sportInfo = this.playerService.sportInfoDefaults()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  resetDefaults() {
    /* Reset form validation */
    const invalidInputs = document.querySelectorAll('.is-invalid')
    invalidInputs.forEach((input) => input.classList.remove('is-invalid'))

    /* Reset tab default active */
    this.active = 1
    this.navImage = 1
    this.resetImage()
  }

  resetImage() {
    this.image = this.programsService.imageDefaults() as any
    if (document.getElementById('inputLabel')) {
      document.getElementById('inputLabel').innerText = ''
    }
  }

  setImageCategory(category: string) {
    this.selectedImageCategory = category
    this.image.category = category
  }

  cancel() {
    this.player = JSON.parse(this.oldPlayer)
    this.resetDefaults()
  }

  showHideInformation(field: string) {
    switch (field) {
      case 'isEnTitleHide':
        this.player.isEnTitleHide = !this.player.isEnTitleHide
        break
      case 'isPtEsTitleHide':
        this.player.isPtEsTitleHide = !this.player.isPtEsTitleHide
        break
      case 'isOtherTitleHide':
        this.player.isOtherTitleHide = !this.player.isOtherTitleHide
        break
    }
  }

  openModal(modalName, entity = null, field = null, filter = null, id = null) {
    if (field) {
      this[entity] = Object.assign(
        {},
        this.player[field].find((data, index) => index === id)
      )
    }

    if (entity === 'sportInfo') {
      this.sportInfo.index = id
    }

    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  openDeleteModal(field, filter, id) {
    const modal = this.confirmModalService.createModal({
      title: 'Confirm removal',
      message: `Are you sure you want to remove this?`,
      isAlert: false,
      isConfirm: true,
    })
    modal.result.then((res) => {
      if (res) {
        this.player[field] = this.player[field].filter(
          (data, index) => index !== id
        )
      }
    })
  }

  closeModal() {
    this.modalService.dismissAll()
    this.sportInfo = this.playerService.sportInfoDefaults()
  }

  addItem(fieldName, entity = null) {
    if (!this.player[fieldName]) {
      this.player[fieldName] = []
    }

    entity?.index != null
      ? (this.player[fieldName][entity.index] = entity)
      : this.player[fieldName].unshift(entity)

    this.closeModal()
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.image.imageFile = event.target.files[0]
      document.getElementById('inputLabel').innerText =
        this.image.imageFile.name
      let fr = new FileReader()
      fr.onload = () => {
        const img = new Image()
        img.onload = () => {
          this.image.height = img.height.toString()
          this.image.width = img.width

          if (this.player?.imageSizes !== undefined) {
            this.image.ratio = this.player.imageSizes[this.navImage - 1]
          } else {
            let gcd = this.gcd(this.image.width, this.image.height)
            let left = +this.image.height / gcd
            this.image.ratio = this.image.width / gcd + ':' + left

            this.player.images.push(this.image)
            const grouped = this.groupBy(this.player.images, 'ratio')
            this.player.imageSizes = Object.keys(grouped)
            this.player.groupedImages = grouped
          }
        }

        img.src = fr.result.toString() // This is the data URL
        this.image.imageURL = fr.result.toString()
      }
      fr.readAsDataURL(event.target.files[0])
    }
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ; (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  gcd(a, b) {
    if (b == 0) return a
    return this.gcd(b, a % b)
  }

  addImage(image: ProgramImage) {
    if (!this.player.images) {
      this.player.images = []
    }

    image.published = false
    this.player.images.push(image)
    if (this.player.groupedImages[image.ratio]) {
      this.player.groupedImages[image.ratio].unshift(image)
    } else {
      this.player.groupedImages = {
        ...this.player.groupedImages,
        [image.ratio]: [image],
      }
    }
    this.resetImage()
  }

  publishedImage(image: ProgramImage) {
    image.published = true

    if (!this.player.publishedImages) {
      this.player.publishedImages = []
    }

    if (!this.player.images) {
      this.player.images = []
    }

    const currentPublishedIndex = this.player.publishedImages.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    currentPublishedIndex >= 0
      ? (this.player.publishedImages[currentPublishedIndex] = image)
      : this.player.publishedImages.push(image)
    this.player.groupedImages[image.ratio] = (
      this.player.groupedImages[image.ratio] as any
    ).map((each) => {
      return {
        ...each,
        published: this.isPublishedImage(each, image),
      }
    })

    if (image.id) {
      const alreadyPub = this.player.images?.find(
        ({ ratio, id }) => ratio === image.ratio && id !== null
      )
      if (alreadyPub) {
        // VLS-2236: make sure new image always saved to db
        this.player.images = this.player.images?.filter(
          ({ ratio, id }) => ratio !== image.ratio || id === ''
        )
      }
      this.player.images.push(image)
    }
  }

  isPublishedImage(storedImage, selectedImage) {
    const storedImageName = storedImage.imageFile?.name
      ? storedImage.imageFile?.name
      : storedImage.id
    const selectedImagename = selectedImage.imageFile?.name
      ? selectedImage.imageFile?.name
      : selectedImage.id

    return (
      storedImage.id == selectedImage.id && storedImageName == selectedImagename
    )
  }

  onImageError(event) {
    event.target.src = this.previewUrl
  }

  deleteImage(image, index) {
    // remove from image list
    this.player.groupedImages[image.ratio].splice(index, 1)

    const i = this.player.images.findIndex((img) => {
      return img.imageFile && img.imageFile.name == image.imageFile.name
    })
    // remove from to save images
    this.player.images.splice(i, 1)

    if (!this.player.publishedImages) {
      this.player.publishedImages = []
    }
    const currentPublishedIndex = this.player.publishedImages.findIndex(
      ({ ratio }) => ratio === image.ratio
    )
    const pubImg = this.player.publishedImages[currentPublishedIndex]?.imageFile
    if (pubImg && pubImg.name == image.imageFile.name) {
      //remove from published image
      this.player.publishedImages.splice(currentPublishedIndex, 1)
    }
  }

  savePlayer() {
    if (JSON.stringify(this.player) === this.oldPlayer) {
      this.showAlert('Warning', `No changes`)
    } else {
      if (this.isValid()) {
        this.buildPlayerName()

        // build external id if available
        if (
          this.player.externalId !== undefined &&
          this.player.externalId !== ''
        ) {
          const index = this.player.externalRefs?.findIndex(
            (item) =>
              item.system === this.CONSTANTS.OPTA &&
              item.refName === this.CONSTANTS.OPTA_PLAYERID
          )
          if (index < 0 || index === undefined) {
            this.player.externalRefs = this.player.externalRefs || []
            // add new
            this.player.externalRefs?.push({
              system: this.CONSTANTS.OPTA,
              refName: this.CONSTANTS.OPTA_PLAYERID,
              id: this.player.externalId,
            })
          } else {
            // update new opta venue id
            this.player.externalRefs[index].id = this.player.externalId
          }
        } else {
          const index = this.player.externalRefs?.findIndex(
            (item) =>
              item.system === this.CONSTANTS.OPTA &&
              item.refName === this.CONSTANTS.OPTA_PLAYERID
          )
          if (index > -1) {
            this.player.externalRefs?.splice(index, 1)
          }
        }

        if (!this.player.id) {
          this.player.id = null
        }

        if (!this.player.vrioId) {
          this.player.vrioId = null
        }

        if (this.player.sportsInfo?.length === 0) {
          this.player.sportsInfo = null
        }

        this.player.dob = this.player.birthday ? `${this.player.birthday}T00:00:00.000Z` : null

        const modal = this.modalService.open(ConfirmationModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
        })
        modal.componentInstance.title = this.CONSTANTS.SUBMIT_CHANGES
        modal.componentInstance.message = this.CONSTANTS.MESSAGE_CONFIRM
        modal.componentInstance.isAlert = false
        modal.result.then((result) => {
          if (result) {
            this.isLoading = true
            this.playerService.save(this.player).subscribe(
              (data) => {
                this.showAlert('Success', `Successfully save player`)
                this.player.id = data?.response.id
                this.playerId.emit(data?.response.id)
                this.newPlayerCreated.emit(data?.response.id)
                this.isLoading = false
              },
              (error) => {
                this.showAlert('Error', error.error.errors[0])
              }
            )
          }
        })
      } else {
        // scroll top to see errors
        document.getElementById('playerForm').scrollTo(0, 0)
      }
    }
  }

  showAlert(type: string, message: string) {
    this.isLoading = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  isValid() {
    const inValid = []
    const fieldsToValidate = []

    if (!this.isSky) {
      if (
        (this.player.englishFirstName === undefined ||
          this.player.englishFirstName === '') &&
        (this.player.spanishFirstName === undefined ||
          this.player.spanishFirstName === '')
      ) {
        fieldsToValidate.push('englishFirstName')
        fieldsToValidate.push('spanishFirstName')
      }
    } else {
      if (
        (this.player.englishFirstName === undefined ||
          this.player.englishFirstName === '') &&
        (this.player.portugueseFirstName === undefined ||
          this.player.portugueseFirstName === '')
      ) {
        fieldsToValidate.push('englishFirstName')
        fieldsToValidate.push('portugueseFirstName')
      }
    }

    if (this.player.otherLanguage && this.player.otherFirstName === '') {
      fieldsToValidate.push('otherFirstName')
    }

    fieldsToValidate.forEach((field) => {
      let fieldToValidate = this.player[field]
      if (typeof this.player[field] === 'object') {
        fieldToValidate = this.player[field]?.value
      }
      if (fieldToValidate === undefined || fieldToValidate === '') {
        const inputField = document.getElementById(field)
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling)
          }
          inValid.push(field)
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">Required field</div>'
          )
          inputField.classList.add('is-invalid')
        }
      }
    })
    return inValid.length === 0
  }

  onFieldChange(event: any, field = this.CONSTANTS.EN, main = false) {
    this.player.englishFullName = this.buildFullName(this.CONSTANTS.INSENSITIVE_EN)
    if (this.isSky) {
      this.player.portugueseFullName = this.buildFullName(this.CONSTANTS.INSENSITIVE_PT)
    } else {
      this.player.spanishFullName = this.buildFullName(this.CONSTANTS.INSENSITIVE_ES)
    }
    this.player.otherFullName = this.buildFullName('other')

    const { id, value } = event.target
    if (value) {
      document.getElementById(id).classList.remove('is-invalid')
    }
    let lang = this.CONSTANTS.INSENSITIVE_EN

    if (main) {
      lang = this.isSky ? this.CONSTANTS.INSENSITIVE_PT : this.CONSTANTS.INSENSITIVE_ES
    }

    if (field !== this.CONSTANTS.EN) {
      const partnerId = lang.concat(field)
      if (document.getElementById(partnerId)) {
        document.getElementById(partnerId).classList.remove('is-invalid')
      }

      if (document.getElementById('otherFirstName')) {
        document.getElementById('otherFirstName').classList.remove('is-invalid')
      }
    }
  }

  buildFullName(field: string) {
    let firstName = ''
    let lastName = ''
    if (field === this.CONSTANTS.INSENSITIVE_EN) {
      firstName = this.player.englishFirstName || ''
      lastName = this.player.englishLastName || ''
    } else if (field === this.CONSTANTS.INSENSITIVE_PT) {
      firstName = this.player.portugueseFirstName || ''
      lastName = this.player.portugueseLastName || ''
    } else if (field === this.CONSTANTS.INSENSITIVE_ES) {
      firstName = this.player.spanishFirstName || ''
      lastName = this.player.spanishLastName || ''
    } else {
      firstName = this.player.otherFirstName || ''
      lastName = this.player.otherLastName || ''
    }
    return firstName + ' ' + lastName
  }

  buildPlayerName() {
    if (
      this.player.englishFullName !== undefined &&
      this.player.englishFullName.replace(' ', '') !== ''
    ) {
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.EN
      )
      if (index < 0) {
        // add new
        this.player.names?.push({
          firstName: this.player.englishFirstName || null,
          lastName: this.player.englishLastName || null,
          language: this.CONSTANTS.EN,
        })
      } else {
        // update new venue address
        this.player.names[index].firstName =
          this.player.englishFirstName || null
        this.player.names[index].lastName = this.player.englishLastName || null
      }
    } else {
      // remove name when value is unavailable
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.EN
      )
      if (index > -1) {
        this.player.names?.splice(index, 1)
      }
    }

    if (
      this.player.spanishFullName !== undefined &&
      this.player.spanishFullName.replace(' ', '') !== ''
    ) {
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.ES
      )
      if (index < 0) {
        // add new
        this.player.names?.push({
          firstName: this.player.spanishFirstName || null,
          lastName: this.player.spanishLastName || null,
          language: this.CONSTANTS.ES,
        })
      } else {
        // update new venue address
        this.player.names[index].firstName =
          this.player.spanishFirstName || null
        this.player.names[index].lastName = this.player.spanishLastName || null
      }
    } else {
      // remove name when value is unavailable
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.ES
      )
      if (index > -1) {
        this.player.names?.splice(index, 1)
      }
    }

    if (
      this.player.portugueseFullName !== undefined &&
      this.player.portugueseFullName.replace(' ', '') !== ''
    ) {
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.PT
      )
      if (index < 0) {
        // add new
        this.player.names?.push({
          firstName: this.player.portugueseFirstName || null,
          lastName: this.player.portugueseLastName || null,
          language: this.CONSTANTS.PT,
        })
      } else {
        // update new venue address
        this.player.names[index].firstName =
          this.player.portugueseFirstName || null
        this.player.names[index].lastName =
          this.player.portugueseLastName || null
      }
    } else {
      // remove name when value is unavailable
      const index = this.player.names?.findIndex(
        (item) => item.language === this.CONSTANTS.PT
      )
      if (index > -1) {
        this.player.names?.splice(index, 1)
      }
    }

    if (
      this.player.otherFullName !== undefined &&
      this.player.otherFullName.replace(' ', '') !== ''
    ) {
      const index = this.player.names?.findIndex(
        (item) => item.language === this.player.otherLanguage
      )
      this.player.names[index].firstName = this.player.otherFirstName || null
      this.player.names[index].lastName = this.player.otherLastName || null
    } else {
      // remove name when value is unavailable
      const index = this.player.names?.findIndex(
        (item) => item.language === this.player.otherLanguage
      )
      if (index > -1) {
        this.player.names?.splice(index, 1)
      }
    }
  }

  formatHeaderTitle(english, other) {
    const englishName = this.player[english]
    const otherName = this.player[other]
    return (
      englishName || otherName
    )
  }
}
