import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service'
import { ChannelManagementService } from '../channel-management.service'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { AppConstants } from 'src/app/app.constants'

@Component({
  selector: 'app-channel-master-slave-modal',
  templateUrl: './channel-master-slave-model.component.html',
  styleUrls: ['./channel-master-slave-model.component.scss'],
})
export class ChannelMasterSlaveModalComponent implements OnInit {
  @Input() channel: SourceChannel
  slaveChannels = [];
  selectedSlaves = []
  originLinkedSlaves = []

  showLoader: boolean = false
  isLoading: boolean = false
  constructor(
    private activeModal: NgbActiveModal,
    private confirmationModal: ConfirmationModalService,
    private serviceChannel: ChannelManagementService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.getSlaveChannels()
  }

  closeModal() {
    this.activeModal.dismiss()
  }

  submit() {
    let unLinkedSlaveIds = this.originLinkedSlaves
      ?.filter((channel) => this.selectedSlaves?.indexOf(channel) === -1)
      ?.map((channel) => channel.id)
    let newLinkedSlaveIds = this.selectedSlaves
      ?.filter((channel) => this.originLinkedSlaves?.indexOf(channel) === -1)
      ?.map((channel) => channel.id)

    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    modal.componentInstance.title = AppConstants.SUBMIT_REQUEST
    modal.componentInstance.message = AppConstants.MESSAGE_LINK_SLAVE_MODAL
    modal.componentInstance.isAlert = false
    modal.result.then((result) => {
      if (result) {
        this.showLoader = true
        this.serviceChannel.updateSlaveChannels(this.channel.id, unLinkedSlaveIds, newLinkedSlaveIds).subscribe(res => {
          if (!!res.response) {
            this.confirmationModal
              .createModal({
                title: AppConstants.ALERT,
                message: AppConstants.MESSAGE_LINKED_SLAVE_SUCCESS,
              })
              .result.then(() => {
                this.activeModal.close(this.channel)
              })
          }
        },  (error) => {
          this.showLoader = false
          if (!!error.errors) {
            this.confirmationModal.createModal({
              title: 'Error',
              message: error.errors[0],
            })
          } else {
            this.confirmationModal.createDefaultErrorMessage()
          }
        });
      }
    });
  }

  getSlaveChannels() {
    this.serviceChannel.getSlaveChannelsByMasterSource(this.channel.id).subscribe((response) => {
      this.getRegularChannels()
      this.originLinkedSlaves = response.response.map(x => (
        {
          id: x.id,
          name: x.sourceId + ' - ' + x.channelNames[0].value,
          sourceId: x.sourceId
        })
      )
      this.selectedSlaves = this.originLinkedSlaves
    })
  }
  
  getRegularChannels(){
    this.serviceChannel.getAllRegularChannel(this.channel.id).subscribe((response) => {
      this.slaveChannels = response.response.map(x => (
        {
          id: x.id,
          name: x.sourceId + ' - ' + x.channelNames[0]?.value,
          sourceId: x.sourceId
        })
      );
      this.slaveChannels.push(...this.selectedSlaves)
      this.isLoading = false
    }, (error)=> {
          this.isLoading = false
          if(!this.selectedSlaves.length){
            this.activeModal.close()
            if (!!error.error?.errors) {
              this.confirmationModal.createModal({
                title: 'Error',
                message: error.error?.errors[0],
              })
            } else {
              this.confirmationModal.createDefaultErrorMessage()
            }
          }
    });
  }
}
