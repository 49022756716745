import { CdkDragDrop } from '@angular/cdk/drag-drop'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationModalService } from 'src/app/components/confirmation-modal/confirmation-modal.service'
import { ChannelModalComponent } from '../channel-modal/channel-modal.component'
import { ChannelManagementService } from '../channel-management.service'
import * as moment from 'moment'
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component'
import { AUTO_STYLE } from '@angular/animations'
import { AppConstants } from 'src/app/app.constants'
import { PlatformService } from 'src/app/services/platform.service'
import { environment } from 'src/environments/environment'

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
  selector: 'app-channel-panel',
  templateUrl: './channel-panel.component.html',
  styleUrls: ['./channel-panel.component.scss'],
})
export class ChannelPanelComponent implements OnInit, OnChanges {
  @ViewChild('dateRangeModal') dateModal: NgbModal
  @ViewChild('daypartModal') dayPartModal: NgbModal
  @ViewChild('mappingModal') mappingModal: NgbModal
  @ViewChild('imageManager') imageManager: NgbModal
  @Input() channel: SourceChannel
  @Input() oldChannel: string
  @Output() refreshChannel: EventEmitter<string> = new EventEmitter()
  previewUrl: string
  scheduler: Scheduler
  providerMappings
  // temporary provider lang mappings for tabbing
  providerLangMapping: ProviderLanguageMapping[]
  regions: Region[]
  categories: ChannelCategory[]
  activeSchedule
  minDate
  isCreate = true
  showLoader
  maxDate
  isAddDaypart = false
  daypart: Daypart
  currentDaypart: number
  isInfoHiden = true;
  daypartIndex: number
  mappingIndex: number
  isLoading: boolean
  image: ProgramImage
  channelImages: ProgramImage[]
  publishedImage: ProgramImage
  newImages: ProgramImage[]
  savedImages: ProgramImage[]
  defaultTimeZone: string
  oldDayParts: any
  dayPartLanguages = location.href.includes('ssla') ? [ {display: 'Spanish', value: 'es'}, {display: 'English', value: 'en'} ] : [ {display: 'Portuguese', value: 'pt'}, {display: 'English', value: 'en'} ]

  readonly CONSTANTS = AppConstants

  constructor(
    private channelService: ChannelManagementService,
    private confirmationModal: ConfirmationModalService,
    private modalService: NgbModal,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    this.getDefaultConfig()
    this.previewUrl =
      'https://aptsoundtesting.co.uk/wp-content/uploads/2018/07/default_no_image-430x250.jpg'
    if (this.channel === undefined) {
      this.channel = this.createNewChannel() as any
    }
    this.daypartIndex = 0;
    this.mappingIndex = 0;
    this.isLoading = false;
    this.image = this.channelService.channelImageDeffault() as any
    this.publishedImage = null;
    this.newImages = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    // check when channel is first init and when it was changed
    if (
      changes.channel !== undefined &&
      changes.channel.currentValue !== undefined &&
      (changes.channel.previousValue === undefined ||
        changes.channel.currentValue.id !== changes.channel.previousValue?.id)
    ) {
      this.providerLangMapping = []
      let index = 0
      let i = 0
      // retrieves latest region information
      this.getRegions()
      // retrieves latest category information
      this.getCategories()

      this.minDate = moment().toDate()
      this.maxDate = moment().add(14, 'days').toDate()

      // retrieve serviceChannel information
      // changes.channel.currentValue.mappings.forEach((element) => {
      //   index += 1
      //   this.updateMappings({ ...element, editable: false, index })
      // })
    }
  }

  onDrop(
    event: CdkDragDrop<ProviderChannel[]>,
    daypartIndex,
    indexSchedule = null
  ) {
    if (this.isValidMapping(event.item.data)) {
      if (indexSchedule === null) {
        this.updateSchedule(daypartIndex, event.item.data)
        this.confirmMappings(false);
      } else if (this.channel.dayParts[daypartIndex]?.mappings) {
        event.item.data.startDate =
          this.channel.dayParts[daypartIndex].mappings[indexSchedule].startDate
        event.item.data.endDate =
          this.channel.dayParts[daypartIndex].mappings[indexSchedule].endDate
        this.updateSchedule(daypartIndex, event.item.data, true, indexSchedule);
        this.confirmMappings(false);
      }
    } else {
      this.showAlert(this.CONSTANTS.WARNING, `This ${this.channel?.channelTarget} channel cannot map with the ${event?.item?.data?.broadcast} provider channel ${event?.item?.data?.channelId}.`)
    }
  }

  isValidMapping(providerChannel) {
    return this.channel?.channelTarget?.toLowerCase() ==
      providerChannel?.broadcast?.toLowerCase() ||
      this.channel?.channelTarget?.toLowerCase() == this.CONSTANTS.BOTH ||
      providerChannel?.broadcast?.toLowerCase() == this.CONSTANTS.BOTH
      ? true
      : false
  }

  confirmMappings(isDaypart) {
    if (isDaypart) {
      if (moment(this.daypart.startDate).isAfter(this.maxDate)) {
        this.mappingErrorModal(
          `Unable to add mapping when start date is outside the guide.`
        )
      } else if (
        this.channel.dayParts &&
        ((this.isAddDaypart === true &&
          this.findBetweenSchedule(this.channel.dayParts, this.daypart)
            .length > 0) ||
          (this.isAddDaypart === false &&
            this.findBetweenSchedule(this.channel.dayParts, this.daypart)
              .length > 1))
      ) {
        if(this.channel.dayParts[0].startDate === undefined &&
          this.channel.dayParts[0].endDate === undefined) {
            this.updateDaypart(this.daypart)
        } else {
          this.mappingErrorModal(`Unable to add mapping when overlap date.`)
        }
      } else {
        this.updateDaypart(this.daypart)
        this.closeModal()
      }
    } else {
      const mappingEndDay = new Date(this.scheduler.endDate)

      const currentDaypartInfo = this.channel.dayParts[this.currentDaypart];
      const daypartEndDate = new Date(currentDaypartInfo.endDate);
      if (
        moment(this.scheduler.startDate).isBefore(currentDaypartInfo.startDate) ||
        moment(mappingEndDay).isAfter(daypartEndDate) ||
        moment(this.scheduler.startDate).isAfter(currentDaypartInfo.endDate)
      ) {
        this.mappingErrorModal(
          `Unable to add mapping when the date is outside the date of daypart.`
        )
      } else {
        this.updateMappings(this.scheduler)
        this.closeModal()
      }
    }
  }

  updateDaypart(element) {
    this.channel.dayParts = this.channel.dayParts ? this.channel.dayParts : []
    //update daypart
    if (!!element.index) {
      this.channel.dayParts[element.index] = element
    } else {
      //add daypart
      this.channel.dayParts.push(element)
    }
  }

  deleteDaypart(index) {
    this.confirmationModal
      .createModal({
        title: 'Delete',
        message: 'Are you sure you want to delete this mapping?',
        isAlert: false,
        isConfirm: true,
      })
      .result.then((res) => {
        if (!!res) {
          this.channel.dayParts = this.channel.dayParts?.filter(
            (data, i) => i !== index
          )
        }
      })
  }

  deleteMapping(event, daypartIndex, mappingIndex) {
    event.preventDefault()
    if (this.channel.dayParts[daypartIndex].mappings.length > 1) {
      let currentLang = this.channel.dayParts[daypartIndex].mappings[mappingIndex].lang
      let firstIndexWithLang = this.channel.dayParts[daypartIndex].mappings.findIndex(mapping => mapping.lang === currentLang) 
      if (firstIndexWithLang != -1 && firstIndexWithLang == mappingIndex) {
        let nextIndex = this.findNextMappingIndex(mappingIndex, this.channel.dayParts[daypartIndex].mappings)
        if (nextIndex != -1) {
          this.channel.dayParts[daypartIndex].mappings[nextIndex].startDate =
            this.channel.dayParts[daypartIndex].mappings[mappingIndex].startDate
        }
      } else {
        let prevIndex = this.findPreviousMappingIndex(mappingIndex, this.channel.dayParts[daypartIndex].mappings)
        if (prevIndex != -1) {
          this.channel.dayParts[daypartIndex].mappings[prevIndex].endDate =
            this.channel.dayParts[daypartIndex].mappings[mappingIndex].endDate
        }
      }
    }
    const updatedDaypart = this.channel.dayParts[
      daypartIndex
    ].mappings?.filter((data, i) => i !== mappingIndex)
    this.channel.dayParts[daypartIndex].mappings = updatedDaypart;
  }

  findNextMappingIndex(currentIndex, mappings) {
    // Get the language of the current index
    const currentLanguage = mappings[currentIndex].lang;
    
    // Loop through the array starting from the next index
    for (let i = currentIndex + 1; i < mappings.length; i++) {
      if (mappings[i].lang === currentLanguage) {
        return i; // Return the index of the next matching language
      }
    }
    
    // Return -1 if no next matching language is found
    return -1;
  }

  findPreviousMappingIndex(currentIndex, mappings) {
    // Get the language of the current index
    const currentLanguage = mappings[currentIndex].lang;
    
    // Loop through the array starting from the previous index
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (mappings[i].lang === currentLanguage) {
        return i; // Return the index of the previous matching language
      }
    }
    
    // Return -1 if no previous matching language is found
    return -1;
  }

  // update provider lang mappings
  updateMappings(element) {
    if (typeof element.startDate != 'string') {
      element.startDate = moment(element.startDate).format(DATE_FORMAT);
    }
    if (typeof element.endDate != 'string') {
      element.endDate = moment(element.endDate).format(DATE_FORMAT);
    }
    this.channel.dayParts[this.currentDaypart].mappings = this.channel.dayParts[this.currentDaypart].mappings || [];

    //add daypart
    if (this.isCreate) {
      //add through onDrop
      if (element.index !== null && element.index !== undefined) {
        this.adjust(element)
        this.channel.dayParts[this.currentDaypart].mappings[element.index] = element;
      } else {
        //add through +
        this.channel.dayParts[this.currentDaypart].mappings.push(element);
        element.index = this.channel.dayParts[this.currentDaypart].mappings.length - 1
        this.adjust(element)
      }
    } else {
      let elmAdj = { ...element }
      elmAdj.index = this.mappingIndex;

      this.channel.dayParts[this.currentDaypart].mappings[this.mappingIndex] = elmAdj;
      this.adjust(elmAdj)
    }
  }

  findBetweenSchedule(schedules: any[], element) {
    return schedules.filter(
      ({ startDate, endDate }) =>
        (moment(element.startDate).isAfter(startDate) &&
          moment(element.startDate).isBefore(endDate)) ||
        (moment(element.endDate).isAfter(startDate) &&
          moment(element.endDate).isBefore(endDate)) ||
        (moment(element.startDate).isAfter(startDate) &&
          moment(element.endDate).isBefore(endDate)) ||
        (moment(element.startDate).isBefore(startDate) &&
          moment(element.endDate).isAfter(endDate))
    )
  }

  sortSchedule() {
    return (a, b) => moment(b.startDate).second() - moment(a.startDate).second()
  }

  mappingErrorModal(message) {
    this.confirmationModal.createModal({
      title: 'Invalid schedule',
      message,
    })
  }

  openDaypart(daypart = null, isAddDaypart = true, index = null) {
    this.isAddDaypart = isAddDaypart
    if (daypart === null) {
      this.daypart = {
        id: null,
        index: null,
        startDate: moment().toDate(),
        endDate: moment().add(14, 'days').toDate(),
        mappings: [],
      }
    } else {
      this.daypart = {
        id: daypart.id ? daypart.id : null,
        index: index,
        startDate: daypart.startDate,
        endDate: daypart.endDate,
        mappings: daypart.mappings || [],
      }
    }
    this.modalService.open(this.dayPartModal, {
      size: 'md',
      centered: true,
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
    })
  }

  updateSchedule(
    indexDaypart = null,
    provider = null,
    isCreate = true,
    indexSchedule = null
  ) {
    if (provider?.startDate && typeof provider.startDate != 'string') {
      provider.startDate = moment(provider.startDate).format(DATE_FORMAT);
    }
    if (provider?.endDate && typeof provider.endDate != 'string') {
      provider.endDate = moment(provider.endDate).format(DATE_FORMAT);
    }

    if (this.scheduler?.startDate && typeof this.scheduler.startDate != 'string') {
      this.scheduler.startDate = moment(this.scheduler.startDate).format(DATE_FORMAT);
    }
    if (this.scheduler?.endDate && typeof this.scheduler.endDate != 'string') {
      this.scheduler.endDate = moment(this.scheduler.endDate).format(DATE_FORMAT);
    }

    this.currentDaypart = indexDaypart
    this.isCreate = isCreate
    if (provider == null) {
      if (
        this.channel.dayParts[this.currentDaypart].mappings &&
        this.channel.dayParts[this.currentDaypart].mappings.length > 0
      ) {
        let currentLength =
          this.channel.dayParts[this.currentDaypart].mappings.length
        this.scheduler = {
          startDate:
            this.channel.dayParts[this.currentDaypart].mappings[
              currentLength - 1
            ].endDate,
          endDate: this.channel.dayParts[this.currentDaypart].endDate,
        }
      } else {
        this.scheduler = {
          startDate: this.channel.dayParts[this.currentDaypart].startDate,
          endDate: this.channel.dayParts[this.currentDaypart].endDate,
        }
      }
      this.scheduler.lang = this.isSSLA() ? 'es' : 'pt';
    } else {
      let lang = this.scheduler?.lang;
      if (this.channel.dayParts[this.currentDaypart]?.mappings?.length == 0 || !lang) {
        lang = this.isSSLA() ? 'es' : 'pt';
      }
      
      if (!provider.startDate && !provider.startDate) {
        if (
          this.channel.dayParts[this.currentDaypart].mappings &&
          this.channel.dayParts[this.currentDaypart].mappings.length > 0
        ) {
          let currentLength = this.channel.dayParts[this.currentDaypart].mappings.length
          this.scheduler = {
            startDate: this.channel.dayParts[this.currentDaypart].mappings[currentLength - 1].endDate,
            endDate: this.channel.dayParts[this.currentDaypart].endDate,
          }
        } else {
          this.scheduler = {
            startDate: this.channel.dayParts[this.currentDaypart].startDate,
            endDate: this.channel.dayParts[this.currentDaypart].endDate,
          }
        }
      }
      this.scheduler = {
        index: indexSchedule,
        name: provider.name,
        prgSvcId: provider.channelId || provider.prgSvcId,
        lang: lang,
        startDate: provider.startDate || this.scheduler.startDate,
        endDate: provider.endDate || this.scheduler.endDate,
        editable: provider.editable || true,
      }
    }
  }

  openScheduler(
    indexDaypart = null,
    provider = null,
    isCreate = true,
    indexSchedule = null
  ) {
    this.updateSchedule(indexDaypart, provider, isCreate, indexSchedule);

    this.modalService.open(this.mappingModal, {
      size: 'md',
      centered: true,
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
    })

    this.oldDayParts = JSON.parse(JSON.stringify(this.channel.dayParts))
  }

  requestGuide() {
    const requestMapped = this.channel.dayParts[this.daypartIndex].mappings[this.mappingIndex]
    let endDate = moment(requestMapped.endDate).utcOffset(0, true).format().slice(0, 10);
    let startDate = moment(requestMapped.startDate).utcOffset(0, true).format().slice(0, 10);
    const sourceId = this.channel.sourceId;
    const currentDate = moment(new Date()).utcOffset(0, true).format().slice(0, 10);

    if(moment(startDate).isBefore(currentDate)) {
      startDate = moment(currentDate).utcOffset(0, true).format().slice(0, 10);
    }
    const newEndDate = moment(moment(startDate).add(14, 'days').toDate())
              .utcOffset(0, true).format().slice(0, 10);
    endDate = moment(endDate).isBefore(newEndDate) ? endDate : newEndDate;

    const title = 'Request schedules'
    if(requestMapped.prgSvcId !== undefined && requestMapped.prgSvcId !== null) {
      this.channelService
        .requestGuide(
          requestMapped.prgSvcId,
          startDate,
          endDate,
          sourceId,
        )
        .subscribe(
          (result) => {
            this.confirmationModal.createModal({
              title,
              message: 'Successfully requested 14-days guide!',
            })
          },
          (error) => {
            const errorMessages = error.error.errors // error messages service response
            if (errorMessages === undefined) {
              // in case cannot connect or callable backend, display default error message
              this.confirmationModal.createDefaultErrorMessage()
            } else {
              this.confirmationModal.createModal({ title, errorMessages })
            }
          }
        )
    } else {
      this.showAlert('Warning', 'No Mapped PrgSvcId');
    }

  }

  getDaypartIndex(daypartIndex, mappingIndex){
    this.daypartIndex = daypartIndex;
    this.mappingIndex = mappingIndex;
    this.scheduler = this.channel.dayParts[this.daypartIndex].mappings[this.mappingIndex];
  }

  deleteMappedService(daypartIndex, index) {
    this.channel.dayParts[daypartIndex].mappings[index].prgSvcId = null;
  }

  closeModal(isEditMapping = false) {
    if (isEditMapping == true) {
      this.channel.dayParts = this.oldDayParts
    }
    this.modalService.dismissAll()
  }

  createNewChannel() {
    return {
      id: '',
      name: '',
      channelTarget: 'OTT',
      channelSrc: 'VLS',
      lang: '',
      assignedTo: [],
      vcId: null,
      adult: false,
      published: false,
      processingType: 'AUTO',
      channelType: 'LINEAR',
      regions: [],
      region: '',
      label: '',
      labels: [],
      callSign: '',
      masterSource: '',
      serviceId: '',
      mappings: [],
      sourceId: '',
      channelNames: [],
      description: [],
      categories: [],
      category: '',
      dayParts:[{
        id: null,
        startDate: '',
        endDate: '',
        mapping: []
      }],
      dthInfo: {
        viewerNumber: null,
        masterSource: null,
        channelResolution: null,
        isSlave: null,
        isEngineering: false
      },
      timeZone: this.defaultTimeZone
    }
  }

  removeProvider(index) {
    this.channel.mappings.splice(index, 1)
    // this.providerLangMapping.schedule.
  }

  // Open create/update ServiceChannel modal
  openChannelFormModal(isCreate) {
    const modal = this.modalService.open(ChannelModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    if(!isCreate && this.channel.dayParts != undefined){
      this.channel.licenseStartDate = this.channel.dayParts[0].startDate;
      this.channel.licenseEndDate = this.channel.dayParts[0].endDate;
    }
    const channelRef = isCreate ? this.createNewChannel() : { ...this.channel }
    modal.componentInstance.isCreate = isCreate
    modal.componentInstance.channel = channelRef
    // modal.componentInstance.regions = this.regions
    // modal.componentInstance.categories = this.categories

    // update model only if action is update
    modal.result.then((res) => {
      this.refreshChannel.emit(res.sourceId)
    })
  }

  // checking when the schedule to be added will have an overlap with the present schedule
  checkAndFixScheduleOverlap() {
    // validate scheduler for overlaps
    // this.confirmationModal.createModal({
    //   title: 'Alert',
    //   message: 'Provider is already mapped!'
    // });
    return false
  }

  /**
   * Update Channel trigger field value.
   * Open confirmation popup and trigger other function
   *
   * @param field Status/Adult/Auto/Bubble
   * @param value true/false
   */
  updateField(field: string, value: boolean) {
    this.confirmationModal
      .createModal({
        title: `Trigger ${field}`,
        message: `Do you want to trigger ${field} for this channel?`,
        isAlert: false,
      })
      .result.then((result) => {
        if (result) {
          this.triggerChannel(field, value)
        }
      })
  }

  /**
   * Call backend to update channel
   *
   * @param field status/adult/auto/bubble
   * @param value true/false
   */
  triggerChannel(field: string, value: any) {
    const title = `Trigger ${field}`
    this.channelService.trigger(field, value, this.channel.id).subscribe(
      (result) => {
        const entityfield = this.getFieldName(field);
        value = entityfield === "processingType" ? field : value;

        this.channel[entityfield] = value // update filed value on UI if success

        // VLS-3672: Auto to Bubble should remove dayParts mappings
        if (field === "bubble") {
          this.channel.dayParts = result.response.dayParts;
        }

        //VLS-4208: Update isEngineering
        if(field === 'engineering'){
          this.channel.dthInfo.isEngineering = result.response?.dthInfo?.isEngineering
        }
        const message = 'Successfully updated'
        const errorMessages =
          result.errors !== undefined && result.errors.length > 0
            ? result.errors
            : []
        this.confirmationModal.createModal({ title, message, errorMessages })
      },
      (error) => {
        const errorMessages = error.error.errors // error messages service response
        if (errorMessages === undefined) {
          // in case cannot connect or callable backend, display default error message
          this.confirmationModal.createDefaultErrorMessage()
        } else {
          this.confirmationModal.createModal({ title, message: errorMessages })
        }
      }
    )
  }

  getCurrentPrgSvcId() {
    const prgSvcId = this.channel?.dayParts[this.daypartIndex]?.mappings[this.mappingIndex]?.prgSvcId;
    this.channel.prgSvcId = prgSvcId ? prgSvcId : '';
    this.channel.dayPartIndex = this.daypartIndex;
    this.channel.mappingIndex = this.mappingIndex;
  }

  /**
   * Call backend to update channel
   *
   * @param field status/adult/auto/bubble
   * @param value true/false
   */
  getFieldName(field: string) {
    if(field === 'auto' || field === 'bubble') {
      field = 'processingType'
    } else if (field === 'status') {
      field = 'published';
    }
    return field;
  }

  formateDate(){
    this.channel.dayParts.forEach( daypart => {
      daypart.endDate = moment(daypart.endDate).utcOffset(0, true).format(DATE_FORMAT);
      daypart.startDate = moment(daypart.startDate).utcOffset(0, true).format(DATE_FORMAT);

      (daypart?.mappings || []).forEach(mapping => {
        mapping.endDate = moment(mapping.endDate).utcOffset(0, true).format(DATE_FORMAT);
        mapping.startDate =moment(mapping.startDate).utcOffset(0, true).format(DATE_FORMAT);
      })
    })
  }

  /**
   * Save provider mapping
   */
  saveProviderMapping() {
    if(this.oldChannel !== JSON.stringify(this.channel)) {
      this.getCurrentPrgSvcId();
      this.formateDate();
      this.channelService.providerMapping(this.channel).subscribe(
        (result) => {
          if (result.response) {
            this.confirmationModal.createModal({
              title: 'Mapping Success',
              message: 'Successfully mapped providers!',
            })
          }

          this.refreshChannel.emit(this.channel.sourceId)
        },
        ({ error }) => {
          this.showLoader = false
          // system errors
          if (!!error.errors) {
            this.confirmationModal.createModal({
              title: 'Error',
              message: error.errors[0],
            })
          } else {
            this.confirmationModal.createDefaultErrorMessage()
          }
        }
      )
    } else {
      this.showAlert('Warning', 'No Changes');
    }
  }

  /**
   * Request channel's guide
   */
  requestChannelGuide() {
    const title = 'Request schedules'
    this.channelService
      .requestChannelGuide(
        this.channel.id,
        this.scheduler.startDate,
        this.scheduler.endDate
      )
      .subscribe(
        (result) => {
          this.confirmationModal.createModal({
            title,
            message: 'Successfully retrieved full 14-days guide!',
          })
        },
        (error) => {
          const errorMessages = error.error.errors // error messages service response
          if (errorMessages === undefined) {
            // in case cannot connect or callable backend, display default error message
            this.confirmationModal.createDefaultErrorMessage()
          } else {
            this.confirmationModal.createModal({ title, errorMessages })
          }
        }
      )
  }

  showHideInformation(){
    this.isInfoHiden = !this.isInfoHiden;
  }

  getRegions() {
    this.channelService.getRegions().subscribe(({ response }) => {
      this.regions = response
    })
  }

  getCategories() {
    this.channelService.getCategories().subscribe(({ response }) => {
      this.categories = response
    })
  }

  checkDateAfter(dateOne, dateTwo) {
    if (moment(dateOne).isAfter(dateTwo)) return true
    else return false
  }

  adjust(element) {
    let indexesToDelete = []
    let nextMapping = element
    for (let i = element.index; i >= 0; i--) {
      let mappingI = this.channel.dayParts[this.currentDaypart].mappings[i];
      if (element.lang != mappingI.lang) {
        continue;
      }
      
      if (moment(mappingI.startDate).isBefore(nextMapping.startDate)) {
        if (i != nextMapping.index) {
          mappingI.endDate = moment(nextMapping.startDate).add(-1, 'days').format(DATE_FORMAT)
        } else {
          mappingI.startDate = nextMapping.startDate
          if (i > 0) {
            mappingI.endDate = moment(nextMapping.startDate).add(-1, 'days').format(DATE_FORMAT)
          }
        }
        nextMapping = mappingI
      }

      if (moment(mappingI.startDate).isSameOrAfter(element.startDate) &&
          i != element.index) {
        // overlap -> delete
        indexesToDelete.push(i)
      }
    }

    for (
      let i = element.index;
      i < this.channel.dayParts[this.currentDaypart].mappings.length;
      i++
    ) {
      let mappingI = this.channel.dayParts[this.currentDaypart].mappings[i];
      if (element.lang != mappingI.lang) {
        continue;
      }
      if (
        moment(mappingI.startDate).isSameOrBefore(element.endDate) &&
        moment(mappingI.endDate).isAfter(element.endDate)
      ) {
        if (i != element.index) {
          mappingI.startDate = moment(element.endDate).add(1, 'days').format(DATE_FORMAT);
        } else {
          mappingI.endDate = element.endDate
          if (i < this.channel.dayParts[this.currentDaypart].mappings.length - 1) {
            const nextIndex = this.channel.dayParts[this.currentDaypart].mappings.findIndex((mapping) => mapping.index > element.index && mapping.lang == element.lang);
            if (nextIndex != -1) {
              this.channel.dayParts[this.currentDaypart].mappings[nextIndex].startDate = moment(element.endDate).add(1, 'days').format(DATE_FORMAT)
            }
          }
        }
      }

      const nextIndex = this.channel.dayParts[this.currentDaypart].mappings.findIndex((mapping) => mapping.index > element.index && mapping.lang == element.lang);
      if (nextIndex != -1) {
        let nextMapping = this.channel.dayParts[this.currentDaypart].mappings[nextIndex];
        if (moment(nextMapping.startDate).isAfter(moment(element.endDate).add(1, 'days'))) {
          nextMapping.startDate = moment(element.endDate).add(1, 'days').format(DATE_FORMAT);
        }
      }

      if (moment(mappingI.endDate).isSameOrBefore(element.endDate) && i != element.index) {
        // overlap -> delete
        indexesToDelete.push(i)
      }
    }

    this.channel.dayParts[this.currentDaypart].mappings = this.channel.dayParts[this.currentDaypart].mappings.filter((data, index) => !indexesToDelete.includes(index))
  }

  showAlert(type, message) {
    this.isLoading = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  openImageManager() {
    this.savedImages = [];
    this.newImages = [];
    this.publishedImage = null;
    this.channelImages = [];

    this.channelService.getAllChannelImages(this.channel.prgSvcId, this.channel.id)
      .subscribe((data: any) => {
        this.channelImages = data.response;

        if(this.channelImages) {
          this.channelImages.forEach((img) => {
            img.imageURL = `${img.baseUrl}${img.uri}`;
          });
        }
        this.channelImages = this.channelImages.filter(img => img.ratio === "4:3");
    })

    this.modalService.open(this.imageManager, {
      size: 'xl',
      centered: true,
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
    })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.image.imageFile = event.target.files[0]
      this.image.contentType = this.image.imageFile.type;
      this.image.downloadState = "DOWNLOADED";
      this.image.source = "VLS";
      this.image.referenceId = [this.channel.id];
      this.image.published = false;

      document.getElementById('inputLabel').innerText =
        this.image.imageFile.name
      let fr = new FileReader()
      fr.onload = () => {
          const img = new Image()
        img.onload = () => {
          this.image.height = img.height.toString()
          this.image.width = img.width
        }

        img.src = fr.result.toString() // This is the data URL
        this.image.imageURL = fr.result.toString()
      }
      fr.readAsDataURL(event.target.files[0])
    }
  }

  addImage(){
    if(this.image.imageFile) {
      this.channelImages.push(this.image);
      this.newImages.push(this.image);
      this.image = this.channelService.channelImageDeffault() as any
    }
  }

  onPublishedChange(image) {
    this.channelImages = this.channelImages.map((each) => {
      return {
        ...each,
        published: this.isPublishedImage(each, image)
      }
    });

    this.publishedImage = this.channelImages.filter(img => img.published)[0];
  }

  submitImages() {
    this.savedImages = [];

    if(this.publishedImage!== null && this.publishedImage.imageFile) {
      const imgFileName = this.publishedImage.imageFile.name;
      this.newImages.forEach(img => {
        const imgCopy = Object.assign({}, img)
        imgCopy.published = false;

        if(imgCopy.imageFile.name === imgFileName) {
          imgCopy.published = true;
        }
        this.savedImages.push(imgCopy);
      });
    } else {
      this.savedImages = this.savedImages.concat(this.newImages);
      if(this.publishedImage) {
        this.savedImages.push(this.publishedImage);
      }
    }

    const payload = {
      channelImages: this.savedImages,
      channelId: this.channel.id,
      prgSvcId: this.channel.prgSvcId
    }

    this.channelService.saveChannelImages(payload)
      .subscribe((data) => {
        if(data) {
          this.modalService.dismissAll();
          this.showAlert('Success', "Channels image updated successfully")

          this.channelService.getChannelImage(payload.channelId, payload.prgSvcId).subscribe(imgData => {
            const imageDetail = imgData.response;
            if (imageDetail.uri){
              this.channel.channelImage = `${imageDetail.baseUrl}${imageDetail.uri}`;
            } else {
              this.channel.channelImage = 'assets/images/noImage.jpg';
            }
          });

        }
      },(error) => {
        const errorMessages = error.error.errors
        this.showAlert('Error', errorMessages)
      });
  }

  isPublishedImage(storedImage, selectedImage) {
    const storedImageName = storedImage.imageFile?.name ? storedImage.imageFile?.name : storedImage.id;
    const selectedImagename = selectedImage.imageFile?.name ? selectedImage.imageFile?.name : selectedImage.id;

    return storedImage.id == selectedImage.id && storedImageName == selectedImagename;
  }

  onImageError(event) {
    event.target.src = this.previewUrl;
  }

  deleteImage(image, index) {
    this.channelImages.splice(index, 1);
    const i = this.newImages.findIndex(img => {
      return img.imageFile && img.imageFile.name == image.imageFile.name
    })
    this.newImages.splice(i, 1)
  }

  get isBubbleChannel() {
    return this.channel.processingType === 'BUBBLE';
  }

  compareDateRange(startDate, endDate) {
    const startMoment = startDate.isValid ? startDate : moment(startDate, DATE_FORMAT);
    const endMoment = endDate.isValid ? startDate : moment(endDate, DATE_FORMAT);

    if (startMoment.isValid() && endMoment.isValid()) {
      return startMoment.isAfter(endMoment);
    }
    return false;
  }

  get startDateValidation() {
    let hasError = false;
    if (this.scheduler) {
      hasError = this.compareDateRange(this.scheduler.startDate, this.scheduler.endDate)
    }

    return hasError ? "Start Date should be before End Date" : null;
  }

  get endDateValidation() {
    let hasError = false;
    if (this.scheduler) {
      hasError = this.compareDateRange(this.scheduler.startDate, this.scheduler.endDate)
    }

    return hasError ? "End Date should be after Start Date" : null;
  }

  isDthPlatform() {
    return this.platformService.verifyPlatform(AppConstants.DTH)
  }

  getDefaultConfig() {
    this.platformService.getDefaultConfigs().subscribe((data: any) => {
      if (data?.response) {
        this.defaultTimeZone = data?.response[AppConstants.DEFAULT_TIME_ZONE]
      } else {
        this.defaultTimeZone = environment.timezone
      }
    })
  }

  isSSLA() {
    return location.href.includes('ssla')
  }

  isDisabledStartDate(dayPartMapping) {
    const lang = dayPartMapping.lang;
    const currentDaypartInfo = this.channel.dayParts[this.currentDaypart];
    const langMappings = currentDaypartInfo.mappings.filter((item) => item.lang == lang);
    
    const langMappingIndex = langMappings.findIndex((item) => 
      item.startDate == dayPartMapping.startDate && item.endDate == dayPartMapping.endDate
    );
    if (this.isCreate && langMappings.length == 0) {
      this.scheduler.startDate = currentDaypartInfo.startDate;
      return true;
    }

    if (!this.isCreate && langMappingIndex == 0) {
      return true;
    }

    return false;
  }

  isDisabledEndDate(dayPartMapping) {
    const lang = dayPartMapping.lang;
    const langMappings = this.channel.dayParts[this.currentDaypart].mappings.filter((item) => item.lang == lang);
    
    const langMappingIndex = langMappings.findIndex((item) => 
      item.startDate == dayPartMapping.startDate && item.endDate == dayPartMapping.endDate
    );

    return !this.isCreate && langMappingIndex == langMappings.length - 1;
  }
}
